import { UserCircleIcon } from '@heroicons/react/solid'
import { LogoutIcon, GiftIcon } from '@heroicons/react/outline'

import Heading from 'components/ui/Heading'
import Link from 'components/ui/Link'
import Text from 'components/ui/Text'
import Card from 'components/ui/Card'
import Icon from 'components/ui/Icon'

import useAuth from 'hooks/useAuth'

import { APP_URLS } from 'urls'
import { formatMobilePhone } from 'utils/formaters'

export default function UserProfile() {
  const { user, signOut } = useAuth()

  return (
    <div className="flex flex-col w-full bg-gray-300">
      <div className="bg-pink-600 text-gray-100 p-4">
        <div className="max-w-screen-sm flex justify-between items-center mx-auto">
          <div className="flex space-x-4">
            <UserCircleIcon className="fill-current h-16" />
            <div className="flex flex-col">
              <Heading as="span" className="text-lg font-semibold">
                {user.name}
              </Heading>
              <Text as="span" className="text-sm font-normal text-pink-100">
                {user.mobilePhone && formatMobilePhone(user.mobilePhone)}
              </Text>
              <Text as="span" className="text-sm font-normal text-pink-100">
                {user.email}
              </Text>
            </div>
          </div>
          <LogoutIcon
            className="stroke-current h-8 cursor-pointer"
            onClick={signOut}
          />
        </div>
      </div>

      <div className="max-w-xl mx-auto text-center py-6 px-4">
        <Link to={APP_URLS.BILLING}>
          <Card className="p-4 rounded-md flex flex-col">
            <div
              className="rounded-full bg-pink-600 mx-auto block p-2 assina-shadow-sm"
              style={{ width: 'fit-content' }}
            >
              <Icon
                name="star"
                size={32}
                className="fill-current text-gray-100"
              />
            </div>

            <Heading className="text-gray-700 text-xl font-semibold my-4">
              Plano atual: <br />
              <Heading as="span" className="text-pink-600">
                Plano {user.isFree ? 'Gratuito' : 'Premium'}
              </Heading>
            </Heading>

            <Text className="text-gray-900">
              {user.isFree
                ? 'Com o plano gratuito você tem muitos benefícios. Clique aqui para detalhes da sua assinatura.'
                : 'Com o plano premium você tem benefícios exclusivos. Clique aqui para detalhes da sua assinatura.'}
            </Text>
          </Card>
        </Link>

        {!user.isFree && (
          <Link to={APP_URLS.HEALTH_CARD}>
            <Card className="flex space-x-4 p-4 rounded-md my-4">
              <Icon
                name="health-card"
                size={32}
                className="stroke-current text-pink-600"
              />
              <div className="text-left space-y-2">
                <Heading className="text-pink-600 font-semibold text-lg">
                  Carteirinha virtual
                </Heading>
                <Text className="text-gray-700">
                  Apresente sua carteirinha e tenha descontos em medicamentos
                  nas farmácias parceiras.
                </Text>
              </div>
            </Card>
          </Link>
        )}

        <Link to={APP_URLS.INVITE_FRIENDS}>
          <Card className="flex space-x-4 p-4 rounded-md mt-4">
            <div>
              <GiftIcon className="w-8 stroke-current text-pink-600" />
            </div>
            <div className="text-left space-y-2">
              <Heading className="text-pink-600 font-semibold text-lg">
                Indique e ganhe
              </Heading>
              <Text className="text-gray-700">
                Convide amigos para a Kompa para dar 90% de desconto e fique
                isento da sua próxima mensalidade.
              </Text>
            </div>
          </Card>
        </Link>
      </div>
    </div>
  )
}
