import cn from 'classnames'
import { SelectComponent as Select } from '../../ui'

import Textarea from '../../ui/Textarea'

const options = [
  'Hemograma',
  'Triglicerides',
  'Ureia',
  'TSH Ultra Sensível',
  'T4 Livre',
  'Colesterol Total e Frações',
  'Transaminase Glutamica Piruvica TGP',
  'Transaminase Glutamica Oxalacetica FGO',
  'Gama Glutamil Transferase',
  'Creatina',
  'Urina e Glicemia',
  'Outro'
]

const UploadFormExamType = props => {
  const isValid = () => {
    return props.examType !== ''
  }

  return (
    <div className="flex flex-col">
      <span className="text-base font-normal mt-5">Que tipo de exame?</span>
      <Select
        className={cn('mt-5', isValid() ? '' : 'border border-red-600 rounded')}
        color="gray"
        options={options}
        value={props.examType}
        onChange={e => props.setExamType(e.target.value)}
        classNameSelect="h-12 rounded shadow"
      />
      {props.examType === 'Outro' && (
        <>
          <Textarea
            placeholder="Especifique..."
            className={cn(
              'block mt-5 w-full border px-4 py-2, pr-8 rounded shadow leading-tight h-24 bg-gray-400 border-gray-400 focus:outline-none focus:shadow-outline'
            )}
            defaultValue={props.otherExam}
            onChange={e => props.setOtherExam(e.target.value)}
          />
          <p className="text-gray-900 font-medium text-sm mt-2">
            Para outros tipos de exame, é necessário especificar de qual se
            trata.
          </p>
        </>
      )}
    </div>
  )
}

export default UploadFormExamType
