import { Select, withStyles } from '@material-ui/core'

const KompaWhiteSelect = withStyles({
  root: {
    backgroundColor: '#FBFBFB',
    borderRadius: '4px',

    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',

    '&:hover': {
      backgroundColor: '#EAEAEA'
    },

    '&.MuiFilledInput-input': {
      padding: '14px 35px 11px 10px'
    }
  },
  select: {
    '&:focus': {
      borderRadius: '4px',
      backgroundColor: '#EAEAEA'
    }
  }
})(Select)

const KompaGraySelect = withStyles({
  root: {
    backgroundColor: '#F2F2F2',
    borderRadius: '4px',

    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',

    '&:hover': {
      backgroundColor: '#EAEAEA'
    },

    '&.MuiFilledInput-input': {
      padding: '14px 35px 11px 10px'
    }
  },
  select: {
    '&:focus': {
      borderRadius: '4px',
      backgroundColor: '#EAEAEA'
    }
  }
})(Select)

export { KompaWhiteSelect, KompaGraySelect }
