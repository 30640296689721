import { TextField, withStyles } from '@material-ui/core'

const baseStyles = {
  borderRadius: '4px',
  '& .MuiFilledInput-root:hover': {
    backgroundColor: '#EAEAEA'
  },
  '& .MuiFilledInput-root.Mui-focused': {
    backgroundColor: '#EAEAEA'
  },
  '& .MuiFilledInput-underline::before': {
    border: 'none',
    borderRadius: '4px'
  },
  '& .MuiFilledInput-input': {
    padding: '14px 10px 11px 10px'
  },
  '& .MuiFilledInput-multiline': {
    padding: '0'
  }
}

const KompaWhiteInput = withStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#FBFBFB',
      borderRadius: '4px'
    },
    ...baseStyles
  }
})(TextField)

const KompaGrayInput = withStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#F2F2F2',
      borderRadius: '4px'
    },
    ...baseStyles
  }
})(TextField)

export { KompaWhiteInput, KompaGrayInput }
