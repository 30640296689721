import Head from 'components/common/Head'
import { useState, useContext } from 'react'
import Component from '../../components/TelemedicineValues'
import { GlobalContext } from '../../contexts/GlobalContext'
import { SITE_URLS } from '../../urls'
import {
  valuesWithoutPlan,
  valuesWithPlan,
  optionsSpecialty,
  aboutSpecialty,
  removeCountryCode
} from '../../utils'

const TelemedicineValues = () => {
  const [selectValue, setSelectValue] = useState()
  const [about, setAboutSpecialty] = useState(null)
  const { user } = useContext(GlobalContext)

  const getValues = idx => {
    setAboutSpecialty({
      valueWithPlan: valuesWithPlan[idx],
      valueWithouPlan: valuesWithoutPlan[idx],
      name: optionsSpecialty[idx].label,
      info: aboutSpecialty[idx]
    })
  }

  const handleBooking = () => {
    const {
      id,
      name,
      cpf,
      birth,
      mobilePhone,
      email,
      isFree,
      memedToken,
      onboarding_done
    } = user

    const params = `id=${id}&name=${name}&cpf=${cpf}&birth=${birth}&phone=${removeCountryCode(
      mobilePhone
    )}&email=${email}&isFree=${isFree}&memedToken=${memedToken}&onboarding_done=${onboarding_done}&specialty=${
      about.name
    }`

    window.open(`${SITE_URLS.BOOK_APPOINTMENT}?${params}#/`, '_blank')
  }

  return (
    <>
      <Head title="Valores das consultas" />
      <Component
        options={optionsSpecialty}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        aboutSpecialty={about}
        getValues={getValues}
        handleBooking={handleBooking}
      />
    </>
  )
}

export default TelemedicineValues
