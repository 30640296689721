import {
  ChatAlt2Icon,
  PaperAirplaneIcon,
  VideoCameraIcon,
  PhoneIcon
} from '@heroicons/react/outline'
import cn from 'classnames'

import Head from 'components/common/Head'
import Button from 'components/ui/Button'
import Card from 'components/ui/Card'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import Link from 'components/ui/Link'

import doctorArray from 'components/HelpPage/doctorArray'

import useAuth from 'hooks/useAuth'

import { getFirstName } from 'utils/formaters'
import { emergencyCareUrl, openWhatsapp } from 'utils/urls'
import { SUPPORT_WHATSAPP } from 'utils/constants'
import { isWorkingHour } from 'utils'

export default function HelpPage() {
  const { user } = useAuth()

  const isCustomerServiceAvailable = isWorkingHour(new Date())

  return (
    <>
      <Head title="Ajuda" />
      <div className="flex flex-col w-full bg-gray-300">
        <div className="bg-pink-600 text-gray-100 px-6 pt-8 pb-16">
          <div className="max-w-screen-sm flex flex-col mx-auto">
            <div className="bg-pink-500 mr-auto block p-2 rounded-full">
              <ChatAlt2Icon className="h-12" />
            </div>
            <div className="space-y-2 mt-2">
              <Heading className="text-xl font-semibold">
                Olá, {getFirstName(user.name)}! <br /> Como podemos ajudar você?
              </Heading>
              <Text className="text-base">
                Fale com sua equipe de enfermagem e tire qualquer dúvida sobre
                saúde.
              </Text>
            </div>
          </div>
        </div>

        <div className="px-6 pb-6">
          <div className="max-w-screen-sm flex flex-col mx-auto space-y-4">
            <Card className="rounded-md -mt-10 p-4 space-y-6">
              <div>
                <Heading className="text-base text-gray-900">
                  Chat com sua equipe de saúde
                </Heading>
                <Text className="text-sm text-gray-700">
                  Geralmente respondem em alguns minutos
                </Text>
              </div>

              <div className="flex">
                {doctorArray.map((doctor, index) => (
                  <img
                    src={doctor}
                    key={doctor}
                    alt="Exemplo doutores"
                    className={cn(
                      'rounded-full assina-shadow-sm',
                      index !== 0 && '-ml-5'
                    )}
                  />
                ))}
              </div>

              <div>
                <Link
                  to={openWhatsapp(
                    SUPPORT_WHATSAPP,
                    'Olá, estou precisando de ajuda.'
                  )}
                  isExternal
                >
                  <Button className="font-semibold w-full flex justify-between px-4">
                    Envie uma mensagem
                    <PaperAirplaneIcon className="h-6 transform rotate-90" />
                  </Button>
                </Link>
              </div>
            </Card>

            {!user.isFree && (
              <Card className="rounded-md p-4 space-y-6">
                <div className="space-y-2">
                  <Heading className="text-base text-gray-900">
                    Atendimento imediato
                  </Heading>
                  <Text className="text-sm text-gray-700">
                    Escolha seu meio de contato preferido para falar com sua
                    equipe de saúde dedicada.{' '}
                    <Text as="span" className="text-pink-600">
                      De segunda à sexta (exceto feriados), das 8h às 18h.
                    </Text>
                  </Text>
                </div>

                <div>
                  <Link
                    to={emergencyCareUrl(String(user.id), user.name)}
                    isExternal
                  >
                    <Button className="font-semibold w-full flex justify-between px-4">
                      Vídeochamada
                      <VideoCameraIcon className="h-6" />
                    </Button>
                  </Link>
                </div>

                <div>
                  <Link
                    to={
                      isCustomerServiceAvailable
                        ? 'tel:+5501128426411'
                        : 'tel:+5508007710617'
                    }
                    isExternal
                  >
                    <Button
                      className="font-semibold w-full flex justify-between px-4"
                      color="outlined"
                    >
                      Telefone
                      <PhoneIcon className="h-6" />
                    </Button>
                  </Link>
                </div>
              </Card>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
