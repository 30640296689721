import Icon from '../../ui/Icon'
import cn from 'classnames'
import { Section } from '../../ui'

const WarningToast = ({ status, message }) => {
  return (
    <Section
      className={cn(
        'flex flex-row border-2 border-orange-300',
        'rounded bg-orange-500',
        'assina-shadow-sm',
        status ? 'block' : 'hidden'
      )}
      as="div"
    >
      <div className="ml-3 mt-3">
        <Icon name="close-square" size={26} />
      </div>
      <div className="text-lg text-white font-normal m-3 w-full">{message}</div>
    </Section>
  )
}

export default WarningToast
