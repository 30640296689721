import styled from 'styled-components'

const Backdrop = styled.div`
  background: #171717;
  opacity: 0.5;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  display: ${props => (props.openBackdrop ? 'flex' : 'none')};

  justify-content: center;
  align-items: center;
`

export default Backdrop
