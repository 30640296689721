import React, { useEffect, useState } from 'react'
import theme from 'styles/theme'

import Section from '../components/layouts/karmen2/Section'
import ImcChart from 'medicalRecord/components/layouts/karmen2/ImcChart'

import { SectionIds } from '../utils/karmenUtils'
import { ReactComponent as Attempiton } from 'assets/svg/attemption.svg'
import { ReactComponent as CheckGreen } from 'assets/svg/check-green.svg'
import { ReactComponent as TwoPerson } from 'assets/svg/two-person.svg'
import { ReactComponent as Xcircle } from 'assets/svg/x-circle.svg'

const Topic = ({
  icon,
  titleAttemption,
  titleOk,
  notFoundLabel,
  isNotAnswered,
  status,
  children
}) => {
  return (
    <div className="border-12 border-outline-karmen border-12 p-2 mt-5">
      {isNotAnswered ? (
        <p className="px-2 flex items-center gap-2 font-lato font-bold text-base text-color-h-emphase">
          <Xcircle width="18px" height="18px" />
          Não foram encontrados {notFoundLabel}
        </p>
      ) : (
        <>
          <p className="flex items-center gap-2 font-lato font-bold px-2 text-base text-color-h-emphase">
            {icon ? (
              icon
            ) : status ? (
              <Attempiton width="18px" height="18px" />
            ) : (
              <CheckGreen width="18px" height="18px" />
            )}
            {status ? titleAttemption : titleOk}
          </p>
          {status && (
            <>
              <div className="mt-2 w-full border-t-2 border-solid border-gray-500" />
              <div className="px-10 mt-2">{children}</div>
            </>
          )}
        </>
      )}
    </div>
  )
}

const ComponentKarmen2 = ({ karmen2Data }) => {
  const [imc, setImc] = useState(null)

  const getBooleanValue = value => {
    return value ? 'Sim' : 'Não'
  }

  const getStringValue = value => {
    return (
      <p
        className={`font-normal ${
          value ? 'text-new_green-500' : 'text-negative'
        }`}
      >
        {value ? 'Sim' : 'Não'}
      </p>
    )
  }

  const getFamilyHistoyValue = familyHistoy => {
    let value = ''
    let hasHistory = false
    familyHistoy.forEach(({ boolean, name }) => {
      if (boolean) {
        value += boolean ? `${hasHistory ? ', ' : ''}${name}` : ''
        hasHistory = true
      }
    })
    return value
  }

  useEffect(() => {
    if (karmen2Data && karmen2Data.userheight && karmen2Data.userweight) {
      let userheight = karmen2Data.userheight
      // Calcule if user height is int or float
      if (userheight % 1 === 0) {
        // transform user height int to float
        const hStr = userheight.toString()
        userheight = parseFloat(
          hStr.slice(0, 1) + '.' + hStr.slice(1, hStr.length)
        )
      }
      const imcValue = karmen2Data.userweight / (userheight * 2)
      if (0 < imcValue && imcValue <= 18.8) {
        setImc({
          value: imcValue,
          chartPercentage: 10,
          color: theme.colors.secondary
        })
      }
      if (18.8 < imcValue && imcValue <= 24.9) {
        setImc({
          value: imcValue,
          chartPercentage: 30,
          color: theme.colors.green3
        })
      }
      if (25.0 < imcValue && imcValue <= 29.9) {
        setImc({
          value: imcValue,
          chartPercentage: 50,
          color: theme.colors.yellow3
        })
      }
      if (30.0 < imcValue && imcValue <= 39.9) {
        setImc({
          value: imcValue,
          chartPercentage: 70,
          color: theme.colors.red2_5
        })
      }
      if (40.0 <= imcValue) {
        setImc({
          value: imcValue,
          chartPercentage: 90,
          color: theme.colors.red3
        })
      }
    }
  }, [karmen2Data])

  return (
    <>
      <Section
        id={SectionIds.anamnese}
        title="Anamnese Básica"
        className="border-12 border-outline-karmen"
      >
        <div className="w-full border-12 bg-pink-600 flex mt-4 pl-1">
          <div className="anamnese-container border-outline-karmen border-r-12 w-full flex p-5  justify-between">
            <div className="w-full">
              <div>
                <p className="text-base font-bold text-color-h-emphase">
                  Hospitalização nos ultimos 12 meses:
                </p>
                <p className="text-color-m-emphase font-lato text-base font-bold text-color-m-emphase">
                  {getBooleanValue(karmen2Data.hospitalizationstatus)}
                </p>
              </div>

              <div className="mt-4">
                <p className="text-base font-bold text-color-h-emphase">
                  Peso:
                </p>
                <p className="text-color-m-emphase font-lato text-base font-bold text-color-m-emphase">
                  {karmen2Data.userweight}kg
                </p>
              </div>

              <div className="mt-4">
                <p className="text-base font-bold text-color-h-emphase">
                  Antecedentes Familiares:
                </p>
                <p className="text-color-m-emphase font-lato text-base font-bold text-color-m-emphase">
                  {getFamilyHistoyValue([
                    {
                      boolean: karmen2Data.smokingfamilyhistory,
                      name: 'Cigarro'
                    },
                    {
                      boolean: karmen2Data.hypertensionfamilyhistory,
                      name: `Hipertensão`
                    },
                    {
                      boolean: karmen2Data.diabetesfamilyhistory,
                      name: 'Diabetes'
                    },
                    {
                      boolean: karmen2Data.alcoholfamilyhistory,
                      name: 'Alcoolismo'
                    }
                  ])}
                </p>
              </div>
            </div>

            <div className="w-full">
              <div>
                <p className="text-base font-bold text-color-h-emphase">
                  Causa da hospitalização:
                </p>
                <p className="text-color-m-emphase font-lato text-base font-bold text-color-m-emphase">
                  {karmen2Data.hospitalizationcause || '-'}
                </p>
              </div>

              <div className="mt-4">
                <p className="text-base font-bold text-color-h-emphase">
                  Altura:
                </p>
                <p className="text-color-m-emphase font-lato text-base font-bold text-color-m-emphase">
                  {karmen2Data.userheight}m
                </p>
              </div>
              <div className="mt-4">
                <p className="text-base font-bold text-color-h-emphase">
                  Idade:
                </p>
                <p className="text-color-m-emphase font-lato text-base font-bold text-color-m-emphase">
                  {karmen2Data.userage}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full rounded">
          {imc ? (
            <div className="mt-2 pt-4 w-full flex justify-between flex-col">
              <ImcChart
                imc={imc}
                colors={[
                  {
                    color: theme.colors.secondary,
                    label: 'Abaixo do peso'
                  },
                  {
                    color: theme.colors.green3,
                    label: 'Peso normal'
                  },
                  {
                    color: theme.colors.yellow3,
                    label: 'Sobrepeso'
                  },
                  {
                    color: theme.colors.red2_5,
                    label: 'Obesidade 1'
                  },
                  {
                    color: theme.colors.red3,
                    label: 'Obesidade 2'
                  }
                ]}
              />
            </div>
          ) : (
            '-'
          )}
        </div>
      </Section>

      <Section
        id={SectionIds.medicalHistory}
        title="Histórico médico"
        className="border-12 border-outline-karmen mt-4"
      >
        <div className="mt-4 flex flex-wrap text-color-m-emphase gap-3 font-lato font-bold text-sm ">
          <p className="flex items-center gap-2">
            <CheckGreen width="16px" height="16px" /> Ponto positivo à saúde
          </p>

          <p className="flex items-center gap-2">
            <Attempiton width="16px" height="16px" />
            Pontos de atenção à saúde
          </p>
          <p className="flex items-center gap-2">
            <Xcircle width="16px" height="16px" />
            Dados insuficientes
          </p>
        </div>

        <Topic
          icon={<TwoPerson width="18px" height="18px" />}
          titleAttemption="Relato do paciente"
          titleOk="Relato do paciente"
          notFoundLabel="relatos do paciente"
          status={karmen2Data.othercomplimentaryinfostatus}
          isNotAnswered={!karmen2Data.othercomplimentaryinfostatus}
        >
          {karmen2Data.othercomplimentaryinfo}
        </Topic>
        <div className="border-12 border-outline-karmen p-2 mt-5">
          <>
            <p className="flex items-center gap-2 font-lato font-bold px-2 text-base text-color-h-emphase">
              {!karmen2Data.exercisestatus ? (
                <Attempiton width="18px" height="18px" />
              ) : (
                <CheckGreen width="18px" height="18px" />
              )}
              {!karmen2Data.exercisestatus
                ? 'Não prática exercícios físicos'
                : 'Prática algum exercício físico'}
            </p>
            {karmen2Data.exercisestatus && (
              <>
                <div className="mt-2 w-full border-t-2 border-solid border-gray-500" />
                <div className="px-10 mt-2">
                  <p className="flex font-lato font-bold text-base text-color-h-emphase">
                    Tipo de exercício: &nbsp;
                    <p className="font-normal">{karmen2Data.exercisetype}</p>
                  </p>
                </div>
              </>
            )}
          </>
        </div>

        <div className="mt-4 flex gap-4">
          <div className="border-12 border-outline-karmen p-2 w-full ">
            <p className="flex items-center gap-2 font-lato font-bold px-2 text-base text-color-h-emphase">
              {karmen2Data.sleepqualitystatus ? (
                <>
                  <Attempiton width="18px" height="18px" />
                  Qualidade do sono RUIM
                </>
              ) : (
                <>
                  <CheckGreen width="18px" height="18px" />
                  Boa qualidade do sono
                </>
              )}
            </p>
          </div>
          <div className="border-outline-karmen border-12 p-2 w-full">
            <p className="flex items-center gap-2 font-lato font-bold px-2 text-base text-color-h-emphase">
              {karmen2Data.stresslevel ? (
                <>
                  <Attempiton width="18px" height="18px" />
                  Há presença de STRESS
                </>
              ) : (
                <>
                  <CheckGreen width="18px" height="18px" />
                  Não há presença de STRESS
                </>
              )}
            </p>
          </div>
        </div>

        <Topic
          status={karmen2Data.diabetesstatus}
          titleAttemption="Perfil de diabetes identificado"
          titleOk="Perfil de diabetes não identificado"
        >
          <div className="flex w-full justify-between">
            <div>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Histórico familiar: &nbsp;
                <p className="font-normal">
                  {getStringValue(karmen2Data.diabetesfamilyhistory)}
                </p>
              </p>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Acomp. Médico: &nbsp;
                <p className="font-normal">
                  {getStringValue(karmen2Data.diabetesmedicalfollowup)}
                </p>
              </p>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Hipoglicem. usados: &nbsp;
                {karmen2Data.diabetesmedicationname || '-'}
              </p>
            </div>
            <div>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Tipo: &nbsp;
                <p className="font-normal">{karmen2Data.diabetestype || '-'}</p>
              </p>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Acomp. Nutri: &nbsp;
                <p className="font-normal">
                  {getStringValue(karmen2Data.diabetesnutritionalfollowup)}
                </p>
              </p>
            </div>

            <div>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Alterações de pele: &nbsp;
                <p className="font-normal">
                  {getStringValue(karmen2Data.diabetesskinalteration)}
                </p>
              </p>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Uso de medicação: &nbsp;
                <p className="font-normal">
                  {getStringValue(karmen2Data.diabetesmedicationstatus)}
                </p>
              </p>
            </div>
          </div>
        </Topic>

        <Topic
          status={karmen2Data.hypertensionstatus}
          titleAttemption="Perfil de hipertensão identificado"
          titleOk="Perfil de hipertensão não identificado"
        >
          <div className="flex w-full justify-between">
            <div>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Histórico familiar: &nbsp;
                <p className="font-normal">
                  {getStringValue(karmen2Data.hypertensionfamilyhistory)}
                </p>
              </p>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Medicação usada: &nbsp;
                <p className="font-normal">
                  {karmen2Data.hypertensionmedicationname || '-'}
                </p>
              </p>
            </div>
            <p className="flex font-lato font-bold text-base text-color-h-emphase">
              Uso de medicação: &nbsp;
              <p className="font-normal">
                {getStringValue(karmen2Data.hypertensionmedicalfollowup)}
              </p>
            </p>
            <p className="flex font-lato font-bold text-base text-color-h-emphase">
              Uso de medicação: &nbsp;
              <p className="font-normal">
                {getStringValue(karmen2Data.hypertensionmedicationstatus)}
              </p>
            </p>
          </div>
        </Topic>
        <Topic
          status={karmen2Data.smokingstatus}
          titleAttemption="Perfil de tabagista identificado"
          titleOk="Perfil de tabagista não identificado"
        >
          <div className="flex w-full justify-between">
            <div>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Perfil tabagista: &nbsp;
                <p className="font-normal">
                  {getStringValue(karmen2Data.smokingprofile)}
                </p>
              </p>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Nível de dependencia: &nbsp;
                <p className="font-normal">
                  {karmen2Data.smokingaddictionlevel || '-'}
                </p>
              </p>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Histórico familiar: &nbsp;
                {getStringValue(karmen2Data.smokingfamilyhistory)}
              </p>
            </div>

            <div>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Ano de inicio: &nbsp;
                {karmen2Data.smokinginitialyear || '-'}
              </p>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Cigarros por dia: &nbsp;
                <p className="font-normal">
                  {karmen2Data.smokingcigarettesperday || '-'}
                </p>
              </p>
            </div>

            <div>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Parou em: &nbsp;
                <p className="font-normal">
                  {karmen2Data.smokingfinalyear || '-'}
                </p>
              </p>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Dific. em parar de fumar: &nbsp;
                <p className="font-normal">
                  {karmen2Data.smokingquittingdifficulty || '-'}
                </p>
              </p>
            </div>
          </div>
        </Topic>

        <Topic
          status={karmen2Data.alcoholconsumptionstatus}
          titleAttemption="Perfil de alcoolismo identificado"
          titleOk="Perfil de alcoolismo não identificado"
        >
          <div className="flex w-full justify-between">
            <p className="flex font-lato font-bold text-base text-color-h-emphase">
              Histórico familiar: &nbsp;
              <p className="font-normal">
                {getStringValue(karmen2Data.smokingquittingdifficulty)}
              </p>
            </p>
            <p className="flex font-lato font-bold text-base text-color-h-emphase">
              Dific. em parar de fumar: &nbsp;
              <p className="font-normal">
                {karmen2Data.alcoholconsumptiondosage || '-'}
              </p>
            </p>
          </div>
        </Topic>

        <Topic
          status={karmen2Data.mentaldisorderstatus}
          titleAttemption="Transtornos mentais identificado"
          titleOk="Transtornos mentais não identificado"
        >
          <div className="flex w-full justify-between">
            <div>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Histórico familiar: &nbsp;
                <p className="font-normal">
                  {getStringValue(karmen2Data.mentaldisordermedicalfollowup)}
                </p>
              </p>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Transtorno: &nbsp;
                <p className="font-normal">
                  {karmen2Data.mentaldisordername || '-'}
                </p>
              </p>
            </div>
            <div>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Uso de medicação: &nbsp;
                <p className="font-normal">
                  {getStringValue(karmen2Data.mentaldisordermedicationstatus)}
                </p>
              </p>
              <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Medicação: &nbsp;
                <p className="font-normal">
                  {karmen2Data.mentaldisordermedicationname || '-'}
                </p>
              </p>
            </div>
          </div>
        </Topic>

        <Topic
          status={karmen2Data.painstatus}
          titleAttemption="Dores relatadas"
          titleOk="Sem dores"
        >
          <div className="w-full justify-between">
            <p className="flex font-lato font-bold text-base text-color-h-emphase">
              Tipo: &nbsp;
              <p className="font-normal">{karmen2Data.paintype || '-'}</p>
            </p>
            <p className="flex font-lato font-bold text-base text-color-h-emphase">
              Local: &nbsp;
              <p className="font-normal">{karmen2Data.painlocation || '-'}</p>
            </p>
          </div>
        </Topic>
        <Topic
          status={karmen2Data.allergystatus}
          titleAttemption="Alergias relatadas"
          titleOk="Sem Alergias"
        >
          <div className="w-full justify-between">
            {/* <p className="flex font-lato font-bold text-base text-color-h-emphase">
                Tipo: &nbsp;
                <p className="font-normal">{karmen2Data.paintype || '-'}</p>
              </p> */}
          </div>
        </Topic>
      </Section>
    </>
  )
}

export default ComponentKarmen2
