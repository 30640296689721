import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: 25px;
  background: ${({ theme }) => theme.colors.white1};
  display: flex;
  ${({ theme }) => theme.mixins.full_flex}

  width: 100%;
  max-width: 850px;
`

export const Title = styled.div`
  display: flex;

  h2 {
    max-width: 140px;
  }

  @media (min-width: 426px) {
    h2 {
      max-width: 100%;
    }
  }
`

export const DeskProgress = styled.div`
  height: 130px;
  width: 130px;
  position: relative;

  display: none;

  margin-right: 30px;

  h1 {
    font-weight: 600;
    font-size: 21px;
    color: ${({ theme }) => theme.colors.teal3};

    position: absolute;
    top: 37%;
    left: 28%;

    pointer-events: none;
    cursor: default;
  }

  @media (min-width: 426px) {
    display: block;
  }
`

export const MobiProgress = styled.div`
  height: 100px;
  width: 100px;
  position: relative;

  h1 {
    font-weight: 600;
    font-size: 21px;
    color: ${({ theme }) => theme.colors.teal3};

    position: absolute;
    top: 35%;
    left: 23%;

    pointer-events: none;
    cursor: default;
  }

  @media (min-width: 426px) {
    display: none;
  }
`

export const Button = styled.button`
  font-family: Titillium Web;
  font-weight: 600;

  background: ${({ theme }) => theme.colors.primaryPink3};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.gray6};

  padding: 8px 20px;
  font-size: 20px;

  width: 100%;
  max-width: 185px;

  user-select: none;

  ${({ success, theme }) =>
    success &&
    css`
      color: ${theme.colors.white1};
      border: 2px solid ${theme.colors.green3};
      background: ${theme.colors.green3};
    `}
`

export const Diretionals = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    ${({ first, theme }) =>
      first &&
      css`
        fill: ${theme.colors.gray5};
      `}

    ${({ last, theme }) =>
      last &&
      css`
        fill: ${theme.colors.gray5};
      `}

        fill: ${({ first, last, theme }) => {
      switch (true) {
        case first:
          return ` ${theme.colors.gray4}`
        case last:
          return `${theme.colors.gray4}`
        default:
          return `${theme.colors.primaryPink3}`
      }
    }};
  }
`
