/* eslint-disable react/jsx-pascal-case */
import Slider_ from 'rc-slider'
import 'rc-slider/assets/index.css'
import styled from 'styled-components'

const SliderContainer = styled.div`
  .rc-slider-handle {
    background-color: #d20e50;
    width: 24px;
    height: 24px;
    border: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    margin-top: -9px;
  }

  .rc-slider-rail {
    background-color: #4db1b1;
    height: 6;
  }

  .rc-slider-track {
    background-color: #4db1b1;
    height: 6;
  }
`

export default function Slider({ value, onChange, ...rest }) {
  return (
    <SliderContainer>
      <Slider_ max={300} value={value} onChange={onChange} {...rest} />
    </SliderContainer>
  )
}
