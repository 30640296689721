/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useContext } from 'react'

import { APIContext } from '../../config/contexts/APIContext'

import Button from 'components/ui/Button'
import AppIcon from 'components/ui/Icon'
import { ReactModal as Modal } from 'components/ui/Modal'

import { Loader } from '../elements'
import { Nav } from '.'

import useMedicalRecord from 'hooks/useMedicalRecord'

// Utils
import { formatFullAge, formatMobilePhone } from 'utils/formaters'
import { formatComplete } from 'commons/utils/utils'

const Header = ({ label }) => {
  const { postForm, loadingFinishedForm, absentPatient } =
    useContext(APIContext)

  const { appointment, patient } = useMedicalRecord()

  const [openFinishModal, setOpenFinishModal] = useState(false)

  const name = patient ? patient.nome : appointment.paciente.nome

  const age = formatFullAge(
    patient ? patient.data_nascimento : appointment.paciente.data_nascimento
  )

  const patient_ = patient || appointment.paciente

  const mobilePhone = patient
    ? patient.celular
      ? formatMobilePhone(patient.celular)
      : 'Não informado'
    : appointment.paciente.celular
    ? formatMobilePhone(appointment.paciente.celular)
    : 'Não informado'

  return (
    <header className="header rounded-md assina-shadow-sm w-64">
      <Modal
        isOpen={openFinishModal}
        onRequestClose={() => setOpenFinishModal(false)}
        contentLabel="finalizar atendimento"
      >
        <div className="modal-header">
          <h1>Finalizar atendimento</h1>
        </div>
        <div className="modal-body">
          <p>
            Ao finalizar o atendimento, você não poderá alterar o prontuário
            novamente e a chamada de vídeo será encerrada. <br /> Deseja
            prosseguir?
          </p>
        </div>
        <footer className="modal-footer">
          <Button
            type="button"
            className="button-back"
            color="white"
            onClick={() => setOpenFinishModal(false)}
          >
            Voltar
          </Button>
          <Button
            type="button"
            onClick={() => {
              postForm()
              setOpenFinishModal(false)
            }}
            className="modal-button"
            color="primary"
          >
            Finalizar
          </Button>
        </footer>
      </Modal>

      <div className="p-5 space-y-6 w-64">
        <div className="header-menu">
          <h1 className="text-teal-600 font-semibold text-lg">{label}</h1>
        </div>

        <div className="space-y-2">
          <h2 className="text-lg text-pink-600 font-semibold">{name}</h2>
          <p className="text-base font-semibold text-gray-900">
            Idade: <span className="font-normal">{age}</span>
          </p>
          <p className="text-base font-semibold text-gray-900">
            Celular: <span className="font-normal">{mobilePhone}</span>
          </p>

          <p className="text-base font-semibold text-gray-900">
            CPF: <span className="font-normal">{patient_.cpf}</span>
          </p>

          <p className="text-base font-semibold text-gray-900">
            Endereço:{' '}
            <span className="font-normal">
              {formatComplete({
                route: patient_.logradouro || '',
                street_number: patient_.numero || '',
                complement: patient_.complemento || '',
                political: patient_.bairro || '',
                postal_code: patient_.cep || '',
                city: patient_.cidade || ''
              })}
            </span>
          </p>
        </div>

        {appointment && (
          <div className="pt-6 border-t-2 border-gray-400">
            <ul className="text-lg font-semibold text-gray-900 space-y-4">
              <li>
                <h3>Principal Queixa:</h3>
                <span className="font-normal">
                  {!appointment.principal_queixa
                    ? 'Não informado'
                    : appointment.principal_queixa.nome}
                </span>
              </li>
              <li>
                <h3>Sintomas e observações:</h3>
                <span className="font-normal">
                  {!appointment.queixa ? 'Não informado' : appointment.queixa}
                </span>
              </li>
            </ul>
          </div>
        )}

        <div className="space-y-4 text-base">
          <Button onClick={postForm} className="w-full">
            {loadingFinishedForm ? (
              <Loader
                type="Oval"
                color="#a4a4a4"
                height={32}
                width={32}
                timeout={60000}
              />
            ) : (
              <>
                <AppIcon
                  name="userSave"
                  size={18}
                  className="fill-current text-gray-300 mr-2"
                />
                {patient ? 'Salvar prontuario' : 'Concluir atendimento'}
              </>
            )}
          </Button>

          {!patient && (
            <Button onClick={absentPatient} color="outlined" className="w-full">
              <AppIcon
                name="userError"
                size={18}
                className="fill-current text-pink-600 mr-2"
              />
              Paciente não acessou
            </Button>
          )}
        </div>
      </div>
      <Nav />
    </header>
  )
}

export default Header
