/* eslint-disable react/jsx-pascal-case */

const Layout = ({ title, className, children, ...props }) => {
  return (
    <div className={`bg-white p-3 rounded-lg ${className}`} {...props}>
      {title ? (
        <h1 className="font-semibold text-xl text-gray-900">{title}</h1>
      ) : null}
      {children}
    </div>
  )
}

export default Layout
