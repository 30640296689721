import { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import Scrollbar from 'react-custom-scrollbars'

import { ReactComponent as CalendarSvg } from '../../../assets/svg/calendarBorder.svg'
import SelectStyles from '../../../commons/utils/SelectStyles'
import { Check } from '../../ui'
import { generateJWT, getToken } from '../../../commons/utils/utils'
import { SITE } from '../../../urls'

const updatedSelectStyles = {
  ...SelectStyles,
  control: styles => ({
    ...styles,
    backgroundColor: '#ffffff',
    fontWeight: 500,
    border: 0,
    boxShadow: 'none',
    svg: { fill: '#D20E50' }
  })
}

const customSelectTheme = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: '#F2B7CB',
    primary: '#D20E50'
  }
})

const options = [
  {
    label: 'Fatura mensal',
    value: 'MONTHLY'
  },
  {
    label: 'Fatura semestral',
    value: 'BIANNUALLY'
  },
  {
    label: 'Fatura anual',
    value: 'YEARLY'
  }
]

const Component = ({
  className,
  setUser,
  user,
  setRecurrence,
  recurrence,
  price,
  planId
}) => (
  <Container className={`flex flex-col items-center p-5 ${className}`}>
    <h1 className="font-semibold">Plano Premium</h1>
    <div className="mt-6 w-full flex border-2 border-white rounded-md h-12 cursor-pointer">
      <Button
        onClick={() => setUser('forMy')}
        user={user}
        valueSelect="forMy"
        className="w-full"
      >
        Para mim
      </Button>
      <Button
        onClick={() => setUser('forFamily')}
        user={user}
        valueSelect="forFamily"
        className="w-full"
      >
        Para minha familia
      </Button>
    </div>
    <div
      htmlFor="select-plan-id"
      className="bg-white flex items-center px-4 w-full mt-6 rounded-md"
    >
      <CalendarSvg width="25" height="25" />
      <ReactSelect
        options={options}
        className="select w-full bg-transparent text-black select-appearance-none"
        styles={updatedSelectStyles}
        placeholder="Selecione..."
        onChange={evt => {
          setRecurrence(evt)
        }}
        value={recurrence}
        components={{
          NoOptionsMessage: () => 'Nenhuma opção encontrada',
          LoadingMessage: () => 'Carregando',
          IndicatorSeparator: () => null
        }}
        theme={customSelectTheme}
        isSearchable={false}
      />
    </div>
    <div className="bg-white mt-6 rounded-md w-full py-4 px-6 flex items-center flex-col">
      <h1 className="font-medium text-pink-600 text-4xl">
        R$ {String(price.toFixed(2)).replace('.', ',')}{' '}
        {recurrence.value === 'MONTHLY' && (
          <span className="text-lg text-gray-900 ml-2">/mês</span>
        )}
      </h1>
      <a
        href={`${SITE}/assinar?plan_id=${planId}&auth=${generateJWT({
          token: getToken('api_token')
        })}#/`}
        type="button"
        target="_blank"
        rel="noreferrer noopener"
        className="mt-8 bg-pink-600 hover:bg-pink-700 text-white py-2 cursor-pointer rounded-md text-center flex items-center justify-center font-medium tracking-widest focus:outline-none text-base px-8 w-full"
      >
        Ir para pagamento
      </a>
    </div>
    <div className="text-white mt-6 w-full">
      <h2 className="font-medium text-xl text-center">
        Todos os benefícios do Plano <br /> Gratuito e mais:
      </h2>
      <Check colorSvg="white">Apoio médico online 24h por dia</Check>
      <Check colorSvg="white">Manutenção odontológica gratuita</Check>
      <Check colorSvg="white">Consultas médicas a partir de R$ 19,00</Check>
      <Check colorSvg="white">Desconto de até 60% em exames e cirurgias</Check>
      <Check colorSvg="white">
        Desconto de até 50% nas principais farmácias
      </Check>
    </div>
  </Container>
)

const PremiumCard = ({ className, full }) => {
  const [user, setUser] = useState('forMy')
  const [recurrence, setRecurrence] = useState({
    label: 'Fatura mensal',
    value: 'MONTHLY'
  })
  const [price, setPrice] = useState(49.9)
  const [planId, setPlanId] = useState('136789')

  useEffect(() => {
    switch (recurrence.value) {
      case 'MONTHLY':
        if (user === 'forMy') {
          setPrice(49.9)
          setPlanId('136789')
        } else {
          setPrice(79.9)
          setPlanId('136791')
        }
        break
      case 'YEARLY':
        if (user === 'forMy') {
          setPrice(468.0)
          setPlanId('138269')
        } else {
          setPrice(767.04)
          setPlanId('138284')
        }
        break
      default:
        if (user === 'forMy') {
          setPrice(270.0)
          setPlanId('138211')
        } else {
          setPrice(431.52)
          setPlanId('138283')
        }
        break
    }
  }, [recurrence, user])

  return full ? (
    <Component
      className={className}
      setUser={setUser}
      setRecurrence={setRecurrence}
      price={price}
      planId={planId}
      user={user}
      recurrence={recurrence}
    />
  ) : (
    <Scrollbar
      autoHeight
      autoHeightMin={500}
      autoHeightMax={window.innerHeight > 1000 ? '100%' : 500}
    >
      <Component
        className={className}
        setUser={setUser}
        setRecurrence={setRecurrence}
        price={price}
        planId={planId}
        user={user}
        recurrence={recurrence}
      />
    </Scrollbar>
  )
}

export default PremiumCard

const Container = styled.div`
  overflow: auto;
  background: #d20e50;

  width: 100%;

  color: white;
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${({ user, valueSelect }) =>
    user === valueSelect ? 'white' : 'none'};
  color: ${({ user, valueSelect }) =>
    user === valueSelect ? '#D20E50' : 'white'};
`
