function Visa({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 71 71"
    >
      <path
        fill="#2566AF"
        d="M31.344 44.61h-4.847l3.03-18.619h4.847l-3.03 18.619zM22.42 25.99l-4.622 12.806-.546-2.757-1.63-8.372s-.198-1.677-2.3-1.677H5.68l-.09.316s2.336.486 5.07 2.128l4.212 16.175h5.05l7.713-18.619h-5.217zM60.546 44.61h4.451l-3.88-18.62H57.22c-1.8 0-2.238 1.388-2.238 1.388l-7.23 17.232h5.054l1.01-2.766h6.162l.568 2.766zm-5.334-6.586l2.547-6.968 1.433 6.968h-3.98zm-7.08-7.555l.691-3.999s-2.134-.811-4.36-.811c-2.405 0-8.117 1.05-8.117 6.163 0 4.81 6.704 4.87 6.704 7.397 0 2.526-6.014 2.073-7.998.48l-.721 4.18s2.164 1.052 5.471 1.052c3.308 0 8.299-1.713 8.299-6.374 0-4.841-6.765-5.292-6.765-7.397 0-2.105 4.721-1.834 6.796-.691z"
      ></path>
      <path
        fill="#E6A540"
        d="M17.251 36.04l-1.63-8.373s-.198-1.676-2.3-1.676H5.68l-.09.315s3.672.761 7.194 3.612c3.368 2.726 4.466 6.122 4.466 6.122z"
      ></path>
    </svg>
  )
}

export default Visa
