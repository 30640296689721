import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { ZoomInIcon, ZoomOutIcon, XCircleIcon } from '@heroicons/react/outline'

import PDFViewer from 'components/PDFViewer/PDFViewer'
import useResize from 'hooks/useResize'
import { formatFileName } from 'utils/formaters'
import { useModalContext } from 'contexts/ModalContext'

const FileContainer = styled.div`
  margin: 16px;

  display: flex;
  justify-content: center;
`

interface IImage {
  controls?: boolean
  maxHeight?: number | string
  maxWidth?: number | string
  imageHeight?: number | string
  imageWidth?: number | string
}

const ImageContainer = styled('div')<IImage>`
  overflow: auto;

  ${({ controls, maxHeight, maxWidth, imageWidth, theme: { sizes } }) => {
    if (controls) {
      return css`
        min-height: calc(${maxHeight && maxHeight}px - 200px);
        min-width: calc(${maxWidth && maxWidth}px - 200px);
        max-height: calc(${maxHeight && maxHeight}px - 200px);
        max-width: calc(${maxWidth && maxWidth}px - 200px);

        display: flex;
        justify-content: ${imageWidth && imageWidth <= 1000
          ? 'center'
          : 'left'};

        @media (max-width: ${sizes.sm}) {
          min-width: calc(${maxWidth && maxWidth}px - 80px);
          max-width: calc(${maxWidth && maxWidth}px - 80px);

          justify-content: left;
        }

        @media (min-width: ${sizes.xl}) {
          min-width: 1000px;
          max-width: 1000px;
        }
      `
    } else {
      return css`
        height: ${maxHeight && maxHeight};
        width: ${maxWidth && maxWidth};
        display: flex;
        justify-content: center;
      `
    }
  }}
`

const Image = styled('img')<IImage>`
  transition: all 0.2s linear;
  ${({ controls, imageHeight, imageWidth }) => {
    if (controls) {
      return css`
        height: ${imageHeight && `${imageHeight}px`};
        width: ${imageWidth && `${imageWidth}px`};
        max-width: none;
      `
    }
  }}
`

const FilePreviewPrompt = ({
  file,
  controls = true,
  scale = 1.0
}: {
  file: { nome: string; arquivo: string }
  controls: boolean
  scale: number
}) => {
  const { width, height } = useResize()
  const { setOpen } = useModalContext()

  const [type, setType] = useState('')
  const [imageHeight, setImageHeight] = useState(500)
  const [imageWidth, setImageWidth] = useState<number>()

  const zoomIn = () => {
    setImageHeight(prev => prev + prev * 0.1)
    setImageWidth(prev => prev && prev + prev * 0.1)
  }
  const zoomOut = () => {
    setImageHeight(prev => prev - prev * 0.1)
    setImageWidth(prev => prev && prev - prev * 0.1)
  }

  const onLoad = (e: any) => {
    setImageHeight(e.target.clientHeight)
    setImageWidth(e.target.clientWidth)
  }

  useEffect(() => {
    if (file && file.nome) {
      setType(file.nome.toLowerCase().substr(-3) === 'pdf' ? 'pdf' : 'image')
    } else setType('image')
  }, [file])

  return (
    <div className="flex flex-col bg-white rounded-md">
      <div className="flex flex-row mb-5 mt-5 relative">
        <h1 className="flex-grow text-center text-base font-medium">
          {formatFileName(file.nome)}
        </h1>
        <XCircleIcon
          className="h-6 text-pink-600 mx-6 absolute right-0 cursor-pointer"
          onClick={() => setOpen(false)}
        />
      </div>

      {!file ? (
        <>
          <span />
        </>
      ) : type === 'pdf' ? (
        <FileContainer>
          <PDFViewer
            file={file.arquivo}
            width={width}
            height={height}
            controls={controls}
            initialScale={scale}
          />
        </FileContainer>
      ) : (
        <div>
          <ImageContainer
            maxHeight={controls ? height : '100%'}
            maxWidth={controls ? width : '100%'}
            controls={controls}
            imageWidth={imageWidth}
          >
            <Image
              controls={controls}
              src={file.arquivo}
              onLoad={onLoad}
              alt="arquivo"
              className="z-20"
              onDoubleClick={zoomIn}
              imageHeight={imageHeight}
              imageWidth={imageWidth}
            />
          </ImageContainer>
          {controls && (
            <div className="flex justify-center items-center space-x-4 py-4">
              <button type="button" onClick={zoomOut}>
                <ZoomOutIcon className="text-pink-600 h-6" />
              </button>
              <button type="button" onClick={zoomIn}>
                <ZoomInIcon className="text-pink-600 h-6" />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default FilePreviewPrompt
