import { ShineEffect } from '../../../ui'

export default function Skeleton() {
  return (
    <>
      <div className="max-w-xl container rounded-md bg-white py-4">
        <div className="my-2 h-8">
          <ShineEffect round="4px" />
        </div>
        <div className="my-2 h-8">
          <ShineEffect round="4px" />
        </div>
        <div className="my-2 h-8">
          <ShineEffect round="4px" />
        </div>
        <div
          className="my-2 h-8 ml-auto"
          style={{
            maxWidth: '124px'
          }}
        >
          <ShineEffect round="4px" />
        </div>
      </div>

      <div className="max-w-xl mx-auto rounded-md">
        <div className="mt-6 mb-4 h-8">
          <ShineEffect round="4px" />
        </div>
      </div>

      <div className="max-w-xl container rounded-md bg-white py-4">
        <div className="my-2 h-8">
          <ShineEffect round="4px" />
        </div>
        <div className="my-2 h-8">
          <ShineEffect round="4px" />
        </div>
        <div
          className="my-2 h-8 ml-auto"
          style={{
            maxWidth: '124px'
          }}
        >
          <ShineEffect round="4px" />
        </div>
      </div>

      <div className="max-w-xl mx-auto rounded-md">
        <div className="mt-6 mb-4 h-8">
          <ShineEffect round="4px" />
        </div>
      </div>

      <div className="max-w-xl container rounded-md bg-white py-4">
        <div className="my-2 h-8">
          <ShineEffect round="4px" />
        </div>
        <div className="my-2 h-8">
          <ShineEffect round="4px" />
        </div>
        <div className="my-2 h-8">
          <ShineEffect round="4px" />
        </div>
        <div className="my-2 h-8">
          <ShineEffect round="4px" />
        </div>
      </div>
    </>
  )
}
