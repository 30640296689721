import React, { useContext } from 'react'

import { Button } from '../../ui'
import {
  Content,
  Pcontent,
  SpanRed,
  SvgBackgroundWomen,
  Title
} from '../Styles'
import { ContextTemporaryPassword } from '../../../containers/TemporaryPassword/TemporaryPassword'
import { GlobalContext } from '../../../contexts/GlobalContext'
import { APP_URLS } from '../../../urls'

const PasswordWelcome = () => {
  const { history } = useContext(ContextTemporaryPassword)
  const { user } = useContext(GlobalContext)
  return (
    <Content>
      <SvgBackgroundWomen />
      <Title className="font-normal h1Style mt-4">
        É muito bom <br /> ter você por <br /> aqui,{' '}
        <SpanRed>{user.name}</SpanRed>!
      </Title>
      <Pcontent>
        Toque em <SpanRed>continuar</SpanRed> para <br /> criar sua senha de
        acesso à <br />
        nossa plataforma.
      </Pcontent>
      <div className="w-1/2 pr-2">
        <Button
          onClick={() => history.push(APP_URLS.CHANGE_PASSWORD_CREATE)}
          className="button-temporary-password w-3/4 flex-shrink-0"
        >
          continuar
        </Button>
      </div>
    </Content>
  )
}

export default PasswordWelcome
