/* eslint-disable react/no-array-index-key */
import { GiftIcon } from '@heroicons/react/outline'
import Tippy from '@tippyjs/react'
import cn from 'classnames'

import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'

import { Spinner, Button, Icon, ReactModal as Modal } from '../../ui'

const InvitePageLayout = ({
  balance,
  onClaim,
  referees,
  isClaimModalVisible,
  isDescriptionExpanded,
  setDescriptionExpanded,
  loading,
  setClaimModalVisible,
  openSupportWhatsApp,
  openPrompt
}) => {
  return (
    <div className="bg-gray-300 p-4">
      <div className="mt-4 p-4 bg-white relative rounded max-w-xl mx-auto">
        <div className="relative">
          <div className="bg-pink-600 w-full flex justify-between p-6 rounded-md assina-shadow-sm">
            <div className="flex items-center justify-center">
              <Heading className="text-2xl text-gray-100 text-center font-semibold">
                Convide seus amigos e ganhe descontos
              </Heading>
            </div>
            <div>
              <GiftIcon className="text-gray-100 h-12" />
            </div>
          </div>
          <div
            className="flex justify-between mt-4 cursor-pointer"
            onClick={() => setDescriptionExpanded(!isDescriptionExpanded)}
          >
            <Heading className="text-lg font-semibold text-pink-600">
              Como funciona?
            </Heading>
            <Icon
              name={isDescriptionExpanded ? 'angle-up' : 'angle-down'}
              className="fill-current text-pink-600"
            />
          </div>
          <div
            className={cn(
              'mt-2 text-gray-900 overflow-hidden',
              isDescriptionExpanded ? 'h-full' : 'h-0'
            )}
          >
            <Text>
              É super simples! Clique abaixo e envie seu convite personalizado
              para todos os seus amigos e familiares por whatsapp, facebook ou
              email. Pronto: a cada nova assinatura a partir do seu link, você
              ganha um mês de isenção, e a primeira mensalidade de sua indicação
              sai a partir de R$&nbsp;4,90.
              <br /> <br /> E o melhor de tudo: é acumulativo!!! Ou seja, se 10
              amigos realizarem a adesão através do seu link, você tem 10 meses
              de assinatura gratuita. É a forma de manter o cuidado de sua saúde
              e não ter nenhum custo com isso por vários meses, aproveite!!!
            </Text>

            <div className="flex items-center justify-center flex-col mt-4">
              <Text as="span">Ainda ficou alguma dúvida?</Text>
              <button
                type="button"
                className="bg-pink-600 px-4 py-2 mt-2 text-lg font-medium text-white tracking-widest hover:bg-pink-700 rounded flex justify-center items-center"
                onClick={openSupportWhatsApp}
              >
                Entre em contato{' '}
                <Icon
                  size={20}
                  name="whatsapp"
                  className="fill-current text-white ml-2"
                />
              </button>
            </div>
          </div>
          <div className="flex justify-center items-center rounded overflow-hidden h-12 mt-4">
            <Button className="px-4" onClick={openPrompt}>
              Enviar convite
            </Button>
          </div>
          <Heading className="text-teal-600 font-semibold text-lg mt-4">
            Seus convidados que aderiram à Kompa Saúde:
          </Heading>
          {referees.map((r, idx) => (
            <div key={idx} className={cn(idx > 0 && 'mt-2')}>
              <Text className="text-green-600 font-medium">{r.name}</Text>
            </div>
          ))}
          {referees.length === 0 && loading && <Spinner color="teal" />}
          {referees.length === 0 && !loading && (
            <Text>Nenhuma indicação utilizou o seu link até agora.</Text>
          )}
          <hr className="mt-4" />
          {balance > 0 && loading && (
            <button
              type="button"
              className="w-full h-12 bg-pink-600 text-lg text-white rounded text-center flex items-center justify-center font-semibold tracking-widest mt-4"
            >
              <Spinner />
            </button>
          )}
          {balance > 0 && !loading && (
            <button
              type="button"
              onClick={onClaim}
              className="w-full h-12 bg-pink-600 text-lg text-white rounded text-center flex items-center justify-center font-semibold tracking-widest mt-4 hover:bg-pink-700"
            >
              Resgatar minhas mensalidades
            </button>
          )}
          {balance === 0 && (
            <Tippy content="Você não tem mensalidades para resgatar.">
              <button
                type="button"
                disabled
                className="cursor-default w-full h-12 bg-gray-600 text-lg text-white rounded text-center flex items-center justify-center font-semibold tracking-widest mt-4"
              >
                Resgatar minhas mensalidades
              </button>
            </Tippy>
          )}
        </div>
      </div>
      <Modal
        isOpen={isClaimModalVisible}
        onRequestClose={() => setClaimModalVisible(false)}
        contentLabel="Solicitação de resgate confirmada"
      >
        <Heading className="text-lg font-semibold text-teal-600">
          Solicitação realizada com sucesso!
        </Heading>
        <Text>
          Nossa equipe entrará em contato em até 24 horas para confirmar o seu
          desconto.
        </Text>
        <Button
          as="a"
          className="mt-4"
          onClick={() => setClaimModalVisible(false)}
        >
          OK
        </Button>
      </Modal>
    </div>
  )
}

export default InvitePageLayout
