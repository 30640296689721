import { useState } from 'react'

import Icon from '../../elements/Icon'

const Section = ({ children, title, className, id, ...rest }) => {
  const [isVisible, setIsVisible] = useState(true)

  const onClickIcon = () => {
    setIsVisible(!isVisible)
  }

  return (
    <div
      className={`w-full p-4 bg-gray-200 rounded shadow-sm ${className}`}
      id={id}
      {...rest}
    >
      <h2
        className="text-pink-600 text-xl font-semibold flex justify-between items-center cursor-pointer"
        onClick={onClickIcon}
      >
        {title}
        {isVisible ? (
          <Icon
            name="arrowTop"
            className="fill-current stroke-current text-gray-700"
            width="20"
            height="20"
          />
        ) : (
          <Icon
            name="arrowDown"
            className="fill-current stroke-current text-gray-700"
            width="20"
            height="20"
          />
        )}
      </h2>
      {isVisible ? <>{children}</> : null}
    </div>
  )
}

export default Section
