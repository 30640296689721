import { ShineEffect } from '../../ui'

const Skeleton = () => (
  <div className="container">
    <div className="my-6 h-8">
      <ShineEffect round="4px" />
    </div>
    <div className="h-20 my-4">
      <ShineEffect round="4px" />
    </div>
    <div className="h-20 my-4">
      <ShineEffect round="4px" />
    </div>
    <div className="my-6 h-8">
      <ShineEffect round="4px" />
    </div>
    <div className="h-40 my-4">
      <ShineEffect round="4px" />
    </div>
  </div>
)

export default Skeleton
