import React, { useState } from 'react'
import NumberFormat from 'react-number-format'

const DEFAULT_FORMAT = '#### #### #### ####'
const AMEX_FORMAT = '#### ###### #####'

const CreditCardInput = ({ onChange, ...rest }) => {
  const [format, setFormat] = useState(DEFAULT_FORMAT)

  const handleChange = e => {
    const { value } = e.target
    setFormat(DEFAULT_FORMAT)
    if (/^3[47]/.test(value)) setFormat(AMEX_FORMAT)
    onChange(e)
  }

  return (
    <NumberFormat
      type="tel"
      format={format}
      onChange={handleChange}
      {...rest}
    />
  )
}

export default CreditCardInput
