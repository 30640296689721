import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { FEATURES, GlobalContext } from 'contexts/GlobalContext'
import { ModalContext } from 'contexts/ModalContext'

import Head from 'components/common/Head'
import Icon from 'components/ui/Icon'
import Heading from 'components/ui/Heading'
import UserAppointments from 'components/Appointment/UserAppointments'
import MenuItem from 'components/Home/MenuItem'
import { NotificationPrompt } from 'components/Notification'
import CardServiceChannels from 'components/Home/CardServiceChannels'
import CardInviteFriends from 'components/Home/CardInviteFriends'
import CardSignPlan from 'components/Home/CardSignPlan'
import FirstSteps from 'containers/FirstSteps'

import useAuth from 'hooks/useAuth'
import useResize from 'hooks/useResize'

import { BACKGROUNDS, APP_URLS, SITE_URLS } from 'urls'
import { APP_URLS as NEW_SITE_URLS } from 'utils/urls'
import { SIZES, SUPPORT_WHATSAPP } from 'utils/constants'
import { openWhatsApp } from 'utils'

export default function UserHomePage() {
  // @ts-ignore
  const { handleModal, setCloseButton } = useContext(ModalContext)
  const { isLoggedIn, navigateToFeature, isFree, isUnlimited } =
    useContext(GlobalContext)

  const { user } = useAuth()

  const history = useHistory()

  const { width } = useResize()

  return (
    <>
      <Head title="Início" />
      <div style={{ maxWidth: '1280px' }} className="mx-auto bg-gray-300">
        <div className="my-4 text-xl text-pink-900 mx-6 sm:mx-10 xl:px-10 xl:mx-auto flex items-center">
          <div
            style={{ width: '47px', height: '47px' }}
            className="flex justify-center items-center mr-2"
          >
            <Icon name="profile-outlined" size={35} />
          </div>
          <Heading>
            <span className="text-pink-600">{user.name.split(' ')[0]}</span>, é
            bom ter você aqui :)
          </Heading>
        </div>
        <div className="mx-6 sm:mx-10 xl:px-10 xl:mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
            <div className="col-span-1 lg:col-span-7">
              <FirstSteps />
              {width < SIZES.lg && (
                <>
                  <Heading
                    as="h3"
                    className="text-gray-600 font-medium rounded px-4 mb-4 lg:mt-0 select-none"
                  >
                    SUAS CONSULTAS
                  </Heading>
                  <UserAppointments />
                </>
              )}
              <Heading
                as="h3"
                className="text-gray-600 font-semibold rounded ml-4 select-none"
              >
                RECOMENDADOS PARA VOCÊ
              </Heading>

              <CardServiceChannels />

              <Heading
                as="h3"
                className="text-gray-600 font-semibold rounded ml-4 select-none mb-4"
              >
                DESCONTOS
              </Heading>
              {/* @ts-ignore */}
              {isFree() ? (
                <CardSignPlan />
              ) : (
                <CardInviteFriends isLoggedIn={isLoggedIn()} />
              )}
              <div className="pt-4">
                <Heading
                  as="h3"
                  className="text-gray-600 font-semibold rounded ml-4 select-none"
                >
                  DESTAQUES
                </Heading>
                <div className="mt-4 grid grid-cols-2 gap-2">
                  <MenuItem
                    title="Consultório virtual"
                    background={BACKGROUNDS.TELEMEDICINE}
                    onClick={() =>
                      navigateToFeature(
                        user.isMedico
                          ? FEATURES.VIRTUAL_CLINIC
                          : FEATURES.SCHEDULE
                      )
                    }
                  />
                  <MenuItem
                    className="booking"
                    title="Agendar consulta"
                    background={BACKGROUNDS.ACCREDITED_NETWORK}
                    onClick={() => {
                      // @ts-ignore
                      if (isUnlimited()) {
                        return openWhatsApp(
                          SUPPORT_WHATSAPP,
                          'Olá, quero agendar uma consulta'
                        )
                      }
                      history.push(APP_URLS.BOOKING)
                    }}
                  />
                  <MenuItem
                    href={NEW_SITE_URLS.CHAT}
                    title="Avaliação de Saúde Online"
                    background={BACKGROUNDS.KARMEN}
                  />
                  <MenuItem
                    onClick={() => navigateToFeature(FEATURES.ORIENTATION_24H)}
                    title="Orientação 24 horas"
                    background={BACKGROUNDS.CALL0800}
                    // @ts-ignore
                    exclusive={isFree()}
                  />

                  <MenuItem
                    title="Minhas Prescrições"
                    background={BACKGROUNDS.MONITORING}
                    onClick={() => navigateToFeature(FEATURES.PRESCRIPTIONS)}
                  />
                  <MenuItem
                    title="Meus exames"
                    background={BACKGROUNDS.EXAMES}
                    onClick={() => navigateToFeature(FEATURES.EXAMS)}
                  />
                  <MenuItem
                    onClick={() => navigateToFeature(FEATURES.DIGITAL_CARD)}
                    title="Carteirinha virtual"
                    background={BACKGROUNDS.DIGITAL_CARD}
                    // @ts-ignore
                    exclusive={isFree()}
                  />
                  <MenuItem
                    title="Conteúdos para minha saúde"
                    background={BACKGROUNDS.BLOG}
                    onClick={() => window.open(SITE_URLS.BLOG, '_blank')}
                  />
                  <MenuItem
                    title="Valores das consultas por telemedicina"
                    background={BACKGROUNDS.TELEMEDICINE_VALUES}
                    onClick={() =>
                      navigateToFeature(FEATURES.TELEMEDICINE_VALUES)
                    }
                  />
                  <MenuItem
                    onClick={() => navigateToFeature(FEATURES.DRUGSTORES)}
                    title="Farmácias com desconto"
                    background={BACKGROUNDS.DRUGSTORES}
                    // @ts-ignore
                    exclusive={isFree()}
                  />
                  <MenuItem
                    to={APP_URLS.SUPPORT}
                    title="Informações, reclamações e sugestões"
                    background={BACKGROUNDS.SUPPORT}
                  />
                </div>
              </div>
              <div className="py-4">
                <Heading
                  as="h3"
                  className="text-gray-600 font-semibold rounded ml-4 select-none"
                >
                  MAIS SERVIÇOS
                </Heading>
                <div className="mt-4 rounded bg-white border border-gray-500 flex flex-col">
                  <button
                    type="button"
                    onClick={() => {
                      setCloseButton(false)
                      handleModal(<NotificationPrompt />)
                    }}
                    className="flex flex-grow p-4 hover:bg-gray-200 text-pink-600 tracking-widest font-semibold"
                  >
                    <Icon
                      name="alert-outline"
                      size={20}
                      className="fill-current text-pink-600"
                    />
                    <Heading as="h4" className="ml-2">
                      Alterar preferências de notificação
                    </Heading>
                  </button>
                </div>
              </div>
            </div>
            {width >= SIZES.lg && (
              <div className="col-span-5">
                <Heading
                  as="h3"
                  className="text-gray-600 font-medium rounded px-4 mb-4 lg:mt-0 select-none"
                >
                  SUAS CONSULTAS
                </Heading>
                <UserAppointments />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
