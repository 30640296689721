import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { getPlans } from 'services/admnistrativeService'

export default function usePlans() {
  const {
    data: plans,
    isLoading: isPlansLoading,
    isError: isPlanError
  } = useQuery('plans', getPlans, {
    refetchOnWindowFocus: false
  })

  const getPlan = useCallback(
    (planId: string) => {
      return plans?.find(({ id }) => id === Number(planId))
    },
    [plans]
  )

  return {
    plans,
    isPlansLoading,
    isPlanError,
    getPlan
  }
}
