import { Link } from 'react-router-dom'

import { useModalContext } from '../../../contexts/ModalContext'
import { APP_URLS } from '../../../urls'
import { openWhatsApp } from '../../../utils'
import { SUPPORT_WHATSAPP } from 'utils/constants'

import Button from '../../ui/Button'

export default function IsUnlimitedPrompt() {
  const { resetModal } = useModalContext()

  return (
    <div className="bg-gray-100 p-4 space-y-4 max-w-sm rounded flex flex-col items-center justify-center text-center">
      <h1 className="text-xl font-medium text-gray-900">
        Você tem um plano de consultas ilimitadas!
      </h1>
      <h2 className="text-lg font-medium text-gray-800">
        Para agendar por favor entre em contato no whatsapp
      </h2>

      <Button
        className="px-4"
        onClick={() =>
          openWhatsApp(SUPPORT_WHATSAPP, 'Olá, quero agendar uma consulta')
        }
      >
        Ir para whatsapp
      </Button>
      <Link to={APP_URLS.HOME}>
        <Button className="px-4" color="white" onClick={() => resetModal()}>
          Voltar para o menu
        </Button>
      </Link>
    </div>
  )
}
