import { backendServices } from './AxiosInstances'

import { parseDateHour } from 'utils/formaters'

import {
  AccRequest,
  AccRequests,
  AccRequestsResponse,
  AccRequestOfferResponse,
  AccRequestStatus
} from 'types/AccRequest'
import { ConfirmOfferInputs, ICreateOffer } from 'types/Offer'

const orderRequestsByStatusUpdate = (requests: AccRequest[]) =>
  requests
    .slice()
    .sort(
      (a, b) =>
        parseDateHour(b.status_updated_at).getTime() -
        parseDateHour(a.status_updated_at).getTime()
    )

export const getRequests = (): Promise<AccRequests> =>
  backendServices
    .get<AccRequestsResponse>('/api/v1/presential/list')
    .then(({ data }) => {
      const requests = data.data

      const solicitations = orderRequestsByStatusUpdate(
        requests.filter(({ status }) => status === 'new')
      )
      const negotiating = orderRequestsByStatusUpdate(
        requests.filter(({ status }) => status === 'negotiating')
      )
      const paying = orderRequestsByStatusUpdate(
        requests.filter(({ status }) => status === 'paying')
      )
      const booked = orderRequestsByStatusUpdate(
        requests.filter(({ status }) => status === 'booked')
      )
      const finalized = orderRequestsByStatusUpdate(
        requests.filter(
          ({ status }) =>
            status === 'done' || status === 'missed' || status === 'canceled'
        )
      )

      return {
        solicitations,
        negotiating,
        paying,
        booked,
        finalized
      }
    })
    .catch(error => {
      throw error
    })

export const updateRequestStatus = async (
  requestId: number | undefined,
  status: AccRequestStatus
) =>
  await backendServices
    .put(`/api/v1/presential/${requestId}/update`, { status })
    .catch(error => {
      throw error.message
    })

export const getRequestOffer = (requestId: number | undefined) =>
  backendServices
    .get<AccRequestOfferResponse>(`/api/v1/presential/${requestId}/offer/list`)
    .then(({ data }) => data.data)
    .catch(error => {
      throw error.message
    })

export const createRequestOffer = async (
  requestId: number | undefined,
  offer: ICreateOffer
) =>
  await backendServices
    .post(`/api/v1/presential/${requestId}/offer/create`, offer)
    .catch(error => {
      throw error.message
    })

export const confirmOffer = async (
  offerId: number,
  { date, hour }: ConfirmOfferInputs
) =>
  await backendServices
    .put(`/api/v2/presential/offer/${offerId}`, {
      date,
      time: hour
    })
    .catch(error => {
      throw error.message
    })
