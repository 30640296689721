import {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState
} from 'react'
import { useHistory } from 'react-router'
import { useQuery } from 'react-query'
import { CircularProgress } from '@material-ui/core'

import Head from 'components/common/Head'
import PageHeader from 'components/common/PageHeader'
import KompaPagination from 'components/ui/KompaPagination'
import FiltersLayout from 'components/layouts/UsersPage/FiltersLayout'
import UsersTableLayout from 'components/layouts/UsersPage/UsersTableLayout'
import Text from 'components/ui/Text'
import Heading from 'components/ui/Heading'
import AdvancedFiltersPrompt from 'components/layouts/UsersPage/AdvancedFiltersPrompt'

import { getUsers } from 'services/admnistrativeService'

import useQueryParams from 'hooks/useQueryParams'

import { AdminUsersFilters } from 'types/AdminUsers'

export default function UsersPage() {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const [filters, setFilters] = useState<AdminUsersFilters>({
    page: useQueryParams('pagina') || undefined,
    results_per_page: useQueryParams('resultados') || undefined,
    q: useQueryParams('busca') || undefined,
    answered_karmen: Boolean(useQueryParams('respondeu_karmen')) || undefined,
    first_consultation_with_nursing:
      Boolean(useQueryParams('passou_enfermeira')) || undefined,
    first_consultation_with_dedicated_doctor:
      Boolean(useQueryParams('passou_medico')) || undefined,
    is_plan_active: Boolean(useQueryParams('ativo')) || undefined,
    dedicated_doctor_id: useQueryParams('doutor_dedicado') || undefined,
    dedicated_nurse_id: useQueryParams('enfermeira_dedicada') || undefined,
    start_date_of_joining_of_the_plan:
      useQueryParams('data_adesao_inicial') || undefined,
    end_date_of_joining_of_the_plan:
      useQueryParams('data_adesao_final') || undefined,
    default_on_debt: useQueryParams('data_inadimplente') || undefined,
    payment_method: useQueryParams('metodo_pagamento') || undefined,
    plan_id: useQueryParams('plano') || undefined,
    buy_reason: useQueryParams('motivo_assinatura') || undefined,
    care_plan_id: useQueryParams('plano_de_cuidado') || undefined
  })

  const history = useHistory()

  const topDiv = useRef() as MutableRefObject<HTMLDivElement>

  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersError
  } = useQuery(
    [
      'users',
      {
        ...filters,
        page: filters.page || '1',
        results_per_page: filters.results_per_page || '30'
      }
    ],
    () =>
      getUsers({
        ...filters,
        page: filters.page || '1',
        results_per_page: filters.results_per_page || '30'
      }),
    {
      refetchOnWindowFocus: false
    }
  )

  const handlePageChange = (_: ChangeEvent<unknown>, value: number) =>
    setFilters(prev => ({ ...prev, page: String(value) }))

  useEffect(() => {
    const params = new URLSearchParams()

    if (filters.page) params.append('pagina', String(filters.page))
    if (filters.results_per_page)
      params.append('resultados', String(filters.results_per_page))
    if (filters.q) params.append('busca', String(filters.q))
    if (filters.answered_karmen)
      params.append('respondeu_karmen', String(filters.answered_karmen))
    if (filters.first_consultation_with_nursing)
      params.append(
        'passou_enfermeira',
        String(filters.first_consultation_with_nursing)
      )
    if (filters.first_consultation_with_dedicated_doctor)
      params.append(
        'passou_medico',
        String(filters.first_consultation_with_dedicated_doctor)
      )
    if (filters.is_plan_active) {
      params.append('ativo', String(filters.is_plan_active))
    }
    if (filters.dedicated_doctor_id) {
      params.append('doutor_dedicado', String(filters.dedicated_doctor_id))
    }
    if (filters.dedicated_nurse_id) {
      params.append('enfermeira_dedicada', String(filters.dedicated_nurse_id))
    }
    if (filters.start_date_of_joining_of_the_plan) {
      params.append(
        'data_adesao_inicial',
        String(filters.start_date_of_joining_of_the_plan)
      )
    }
    if (filters.end_date_of_joining_of_the_plan) {
      params.append(
        'data_adesao_final',
        String(filters.end_date_of_joining_of_the_plan)
      )
    }
    if (filters.default_on_debt) {
      params.append('data_inadimplente', String(filters.default_on_debt))
    }
    if (filters.payment_method) {
      params.append('metodo_pagamento', String(filters.payment_method))
    }
    if (filters.plan_id) {
      params.append('plano', String(filters.plan_id))
    }
    if (filters.buy_reason) {
      params.append('motivo_assinatura', String(filters.buy_reason))
    }
    if (filters.care_plan_id) {
      params.append('plano_de_cuidado', String(filters.care_plan_id))
    }

    history.push({ search: params.toString() })
  }, [filters, history])

  useEffect(() => {
    topDiv.current.scrollIntoView({ behavior: 'smooth' })
  }, [filters.page])

  const handleDialogOpen = () => setIsDialogOpen(prev => !prev)

  const handleDialogClose = () => setIsDialogOpen(prev => !prev)

  return (
    <>
      <Head title="Usuários" />

      <AdvancedFiltersPrompt
        filters={filters}
        setFilters={setFilters}
        isDialogOpen={isDialogOpen}
        handleDialogClose={handleDialogClose}
      />

      <div className="flex flex-col w-full">
        <div ref={topDiv} />

        <PageHeader title="Pacientes" />

        <FiltersLayout
          filters={filters}
          setFilters={setFilters}
          handleDialogOpen={handleDialogOpen}
          syncQueryValue={filters.q}
        />

        {isUsersLoading && (
          <div className="flex items-center justify-center mb-4 mt-8 h-full">
            <CircularProgress color="primary" size={35} />
          </div>
        )}

        {!isUsersLoading && isUsersError && (
          <Text className="text-center flex items-center justify-center mb-4 mt-8 h-full">
            Não foi possível carregar os usuários.
          </Text>
        )}

        {users && (
          <>
            {users.data.length === 0 ? (
              <Text className="text-center flex items-center justify-center mb-4 mt-8 h-full">
                Nenhum usuário encontrado
              </Text>
            ) : (
              <>
                <UsersTableLayout users={users.data} />
                <Text className="text-center px-3 text-gray-700 select-none">
                  {users.total_items} resultados
                </Text>
                {users.total_pages !== 1 && (
                  <div className="flex justify-end space-x-2 pt-2 px-3 pb-3">
                    <KompaPagination
                      color="primary"
                      shape="rounded"
                      page={Number(filters.page) || 1}
                      count={users.total_pages}
                      onChange={handlePageChange}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
        <div className="py-4 text-center text-gray-600 select-none">
          <Heading>Administrativo Kompa Saúde</Heading>
        </div>
      </div>
    </>
  )
}
