import { NavLink } from 'react-router-dom'

const LinkH = ({
  to,
  children,
  className,
  activeStyle = {
    backgroundColor: '#008F8F',
    fontWeight: 'bold',
    color: '#FBFBFB'
  },
  exact = true,
  ...rest
}) => {
  return (
    <NavLink
      activeStyle={activeStyle}
      to={to}
      exact={exact}
      className={className}
      {...rest}
    >
      {children}
    </NavLink>
  )
}

export default LinkH
