import cn from 'classnames'
import Icon from '../../ui/Icon'

const UploadTypePill = ({
  type = 'Exame',
  active = false,
  onClick = () => {}
}) => {
  return (
    <div
      className={cn(
        'flex flex-row cursor-pointer',
        active ? 'border rounded' : ''
      )}
      style={{
        borderColor: '#008F8F'
      }}
      onClick={onClick}
    >
      <div className="ml-5 my-2">
        <Icon
          name={
            type === 'Exame'
              ? active
                ? 'paper-green'
                : 'paper'
              : active
              ? 'folder-green'
              : 'folder'
          }
          size={20}
        />
      </div>
      <div
        className="ml-2 my-1 text-base font-medium"
        style={{
          color: active ? '#008F8F' : '#5E5E5E'
        }}
      >
        {type}
      </div>
    </div>
  )
}

export default UploadTypePill
