const style = {
  height: '100%',
  width: '100%'
}

const LocationPage = ({ mapRef, visible }) => {
  return (
    <div style={visible ? style : null}>
      <div style={visible ? style : null} ref={mapRef} />
    </div>
  )
}

export default LocationPage
