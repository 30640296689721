import cn from 'classnames'

import SummaryHeader from './SummaryHeader'

const SummarySection = ({
  children,
  className,
  createdBy,
  date,
  title,
  indexOnData,
  maxIndex
}) => {
  return (
    <div className={cn('flex w-full bg-white')}>
      <SummaryHeader
        createdBy={createdBy}
        date={date}
        title={title}
        isFirst={indexOnData !== -1 && indexOnData === 1}
        isLast={indexOnData === maxIndex}
      />
      <div
        className={cn(
          'summary-body w-full py-3 mx-3',
          className,
          // IsNotFirst
          indexOnData !== -1 &&
            indexOnData !== 0 &&
            'border-t-2 border-gray-400',
          //IsFirst
          indexOnData !== -1 && indexOnData === 0 && 'rounded-tr-md',
          //IsLast
          indexOnData === maxIndex && 'rounded-br-md'
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default SummarySection
