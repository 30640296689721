import styled from 'styled-components'

const TextareaStyle = styled.textarea`
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
  &:required {
    box-shadow: none;
  }
`

export default TextareaStyle
