export const SectionIds = {
  medicalHistory: 'medical-history',
  additionalMedicalInformation: 'additional-medical-information',
  anamnese: 'anamnese',
  symptoms: 'symptoms'
}

export const hasAnsweredQuestions = data =>
  data.filter(
    ({ data }) => data !== 'unknown' && data !== 'não' && data !== '-'
  ).length !== 0

const getAnsweredQuestions = data =>
  Object.entries(data).filter(([_, value]) => {
    return value && value !== 'não'
  })

const getNotAnsweredQuestions = data =>
  Object.entries(data).filter(([_, value]) => {
    return value === 'não'
  })

const getNotAskedQuestions = data =>
  Object.entries(data).filter(([_, value]) => {
    return !value
  })

export const formatAnswersByPriority = data => {
  const answered = getAnsweredQuestions(data)
  const notAnswered = getNotAnsweredQuestions(data)
  const notAsked = getNotAskedQuestions(data)

  return [...answered, ...notAnswered, ...notAsked]
}
