import { Icon, Section } from '../../../ui'

import { capitalizeWords } from '../../../../utils'

const MedicalUnit = ({
  unitDetails: { logradouro, numero, bairro, nome_fantasia },
  selectUnit
}) => {
  return (
    <Section
      className="flex rounded assina-shadow-sm my-4 cursor-pointer"
      as="a"
      onClick={selectUnit}
    >
      <Icon name="map" size={30} className="fill-current text-pink-600" />
      <div className="ml-6 flex flex-col justify-between">
        <h2 className="text-lg text-teal-600">São Paulo - SP</h2>
        <h2 className="text-xl font-medium text-pink-600 mb-2">
          {capitalizeWords(nome_fantasia)}
        </h2>
        <span className="text-lg text-gray-800 leading-tight">
          {capitalizeWords(logradouro)}, {numero} <br />
          {capitalizeWords(bairro)}
        </span>
      </div>
    </Section>
  )
}

export default MedicalUnit
