/* eslint-disable array-callback-return */
import NavbarMenuItem from 'components/elements/Navbar/NavbarMenuItem'

import useAuth from 'hooks/useAuth'

import navItems from 'utils/navbarItems'

export default function AdminNavbarMenu() {
  const { user } = useAuth()

  return (
    <>
      {navItems.map(({ name, icon, link, onlyAdmin, onlyDoctor, allUsers }) => {
        if (onlyAdmin || allUsers || onlyDoctor) {
          if (onlyDoctor && !user.isMedico) {
            return
          }
          return (
            <NavbarMenuItem name={name} icon={icon} link={link} key={name} />
          )
        }
      })}
    </>
  )
}
