import { useQuery } from 'react-query'

import Head from 'components/common/Head'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import Spinner from 'components/ui/Spinner'

import useAuth from 'hooks/useAuth'

import { getSmartConsultations } from 'services/api'
import { ISmartConsultation } from 'types/Appointment'
import TableItemAction from 'components/elements/VirtualClinic/TableItemAction'
import { ExternalLinkIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { APP_URLS } from 'urls'
import { useHistory } from 'react-router-dom'
import WindowOpener from 'medicalRecord/components/elements/WindowOpen'
import { BASE_API_URL } from 'utils/constants'
import useSesame from 'hooks/useSesame'
import { capitalize } from 'lodash'

export const STATUS_SMART_CONSULTATION = [
  {
    value: 'Resolvido',
    color: 'new_green-500'
  },
  {
    value: 'Primeiro contato',
    color: 'new_yellow-500'
  },
  {
    value: 'Encerrado sem resposta do paciente',
    color: 'new_yellow-500'
  },
  {
    value: 'Em acompanhamento',
    color: 'new_yellow-500'
  }
]

const SmartConsultationElement = ({ item, index }: any) => {
  const { loading, sesame } = useSesame()
  const status = STATUS_SMART_CONSULTATION.find(
    s => s.value === item.status
  ) || {
    value: 'without-value',
    color: 'gray-600'
  }
  const history = useHistory()

  const hasSuggestedCare = item.conversation
    ? item.conversation.suggested_care
    : null

  const manchesterColor = () => {
    if (hasSuggestedCare) {
      switch (capitalize(item.conversation.suggested_care)) {
        case 'Check-up de rotina com o médico':
        case 'Check-up de rotina do médico':
        case 'Check-up médico de rotina':
          return '#5AC484'
        case 'Avaliação com seu time de saúde':
        case 'Avaliação clínica':
          return '#f6e05e'
        case 'Doutor hoje':
        case 'Avaliação clínica o mais rápido possível':
        case 'Avaliação clínica urgente':
          return '#f6ad55'
        case 'Serviço de emergência':
          return '#D90D0D'
        default:
          return '#4299e1'
      }
    }
  }

  return (
    <div
      key={`${item.id}+${index}`}
      className="flex flex-col rounded-lg p-2 my-4 w-full bg-white"
    >
      <h1>Diagnóstico em andamento</h1>
      <div className="p-2 border-2 border-gray-200 rounded-lg">
        <div className="flex">
          <div className="w-full">
            <h3>Nome</h3>
            <p className="text-gray-700 text-sm">{item.user.nome}</p>
          </div>
          <div className="w-full">
            <h3>Queixa principal</h3>
            <p className="text-gray-700 text-sm">
              {item.conversation ? item.conversation.symptom.name : '-'}
            </p>
          </div>
          <div className="w-full">
            <h3>Data de realização</h3>
            <p className="text-gray-700 text-sm">
              {item.conversation ? item.conversation.created_at : '-'}
            </p>
          </div>
        </div>

        <div className="flex">
          <div className="w-full">
            <h2 className="mt-2">Status</h2>

            <div className="p-2 border-2 border-gray-200 rounded-lg mt-2 flex justify-center items-center">
              <div
                className={`rounded-full h-4 w-4 bg-${
                  status ? status.color : ''
                } mr-4`}
              />
              <p className={`w-full text-${status ? status.color : ''} `}>
                {item.status}
              </p>
            </div>
          </div>

          {hasSuggestedCare ? (
            <>
              <div className="w-full">
                <h2 className="mt-2">Recomendação de cuidado</h2>

                <div className="p-2 border-2 border-gray-200 rounded-lg mt-2 flex items-center">
                  <div
                    className={`rounded-full h-4 w-4 mr-4`}
                    style={{
                      backgroundColor: manchesterColor()
                    }}
                  />
                  <p className="text-gray-700">
                    {capitalize(item.conversation.suggested_care)}
                  </p>
                </div>
              </div>
              <div className="w-full"></div>
            </>
          ) : null}
        </div>

        <TableItemAction
          label="Acessar"
          title="Prontuário"
          icon={<ExternalLinkIcon className=" text-pink-600 h-5" />}
          action={() =>
            history.push(
              `${APP_URLS.VIRTUAL_CLINIC}/paciente/${item.user.id}/prontuario/consulta-inteligente`
            )
          }
        />

        <div className="mt-4 flex justify-between">
          <div>
            <h2>Possíveis causas</h2>
            <p>{item.conversation ? item.conversation.possible_causes : '-'}</p>
          </div>
          {!loading && sesame && item.conversation && (
            <WindowOpener
              opts="width=600,height=600"
              name="popup"
              url={`${BASE_API_URL}/admin/symptom_checker/conversation/${item.conversation.id}/change/${sesame}`}
            >
              <div className="flex items-center gap-2">
                <p className="text-pink-600 font-bold">
                  Acessar triagem completa
                </p>
                <ChevronRightIcon className="text-pink-600 h-5" />
              </div>
            </WindowOpener>
          )}
        </div>
      </div>
    </div>
  )
}

export default function SmartConsultation() {
  const { user } = useAuth()

  const handleGetConsultations = (): Promise<ISmartConsultation[]> => {
    return getSmartConsultations({
      doctor_attendant_id: user.id
    }).then(response => response.data.data)
  }

  const {
    data: smartConsultations,
    isError,
    isLoading
  } = useQuery('smart-consultation', handleGetConsultations, {
    refetchInterval: 5000
  })

  return (
    <>
      <Head title="Consultório Virtual" />
      <div className="flex flex-col w-full">
        <div className="bg-gray-100 text-center py-8">
          <Heading className="text-pink-600 font-semibold text-4xl">
            Consulta inteligente
          </Heading>
          <Text as="h2" className="text-2xl text-gray-700">
            Exibindo todas as suas consultas inteligentes
          </Text>
        </div>

        <div className="p-2">
          {isLoading && (
            <div className="flex items-center justify-center mb-4 mt-8 h-full">
              <Spinner color="pink" size={35} />
            </div>
          )}

          {!isLoading && isError && (
            <Text className="text-center flex items-center justify-center mb-4 mt-8 h-full">
              Não foi possível carregar suas consultas.
            </Text>
          )}

          {!isLoading && !isError ? (
            smartConsultations && smartConsultations.length !== 0 ? (
              smartConsultations.map((item, index) => (
                <SmartConsultationElement item={item} index={index} />
              ))
            ) : (
              <div className="w-full text-center py-16 text-lg font-semibold">
                <Heading>
                  Não há nenhuma consulta inteligente para exibir.
                </Heading>
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  )
}
