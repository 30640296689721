import { Link } from 'react-router-dom'
import { GiftIcon } from '@heroicons/react/outline'

import { APP_URLS, BACKEND_URLS } from '../../../urls'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'

const CardInviteFriends = ({ isLoggedIn }) => {
  return (
    <div className="bg-white rounded p-4 overflow-hidden border border-gray-500">
      <div className="flex flex-col sm:flex-row sm:justify-between items-center bg-pink-600 p-3 rounded-md assina-shadow-sm">
        <Heading className="text-xl text-gray-100 font-semibold sm:w-3/4 order-2 sm:order-1">
          Dê 90% de desconto para uma pessoa especial e fique isento de sua
          próxima mensalidade
        </Heading>
        <div className="order-1 sm:order-2">
          <GiftIcon className="text-gray-100 h-12" />
        </div>
      </div>
      <Text className="mt-4">
        Entregue saúde para quem você ama: a sua indicação recebe 90% de
        desconto na primeira mensalidade e você fica isento de sua próxima
        mensalidade.
      </Text>
      {isLoggedIn && (
        <Link
          to={APP_URLS.INVITE_FRIENDS}
          className="w-full sm:w-64 h-12 bg-pink-600 text-lg text-white rounded text-center flex items-center justify-center font-semibold tracking-widest mt-4 hover:bg-pink-700"
        >
          <Heading as="h4">Saiba mais</Heading>
        </Link>
      )}
      {!isLoggedIn && (
        <a
          href={BACKEND_URLS.__LOGIN_AND_REDIRECT(BACKEND_URLS.SPA_HOME)}
          className="w-full sm:w-64 h-12 bg-pink-600 text-lg text-white rounded text-center flex items-center justify-center font-medium tracking-widest mt-4 hover:bg-pink-700"
        >
          Acesse sua conta
        </a>
      )}
    </div>
  )
}

export default CardInviteFriends
