import { useContext } from 'react'
import styled from 'styled-components'
import { ModalContext } from '../../../contexts/ModalContext'

import { ReactComponent as Svg } from '../../../assets/svg/Estetoscopio.svg'
import { PremiumCard } from '../../Plans'

const CardSignPlan = () => {
  const { handleModal } = useContext(ModalContext)

  return (
    <div className="bg-white rounded py-4 px-6 justify-end items-center flex-wrap flex-row-reverse overflow-hidden border border-gray-500 flex">
      <div className="flex items-center justify-center">
        <Svg width="90" />
      </div>
      <Content className="w-4/5">
        <Title className="text-xl text-teal-600 font-medium sm:w-3/4">
          Precisa de um{' '}
          <span className="text-pink-600">médico especialista</span>?
        </Title>
        <p className="text-gray-900">
          Seja um usuário premium por apenas R$ 49,90 e agende sua consulta com
          especialista gratuitamente por telemedicina!{' '}
        </p>

        <p className="text-pink-900 mt-4">
          Quero me tornar usuário Premium e agendar minha
          <br />
          <span className="text-pink-600">consulta gratuita.</span>
        </p>

        <Button
          onClick={() => {
            handleModal(
              <div className="max-w-lg overflow-auto">
                <PremiumCard />
              </div>
            )
          }}
          type="button"
          className="mt-2 bg-pink-600 hover:bg-pink-700 text-white py-2 cursor-pointer rounded-md text-center flex items-center justify-center font-medium tracking-widest focus:outline-none text-base px-8"
        >
          Assinar agora
        </Button>
      </Content>
    </div>
  )
}

export default CardSignPlan

const Title = styled.div`
  color: #540620;
`

const Content = styled.div`
  @media (max-width: 530px) {
    width: 100%;
    text-justify: inter-word;
  }
`

const Button = styled.button`
  @media (max-width: 530px) {
    width: 100%;
  }
`
