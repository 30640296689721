const Animate = {
  initial: {
    opacity: 0,
    y: '-15vh'
  },
  in: {
    opacity: 1,
    y: '0'
  },
  exit: {
    opacity: 0,
    y: '+15vh'
  }
}

export default Animate
