import React, { useEffect, useMemo, useState } from 'react'
import { Chip, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { withStyles } from '@material-ui/styles'
import { throttle } from 'lodash'
import { getEnums } from 'medicalRecord/services/api'
import { toast } from 'react-toastify'
import { ToastInvalidStyles } from 'utils/ToastStyles'
import bugsnag from 'lib/bugsnag'

const Input = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      minHeight: 48,
      alignItens: 'center',
      '& fieldset': {
        borderRadius: 8
      }
    }
  }
})(TextField)

const AutoCompleteSelectSearch = ({
  name,
  value,
  setValue,
  url,
  urlBack = '/prontuario-v1'
}) => {
  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)

  const searchEnums = async query => {
    setLoading(true)
    try {
      const response = await getEnums(url, query, urlBack)
      setOptions(response.data.data)
    } catch (err) {
      toast(`Erro ao carregar ${name}(s)`, ToastInvalidStyles)
      bugsnag.notify(err)
    } finally {
      setLoading(false)
    }
  }

  const memoSearchEnums = useMemo(
    () => throttle(query => searchEnums(query), 200),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (value === '') {
      setOptions(value ? [value] : [])
    }

    memoSearchEnums(inputValue || '')
  }, [value, inputValue, memoSearchEnums])

  return (
    <div className="flex flex-col w-full ">
      <label
        className="text-base font-bold text-gray-900 mb-2 font-lato"
        htmlFor="cid"
      >
        {name}
      </label>
      <Autocomplete
        multiple
        fullWidth
        className="w-full"
        id="combo-box-demo"
        options={options}
        value={value}
        loading={loading}
        filterOptions={x => x}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.label
        }
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options)
          setValue(newValue)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={typeof option === 'string' ? option : option.label}
              {...getTagProps({ index })}
              color="secondary"
            />
          ))
        }
        renderInput={params => (
          <Input
            {...params}
            variant="outlined"
            id="cid"
            fullWidth
            placeholder={value.length > 0 ? '' : 'Selecione uma opção'}
          />
        )}
      />
    </div>
  )
}

export default AutoCompleteSelectSearch
