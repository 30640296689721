import { createContext, ReactNode, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { handleSignIn, handleSignOut } from 'services/authService'
import { backendServices } from 'services/AxiosInstances'

import { getCookie, setCookie } from 'utils/cookie'
import { USER_COOKIE, AUTH_COOKIE, MAX_AGE_COOKIE } from 'utils/constants'
import { APP_URLS } from 'urls'

import posthog from 'lib/posthog'
import oneSignal from 'lib/oneSignal'

import ISignIn from 'types/SignIn'
import IUser, { IFormatedUser } from 'types/User'

const storedUser = getCookie(USER_COOKIE)
const storedToken = getCookie(AUTH_COOKIE)

export const formatUser = (user: IUser): IFormatedUser => ({
  name: user.nome,
  birth: user.data_nascimento,
  phone: user.telefone,
  mobilePhone: user.celular,
  billingId: user.id_cobranca,
  isFree: user.is_free,
  isUnlimited: user.is_unlimited,
  account_holder: user.titular,
  onboarding_done: user.genero ? true : false,
  isStaff: user.is_staff,
  isMedico: user.is_medico,
  cpf: user.cpf,
  email: user.email,
  id: user.id,
  isActive: user.is_active,
  senha_temporaria: user.senha_temporaria ? true : false,
  gender: user.genero,
  specialties: user.especialidades
})

interface IAuthContext {
  isLoggedIn: boolean
  user: IFormatedUser
  updateUser: (newData: IFormatedUser) => void
  signIn: ({ username, password }: ISignIn) => Promise<void>
  signOut: () => void
  isLoading: boolean
  error: string | null
}

const AuthContext = createContext({} as IAuthContext)

interface IAuthProvider {
  children: ReactNode
}

function AuthProvider({ children }: IAuthProvider) {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    storedUser ? true : false
  )
  const [user, setUser] = useState<IFormatedUser>(
    storedUser && JSON.parse(storedUser)
  )
  const [error, setError] = useState<string | null>(null)

  const browserHistory = useHistory()

  useEffect(() => {
    if (storedUser && storedToken) {
      backendServices.defaults.headers['Authorization'] = `Token ${storedToken}`

      setIsLoggedIn(true)

      posthog.identifyOrOptOut()
      oneSignal.identify()
    }
  }, [])

  const { isLoading, mutateAsync } = useMutation(handleSignIn)

  // With react-query mutation this got too complex :/
  // For posts and updates, there is no motive to use it
  const signIn = async ({ username, password }: ISignIn) => {
    await mutateAsync(
      { username, password },
      {
        onSettled: data => {
          if (data?.error) {
            return setError(data.error)
          }

          if (data?.data) {
            const formatedUser = formatUser(data.data)

            setUser(formatedUser)

            setIsLoggedIn(true)

            setError(null)
          }
        }
      }
    )
  }

  const signOut = () => {
    handleSignOut()
    setIsLoggedIn(false)
    browserHistory.replace(APP_URLS.WELCOME)
  }

  const updateUser = (newData: IFormatedUser) => {
    const newUser: IFormatedUser = { ...user, ...newData }

    setUser(newUser)

    setCookie(USER_COOKIE, JSON.stringify(newUser), {
      expires: MAX_AGE_COOKIE
    })
  }

  const providerValue = {
    isLoggedIn,
    user,
    updateUser,
    signIn,
    signOut,
    isLoading,
    error
  }

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
