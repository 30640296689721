export const SPECIALTY_ENDOCRINE_ID = 18

export const SPECIALTY_GP_ID = 15 // (eg General Practitioner or "Clinico Geral")

export const FLICCHAT_RATING_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLScQnmLFNe-KrFgHJvky01RsE5_xq4QRzFvi157_iRLdojK7qA/viewform'

export const FLICCHAT_SDK_URL = 'https://sdk.flicchat.com/fcsdk.js'

export const STATUS_POLLING_INTERVAL = 5000 // 5 seconds

export const APPOINTMENT_STATUS = {
  STARTED: 4,
  NOT_STARTED: 1
}

export const APPOINTMENT_STATUS_ENTITY = {
  DOCTOR: 'D',
  PATIENT: 'P'
}

export const AUTH_COOKIE = 'kompa.token'

export const USER_COOKIE = 'kompa.user'

export const CURRENT_APPOINTMENT_COOKIE = 'kompa.consulta_atual'

export const MAX_AGE_COOKIE = 60 * 60 * 24 // 24 hours
