/* eslint-disable import/no-named-as-default */
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

// Contexts
import { ModalProvider } from './contexts/ModalContext'
import { NotificationProvider } from './contexts/NotificationContext'
import { AuthProvider } from 'contexts/AuthContext'

// Utils
import theme from 'styles/theme'

// Mui Theme
import KompaThemeProvider from 'styles/muiTheme/KompaThemeProvider'

import Routes from 'routes'

const queryClient = new QueryClient()

/*
  Only setup providers that both user and internal user need to use.
  If possible, setup only on the routes you need
*/

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <KompaThemeProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ModalProvider>
              <NotificationProvider>
                <AuthProvider>
                  <Routes />
                </AuthProvider>
              </NotificationProvider>
            </ModalProvider>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </KompaThemeProvider>
    </ThemeProvider>
  )
}
