import { useState, useEffect, useContext } from 'react'
import { getUserFiles } from '../../services/api'

import { GlobalContext } from '../../contexts/GlobalContext'

import ExamHeader from './Partials/ExamHeader'
import UploadComponent from './UploadComponent'
import ListTabPanel from './ListTabPanel'
import SuccessToast from './Partials/SuccessToast'
import ErrorToast from './Partials/ErrorToast'
import { AnimatedDiv, Section } from '../ui'
import { Parent } from '../Booking/SelectAppointmentType/Animate'
import WarningToast from './Partials/WarningToast'

export default function ExamUpload({
  success,
  error,
  warning,
  message,
  tab,
  setTab,
  OnUploadForm = () => {},
  OnEditForm = () => {},
  OnDeleteForm = () => {},
  OnFileRejection = () => {}
}) {
  const { user } = useContext(GlobalContext)
  const [userFiles, setUserFiles] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(null)

  const setFiles = async id => {
    const type_param = tab === 'exames' ? 'exam' : 'attach'
    const res = await getUserFiles(
      id,
      `?page=${page}&upload_type=${type_param}`
    )
    setTotalPages(res.total_pages)
    setUserFiles(res.data)
  }

  useEffect(() => {
    setFiles(user.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (page !== 1) setPage(1)
    else setFiles(user.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
    <>
      <div className="flex flex-col w-full h-auto">
        <ExamHeader />
        {(error || success || warning) && (
          <AnimatedDiv
            variants={Parent}
            initial="initial"
            animate="in"
            exit="exit"
            className="container py-8 max-w-xl w-full mx-auto pb-0"
          >
            <SuccessToast success={success} message={message} />
            <ErrorToast error={error} message={message} />
            <WarningToast status={warning} message={message} />
          </AnimatedDiv>
        )}
        <AnimatedDiv
          variants={Parent}
          initial="initial"
          animate="in"
          exit="exit"
          className="max-w-xl w-full sm:py-8 sm:mx-auto"
        >
          <Section
            className="flex flex-col sm:rounded sm:assina-shadow-sm"
            as="div"
            isContainer={false}
          >
            <UploadComponent
              onLoadFile={file => OnUploadForm(file)}
              onFileRejection={OnFileRejection}
            />
            <ListTabPanel
              tab={tab}
              setTab={setTab}
              userFiles={userFiles}
              OnEdit={OnEditForm}
              OnDelete={OnDeleteForm}
              setPage={setPage}
              totalPages={totalPages}
              page={page}
            />
          </Section>
        </AnimatedDiv>
      </div>
    </>
  )
}
