import styled from 'styled-components'

export const Message = styled.p`
  margin-top: 0.5rem;
  font-size: 1.25rem;
  max-width: 32rem;

  @media (max-width: 645px) {
    max-width: 25rem;
  }

  @media (max-width: 525px) {
    max-width: 20rem;
  }

  @media (max-width: 445px) {
    max-width: 15rem;
  }

  @media (max-width: 355px) {
    max-width: 12rem;
  }
`

export const Div = styled.div`
  width: 100%;
  background: #fbfbfb;
  min-height: ${({ notHeight }) =>
    notHeight ? '' : 'calc(100vh - var(--height-header-footer))'};
`

export const DivChildrensBorderTop = styled.div`
  .border-top {
    margin-top: 0.8rem;
    border-top: 1px solid #eaeaea;
  }
`

export const Container = styled.div`
  overflow: auto;
  background: white;

  width: 100%;

  color: white;
`
