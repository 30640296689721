import styled from 'styled-components'

import { BACKGROUNDS } from '../../../../urls'
import { Icon } from '../../../ui'
import RoundButton from '../RoundButton'

const HowToContainer = styled.div`
  background-image: url(${BACKGROUNDS.BG_HOW_TO});
  background-position: bottom;
  background-repeat: no-repeat;

  height: 100%;
  /* height: ${props => (props.width < 769 ? '100%' : 'auto')}; */

  background-color: ${props => props.theme.colors.white1};

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 35px;
`

export default function HowTo({
  backToBeggining,
  subject,
  measureTypes,
  width
}) {
  return (
    <HowToContainer>
      <h1 className="font-medium text-3xl text-gray-900">{subject}</h1>
      <h1 className="text-3xl text-pink-600">Como coletar?</h1>
      <span className="text-xl text-gray-900 tracking-wider my-6">
        {measureTypes.find(item => item.name === subject).howTo}
      </span>
      <div className="text-center">
        {width < 769 ? (
          <RoundButton primary onClick={backToBeggining}>
            <Icon name="back" size={27} />
          </RoundButton>
        ) : (
          <span
            className="text-3xl text-pink-600 tracking-wider cursor-pointer"
            onClick={backToBeggining}
          >
            Voltar
          </span>
        )}
      </div>
    </HowToContainer>
  )
}
