/* eslint-disable no-unused-expressions */
import { useContext, useRef, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { Controller, useForm } from 'react-hook-form'
import { useReactToPrint } from 'react-to-print'

import { Form, Input, Select } from '../elements'
import { MedicalCertificate } from '.'

import { APIContext } from '../../config/contexts/APIContext'
import { WindowContext } from '../../config/contexts/WindowContext'

// Utils
import {
  persistOnStorage,
  checkStorage,
  formatDate,
  fullDate,
  formatToData
} from '../../utils/Utils'

const Documentos = () => {
  const { handleForms, certificates, loadCertificates } = useContext(APIContext)

  const { persistDocuments, setPersistDocuments, doctor, patient } =
    useContext(WindowContext)

  const { register, control, handleSubmit, reset, setValue } = useForm({
    defaultValues: persistDocuments
  })
  const componentRef = useRef()

  const [readyToPrint, setReadyToPrint] = useState(false)
  const [save, setSave] = useState(false)
  const [hour, setHour] = useState('')

  useEffect(() => {
    loadCertificates()

    const check = checkStorage('documento')
    if (!check) return
    if (check.save) {
      setSave(true)
      setHour(check.hour)
    }
    setPersistDocuments(check)
    reset(check)

    setTimeout(() => {
      check.texto_atestado.length !== 0 && setReadyToPrint(true)
    }, 1000)
    // eslint-disable-next-line
  }, [])

  const handleBlur = data => {
    setPersistDocuments(data)
    persistOnStorage(data, 'documento')

    if (data.modelo.length !== 0) {
      setValue(
        'texto_atestado',
        data.texto_atestado
          .replace('#nome_paciente', patient.nome)
          .replace('#rg_paciente', patient.cpf)
      )
      setReadyToPrint(true)
    }

    setSave(false)
  }

  const onSubmit = data => {
    const date = new Date()

    setPersistDocuments(data)
    const newHour = formatDate(date)
    setHour(newHour)
    persistOnStorage({ ...data, save: true, hour: newHour }, 'documento')

    const formData = {
      data: formatToData(data.data),
      documento: `${data.modelo.label}, ${data.texto_atestado}`
    }

    handleForms(formData, 'documento')
    setSave(true)
  }

  const handleExamTemplate = e => {
    if (e.value.length !== 0) {
      setValue(
        'texto_atestado',
        e.value
          .replace('#nome_paciente', patient.nome)
          .replace('#rg_paciente', patient.cpf)
      )
      setReadyToPrint(true)
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  return (
    <Form
      title="Documentos e atestados"
      onSubmit={handleSubmit(onSubmit)}
      save={save}
      hour={hour}
      print={readyToPrint}
      handlePrint={handlePrint}
    >
      <Controller
        name="data"
        label="Data"
        defaultValue=""
        blurDiv={handleSubmit(handleBlur)}
        control={control}
        as={NumberFormat}
        customInput={Input}
        format="##/##/####"
        placeholder="__/__/____"
        mask={['_', '_', '_', '_', '_', '_', '_', '_']}
      />

      <Controller
        name="modelo"
        defaultValue=""
        render={props => (
          <Select
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            options={certificates}
            blurDiv={handleSubmit(handleBlur)}
            isClearable
            label="Modelo de atestados"
            placeholder="Selecione..."
            onChange={event => {
              props.onChange(event)
              handleExamTemplate(event)
            }}
          />
        )}
        control={control}
      />

      <Input
        label="Conteúdo do atestado"
        name="texto_atestado"
        type="textarea"
        placeholder="Digite..."
        rows="7"
        inputRef={register}
        defaultValue=""
        blurDiv={handleSubmit(handleBlur)}
      />

      {readyToPrint && (
        <div className="hidden">
          <MedicalCertificate
            ref={componentRef}
            doctor={doctor}
            date={fullDate(persistDocuments.data)}
            text={persistDocuments.texto_atestado}
          />
        </div>
      )}
    </Form>
  )
}

export default Documentos
