import React, { useEffect, useState } from 'react'
import Switch from 'react-switch'

const TypeSwitch = ({ selectOptionType, bookingOptions }) => {
  const [tele, setTele] = useState(false)
  const [inPerson, setInPerson] = useState(false)

  useEffect(() => {
    if (bookingOptions) {
      if (bookingOptions.type === 'telemedicina') return setTele(true)
      if (bookingOptions.type === 'presencial') return setInPerson(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkOption = type => {
    switch (type) {
      case 'tele': {
        setInPerson(false)
        setTele(true)
        return selectOptionType('type', 'telemedicina')
      }
      case 'in-person': {
        setTele(false)
        setInPerson(true)
        return selectOptionType('type', 'presencial')
      }
      default:
        break
    }
  }

  return (
    <div className="mt-4">
      <div
        className="mt-4 p-4 rounded-md cursor-pointer select-none"
        onClick={() => checkOption('tele')}
      >
        <Switch
          checked={tele}
          onChange={() => checkOption('tele')}
          boxShadow="0px 2.65625px 5.3125px 0.53125px rgba(0, 0, 0, 0.05)"
          offColor="#ebebeb"
          onColor="#008F8F"
          offHandleColor="#bfbfbf"
          onHandleColor="#ffffff"
          uncheckedIcon={false}
          checkedIcon={false}
          height={24}
          width={48}
        />

        <h3 className="text-xl text-teal-600 font-medium my-2">Telemedicina</h3>
        <p className="text-lg text-gray-900">
          <span className="font-medium">Chamada de vídeo</span>, pelo computador
          ou celular, capaz de resolver até{' '}
          <span className="font-medium">80%</span> dos casos.
        </p>
      </div>
      <hr className="bg-gray-100 m-4" />
      <div
        className="p-4 rounded-md cursor-pointer select-none"
        onClick={() => checkOption('in-person')}
      >
        <Switch
          checked={inPerson}
          onChange={() => checkOption('in-person')}
          boxShadow="0px 2.65625px 5.3125px 0.53125px rgba(0, 0, 0, 0.05)"
          offColor="#ebebeb"
          onColor="#008F8F"
          offHandleColor="#bfbfbf"
          onHandleColor="#ffffff"
          uncheckedIcon={false}
          checkedIcon={false}
          height={24}
          width={48}
        />

        <h3 className="text-xl text-teal-600 font-medium my-2">Presencial</h3>
        <p className="text-lg text-gray-900">
          <span className="font-medium">Consulta em uma unidade.</span> Nossos
          especialistas estão prontos para te ajudar!
        </p>
      </div>
    </div>
  )
}

export default TypeSwitch
