import Head from 'components/common/Head'
import LoadingBackdrop from 'components/layouts/LoadingBackdrop'
import VirtualRoomLayout from 'components/layouts/VirtualRoom/VirtualRoomLayout'

import useMedicalRecord from 'hooks/useMedicalRecord'

export default function VirtualRoomPage() {
  const { appointment, isLoadingAppointment } = useMedicalRecord()

  if (isLoadingAppointment) {
    return <LoadingBackdrop isOpen />
  }

  return (
    <>
      <Head title={`${appointment.paciente.nome} - Consulta`} />
      <div className="h-full w-full" id="PEP">
        <VirtualRoomLayout appointment={appointment} />
      </div>
    </>
  )
}
