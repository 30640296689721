import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import { SummarySection, Button, Icon } from '../../elements'
import MedicalCertificate from '../MedicalCertificate'
import { fullDate } from 'commons/utils/utils'

const PartialDocuments = ({ info, isMultiple, indexOnData, maxIndex }) => {
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  return (
    <>
      {Object.keys(info).length !== 0 && (
        <>
          <SummarySection
            createdBy={info.created_by.nome}
            date={info.data_cadastro.split(' ')[1]}
            title="Documentos e Atestados"
            isMultiple={isMultiple}
            indexOnData={indexOnData}
            maxIndex={maxIndex}
          >
            <p>{info.documento}</p>
            <div className="print-document">
              <Button
                type="button"
                color="white"
                className="button-print"
                onClick={handlePrint}
              >
                <Icon name="printer" />
                Imprimir
              </Button>
            </div>
            <div className="hidden">
              <MedicalCertificate
                ref={componentRef}
                doctor={{ name: info.created_by.nome }}
                date={fullDate(info.data_cadastro)}
                text={info.documento}
              />
            </div>
          </SummarySection>
        </>
      )}
    </>
  )
}

export default PartialDocuments
