export const sexOptions = [
  { value: 'F', label: 'Feminino' },
  { value: 'M', label: 'Masculino' }
]

export const genderOptions = [
  { value: 'Feminino', label: 'Feminino' },
  { value: 'Masculino', label: 'Masculino' },
  { value: 'Homem Transgênero', label: 'Homem Transgênero' },
  { value: 'Mulher Transgênero', label: 'Mulher Transgênero' },
  { value: 'Mulher Transexual', label: 'Mulher Transexual' },
  { value: 'Cisgênero', label: 'Cisgênero' },
  { value: 'Não sei responder', label: 'Não sei responder' },
  {
    value: 'Prefiro não responder',
    label: 'Prefiro não responder'
  },
  { value: 'Outro', label: 'Outro' }
]

export const maritalStatusOptions = [
  { value: 'S', label: 'Solteiro(a)' },
  { value: 'C', label: 'Casado(a)' },
  { value: 'D', label: 'Divorciado(a)' },
  { value: 'V', label: 'Viúvo(a)' },
  { value: 'Outro', label: 'Outro' }
]

export const buyReasonOptions = [
  { value: '1', label: 'Cobertura / Acompanhamento' },
  { value: '2', label: 'Tratamento' },
  { value: '3', label: 'Consultas / Exames' },
  { value: '4', label: 'Empresarial' }
]

export const dependencyRelationOptions = [
  {
    value: '1',
    label: 'Beneficiário titular (maior ou menor de idade)'
  },
  { value: '3', label: 'Conjugue / Companheiro' },
  { value: '4', label: 'Filho / Filha' },
  { value: '6', label: 'Enteado / Enteada' },
  { value: '8', label: 'Pai / Mãe' },
  { value: '10', label: 'Agregado / Outros' }
]
