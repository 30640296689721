/* eslint-disable no-unused-expressions */
import { createContext, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import {
  handleMultipart,
  getFiles,
  finishForm,
  getRecords,
  searchEnums,
  getEnum,
  memedToken,
  rules,
  userRules,
  deleteRule,
  postHistoricalExam,
  getHistoricalExams
} from '../../services/api'
import { WindowContext } from './WindowContext'
import { fetchApi } from 'services/api'
import Modal from 'components/ui/Modal/ReactModal'
import { Button } from '../../components/elements'

import { updateStatus } from '../../services/appointmentService'
import useMedicalRecord from 'hooks/useMedicalRecord'
import { APP_URLS } from 'urls'
import { preservePatientIdParam } from 'utils/helpers'

export const APIContext = createContext({
  diseases: () => null,
  doctors: [],
  habits: () => null,
  lifestyle: () => null,
  complaints: () => null,
  certificates: () => null,
  setCertificates: () => null,
  handleForms: () => null,
  postForm: () => null,
  attachmentHandler: () => null,
  sendHistoricalExam: () => null,
  getFiles: () => null,
  handleEnumSearch: () => null,
  loadAnamnesisEnums: () => null,
  loadCertificates: () => null,
  isOpenError: () => null,
  openError: () => null,
  msgError: () => null,
  setMsgError: () => null,
  getMemedToken: () => null,
  getAllRules: () => null,
  getUserRules: () => null,
  deleteUserRule: () => null,
  loadUserQuiz: () => null,
  getUserKarmenData: () => null,
  getHistoricalExams_: () => null,
  quiz: {
    preenchido_em: '2020-01-01',
    respostas: [
      {
        id: 1,
        pergunta: '...',
        resposta: '...'
      }
    ]
  },
  setQuiz: () => null,
  loading: () => null,
  specificError: () => null,
  getDoctors: () => null,
  reloadQueixas: () => null,
  loadingFinishedForm: false,
  karmenData: false
})

const APIProvider = ({ children }) => {
  // User States - WindowContext
  const { resetAllInfo, setLoadingRecords } = useContext(WindowContext)

  const { appointment, patient } = useMedicalRecord()

  const history = useHistory()

  // Enums
  const [diseases, setDiseases] = useState([])
  const [doctors, setDoctors] = useState([])
  const [habits, setHabits] = useState([])
  const [lifestyle, setLifestyle] = useState([])
  const [complaints, setComplaints] = useState([])
  const [certificates, setCertificates] = useState([])
  const [loadingFinishedForm, setLoadingFinishedForm] = useState(false)
  const [karmenData, setKamenData] = useState(null)

  const [quiz, setQuiz] = useState({
    preenchido_em: '2020-01-01',
    respostas: [
      {
        id: 1,
        pergunta: '...',
        resposta: '...'
      }
    ]
  })
  const [loading, setLoading] = useState(true)

  // Error Modal
  const [isOpenError, openError] = useState(false) // TODO: Mudar para WindowContext
  const [msgError, setMsgError] = useState('')

  const [isOpenConfirm, setIsOpenConfirm] = useState(false)

  const genericError = error => {
    openError(true)

    setMsgError(
      'Ocorreu um erro desconhecido. Por favor, entre em contato com o nosso suporte através do WhatsApp (011) 99223-0894.'
    )

    throw new Error(error)
  }

  const specificError = msg => {
    openError(true)

    setMsgError(msg)

    throw new Error(msg)
  }

  // Info Handlers

  const handleGetRecords = async filterRecords => {
    let objRecords

    setLoadingRecords(true)

    try {
      const data = await getRecords(
        patient ? patient.id : appointment.paciente.id,
        filterRecords
      )
      setLoadingRecords(false)
      objRecords = data.length !== 0 ? { empty: false, data } : { empty: true }
    } catch (error) {
      genericError(error)
    } finally {
      setLoadingRecords(false)
    }
    return objRecords
  }

  const getUserKarmenData = async ({ cpf }) => {
    try {
      const data = await fetchApi(`/api/v1/karmen-data?cpf=${cpf}`)
      setLoading(false)
      setKamenData(data)
    } catch (error) {
      setLoading(false)
      throw new Error(error)
    }
  }

  const reloadQueixas = async () => {
    try {
      await getEnum('queixas').then(data => setComplaints(data))
    } catch (error) {
      genericError(error)
    }
  }

  const loadAnamnesisEnums = async () => {
    try {
      await getEnum('doencas').then(data => setDiseases(data))
      await getEnum('habitos').then(data => setHabits(data))
      await getEnum('estilos-vida').then(data => setLifestyle(data))
      await getEnum('queixas').then(data => setComplaints(data))
    } catch (error) {
      genericError(error)
    }
  }

  const getDoctors = async () => {
    try {
      await fetchApi('/prontuario-v1/doctors').then(data =>
        setDoctors(data.data)
      )
    } catch (error) {
      genericError(error)
    }
  }

  const loadCertificates = async () => {
    try {
      await getEnum('modelo_atestado').then(data => setCertificates(data))
    } catch (error) {
      genericError(error)
    }
  }

  const handleEnumSearch = async (url, search, app_url = '/prontuario-v1') => {
    if (search.length < 3) return false

    try {
      const res = await searchEnums(url, search, app_url)
      return res
    } catch (error) {
      return genericError(error)
    }
  }

  // ===

  // Memed

  const getMemedToken = async () => {
    try {
      const res = await memedToken().then(data => data)
      return res.token
    } catch (error) {
      return genericError(error)
    }
  }

  const getAllRules = async () => {
    try {
      const res = await rules()
      return res
    } catch (error) {
      return genericError(error)
    }
  }

  const getUserRules = async () => {
    try {
      const res = await userRules(
        patient ? patient.id : appointment.paciente.id
      )
      return res
    } catch (error) {
      genericError(error)
      throw new Error(error)
    }
  }

  const deleteUserRule = async rule => {
    try {
      const res = await deleteRule(
        patient ? patient.id : appointment.paciente.id,
        rule
      )
      return res
    } catch (error) {
      genericError(error)
      throw new Error(error)
    }
  }

  const sendHistoricalExam = data => {
    return postHistoricalExam({
      data,
      patientId: patient ? patient.id : appointment.paciente.id
    })
  }

  const getHistoricalExams_ = params => {
    return getHistoricalExams({
      params,
      patientId: patient ? patient.id : appointment.paciente.id
    })
  }

  // ===

  // Multipart

  const attachmentHandler = async (method, formData, isDelete) => {
    try {
      const res = await handleMultipart(
        patient ? patient.id : appointment.paciente.id,
        method,
        formData,
        isDelete
      )
      return res
    } catch (error) {
      return genericError(error)
    }
  }

  // ===

  // Form Handlers

  const queryClient = useQueryClient()

  const invalidateAllQueries = () => {
    queryClient.invalidateQueries('medicalRecordAppointment')
    queryClient.invalidateQueries('medicalRecordPatient')
    queryClient.invalidateQueries('medicalRecords')
  }

  const postForm = async () => {
    const form = localStorage.getItem('prontuario')
    const formElement = document.getElementById('form')

    if (!form) {
      setIsOpenConfirm(true)
      return false
    }

    setLoadingFinishedForm(true)

    try {
      const parsedData = JSON.parse(form)

      await finishForm(
        patient ? patient.id : appointment.paciente.id,
        parsedData.data
      )

      formElement && formElement.submit()

      resetAllInfo()

      setLoadingFinishedForm(false)

      if (appointment) {
        await updateStatus(appointment.id, 'finished')
        invalidateAllQueries()
        history.push(APP_URLS.VIRTUAL_CLINIC)
      } else {
        invalidateAllQueries()
        history.push(preservePatientIdParam(APP_URLS.VIRTUAL_CLINIC_PATIENT))
      }
    } catch (error) {
      setLoadingFinishedForm(false)
      return genericError(error)
    }
  }

  const absentPatient = async () => {
    await updateStatus(appointment.id, 'absent')

    history.push(APP_URLS.VIRTUAL_CLINIC)
  }

  const handleForms = (data, form) => {
    const prontuario = localStorage.getItem('prontuario')
    const parseProntuario = JSON.parse(prontuario)
    if (parseProntuario && parseProntuario.data[form]) {
      parseProntuario.data[form] = data
      const stringJson = JSON.stringify(parseProntuario)
      localStorage.setItem('prontuario', stringJson)
      return
    }
    const oldProntu = parseProntuario ? parseProntuario.data : null
    const newProntuario = {
      data: {
        ...oldProntu,
        [form]: data
      }
    }
    const stringJson = JSON.stringify(newProntuario)
    localStorage.setItem('prontuario', stringJson)
  }

  // ===

  const providerValue = {
    diseases,
    doctors,
    habits,
    lifestyle,
    complaints,
    certificates,
    setCertificates,
    handleForms,
    postForm,
    attachmentHandler,
    sendHistoricalExam,
    getFiles,
    handleEnumSearch,
    loadAnamnesisEnums,
    loadCertificates,
    isOpenError,
    openError,
    msgError,
    setMsgError,
    getMemedToken,
    getAllRules,
    getUserRules,
    deleteUserRule,
    quiz,
    setQuiz,
    loading,
    handleGetRecords,
    specificError,
    getDoctors,
    reloadQueixas,
    loadingFinishedForm,
    getUserKarmenData,
    karmenData,
    absentPatient,
    getHistoricalExams_
  }

  return (
    <APIContext.Provider value={providerValue}>
      <Modal
        isOpen={isOpenError}
        onRequestClose={() => openError(false)}
        contentLabel="finalizar atendimento"
      >
        <div>
          <div className="modal-header">
            <h1>Ocorreu um erro com sua requisição</h1>
          </div>
          <div className="modal-body">
            <p>{msgError}</p>
          </div>
          <footer className="modal-footer">
            <Button
              type="button"
              onClick={() => openError(false)}
              className="modal-button"
              color="primary"
            >
              OK
            </Button>
          </footer>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenConfirm}
        onRequestClose={() => setIsOpenConfirm(false)}
        contentLabel="finalizar atendimento"
      >
        <div>
          <div className="modal-header">
            <h1>Finalização</h1>
          </div>
          <div className="modal-body py-8">
            <p>
              Não há informações no formulário, será finalizado sem evoluções.
            </p>
          </div>
          <footer className="modal-footer">
            <Button
              type="button"
              className="button-back"
              color="white"
              onClick={() => setIsOpenConfirm(false)}
            >
              Voltar
            </Button>
            <Button
              type="button"
              onClick={() => {
                setIsOpenConfirm(false)
                if (appointment) {
                  updateStatus(appointment.id, 'finished').then(() => {
                    invalidateAllQueries()
                    history.push(APP_URLS.VIRTUAL_CLINIC)
                  })
                } else {
                  invalidateAllQueries()
                  history.push(preservePatientIdParam(APP_URLS.VIRTUAL_CLINIC))
                }
              }}
              className="modal-button"
              color="primary"
            >
              Finalizar
            </Button>
          </footer>
        </div>
      </Modal>
      {children}
    </APIContext.Provider>
  )
}

export default APIProvider
