import { createContext, useState, useEffect, useContext } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import useModal from '../hooks/useModal'

export const ModalContext = createContext({
  handleModal: content => null,
  setOpen: boolean => null,
  setCloseButton: boolean => null
})

const modalRoot = document.querySelector('#modal-root')

export const ModalProvider = ({ children }) => {
  const { isOpen, setOpen, handleModal, modalContent } = useModal()
  const [isBottom, setIsBottom] = useState(false)
  const [closeButton, setCloseButton] = useState(true)

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(modalRoot)
    } else {
      enableBodyScroll(modalRoot)
    }
  }, [isOpen])

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        setOpen,
        handleModal,
        modalContent,
        setIsBottom,
        setCloseButton,
        isBottom,
        closeButton
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export const useModalContext = () => {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error(`useModalContext must be used within a ModalProvider`)
  }
  return context
}
