import { ReactNode } from 'react'
import { MuiThemeProvider } from '@material-ui/core'

import KompaTheme from './theme'

interface IKompaThemeProvider {
  children: ReactNode
}

export default function KompaThemeProvider({ children }: IKompaThemeProvider) {
  return <MuiThemeProvider theme={KompaTheme}>{children}</MuiThemeProvider>
}
