const palette = {
  common: {
    black: '#000',
    white: '#fff'
  },
  background: {
    paper: 'rgba(251, 251, 251, 1)',
    default: 'rgba(242, 242, 242, 1)'
  },
  primary: {
    light: 'rgba(224, 86, 133, 1)',
    main: 'rgba(210, 14, 80, 1)',
    dark: 'rgba(126, 8, 48, 1)',
    contrastText: 'rgba(251, 251, 251, 1)'
  },
  secondary: {
    light: 'rgba(77, 177, 177, 1)',
    main: 'rgba(0, 143, 143, 1)',
    dark: 'rgba(0, 100, 100, 1)',
    contrastText: 'rgba(251, 251, 251, 1)'
  },
  error: {
    light: '#e57373',
    main: 'rgba(176, 0, 32, 1)',
    dark: 'rgba(158, 0, 28, 1)',
    contrastText: '#fff'
  },
  text: {
    primary: 'rgba(23, 23, 23, 1)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(164, 164, 164, 1)',
    hint: 'rgba(0, 0, 0, 0.38)'
  }
}

export default Object.freeze(palette)
