import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars'

// Options example
// eslint-disable-next-line no-unused-vars
const example = [
  {
    label: 'Feminino',
    value: '1'
  },
  {
    label: 'Masculino',
    value: '2'
  }
]

const SelectContainer = styled.div``

const InputContainer = styled.div``

const Input = styled.input`
  cursor: pointer;
  background: none;
`

const fadeIn = keyframes`
    0% { top: -20%; opacity: 0; }
    100% { top: 0; opacity: 1;}
`

const Dropdown = styled.div`
  display: ${props => (props.openDropdown ? 'inline' : 'none')};
`

const DropdownItems = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  min-width: 195px;
  min-width: 220px;
  height: 200px;
  max-height: 200px;

  padding: 15px 0;

  overflow-y: hidden;

  background: ${({ theme }) => theme.colors.gray5};

  ${({ theme }) => theme.mixins.shadow_medium}

  ${({ theme }) => theme.mixins.round_light}

    animation: ${fadeIn};
  animation-duration: 0.2s;
`

const DropdownItem = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.gray6};

  padding: 4px 10px;

  cursor: pointer;

  z-index: 3;

  &:hover {
    background: ${({ theme }) => theme.colors.teal4};
  }
`

const NotFound = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.gray6};

  padding: 4px 10px;
`

const Scrollbar = styled(Scrollbars)`
  div {
    display: flex;
    flex-direction: column;
  }
`

const Select = ({ placeholder, options, type, name, onChange, creatable }) => {
  const [inputValue, setInputValue] = useState('')
  const [results, setResults] = useState(options)

  const [openDropdown, setOpenDropdown] = useState(false)
  const [dropOnFocus, setDropOnFocus] = useState(true)

  useEffect(() => {
    if (inputValue === '') return
    onChange(name, inputValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  useEffect(() => {
    if (results.length > 10) setDropOnFocus(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterOptions = e => {
    const { value } = e.target
    const trimmed = value.replace('"', '')

    setOpenDropdown(true)
    const filtered = options.filter(item =>
      item.label.toLowerCase().includes(trimmed.toLowerCase())
    )

    if (creatable) {
      if (filtered.length === 0) {
        filtered.push({ value: 253, label: `"${trimmed}"` })

        setInputValue(trimmed.replace('"', ''))
        return setResults(filtered)
      }
    }

    setInputValue(trimmed)
    setResults(filtered)
  }

  return (
    <SelectContainer>
      <InputContainer>
        <Input
          type={type || 'text'}
          onFocus={() => setOpenDropdown(dropOnFocus)}
          onBlur={() => {
            setTimeout(() => {
              setOpenDropdown(false)
            }, 200)
          }}
          placeholder={placeholder}
          value={inputValue}
          onChange={e => filterOptions(e)}
          name={name}
        />
      </InputContainer>
      <Dropdown openDropdown={openDropdown}>
        <div className="relative">
          <DropdownItems>
            <Scrollbar>
              {results.length !== 0 ? (
                results.map(item => (
                  <DropdownItem
                    key={item.value}
                    onClick={() => {
                      setInputValue(item.label.replace(/"/g, ''))
                    }}
                  >
                    {item.label}
                  </DropdownItem>
                ))
              ) : (
                <NotFound>Nenhum resultado encontrado</NotFound>
              )}
            </Scrollbar>
          </DropdownItems>
        </div>
      </Dropdown>
    </SelectContainer>
  )
}

export default Select
