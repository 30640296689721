import { InformationCircleIcon } from '@heroicons/react/solid'

import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import DashboardItem from '../DashboardItem'

import useResize from 'hooks/useResize'

import {
  AMOUNT_OF_COLUMNS,
  CARD_MIN_WIDTH,
  DASHBOARD_MIN_WIDTH
} from 'utils/accreditationUtils'

import { AccRequest } from 'types/AccRequest'

const headingHeight = 209
const columnGaps = 30

interface DashboardColumnProps {
  title: string
  requests: AccRequest[]
  onRequestClick: (request: AccRequest) => void
}

export default function DashboardColumn({
  title,
  requests,
  onRequestClick
}: DashboardColumnProps) {
  const { height: screenHeight, width: screenWidth } = useResize()

  const isStatusNeeded = title === 'Finalizado'

  const columnWidth =
    screenWidth >= DASHBOARD_MIN_WIDTH
      ? screenWidth / AMOUNT_OF_COLUMNS - columnGaps
      : CARD_MIN_WIDTH

  const columnHeight = screenHeight - headingHeight

  return (
    <div
      className="bg-gray-100 rounded-md assina-shadow-sm p-3 flex-shrink-0 transition-all duration-150"
      style={{ height: 'min-content', width: columnWidth }}
    >
      <div className="flex justify-between mb-4">
        <div className="flex items-center space-x-2">
          <InformationCircleIcon className="text-pink-600 h-5" />
          <Heading className="font-semibold text-base">{title}</Heading>
        </div>
        <Text className="text-sm text-gray-600">{requests.length}</Text>
      </div>
      <div
        className="space-y-4 overflow-x-hidden overflow-y-auto"
        id="dashboard-column-scrollbar"
        style={{ maxHeight: columnHeight }}
      >
        {requests.length === 0 ? (
          <Text className="text-center">Nenhuma solicitação</Text>
        ) : (
          requests.map(item => (
            <DashboardItem
              isStatusNeeded={isStatusNeeded}
              request={item}
              onClick={() => onRequestClick(item)}
              key={item.id}
            />
          ))
        )}
      </div>
    </div>
  )
}
