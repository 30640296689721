import cn from 'classnames'

import InputPassword from '../InputPassword'

const PasswordFieldValidation = ({
  label,
  placeholder,
  classNameLabel,
  onChange,
  value,
  strength,
  useRef,
  ...others
}) => {
  return (
    <>
      <div className="content-password-zxcvbn">
        <InputPassword
          label={label}
          placeholder={placeholder}
          classNameLabel={classNameLabel}
          onChange={onChange}
          value={value}
          validation
          useRef={useRef}
          {...others}
        />
        <div
          className={cn(
            'strength-meter mt-2',
            value.length > 0 ? 'visible' : 'invisible'
          )}
        >
          <div className="strength-meter-fill" data-strength={strength} />
        </div>
      </div>
    </>
  )
}

export default PasswordFieldValidation
