import {
  HomeIcon,
  CalendarIcon,
  ChatIcon,
  UserCircleIcon,
  VideoCameraIcon,
  BookOpenIcon,
  UsersIcon
} from '@heroicons/react/solid'

import { APP_URLS } from 'urls'

const iconClassNames = 'fill-current h-6'

const navItems = [
  {
    name: 'Menu',
    icon: <HomeIcon className={iconClassNames} />,
    link: APP_URLS.HOME,
    allUsers: true
  },
  {
    name: 'Agenda',
    icon: <CalendarIcon className={iconClassNames} />,
    link: APP_URLS.SCHEDULE
  },
  {
    name: 'Ajuda',
    icon: <ChatIcon className={iconClassNames} />,
    link: APP_URLS.HELP
  },
  {
    name: 'Credenciamento',
    icon: <BookOpenIcon className={iconClassNames} />,
    link: APP_URLS.ADMINISTRATIVE.PRESENTIAL_DASHBOARD,
    onlyAdmin: true
  },
  {
    name: 'Usuários',
    icon: <UsersIcon className={iconClassNames} />,
    link: APP_URLS.ADMINISTRATIVE.USERS,
    onlyAdmin: true,
    onlyDoctor: true
  },
  {
    name: 'Consultório',
    icon: <VideoCameraIcon className={iconClassNames} />,
    link: APP_URLS.VIRTUAL_CLINIC,
    onlyDoctor: true
  },
  {
    name: 'Perfil',
    icon: <UserCircleIcon className={iconClassNames} />,
    link: APP_URLS.PROFILE,
    allUsers: true
  }
]

export default navItems
