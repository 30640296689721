import { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

// Contexts
import GlobalContextProvider from 'contexts/GlobalContext'
import { WindowProvider } from 'contexts/WindowContext'

// Containers
import ExamUpload from 'containers/ExamUpload/ExamUpload'
import HealthMonitoring from 'containers/HealthMonitoring/HealthMonitoring'
import TemporaryPassword from 'containers/TemporaryPassword/TemporaryPassword'
import Onboarding from 'containers/Onboarding/Onboarding'
import ExamPage from 'containers/ExamPage/ExamPage'
import Profile from 'containers/Profile'
import TelemedicineValues from 'containers/TelemedicineValues'
import Booking from 'containers/Booking'
import Services from 'containers/Services/Services'
import Prescriptions from 'containers/Prescriptions/Prescriptions'

// Components
import Call0800Page from 'components/Call0800Page/Call0800Page'
import SupportPage from 'components/SupportPage/SupportPage'
import RequestError from 'components/RequestError/RequestError'
import Layout from 'components/common/Layout'
import LoadingBackdrop from 'components/layouts/LoadingBackdrop'

// Pages
import HealthCardPage from 'pages/HealthCardPage'
import HelpPage from 'pages/HelpPage'
import SchedulePage from 'pages/SchedulePage'
import {
  VirtualClinicPage,
  VirtualRoomPage,
  MedicalRecordPage
} from 'pages/VirtualClinic'
import InvitePage from 'pages/InvitePage'
import UserHomePage from 'pages/HomePage/UserHomePage'

// Routes
import AdministrativeRoutes from './AdministrativeRoutes'

// Hooks
import useAuth from 'hooks/useAuth'

// Utils
import { APP_URLS } from 'urls'
import { isUserAdmin, isUserDoctor } from 'utils/helpers'

import AdminHomePage from 'pages/HomePage/AdminHomePage'

import DoctorHomePage from 'pages/HomePage/DoctorHomePage'

import PromptMedicalCare from 'pages/PromptMedicalCare/PromptMedicalCare'
import SmartConsultation from 'pages/SmartConsultation/SmartConsultation'

export default function UserRoutes() {
  const { user } = useAuth()

  return (
    <GlobalContextProvider>
      <WindowProvider>
        <Suspense fallback={<LoadingBackdrop isOpen />}>
          {user.senha_temporaria ? (
            <Switch>
              <Route path={APP_URLS.CHANGE_PASSWORD_WELCOME}>
                <TemporaryPassword />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Layout>
                <Route exact path={APP_URLS.HOME}>
                  {isUserAdmin() ? (
                    <AdminHomePage />
                  ) : isUserDoctor() ? (
                    <DoctorHomePage />
                  ) : (
                    <UserHomePage />
                  )}
                </Route>

                <Route path={APP_URLS.CHANGE_PASSWORD_WELCOME}>
                  <TemporaryPassword />
                </Route>
                <Route path={APP_URLS.EXAM_UPLOAD}>
                  <ExamUpload />
                </Route>
                <Route path={APP_URLS.HEALTH_MONITORING}>
                  <HealthMonitoring />
                </Route>
                <Route path={APP_URLS.ONBOARDING}>
                  <Onboarding />
                </Route>
                <Route path={APP_URLS.CALL_0800}>
                  <Call0800Page />
                </Route>
                <Route path={APP_URLS.SUPPORT}>
                  <SupportPage />
                </Route>
                <Route path={APP_URLS.INVITE_FRIENDS}>
                  <InvitePage />
                </Route>
                <Route path={APP_URLS.REQUEST_ERROR}>
                  <RequestError />
                </Route>
                <Route path={APP_URLS.EXAM_PAGE}>
                  <ExamPage />
                </Route>
                <Route path={APP_URLS.PROFILE}>
                  <Profile />
                </Route>
                <Route path={APP_URLS.BOOKING}>
                  <Booking />
                </Route>
                <Route path={APP_URLS.TELEMEDICINE_VALUES}>
                  <TelemedicineValues />
                </Route>
                <Route path={APP_URLS.SERVICES}>
                  <Services />
                </Route>
                <Route path={APP_URLS.PRESCRIPTIONS}>
                  <Prescriptions />
                </Route>
                <Route path={APP_URLS.HEALTH_CARD} component={HealthCardPage} />
                <Route path={APP_URLS.HELP} component={HelpPage} />
                <Route path={APP_URLS.SCHEDULE} component={SchedulePage} />

                {/* 
                Virtual Clinic Routes
              */}
                <Route
                  exact
                  path={APP_URLS.VIRTUAL_CLINIC}
                  component={VirtualClinicPage}
                />
                <Route
                  exact
                  path={APP_URLS.SMART_CONSULTATIONS}
                  component={SmartConsultation}
                />
                <Route
                  path={APP_URLS.VIRTUAL_CLINIC_ROOM}
                  component={VirtualRoomPage}
                />

                {isUserDoctor() && (
                  <>
                    <Route
                      path={APP_URLS.VIRTUAL_CLINIC_PATIENT}
                      component={MedicalRecordPage}
                    />
                    <Route
                      exact
                      path={APP_URLS.PROMPT_MEDICAL_CARE}
                      component={PromptMedicalCare}
                    />
                  </>
                )}

                {(isUserAdmin() || isUserDoctor()) && <AdministrativeRoutes />}
              </Layout>
            </Switch>
          )}
        </Suspense>
      </WindowProvider>
    </GlobalContextProvider>
  )
}
