import { Container } from './Styles'

import { Icon } from '../../../ui'

export default function MonitoringMenuButton({
  name,
  changeSubject,
  index,
  setShowAddRecord
}) {
  return (
    <Container index={index}>
      <div>
        <div
          className="cursor-pointer h-full w-full flex items-center"
          onClick={() => changeSubject(name)}
        >
          <span className="text-xl font-medium tracking-widest">{name}</span>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            changeSubject(name)
            setShowAddRecord(true)
          }}
        >
          <Icon name="plus_circle" size={30} className="fill-current" />
        </div>
      </div>
    </Container>
  )
}
