import { Link } from 'react-router-dom'
import { MouseEventHandler } from 'react'
import cn from 'classnames'

import Badge from '../Badge'
import Heading from 'components/ui/Heading'

interface IMenuItem {
  to?: string
  title: string
  href?: string
  target?: string
  background?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  comingSoon?: string
  className?: string
  exclusive?: boolean
}

export default function MenuItem({
  to,
  title,
  href,
  target,
  background,
  onClick,
  comingSoon,
  className,
  exclusive
}: IMenuItem) {
  const containerClasses = cn({
    'bg-white rounded h-40 bg-right-bottom bg-no-repeat flex flex-col border border-gray-500 text-gray-900':
      true,
    'cursor-pointer hover:bg-teal-600 hover:text-white transition-all transition duration-150 ease-in-out':
      !comingSoon,
    className
  })

  const TagName = to ? Link : 'a'

  return (
    // @ts-ignore
    <TagName
      to={to}
      href={href}
      target={target}
      className={containerClasses}
      style={{
        backgroundImage: `url(${background})`,
        backgroundPositionY: '1000%'
      }}
      onClick={onClick}
      id={title.toLowerCase().replaceAll(' ', '-').replaceAll(',', '')}
    >
      <Heading className="p-2 font-semibold tracking-wide pr-8">
        {title}
      </Heading>
      <div className="flex-grow" />
      {comingSoon && <Badge title="Em breve" className="m-2 mr-auto" />}
      {exclusive && (
        <Badge
          title="Assinantes"
          iconName="star"
          className="m-2 mr-auto"
          color="pink"
        />
      )}
    </TagName>
  )
}
