import { Route } from 'react-router-dom'

// Contexts
import APIProvider from '../config/contexts/APIContext'
import PatientProvider from '../config/contexts/PatientContext'

// Pages
import UserKarmenInfos from '../pages/Karmen'
import PEP from '../pages/PEP'
import Versatilis from '../pages/Versatilis'

//Components
import Layout from '../components/common/Layout/Layout'
import { APP_URLS } from 'urls'
import WindowProvider from '../config/contexts/WindowContext'
import LastLocationProvider from 'hooks/useLastLocation'

export default function DoctorRoutes() {
  return (
    <WindowProvider>
      <APIProvider>
        <PatientProvider>
          <LastLocationProvider>
            <Layout>
              <Route
                path={APP_URLS.VIRTUAL_CLINIC_ROOM_VERSATILIS}
                component={Versatilis}
              />
              <Route
                path={APP_URLS.VIRTUAL_CLINIC_ROOM_KARMEN}
                component={UserKarmenInfos}
              />
              <Route path={APP_URLS.VIRTUAL_CLINIC_ROOM} component={PEP} />
            </Layout>
          </LastLocationProvider>
        </PatientProvider>
      </APIProvider>
    </WindowProvider>
  )
}
