import { generateUUID } from './generators'

export const APP_URLS = {
  CHAT: 'http://app.kompa.com.br/chat',
  LOGIN: '/'
}

export const quickService = () =>
  `https://atendimentos.kompa.com.br/?internalId=${generateUUID()}&name=Komper`

const WhatsappURL = 'https://wa.me'

export const openWhatsapp = (number: string, message: string) => {
  const formatedNumber = number.replace(/[^\w\s]/gi, '').replace(/ /g, '')
  return `${WhatsappURL}/${formatedNumber}?text=${encodeURI(message)}`
}

export const emergencyCareUrl = (userId: string, userName: string) =>
  `https://atendimentos.kompa.com.br/?internalId=${userId}&name=${userName}`

const SITE = 'https://kompa.com.br'

export const SITE_URLS = {
  BOOK: `${SITE}/agendar`
}

export const FLICCHAT_RATING_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLScQnmLFNe-KrFgHJvky01RsE5_xq4QRzFvi157_iRLdojK7qA/viewform'

export const FLICCHAT_SDK_URL = 'https://sdk.flicchat.com/fcsdk.js'
