import styled from 'styled-components'
import Scrollbar from 'react-custom-scrollbars'
import { AnimatedDiv, Section, Icon } from '../../ui'
import { Parent, Children } from '../../Profile/Payment/PaymentView/Animations'
import CreditCardNewCardForm from './CreditCardNewCardForm'

const SectionWrapper = styled.div`
  @media (max-width: 350px) {
    display: none;
  }
`

const calculateInnerHeight =
  window.innerHeight > 1000
    ? '100%'
    : window.innerHeight < 600
    ? window.innerHeight - 300
    : '90%'

const CreditCardPayment = ({ onSubmit }) => {
  return (
    <AnimatedDiv
      className="mx-auto max-w-xl"
      variants={Parent}
      initial="hidden"
      animate="show"
    >
      <Scrollbar
        autoHeight
        autoHeightMin={calculateInnerHeight}
        autoHeightMax={calculateInnerHeight}
      >
        <AnimatedDiv variants={Children}>
          <SectionWrapper>
            <Section className="text-center flex items-center justify-center p-14 rounded-t">
              <div className="flex mt-4 items-center px-2 py-2 border-green-400 border-2">
                <div className="m-2 md:m-4">
                  <Icon name="shield_done" size={55} />
                </div>
                <div className="py-4 pl-4 text-left">
                  <h1 className="text-2xl font-medium text-green-600">
                    Super-seguro!
                  </h1>
                  <p>
                    Usamos criptografia de ponta para proteger todas as suas
                    informações.
                  </p>
                </div>
              </div>
            </Section>
          </SectionWrapper>
        </AnimatedDiv>
        <AnimatedDiv variants={Children}>
          <CreditCardNewCardForm onSubmit={onSubmit} />
        </AnimatedDiv>
      </Scrollbar>
    </AnimatedDiv>
  )
}

export default CreditCardPayment
