import { useEffect, useState, useRef } from 'react'
import {
  Room,
  connect,
  RemoteParticipant,
  createLocalTracks,
  createLocalVideoTrack,
  LocalVideoTrackPublication,
  LocalAudioTrackPublication,
  LocalDataTrack,
  LocalVideoTrack
} from 'twilio-video'

import { fetchApi } from 'services/api'

import Chat from '../Chat/Chat'

import { VideoCameraIcon as VideoCameraIconOutline } from '@heroicons/react/outline'
import { VideoCameraIcon as VideoCameraIconSolid } from '@heroicons/react/solid'

import { MicrophoneIcon as MicrophoneIconOutline } from '@heroicons/react/outline'
import { MicrophoneIcon as MicrophoneIconSolid } from '@heroicons/react/solid'

import { ChatIcon as ChatIconOutline } from '@heroicons/react/outline'
import { ChatIcon as ChatIconSolid } from '@heroicons/react/solid'

export interface ChatMessage {
  position: string
  type: string
  text: string
  date: Date
  title: string
  style?: object
}

export default function VideoCallLayout({ appointment }: any) {
  const roomConnected = useRef<Room | null>(null)
  const [remoteParticipants, setRemoteParticipants] = useState<
    RemoteParticipant[]
  >([])
  const [remoteLeft, setRemoteLeft] = useState(false)
  const [remoteCamera, setRemoteCamera] = useState(true)
  const [loading, setLoading] = useState(false)
  const localTrack = useRef<LocalVideoTrack | null>(null)
  const dataTrack = useRef<LocalDataTrack | null>(null)

  const [camera, setCamera] = useState<boolean>(true)
  const [mic, setMic] = useState<boolean>(true)
  const [chat, setChat] = useState<boolean>(false)

  const [messages, setMessages] = useState<ChatMessage[]>([])

  const participantConnected = (participant: RemoteParticipant) => {
    console.log('Participant "%s" connected', participant.identity)
    const div = document.createElement('div')
    div.id = participant.sid
    participant.on('trackSubscribed', (track: any) =>
      trackSubscribed(div, track)
    )
    participant.on('trackUnsubscribed', trackUnsubscribed)
    participant.tracks.forEach((publication: any) => {
      if (publication.isSubscribed) {
        trackSubscribed(div, publication.track)
      }
    })
    if (remoteLeft) {
      setRemoteLeft(false)
    }
    setRemoteParticipants(participants => [...participants, participant])
    document.getElementById('video-main')?.appendChild(div)
  }

  const participantDisconnected = (participant: RemoteParticipant) => {
    console.log('Participant "%s" disconnected', participant.identity)
    setRemoteLeft(true)
    setRemoteParticipants(participants =>
      participants.filter(p => {
        return p.sid !== participant.sid
      })
    )
    document.getElementById(participant.sid)?.remove()
  }

  const trackSubscribed = (div: any, track: any) => {
    if (track.kind === 'data') {
      track.on('message', (data: string) => {
        appendNewMessage(data, appointment.paciente.nome, 'left')
      })
    }
    if (!track || track.attach === undefined) return

    if (track.kind === 'video') {
      setRemoteCamera(true)
    }

    const video = track?.attach()
    video.style.transform = 'scale(-1, 1)'
    video.style.margin = '0 auto'
    div.appendChild(video)
  }

  const trackUnsubscribed = (track: any) => {
    if (typeof track.detach !== 'function') return
    setRemoteCamera(false)
    track.detach().forEach((element: any) => element.remove())
  }

  const connectVideo = () => {
    setLoading(true)
    fetchApi(`/api/v1/consultation/${appointment.id}/video-access`).then(
      response => {
        const { token } = response.data
        createLocalTracks({
          audio: true,
          video: { height: 480, frameRate: 24, width: 720 }
        }).then(localTracks => {
          dataTrack.current = new LocalDataTrack()
          return connect(token, {
            name: appointment.id.toString(),
            tracks: [...localTracks, dataTrack.current],
            maxAudioBitrate: 16000,
            video: { height: 480, frameRate: 24, width: 720 }
          }).then((room: any) => {
            console.log('Connected to Room "%s"', room.name)
            roomConnected.current = room

            room.participants.forEach(participantConnected)
            room.on('participantConnected', participantConnected)
            room.on('participantDisconnected', participantDisconnected)
            room.once('disconnected', (error: any) => {
              room.participants.forEach(participantDisconnected)
            })
            setLoading(false)
          })
        })
      }
    )
    createLocalVideoTrack().then((track: LocalVideoTrack) => {
      localTrack.current = track
      const localMediaContainer = document.getElementById('video-main')
      const video = track.attach()
      video.id = 'webcam'
      video.style.transform = 'scale(-1, 1)'
      video.style.width = '200px'
      video.style.right = '0px'
      video.style.bottom = '0px'
      video.style.position = 'absolute'
      localMediaContainer?.appendChild(video)
    })
  }

  const handleHangup = () => {
    localTrack.current?.stop()
    localTrack.current?.detach()
    document.getElementById('webcam')?.remove()

    roomConnected.current?.localParticipant.tracks.forEach(
      (publication: any) => {
        if (publication.kind !== 'data') {
          publication.track.stop()
        }
      }
    )
    roomConnected.current?.disconnect()
  }

  const handleCamera = () => {
    setCamera(!camera)
    roomConnected.current?.localParticipant.videoTracks.forEach(
      (track: LocalVideoTrackPublication) => {
        if (camera) {
          localTrack.current?.disable()
          track.track.disable()
        } else {
          localTrack.current?.enable()
          track.track.enable()
        }
      }
    )
  }

  const handleMic = () => {
    setMic(!mic)
    roomConnected.current?.localParticipant.audioTracks.forEach(
      (track: LocalAudioTrackPublication) => {
        if (mic) {
          track.track.disable()
        } else {
          track.track.enable()
        }
      }
    )
  }

  const handleSendMessage = (message: string) => {
    appendNewMessage(message, appointment.medico.nome, 'right')
    dataTrack.current?.send(message)
  }

  const appendNewMessage = (
    message: string,
    author: string,
    position: string
  ) => {
    const newMessage: ChatMessage = {
      position: position,
      type: 'text',
      text: message,
      date: new Date(),
      title: author
    }
    setMessages(oldMessages => [...oldMessages, newMessage])
  }

  const handleChat = () => {
    setChat(!chat)
  }

  useEffect(() => {
    connectVideo()

    return () => {
      handleHangup()
    }
  }, [])

  const hasRemoteParticipants = remoteParticipants.length > 0 && !loading
  const isRemoteWithoutCamera = hasRemoteParticipants && !remoteCamera
  const isAwaitRemoteParticipants = !hasRemoteParticipants && !remoteLeft
  const isRemoteLeft = !hasRemoteParticipants && remoteLeft

  return (
    <div>
      <div className="w-full h-12 items-center bg-pink-600 flex">
        <h1 className="text-white text-xl">Chamada de vídeo</h1>
      </div>

      <div className="flex">
        <div
          style={{
            minHeight: 248
          }}
          className={`w-full relative bg-gray-900 h-auto flex flex-col items-center justify-between gap-4 py-2 `}
        >
          {isRemoteWithoutCamera && (
            <p className="text-gray-200 mt-24">Paciente sem câmera</p>
          )}
          {isAwaitRemoteParticipants && (
            <p className="text-gray-200 mt-24">
              Aguardando paciente se conectar...
            </p>
          )}
          {isRemoteLeft && (
            <p className="text-gray-200 mt-24">Paciente desconectado.</p>
          )}

          <div id="video-main"></div>
          <div className="z-10 flex gap-4">
            <button onClick={handleCamera} className="text-white ">
              {camera ? (
                <VideoCameraIconSolid className="h-8 text-pink-600" />
              ) : (
                <VideoCameraIconOutline className="h-8" />
              )}
            </button>
            <button onClick={handleMic} className="text-white ">
              {mic ? (
                <MicrophoneIconSolid className="h-8 text-pink-600" />
              ) : (
                <MicrophoneIconOutline className="h-8" />
              )}
            </button>
            <button onClick={handleChat} className="text-white ">
              {chat ? (
                <ChatIconSolid className="h-8 text-pink-600" />
              ) : (
                <ChatIconOutline className="h-8" />
              )}
            </button>
          </div>
        </div>
        {chat && <Chat sendMessage={handleSendMessage} dataSource={messages} />}
      </div>
    </div>
  )
}
