/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'

import { ExamUpload as ExamUploadComponent } from '../../components/ExamUpload'
import UploadForm from '../../components/ExamUpload/UploadForm'
import { APP_URLS } from '../../urls'
import {
  postUserFile,
  setFileObservations,
  disableUserFile
} from '../../services/api'
import { GlobalContext } from '../../contexts/GlobalContext'

import Head from 'components/common/Head'

export default function ExamUpload() {
  const { user } = useContext(GlobalContext)
  const history = useHistory()
  const location = useLocation()
  const [file, setFile] = useState(null)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [warning, setWarning] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [percentUpload, setPercentUpload] = useState(0)
  const [tab, setTab] = useState('exames')

  const OnUploadFormSubmit = async ({ file, type, examType, otherExam }) => {
    await fileUpload(file, type, examType, otherExam)
  }

  const OnEditFormSubmit = async ({ file, examType, otherExam }) => {
    await fileTypeUpload(file.id, examType, otherExam)
  }

  const OnDeleteFormSubmit = async file => {
    await deleteFile(file.id)
  }

  const fileUpload = async (
    file,
    uploadType,
    examType,
    otherExamDescription
  ) => {
    if (examType === '' && uploadType === 'exam') return false
    const data = new FormData()
    try {
      data.append('arquivo', file)
      data.append('upload_type', uploadType)
      data.append('exam_type', examType)
      data.append('other_exam_description', otherExamDescription)
      const options = {
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          setPercentUpload(percent)
          if (percent === 100) setPercentUpload(0)
        }
      }
      await postUserFile(user.id, data, options)
      setSuccess(true)
      setMessage(
        uploadType === 'exam'
          ? 'O exame foi adicionado ao prontuário.'
          : 'O anexo foi adicionado ao prontuário.'
      )
    } catch (error) {
      setError(true)
      setMessage('Um erro ocorreu ao receber seu arquivo.')
    } finally {
      history.push(APP_URLS.EXAM_UPLOAD)
    }
  }

  const fileTypeUpload = async (id, examType, otherType) => {
    const data = new FormData()
    data.append('exam_type', examType)
    data.append('other_exam_description', otherType)
    data.append('observacoes', examType)
    data.append('id', id)

    await setFileObservations(user.id, data)
      .then(() => {
        setSuccess(true)
        setMessage('Exame alterado com sucesso!')
      })
      .catch(error => {
        setError(true)
        setMessage('Erro alterando exame...')
      })
      .finally(() => {
        history.push(APP_URLS.EXAM_UPLOAD)
      })
  }

  const deleteFile = async id => {
    const data = new FormData()
    data.append('id', id)
    await disableUserFile(user.id, data)
      .then(() => {
        setSuccess(true)
        setMessage('Arquivo apagado com sucesso!')
      })
      .catch(error => {
        setError(true)
        setMessage('Erro apagando arquivo...')
      })
      .finally(() => {
        history.push(APP_URLS.EXAM_UPLOAD)
      })
  }

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false)
      setError(false)
      setWarning(false)
    }, 4000)
  }, [success, error, warning])

  return (
    <>
      <Head title="Exames e Anexos" />
      <Switch location={location} key={location.pathname}>
        <Route exact path={APP_URLS.EXAM_UPLOAD}>
          <ExamUploadComponent
            success={success}
            error={error}
            warning={warning}
            message={message}
            tab={tab}
            setTab={setTab}
            OnUploadForm={file => {
              setFile(file)
              history.push(APP_URLS.EXAM_UPLOAD_NEW)
            }}
            OnEditForm={file => {
              setFile(file)
              history.push(APP_URLS.EXAM_UPLOAD_EDIT)
            }}
            OnDeleteForm={file => {
              setFile(file)
              history.push(APP_URLS.EXAM_UPLOAD_DELETE)
            }}
            OnFileRejection={() => {
              setWarning(true)
              setMessage('Tipo de arquivo não suportado!')
            }}
          />
        </Route>
        <Route path={APP_URLS.EXAM_UPLOAD_NEW}>
          <UploadForm action="" file={file} onUpload={OnUploadFormSubmit} />
        </Route>
        <Route path={APP_URLS.EXAM_UPLOAD_EDIT}>
          <UploadForm action="edit" file={file} onEdit={OnEditFormSubmit} />
        </Route>
        <Route path={APP_URLS.EXAM_UPLOAD_DELETE}>
          <UploadForm
            action="delete"
            file={file}
            onDelete={OnDeleteFormSubmit}
          />
        </Route>
      </Switch>
    </>
  )
}
