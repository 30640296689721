import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { CalendarIcon, ClockIcon } from '@heroicons/react/solid'
import NumberFormat from 'react-number-format'
import { CircularProgress } from '@material-ui/core'

import { KompaWhiteInput } from 'components/ui/KompaInput'
import Text from 'components/ui/Text'
import MuiButton from 'components/ui/MuiButton'

import { ConfirmOfferInputs } from 'types/Offer'

interface UpdateOfferFormProps {
  offerId: number
  isConfirmOfferLoading: boolean
  handleConfirmOffer: (offerId: number, values: ConfirmOfferInputs) => void
}

export default function UpdateOfferForm({
  offerId,
  handleConfirmOffer,
  isConfirmOfferLoading
}: UpdateOfferFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ConfirmOfferInputs>({
    mode: 'onChange',
    defaultValues: {
      date: '',
      hour: ''
    }
  })

  const onSubmit: SubmitHandler<ConfirmOfferInputs> = async ({ date, hour }) =>
    handleConfirmOffer(offerId, { date, hour })

  return (
    <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
      <Text className="text-gray-700 text-base" as="span">
        Confirme a data e o horário da consulta
      </Text>
      <div className="grid grid-cols-5 gap-3 mt-2">
        <Controller
          id="date"
          name="date"
          type="date"
          placeholder="Data da consulta"
          className="col-span-2"
          as={KompaWhiteInput}
          control={control}
          fullWidth
          variant="filled"
          error={!!errors.date}
          rules={{
            required: true
          }}
          InputProps={{
            startAdornment: <CalendarIcon className="h-6 text-pink-600" />
          }}
        />
        <Controller
          id="hour"
          name="hour"
          type="hour"
          placeholder="Hora da consulta"
          className="col-span-2"
          as={NumberFormat}
          customInput={KompaWhiteInput}
          format="##:##"
          mask="_"
          control={control}
          fullWidth
          variant="filled"
          error={!!errors.hour}
          rules={{
            required: true
          }}
          InputProps={{
            startAdornment: <ClockIcon className="h-6 text-pink-600" />
          }}
        />
        <MuiButton variant="contained" color="primary" type="submit">
          {isConfirmOfferLoading ? (
            <CircularProgress style={{ color: 'white' }} size="24px" />
          ) : (
            'Confirmar'
          )}
        </MuiButton>
      </div>
    </form>
  )
}
