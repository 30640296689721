import { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import imageCompression from 'browser-image-compression'
import DragNDrop from '../DragNDrop/DragNDrop'
import Icon from '../ui/Icon'

const imageType = ['image/png', 'image/jpeg', 'image/jpg']

const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']

const UploadComponent = ({
  onLoadFile = () => {},
  onFileRejection = () => {}
}) => {
  const OpenUploadForm = file => {
    onLoadFile(file)
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: allowedTypes,
    maxSize: 10000000,
    onDrop: acceptedFiles => {
      handleFileInput(acceptedFiles[0])
    }
  })

  const handleFileInput = async rawFile => {
    let compressedFile

    if (rawFile) {
      if (imageType.includes(rawFile.type)) {
        const compression = await imageCompression(rawFile, {
          maxWidthOrHeight: 1200
        })
        compressedFile = new File([compression], compression.name, {
          type: compression.type
        })
      }

      const file = compressedFile || rawFile

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file)
      })

      OpenUploadForm(newFile)
    }
  }

  useEffect(() => {
    if (fileRejections.length !== 0) onFileRejection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections])

  return (
    <>
      <DragNDrop
        rootProps={getRootProps()}
        inputProps={getInputProps()}
        outerClasses="flex flex-col w-full h-auto bg-white"
        innerClasses=""
      >
        <div
          className="m-6 border-2 border-dashed rounded cursor-pointer"
          style={{
            backgroundColor: '#E6F4F4',
            borderColor: '#008F8F'
          }}
        >
          <div className="flex flex-col items-center m-6">
            <Icon name="upload-cloud" size={34} className="" />
            <h3 className="text-base font-medium">
              Toque ou arraste aqui{' '}
            </h3>{' '}
            <span className="text-base font-normal">
              para enviar seus arquivos{' '}
            </span>
          </div>
        </div>
      </DragNDrop>
    </>
  )
}

export default UploadComponent
