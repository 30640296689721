import PropTypes from 'prop-types'
import React from 'react'
// Main Window.
let browser = null
// child window.
let popup = null
// interval
let timer = null

// This function is what the name says.
// it checks whether the popup still open or not
function watcher() {
  // if popup is null then let's clean the intervals.
  if (popup === null) {
    clearInterval(timer)
    timer = null
    // if popup is not null and it is not closed, then let's set the focus on it... maybe...
  } else if (popup !== null && !popup.closed) {
    popup.focus()
    // if popup is closed, then let's clean errthing.
  } else if (popup !== null && popup.closed) {
    clearInterval(timer)
    browser.focus()
    // the onCloseEventHandler it notifies that the child has been closed.
    timer = null
    popup = null
  }
}

export default class WindowOpener extends React.Component {
  // The properties of the component are the following
  // `url`: URI in which the new window will open in.
  // `name`: name of the popup.
  // `opts`: options that the window has. if you want to know all the options goto
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/open
  constructor(props) {
    super(props)
    // binding
    this.onClickHandler = this.onClickHandler.bind(this)
    // browser is set to current window
    browser = window.self
  }
  // opens a child
  onClickHandler(evt) {
    const { url, name, opts } = this.props
    // if there is  already a child open, let's set focus on it
    if (popup && !popup.closed) {
      popup.focus()

      return
    }
    // we open a new window.
    popup = browser.open(url, name, opts)

    if (timer === null) {
      // each two seconds we check if the popup still open or not
      timer = setInterval(watcher, 2000)
    }

    return
  }

  render() {
    const { children, className = '' } = this.props
    return (
      <button className={className} type="button" onClick={this.onClickHandler}>
        {children}
      </button>
    )
  }
}

WindowOpener.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string,
  opts: PropTypes.string
}
WindowOpener.defaultProps = {
  name: 'Cool popup',
  opts: `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=${300}, height=${300}`
}
