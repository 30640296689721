import React, { useContext } from 'react'
import ReactDOM from 'react-dom'

import { ModalContext } from '../../../../contexts/ModalContext'
import { Modal, ModalBody, CloseButton, CloseDiv } from './Styles'

const PortalModal = ({ isBottom, closeButton }) => {
  const { modalContent, isOpen, setOpen, handleModal } =
    useContext(ModalContext)

  if (isOpen) {
    return ReactDOM.createPortal(
      <Modal isBottom={isBottom}>
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => setOpen(false)}
          aria-hidden="true"
        >
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <ModalBody isBottom={isBottom}>
          <CloseDiv show={closeButton}>
            <CloseButton onClick={handleModal} />
          </CloseDiv>
          {modalContent}
        </ModalBody>
      </Modal>,
      document.querySelector('#modal-root')
    )
  }
  return null
}

export default PortalModal
