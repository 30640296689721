import React, { useContext, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'

import { useForm, Controller } from 'react-hook-form'
import { APIContext } from '../../config/contexts/APIContext'
import { WindowContext } from '../../config/contexts/WindowContext'
import { checkStorage, persistOnStorage, formatToData } from '../../utils/Utils'
import { Form, Input } from '../elements'

const EvolucaoSimples = () => {
  const { getDoctors, handleForms } = useContext(APIContext)
  const { persistEvolucaoSimples, setPersistEvolucaoSimples } =
    useContext(WindowContext)
  const [save, setSave] = useState(false)
  const [hour, setHour] = useState(0)
  const { handleSubmit, register, control, reset } = useForm({
    defaultValues: persistEvolucaoSimples
  })

  const onSubmit = data => {
    setPersistEvolucaoSimples(data)
    const formatedData = {
      prontuario: data.observacoes,
      data_versatilis: formatToData(data.data)
    }
    handleForms(formatedData, 'evolucao_simples')
    setSave(true)
  }

  const handleBlur = data => {
    setPersistEvolucaoSimples(data)
    persistOnStorage(data, 'evolucao_simples')

    setSave(false)
  }

  useEffect(() => {
    const check = checkStorage('evolucao_simples')
    if (!check) return
    if (check.save) {
      setSave(true)
      setHour(check.hour)
    }
    setPersistEvolucaoSimples(check)
    reset(check)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getDoctors()
    // eslint-disable-next-line
  }, [])

  return (
    <Form
      save={save}
      title="Evoluções Versatilis"
      hour={hour}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="data"
        label="Data"
        defaultValue=""
        blurDiv={handleSubmit(handleBlur)}
        control={control}
        as={NumberFormat}
        customInput={Input}
        format="##/##/####"
        placeholder="__/__/____"
        mask={['_', '_', '_', '_', '_', '_', '_', '_']}
      />

      <Input
        name="observacoes"
        label="Observações"
        type="textarea"
        placeholder="Digite..."
        rows="6"
        defaultValue=""
        inputRef={register}
        blurDiv={handleSubmit(handleBlur)}
        isClearable
      />
    </Form>
  )
}

export default EvolucaoSimples
