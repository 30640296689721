import cn from 'classnames'

const IconText = ({ label, Icon, width, height, className }) => {
  return (
    <p className={cn('flex items-center gap-4 mt-2 text-sm', className)}>
      <Icon width={width} height={height} />
      {label}
    </p>
  )
}

export default IconText
