import { useEffect } from 'react'
import cn from 'classnames'
import { usePostHogContext } from 'posthog-js/react'

import Icon from '../../ui/Icon'
import { Section } from '../../ui'

const SuccessToast = ({
  success,
  message = 'O exame foi adicionado ao prontuário.'
}) => {
  const posthogCtx = usePostHogContext()

  useEffect(() => {
    if (process.env.APP_ENV === 'production') {
      posthogCtx.client.capture('[EXAMES-ANEXOS] item adicionado')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Section
      className={cn(
        'flex flex-row border-2 border-green-700',
        'rounded bg-green-500',
        'assina-shadow-sm',
        success ? 'block' : 'hidden'
      )}
      as="div"
    >
      <div className="ml-3 mt-3">
        <Icon name="tick-square" size={26} />
      </div>
      <div className="text-lg text-white font-normal m-3">
        <span className="font-medium">Sucesso!</span>
        <br />
        {message}
      </div>
    </Section>
  )
}

export default SuccessToast
