import cn from 'classnames'
import styled from 'styled-components'
import { ReactComponent as check_circle } from '../../../../assets/svg/check-circle.svg'

import { formatValue } from '../../BookingUtils'
import { AnimatedDiv, Button } from '../../../ui'
import { SITE_URLS } from '../../../../urls'
import { Item, Parent } from '../Animate'

const CheckIcon = styled(check_circle)`
  min-height: 50px;
  min-width: 50px;
  height: 50px;
  width: 50px;

  fill: ${({ theme: { colors } }) => colors.white1};

  background: #7bd09d;
  border-radius: 50%;
`

const PaidPlan = ({ prices, isFree, freeAppointment, type }) => (
  <div
    className={cn(
      'text-center py-4 px-4 w-full bg-white',
      prices[0] === '0.00' &&
        !isFree() &&
        'bg-green-500 border-none assina-shadow-sm',
      isFree()
        ? 'opacity-80'
        : 'assina-shadow-sm border-2 border-pink-600 rounded'
    )}
  >
    {type !== 'presencial' && freeAppointment ? (
      <section className="m-0">
        {isFree() ? 'Para assinantes' : 'No seu plano'}
        <h1 className="text-xl text-gray-900">
          Primeira consulta{' '}
          <span className="font-medium text-pink-600">grátis</span>
        </h1>
      </section>
    ) : isFree() ? (
      <>
        <h2 className="text-xl font-medium text-pink-600">
          {formatValue(prices[0])}
        </h2>

        <h1 className="text-lg text-gray-800">
          Para assinantes
          <p className="mt-2 text-gray-700">
            Economize até <span className="font-medium text-pink-600">65%</span>{' '}
            nas suas consultas
          </p>
        </h1>
        <Button
          color="outlined"
          className="mt-4 w-full"
          onClick={() => window.open(SITE_URLS.PLANS, '_blank')}
        >
          Conheça nossos planos
        </Button>
      </>
    ) : prices[0] === '0.00' ? (
      <div className="flex text-left space-x-4" style={{ marginTop: '0' }}>
        <CheckIcon />
        <div className="text-white" style={{ marginTop: '0', opacity: '100%' }}>
          <h1 className="text-xl font-medium">
            Por ser assinante, as consultas com essa especialidade são
            gratuitas!
          </h1>
          <p className="text-lg font-base mt-1">
            Somente para atendimentos por telemedicina.
          </p>
        </div>
      </div>
    ) : (
      <>
        <h2 className="text-xl font-medium text-pink-600">
          {formatValue(prices[0])}
        </h2>
        <h1 className="text-lg text-gray-800">
          No seu plano
          <p className="mt-2 text-gray-700">
            Você economiza até{' '}
            <span className="font-medium text-pink-600">65%</span> nas suas
            consultas
          </p>
        </h1>
      </>
    )}
  </div>
)

const FreePlan = ({ prices, isFree }) => (
  <div
    className={cn(
      'text-center py-4 w-full bg-white',
      isFree()
        ? 'assina-shadow-sm border-2 border-pink-600 rounded'
        : 'opacity-80 border-gray-800 border-2 rounded'
    )}
  >
    <h2
      className={cn(
        'text-xl font-medium',
        isFree() ? 'text-pink-600' : 'text-gray-900'
      )}
    >
      {formatValue(prices[1])}
    </h2>
    <span className="text-lg text-gray-800">
      {isFree() ? 'No seu plano gratuito' : 'No particular'}
    </span>
  </div>
)

const UnlimitedPlan = () => (
  <div className="text-center py-4 w-full bg-white assina-shadow-sm border-2 border-pink-600 rounded">
    <section className="m-0">
      No seu plano
      <h1 className="text-xl text-gray-900">
        Consultas <span className="font-medium text-pink-600">ilimitadas</span>
      </h1>
    </section>
  </div>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 22px;

  div:last-child {
    opacity: 80%;
    margin-top: 15px;
  }
`

const PricesDisplay = ({
  prices,
  isFree,
  isUnlimited,
  freeAppointment,
  type
}) => {
  return (
    <AnimatedDiv variants={Parent} initial="hidden" animate="show" out="hidden">
      <Container>
        <h2 className="text-lg text-gray-800 text-left">A partir de:</h2>
        <AnimatedDiv layout variants={Item}>
          {isFree() ? (
            <>
              <FreePlan prices={prices} isFree={isFree} />
              <PaidPlan
                prices={prices}
                isFree={isFree}
                freeAppointment={freeAppointment}
                type={type}
              />
            </>
          ) : (
            <>
              {isUnlimited() ? (
                <UnlimitedPlan />
              ) : (
                <>
                  <PaidPlan
                    prices={prices}
                    isFree={isFree}
                    freeAppointment={freeAppointment}
                    type={type}
                  />
                  <FreePlan prices={prices} isFree={isFree} />
                </>
              )}
            </>
          )}
        </AnimatedDiv>
      </Container>
    </AnimatedDiv>
  )
}

export default PricesDisplay
