import { Circle } from 'rc-progress'

import {
  Button,
  Container,
  DeskProgress,
  MobiProgress,
  Title,
  Diretionals
} from './styles'

import { Icon } from '../../ui'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'

const FirstSteps = ({
  item,
  max,
  active,
  first,
  last,
  getPrevious,
  getNext,
  percentage,
  buttonAction,
  stepDone
}) => {
  if (!active) return null
  return (
    <Container className="mb-4 flex justify-center">
      <DeskProgress>
        <Heading>
          {item} de {max}
        </Heading>
        <Circle
          percent={percentage}
          strokeWidth="6"
          strokeColor="#D20E50"
          trailWidth="6"
          trailColor="#EAEAEA"
          style={{ height: '100%' }}
        />
      </DeskProgress>
      <div className="w-full">
        <Title>
          <MobiProgress>
            <Heading>
              {item} de {max}
            </Heading>
            <Circle
              percent={percentage}
              strokeWidth="6"
              strokeColor="#D20E50"
              trailWidth="6"
              trailColor="#EAEAEA"
              style={{ height: '100%' }}
            />
          </MobiProgress>
          <div className="text-left w-full flex flex-col justify-center items-center sm:items-start">
            <Heading
              as="h2"
              className="font-regular text-xl text-pink-700 mb-2 select-none"
            >
              Primeiros passos
            </Heading>
            <Heading className="text-center sm:text-left font-semibold text-lg text-pink-600 select-none">
              {active.title}
            </Heading>
          </div>
        </Title>
        <Text className="text-base text-gray-700 my-4 select-none">
          {active.message}
        </Text>
        <div className="flex items-center justify-between">
          <Button
            success={stepDone}
            type="button"
            className="tracking-wide"
            onClick={() => {
              if (stepDone) return
              buttonAction(active.name)
            }}
          >
            {stepDone ? 'Feito' : active.buttonTitle}
          </Button>
          <div className="flex">
            <Diretionals first={first}>
              <Icon
                name="directional_left"
                size={30}
                className="cursor-pointer mx-8"
                onClick={getPrevious}
              />
            </Diretionals>
            <Diretionals last={last}>
              <Icon
                name="directional_right"
                size={30}
                className="cursor-pointer"
                onClick={getNext}
              />
            </Diretionals>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default FirstSteps
