import { withStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const KompaAutocomplete = withStyles({
  root: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '3px 0 4px 12px'
    }
  }
})(Autocomplete)

export default KompaAutocomplete
