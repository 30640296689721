import { Route, Switch } from 'react-router-dom'

// Pages
import WelcomePage from 'pages/WelcomePage'
import LoginPage from 'pages/LoginPage'

// Utils
import { APP_URLS } from 'urls'

export default function AuthRoutes() {
  return (
    <Switch>
      <Route exact path={APP_URLS.WELCOME} component={WelcomePage} />
      <Route path={APP_URLS.LOGIN} component={LoginPage} />
    </Switch>
  )
}
