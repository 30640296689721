import { backendServices } from './AxiosInstances'

export const getUserNotifications = (id, params) =>
  backendServices
    .get(`/api/v1/user/${id}/notifications`, {
      params: {
        ...params,
        results_per_page: 5
      }
    })
    .then(({ data }) => data)
