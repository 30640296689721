import Select from 'react-select/creatable'

const CustomStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 0,
    boxShadow: 'none',
    padding: '5px'
  }),

  placeholder: styles => {
    return {
      ...styles,
      fill: '#a4a4a4',
      fontSize: '15px'
    }
  }
}

const customSelectTheme = theme => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: '#F2B7CB',
    primary: '#D20E50'
  }
})

const SelectType = ({ options, onCreate, ...rest }) => {
  return (
    <Select
      options={options}
      components={{
        IndicatorSeparator: () => null,
        NoOptionsMessage: () => 'Nenhuma opção encontrada'
      }}
      className="w-full"
      isSearchable
      styles={CustomStyles}
      theme={customSelectTheme}
      onCreateOption={onCreate}
      formatCreateLabel={e =>
        `Não encontrou a especialidade "${e}"? Clique para entrar em contato`
      }
      maxMenuHeight={200}
      {...rest}
    />
  )
}

export default SelectType
