import { TicketIcon } from '@heroicons/react/outline'
import cn from 'classnames'

import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import TimeChip from '../TimeChip'

import { getWaitingStatus } from 'utils/accreditationUtils'
import { formatDate, splitAndParseDate } from 'utils/formaters'

import { AccRequest } from 'types/AccRequest'

const LabelGroup = ({
  title,
  content
}: {
  title: string
  content: string | number | null
}) => (
  <div className="space-y-0 text-sm">
    <Heading className="text-gray-900 font-semibold">{title}:</Heading>
    <Heading className="text-gray-800">{content}</Heading>
  </div>
)

export default function DashboardItem({
  isStatusNeeded,
  onClick,
  request: {
    id,
    patient,
    specialty,
    range_in_km,
    data_cadastro,
    status_updated_at,
    bank_slip,
    bank_slip_payment_voucher
  }
}: {
  isStatusNeeded: boolean
  onClick: () => void
  request: AccRequest
}) {
  const wrapperClasses = cn(
    'bg-gray-300 rounded-md p-2 flex flex-col border-l-4 cursor-pointer select-none',
    !isStatusNeeded && getWaitingStatus(status_updated_at)
  )

  const date = formatDate(
    splitAndParseDate(data_cadastro.split(' ')[0]),
    "dd 'de' MMMM 'de' yyyy"
  )

  return (
    <div className={wrapperClasses} onClick={onClick}>
      <div className="text-gray-600 flex space-x-2 items-center mb-1">
        <TicketIcon className="h-4" />
        <Text className="text-sm">{date}</Text>
      </div>
      <Heading className="font-semibold text-gray-900 break-all">
        {specialty.nome}
      </Heading>
      <Text className="text-sm text-gray-700">{patient.nome}</Text>
      <LabelGroup title="Raio" content={`${range_in_km}KM`} />
      {!isStatusNeeded && bank_slip ? (
        bank_slip && !bank_slip_payment_voucher ? (
          <Heading className="text-teal-700 font-semibold mt-1 text-sm">
            Boleto gerado
          </Heading>
        ) : (
          <Heading className="text-teal-700 font-semibold mt-1 text-sm">
            Comprovante enviado
          </Heading>
        )
      ) : null}
      <div className="flex justify-between mt-2">
        <div className="flex space-x-2">
          <Text className="text-gray-600 border-2 border-gray-600 px-2 rounded-md text-sm">
            #{id}
          </Text>
          {!isStatusNeeded && <TimeChip time={status_updated_at} />}
        </div>
      </div>
    </div>
  )
}
