/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon, ReactModal as Modal } from '../ui'

const WelcomeModal = ({ name, isOpen, onRequestClose }) => {
  return (
    <Modal
      className="max-w-lg p-8"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <h1 className="text-2xl text-teal-600 font-medium">
        É muito bom te ver por aqui, {name}!
      </h1>
      <div className="flex items-center mt-2">
        <Icon
          name="phone"
          className="fill-current text-teal-600 flex-shrink-0  "
        />
        <p className="ml-2">
          Você receberá uma ligação de nossa equipe de saúde em até 24 horas
          úteis para iniciar seu acompanhamento médico.
        </p>
      </div>
      <div className="mt-2">
        <p>
          Enquanto isso, conheça todas as funcionalidades do seu plano
          assistindo ao vídeo abaixo:
        </p>
      </div>
      <iframe
        title="Video"
        className="mt-2 mx-auto w-full sm:h-64"
        src="https://player.vimeo.com/video/469936244"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
      <div className="mt-4">
        <a
          onClick={onRequestClose}
          className="cursor-pointer text-lg font-medium text-pink-600 block py-2 mt-1 tracking-widest text-center"
        >
          OK, ir ao menu completo
        </a>
      </div>
    </Modal>
  )
}

export default WelcomeModal
