import { useState } from 'react'

import MonitoringMenu from './MonitoringMenu'
import Details from '../SubjectDetails/DetailsViewsMobile'

export default function MobileLayout({
  subject,
  setSubject,
  measureTypes,
  width,
  subjectData,
  addHistory,
  hasNoRecords,
  loading
}) {
  const [showAddRecord, setShowAddRecord] = useState(false)

  return (
    <>
      {subject ? (
        <Details
          subject={subject}
          resetSubject={setSubject}
          showAddRecord={showAddRecord}
          setShowAddRecord={setShowAddRecord}
          measureTypes={measureTypes}
          width={width}
          subjectData={subjectData}
          addHistory={addHistory}
          hasNoRecords={hasNoRecords}
          loading={loading}
        />
      ) : (
        <MonitoringMenu
          changeSubject={setSubject}
          measureTypes={measureTypes}
          setShowAddRecord={setShowAddRecord}
        />
      )}
    </>
  )
}
