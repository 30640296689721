import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

type LocationType = null | string

type UpdateLastLocation = {
  location: LocationType
  nextLocation: string
}

interface ILastLocationContext {
  lastLocation: LocationType
}

interface ILastLocationProvider extends RouteComponentProps {
  children: ReactNode
}

const LastLocationContext = createContext({} as ILastLocationContext)

const LastLocationProvider = ({
  location,
  children
}: ILastLocationProvider) => {
  const [currentLocation, setCurrentLocation] = useState<LocationType>(null)
  const [lastLocation, setLastLocation] = useState<LocationType>(null)

  const updateLastLocation = ({
    location,
    nextLocation
  }: UpdateLastLocation) => {
    if (currentLocation === null) {
      return
    }

    if (nextLocation === lastLocation) {
      return
    }

    setLastLocation(location)
  }

  /*
    This is hard to understand.
    This state update need to be done in this particular order

    Last Location is defined by the old current location, while the current location is updated with the new location path
  */

  useEffect(() => {
    updateLastLocation({
      location: currentLocation,
      nextLocation: location.pathname
    })
    setCurrentLocation(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const providerValue = {
    lastLocation
  }

  return (
    <LastLocationContext.Provider value={providerValue}>
      {children}
    </LastLocationContext.Provider>
  )
}

const useLastLocation = () => {
  return useContext(LastLocationContext)
}

export { useLastLocation }

export default withRouter(LastLocationProvider)
