export const Parent = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
}

export const Children = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
}
