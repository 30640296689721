import { useState } from 'react'
import { useQuery } from 'react-query'
import { ToastContainer } from 'react-toastify'

import DashboardColumn from 'components/elements/PresentialDashboard/DashboardColumn'
import RequestPrompt from 'components/layouts/PresentialDashboard/RequestPrompt'
import Head from 'components/common/Head'
import PageHeader from 'components/common/PageHeader'
import Spinner from 'components/ui/Spinner'
import Text from 'components/ui/Text'

import { getRequests } from 'services/accreditationService'

import { DASHBOARD_MIN_WIDTH } from 'utils/accreditationUtils'

import { AccRequest } from 'types/AccRequest'

export default function PresentialDashboardPage() {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
  const [activeRequest, setActiveRequest] = useState<AccRequest | null>(null)

  const handleDialogOpen = (request: AccRequest) => {
    setDialogOpen(prev => !prev)
    setActiveRequest(request)
  }

  const handleDialogClose = () => {
    setDialogOpen(prev => !prev)
    setActiveRequest(null)
  }

  const {
    data: requests,
    isLoading,
    isError
  } = useQuery('accreditationRequests', getRequests)

  return (
    <>
      <Head title="Consultas Presenciais" />

      <ToastContainer />

      <RequestPrompt
        isDialogOpen={isDialogOpen}
        activeRequest={activeRequest}
        handleDialogClose={handleDialogClose}
      />

      <div className="flex flex-col w-full">
        <PageHeader title="Consultas Presenciais" />

        {isLoading && (
          <div className="flex items-center justify-center mb-4 mt-8 h-full">
            <Spinner color="pink" size={35} />
          </div>
        )}

        {!isLoading && isError && (
          <Text className="text-center flex items-center justify-center mb-4 mt-8 h-full">
            Não foi possível carregar as consultas.
          </Text>
        )}

        {requests && (
          <div className="p-3 overflow-x-auto">
            <div
              className="flex gap-3"
              style={{
                minWidth: DASHBOARD_MIN_WIDTH
              }}
            >
              <DashboardColumn
                title="Novas solicitações"
                requests={requests.solicitations}
                onRequestClick={handleDialogOpen}
              />
              <DashboardColumn
                title="Aguardando pac."
                requests={requests.negotiating}
                onRequestClick={handleDialogOpen}
              />
              <DashboardColumn
                title="Agendamento pendente"
                requests={requests.booked}
                onRequestClick={handleDialogOpen}
              />
              <DashboardColumn
                title="Aguardando pgto."
                requests={requests.paying}
                onRequestClick={handleDialogOpen}
              />
              <DashboardColumn
                title="Finalizado"
                requests={requests.finalized}
                onRequestClick={handleDialogOpen}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
