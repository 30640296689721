import styled from 'styled-components'

const styles = {
  control: styles => ({
    ...styles,
    backgroundColor: '#f2f2f2',
    minHeight: '54px',
    border: 0,
    boxShadow: 'none',
    padding: '5px',
    '&:focus-within': {
      border: '1px solid #e05685',
      boxShadow: '0 0 1px 2.5px #f2b7cb'
    }
  }),

  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: ' #B3DDDD',
      borderRadius: '24px',
      padding: '0px 4px'
    }
  },

  multiValueLabel: styles => ({
    ...styles,
    color: '#171717',
    backgroundColor: '#B3DDDD',
    height: '35px',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center'
  }),

  multiValueRemove: styles => ({
    ...styles,
    color: '#171717',
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      backgroundColor: '#cbe0e0',
      borderRadius: '24px'
    }
  })
}

export default styles

export const InvalidSelect = {
  control: styles => ({
    ...styles,
    backgroundColor: '#f2f2f2',
    minHeight: '54px',
    borderWidth: '1px',
    borderRadius: '5px',
    borderColor: 'red',
    borderStyle: 'solid',
    boxShadow: 'none',
    padding: '5px',
    '&:focus-within': {
      border: '1px solid #e05685',
      boxShadow: '0 0 1px 2.5px #f2b7cb'
    }
  })
}

export const SelectOutside = styled.div`
  padding: 10px 0;
  width: 100%;

  .select {
    margin-top: 5px;
    font-weight: 500;
    color: $gray1;
    font-size: 16px;
  }

  .select-label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      height: 25px;

      zoom: 1.1;

      fill: $gray3;

      cursor: pointer;

      &:hover {
        fill: $gray2;
      }
    }
  }
`
