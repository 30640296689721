import useResize from 'hooks/useResize'
import { Background } from '../Styles'

const ChangeContainer = ({ children }) => {
  const { height } = useResize()

  return (
    <Background className="w-full" style={{ minHeight: height }}>
      {children}
    </Background>
  )
}

export default ChangeContainer
