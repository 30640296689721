import NavbarMenuItem from 'components/elements/Navbar/NavbarMenuItem'

import navItems from 'utils/navbarItems'

export default function DoctorNavbarMenu() {
  return (
    <>
      {navItems.map(
        ({ name, icon, link, onlyDoctor, allUsers }) =>
          (onlyDoctor || allUsers) && (
            <NavbarMenuItem name={name} icon={icon} link={link} key={name} />
          )
      )}
    </>
  )
}
