import TableHeading from 'components/elements/PromptMedicalCare/TableHeading'
import TableItem from 'components/elements/PromptMedicalCare/TableItem'

import { IAppointment } from 'types/Appointment'

interface ITableLayout {
  appointments: IAppointment[]
}

export default function TableLayout({ appointments }: ITableLayout) {
  return (
    <div className="p-3 bg-gray-100 assina-shadow-sm rounded-md">
      <TableHeading />

      {appointments.map(appointment => (
        <TableItem appointment={appointment} />
      ))}
    </div>
  )
}
