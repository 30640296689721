import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { getAppointment } from 'services/appointmentService'
import { getPatient } from 'services/medicalRecordService'

import {
  ACTIVE_APPOINTMENT_COOKIE,
  ACTIVE_PATIENT_COOKIE
} from 'utils/constants'
import { setCookie } from 'utils/cookie'

const useMedicalRecord = () => {
  const { appointmentId, patientId } =
    useParams<{ appointmentId: string; patientId: string }>()

  const {
    data: patient,
    isLoading: isLoadingPatient,
    isError: isErrorPatient,
    error: errorPatient
  } = useQuery(
    ['medicalRecordPatient', patientId],
    () => getPatient(patientId),
    {
      refetchOnWindowFocus: false,
      enabled: !!patientId,
      onSuccess: () => {
        setCookie(ACTIVE_PATIENT_COOKIE, patientId)
      }
    }
  )

  const {
    data: appointment,
    isLoading: isLoadingAppointment,
    isError: isErrorAppointment,
    error: errorAppointment
  } = useQuery(
    ['medicalRecordAppointment', appointmentId],
    () => getAppointment(appointmentId),
    {
      refetchOnWindowFocus: false,
      enabled: !!appointmentId,
      onSuccess: () => {
        setCookie(ACTIVE_APPOINTMENT_COOKIE, appointmentId)
      }
    }
  )

  return {
    patient,
    isLoadingPatient,
    isErrorPatient,
    errorPatient,
    appointment,
    isLoadingAppointment,
    isErrorAppointment,
    errorAppointment
  }
}

export default useMedicalRecord
