import NavbarMenuItem from 'components/elements/Navbar/NavbarMenuItem'

import navItems from 'utils/navbarItems'

export default function UserNavbarMenu() {
  return (
    <>
      {navItems.map(({ name, icon, link, allUsers, onlyAdmin, onlyDoctor }) => {
        if (onlyAdmin || onlyDoctor) {
          // eslint-disable-next-line array-callback-return
          return
        }
        return <NavbarMenuItem name={name} icon={icon} link={link} key={name} />
      })}
    </>
  )
}
