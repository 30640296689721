import { ReactNode, Ref } from 'react'
import { Control, RegisterOptions } from 'react-hook-form'
import { InputAdornment } from '@material-ui/core'
import NumberFormat, { NumberFormatPropsBase } from 'react-number-format'
import mergeRefs from 'react-merge-refs'

import { KompaGrayInput, KompaWhiteInput } from 'components/ui/KompaInput'
import Text from 'components/ui/Text'
import FormLabelController from '../FormLabelController'

interface ControlledMaskedInputProps extends NumberFormatPropsBase {
  label?: string
  name: string
  inputColor: 'gray' | 'white'
  control: Control
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  errorMessage?: string
  startIcon?: ReactNode
  error?: boolean
  inputRef?: Ref<any>
  fullWidth?: boolean
  controllerDefaultValue?: unknown
}

export default function ControlledMaskedInput({
  label,
  control,
  name,
  inputColor,
  rules,
  onChange,
  error,
  errorMessage,
  startIcon,
  inputRef,
  fullWidth,
  controllerDefaultValue,
  ...rest
}: ControlledMaskedInputProps) {
  const Input = inputColor === 'gray' ? KompaGrayInput : KompaWhiteInput

  const startAdornment = startIcon ? (
    <InputAdornment position="start">{startIcon}</InputAdornment>
  ) : null

  const dataTestId = `${name}-testId`

  return (
    <div>
      <FormLabelController
        label={label}
        forInput={name}
        name={name}
        control={control}
        rules={rules}
        defaultValue={controllerDefaultValue}
        render={({
          onChange: controllerOnChange,
          ref: controllerRef,
          ...props
        }) => (
          <NumberFormat
            data-testid={dataTestId}
            id={name}
            variant="filled"
            error={error}
            customInput={Input}
            InputProps={{
              startAdornment
            }}
            onChange={e => {
              controllerOnChange(e.target.value)
              onChange && onChange(e)
            }}
            inputRef={
              inputRef ? mergeRefs([controllerRef, inputRef]) : controllerRef
            }
            fullWidth={fullWidth}
            {...props}
            {...rest}
          />
        )}
      />
      {error && (
        <Text
          as="span"
          className="text-sm select-none font-normal"
          style={{ color: '#b00020' }}
        >
          {errorMessage}
        </Text>
      )}
    </div>
  )
}

/*
  Example Usage

  const {
    control,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: { testInput: '' }
  })

  return (
    <ControlledInput
      label="Test Masked Input"
        forInput="testMaskedInput"
        control={control}
        name="testMaskedInput"
        inputColor="white"
        format="#####-###"
        mask="_"
        fullWidth
        startIcon={<SearchIcon className="h-5 text-pink-600" />}
        rules={{ required: true }}
        error={!!errors.testMaskedInput}
        errorMessage={`${
          errors.testMaskedInput?.type === 'required' &&
          FORM_FIELD_MESSAGES.REQUIRED_FIELD
        }`}
    />
  )
*/
