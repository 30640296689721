import {
  ChangeEvent,
  FormEvent,
  SetStateAction,
  useCallback,
  useState,
  useEffect
} from 'react'
import { MenuItem } from '@material-ui/core'
import {
  ChevronDownIcon,
  FilterIcon,
  SearchIcon,
  TrashIcon
} from '@heroicons/react/solid'
import cn from 'classnames'

import Heading from 'components/ui/Heading'
import { KompaWhiteSelect } from 'components/ui/KompaSelect'
import { KompaWhiteInput } from 'components/ui/KompaInput'
import Text from 'components/ui/Text'
import Button from 'components/ui/Button'
import MuiButton from 'components/ui/MuiButton'
import Icon from 'components/ui/Icon'
import FilterChip from 'components/elements/UsersPage/FilterChip'

import useMedicalProfessionals from 'hooks/useMedicalProfessionals'
import usePlans from 'hooks/usePlans'
import useCareLines from 'hooks/useCareLines'

import { getBuyReason } from 'utils/admnistrativeUtils'

import { AdminUsersFilters } from 'types/AdminUsers'

const iconClasses =
  'h-6 cursor-pointer transition-colors duration-150 ease-in-out'

const filterColor = (filter: boolean | 'inactive') => {
  if (filter === 'inactive') {
    return ''
  }
  if (filter) {
    return 'text-green-600'
  }

  return 'text-pink-600'
}

const undefinedFilters = {
  page: undefined,
  results_per_page: undefined,
  q: undefined,
  answered_karmen: undefined,
  first_consultation_with_nursing: undefined,
  first_consultation_with_dedicated_doctor: undefined,
  is_plan_active: undefined,
  dedicated_doctor_id: undefined,
  dedicated_nurse_id: undefined,
  start_date_of_joining_of_the_plan: undefined,
  end_date_of_joining_of_the_plan: undefined,
  default_on_debt: undefined,
  payment_method: undefined,
  plan_id: undefined,
  buy_reason: undefined,
  care_plan_id: undefined
}

interface FiltersLayoutProps {
  filters: AdminUsersFilters
  setFilters: (value: SetStateAction<AdminUsersFilters>) => void
  handleDialogOpen: () => void
  syncQueryValue: string | undefined
}

export default function FiltersLayout({
  filters,
  setFilters,
  handleDialogOpen,
  syncQueryValue
}: FiltersLayoutProps) {
  const [queryValue, setQueryValue] = useState<string>(filters.q || '')

  const { healthProfessionals, getHealthProfessional } =
    useMedicalProfessionals()

  const { plans, getPlan } = usePlans()

  const { getCareLine } = useCareLines()

  const [karmenAnswered, setKarmenAnswered] = useState<boolean | 'inactive'>(
    typeof filters.answered_karmen == 'undefined'
      ? 'inactive'
      : filters.answered_karmen
  )

  const [consultedNurse, setConsultedNurse] = useState<boolean | 'inactive'>(
    typeof filters.first_consultation_with_nursing == 'undefined'
      ? 'inactive'
      : filters.first_consultation_with_nursing
  )

  const [consultedDoctor, setConsultedDoctor] = useState<boolean | 'inactive'>(
    typeof filters.first_consultation_with_dedicated_doctor == 'undefined'
      ? 'inactive'
      : filters.first_consultation_with_dedicated_doctor
  )

  const submitQuery = (e: FormEvent) => {
    e.preventDefault()
    setFilters(prev => ({
      ...prev,
      q: queryValue.trim(),
      page: '1'
    }))
  }

  const handleItemsPerPageChange = (event: ChangeEvent<{ value: unknown }>) =>
    setFilters(prev => ({
      ...prev,
      results_per_page: String(event.target.value),
      page: '1'
    }))

  const handleStatusChange = (event: ChangeEvent<{ value: unknown }>) =>
    setFilters(prev => ({
      ...prev,
      is_plan_active:
        event.target.value === '' ? undefined : event.target.value === 'true'
    }))

  const handleDeleteQuery = () =>
    setFilters(prev => ({ ...prev, q: undefined }))

  const handleDeleteDedicatedDoctor = () =>
    setFilters(prev => ({ ...prev, dedicated_doctor_id: undefined }))

  const handleDeleteDedicatedNurse = () =>
    setFilters(prev => ({ ...prev, dedicated_nurse_id: undefined }))

  const handleDeleteStartDate = () =>
    setFilters(prev => ({
      ...prev,
      start_date_of_joining_of_the_plan: undefined
    }))

  const handleDeleteEndDate = () =>
    setFilters(prev => ({
      ...prev,
      end_date_of_joining_of_the_plan: undefined
    }))

  const handleDeleteDefaultOnDebt = () =>
    setFilters(prev => ({ ...prev, default_on_debt: undefined }))

  const handleDeletePaymentMethod = () =>
    setFilters(prev => ({ ...prev, payment_method: undefined }))

  const handleDeletePlan = () =>
    setFilters(prev => ({ ...prev, plan_id: undefined }))

  const handleDeleteBuyReason = () =>
    setFilters(prev => ({ ...prev, buy_reason: undefined }))

  const handleDeleteCareLine = () =>
    setFilters(prev => ({ ...prev, care_plan_id: undefined }))

  const handleKarmenFilter = useCallback(() => {
    let newValue: boolean | undefined

    if (karmenAnswered === 'inactive') {
      newValue = false
      setKarmenAnswered(false)
    } else if (!karmenAnswered) {
      newValue = true
      setKarmenAnswered(true)
    } else {
      newValue = undefined
      setKarmenAnswered('inactive')
    }

    setFilters(prev => ({
      ...prev,
      answered_karmen: newValue,
      page: '1'
    }))
  }, [karmenAnswered, setFilters])

  const handleNurseFilter = useCallback(() => {
    let newValue: boolean | undefined

    if (consultedNurse === 'inactive') {
      newValue = false
      setConsultedNurse(false)
    } else if (!consultedNurse) {
      newValue = true
      setConsultedNurse(true)
    } else {
      newValue = undefined
      setConsultedNurse('inactive')
    }

    setFilters(prev => ({
      ...prev,
      first_consultation_with_nursing: newValue,
      page: '1'
    }))
  }, [consultedNurse, setFilters])

  const handleDoctorFilter = useCallback(() => {
    let newValue: boolean | undefined

    if (consultedDoctor === 'inactive') {
      newValue = false
      setConsultedDoctor(false)
    } else if (!consultedDoctor) {
      newValue = true
      setConsultedDoctor(true)
    } else {
      newValue = undefined
      setConsultedDoctor('inactive')
    }

    setFilters(prev => ({
      ...prev,
      first_consultation_with_dedicated_doctor: newValue,
      page: '1'
    }))
  }, [consultedDoctor, setFilters])

  const hasAdvancedFilters = useCallback(() => {
    if (
      filters.dedicated_doctor_id ||
      filters.dedicated_nurse_id ||
      filters.start_date_of_joining_of_the_plan ||
      filters.end_date_of_joining_of_the_plan ||
      filters.default_on_debt ||
      filters.payment_method ||
      filters.plan_id ||
      filters.buy_reason ||
      filters.care_plan_id ||
      filters.q
    ) {
      return true
    }
    return false
  }, [filters])

  useEffect(() => {
    setQueryValue(syncQueryValue || '')
  }, [syncQueryValue])

  return (
    <div className="py-3 px-6 flex flex-col">
      <div className="flex items-center justify-between">
        <Heading className="text-pink-600 font-semibold text-lg">
          Filtros
        </Heading>
        <div className="flex space-x-4">
          <div className="flex items-center space-x-2">
            <Text className="text-gray-800 text-sm ">
              Resultados por página:
            </Text>
            <KompaWhiteSelect
              value={Number(filters.results_per_page) || 30}
              onChange={handleItemsPerPageChange}
              variant="filled"
              IconComponent={props => (
                <ChevronDownIcon {...props} style={{ height: '24px' }} />
              )}
            >
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </KompaWhiteSelect>
          </div>

          <KompaWhiteSelect
            value={
              typeof filters.is_plan_active == 'undefined'
                ? ''
                : String(filters.is_plan_active)
            }
            onChange={handleStatusChange}
            variant="filled"
            displayEmpty
            IconComponent={props => (
              <ChevronDownIcon {...props} style={{ height: '24px' }} />
            )}
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="true">Ativos</MenuItem>
            <MenuItem value="false">Inativos</MenuItem>
          </KompaWhiteSelect>

          <form onSubmit={submitQuery}>
            <KompaWhiteInput
              value={queryValue}
              onChange={e => setQueryValue(e.target.value)}
              variant="filled"
              placeholder="Pesquisa"
              InputProps={{
                startAdornment: <SearchIcon className="h-6 text-pink-600" />
              }}
            />
          </form>

          <MuiButton
            variant="contained"
            color={hasAdvancedFilters() ? 'primary' : 'default'}
            onClick={handleDialogOpen}
          >
            <FilterIcon
              className={
                hasAdvancedFilters() ? 'h-6 text-gray-100' : 'h-6 text-pink-600'
              }
            />
          </MuiButton>

          <div className="text-gray-600 flex items-center space-x-2">
            <Icon
              name="karmen"
              className={cn(iconClasses, filterColor(karmenAnswered))}
              onClick={handleKarmenFilter}
              title="Respondeu a Karmen"
            />
            <Icon
              name="nurse"
              className={cn(iconClasses, filterColor(consultedNurse))}
              onClick={handleNurseFilter}
              title="Fez consulta com a enfermeira dedicada"
            />
            <Icon
              name="dedicated-doctor"
              className={cn(iconClasses, filterColor(consultedDoctor))}
              onClick={handleDoctorFilter}
              title="Fez consulta com o médico dedicado"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-3 items-center">
        <div
          className={cn('flex flex-wrap gap-3', hasAdvancedFilters() && 'mt-3')}
        >
          {filters.q && (
            <FilterChip
              label={`Consulta: ${filters.q}`}
              onDelete={handleDeleteQuery}
            />
          )}

          {filters.dedicated_doctor_id && (
            <FilterChip
              label={`Doutor dedicado: ${
                getHealthProfessional(filters.dedicated_doctor_id, 'DOCTOR')
                  ?.name
              }`}
              onDelete={handleDeleteDedicatedDoctor}
            />
          )}
          {filters.dedicated_nurse_id && healthProfessionals && (
            <FilterChip
              label={`Enfermeira dedicada: ${
                getHealthProfessional(filters.dedicated_nurse_id, 'NURSE')?.name
              }`}
              onDelete={handleDeleteDedicatedNurse}
            />
          )}

          {filters.start_date_of_joining_of_the_plan && (
            <FilterChip
              label={`Data inicial de adesão ao plano: ${filters.start_date_of_joining_of_the_plan}`}
              onDelete={handleDeleteStartDate}
            />
          )}

          {filters.end_date_of_joining_of_the_plan && (
            <FilterChip
              label={`Data final de adesão ao plano: ${filters.end_date_of_joining_of_the_plan}`}
              onDelete={handleDeleteEndDate}
            />
          )}

          {filters.default_on_debt && (
            <FilterChip
              label={`Data desde inadimplencia: ${filters.default_on_debt}`}
              onDelete={handleDeleteDefaultOnDebt}
            />
          )}

          {filters.payment_method && (
            <FilterChip
              label={`Método de pagamento: ${
                filters.payment_method === 'cartão'
                  ? 'Cartão de crédito'
                  : 'Boleto bancário'
              }`}
              onDelete={handleDeletePaymentMethod}
            />
          )}

          {filters.plan_id && plans && (
            <FilterChip
              label={`Plano: ${getPlan(filters.plan_id)?.name}`}
              onDelete={handleDeletePlan}
            />
          )}

          {filters.buy_reason && (
            <FilterChip
              label={`Motivo de assinatura: ${
                getBuyReason(filters.buy_reason)?.name
              }`}
              onDelete={handleDeleteBuyReason}
            />
          )}

          {filters.care_plan_id && (
            <FilterChip
              label={`Linha de cuidado ${
                getCareLine(filters.care_plan_id)?.description
              }`}
              onDelete={handleDeleteCareLine}
            />
          )}
        </div>
        <div className={cn(hasAdvancedFilters() && 'mt-3')}>
          {hasAdvancedFilters() && (
            <Button
              color="transparent"
              className="text-pink-600"
              type="button"
              onClick={() => {
                setFilters(prev => undefinedFilters)
              }}
            >
              <TrashIcon className="h-6 mr-2" />
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
