import cn from 'classnames'

const FormError = ({ className, children, ...rest }) => {
  return (
    <span className={cn('text-red-600 font-medium', className)} {...rest}>
      {children}
    </span>
  )
}

export default FormError
