import React, { ReactNode } from 'react'
import bugsnagClient from '@bugsnag/js'
import BugsnagPluginReact, {
  BugsnagPluginReactResult
} from '@bugsnag/plugin-react'

import { BUGSNAG_KEY, isProduction } from 'utils/constants'
import { getCurrentUser } from 'utils/helpers'

const bugsnag = {
  init: () => {
    if (isProduction)
      bugsnagClient.start({
        apiKey: BUGSNAG_KEY,
        plugins: [new BugsnagPluginReact()],
        onError: event => {
          const currentUser = getCurrentUser()

          if (currentUser) {
            return event.setUser(
              String(currentUser.id),
              currentUser.email,
              currentUser.name
            )
          }

          event.setUser(
            'notLoggedIn',
            'notLoggedIn@notKompa.com.br',
            'Not Logged In User'
          )
        }
      })
  },
  notify: (error: any) => {
    if (isProduction) bugsnagClient.notify(error)
  }
}

const BugsnagErrorBoundary = ({ children }: { children: ReactNode }) => {
  const plugin = bugsnagClient.getPlugin('react') as BugsnagPluginReactResult
  const ErrorBoundary = plugin.createErrorBoundary(React)

  return <ErrorBoundary>{children}</ErrorBoundary>
}

export { bugsnagClient, BugsnagErrorBoundary }

export default bugsnag
