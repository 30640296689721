import cn from 'classnames'

import Text from 'components/ui/Text'

import { getWaitingStatus } from 'utils/accreditationUtils'
import { elapsedHours } from 'utils/formaters'

export default function TimeChip({
  time,
  className
}: {
  time: string | undefined
  className?: string
}) {
  const elapsed = time && elapsedHours(time)

  const wrapperClasses = cn(
    'inline-flex px-2 border-2 items-center self-end bg-gray-100 rounded-md space-x-2 assina-shadow-sm text-gray-100 text-sm select-none',
    time && getWaitingStatus(time, false),
    time && getWaitingStatus(time),
    className
  )

  return (
    <div className={wrapperClasses}>
      <Text>{elapsed}</Text>
    </div>
  )
}
