import { MutableRefObject, ReactElement } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'

import FormLabel from '../FormLabel'

interface FormLabelControllerProps
  extends Omit<ControllerProps<'input'>, 'as' | 'render'> {
  label?: string
  forInput?: string
  render: (args: {
    name: string
    onBlur: () => void
    onChange: (...event: any[]) => void
    ref: MutableRefObject<any>
    value: any
  }) => ReactElement
}

export default function FormLabelController({
  label,
  forInput,
  render,
  ...rest
}: FormLabelControllerProps) {
  return label && forInput ? (
    <FormLabel label={label} forInput={forInput}>
      <Controller render={render} {...rest} />
    </FormLabel>
  ) : (
    <Controller render={render} {...rest} />
  )
}
