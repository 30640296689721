/* eslint-disable no-alert */
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useModalContext } from 'contexts/ModalContext'
import InvitePageLayout from 'components/layouts/InvitePage/InvitePageLayout'
import SendInvitePrompt from 'components/layouts/InvitePage/SendInvitePrompt'
import Head from 'components/common/Head'

import useAuth from 'hooks/useAuth'

import ToastStyles from 'utils/ToastStyles'
import { BROKER_URL, SUPPORT_WHATSAPP } from 'utils/constants'
import { copyTextToClipboard, openWhatsApp } from 'utils'
import { APP_URLS } from 'urls'

const InvitePage = () => {
  const history = useHistory()
  const { user } = useAuth()
  const { handleModal, setCloseButton } = useModalContext()

  const [referees, setReferees] = useState([])
  const link = `https://kompa.com.br/convite?ref=${user.email}&ref_name=${
    user.name.split(' ')[0]
  }`
  const [isClaimModalVisible, setClaimModalVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const response = await axios.get(
          `${BROKER_URL}/referees?email=${user.email}`
        )
        setReferees(response.data.data)
      } catch (err) {
        console.error(err)
        alert(
          'Ocorreu um erro desconhecido ao carregar suas indicações. Retornando ao menu principal.'
        )
        history.replace(APP_URLS.HOME)
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCopy = () => {
    copyTextToClipboard(link)
    toast('Link copiado com sucesso!', ToastStyles)
  }

  const onClaim = async () => {
    setLoading(true)
    try {
      await axios.post(`${BROKER_URL}/referees/redeem`, {
        email: user.email
      })
      setClaimModalVisible(true)
    } catch (err) {
      console.error(err)
      alert(
        'Ocorreu um erro ao solicitar o resgate. Por favor, entre em contato o atendimento.'
      )
    }
    setLoading(false)
  }

  const balance = referees
    .map((r: any) => r.value)
    .reduce((v, acc) => v + acc, 0)

  const openSupportWhatsApp = () =>
    openWhatsApp(
      SUPPORT_WHATSAPP,
      'Olá, estou com dúvidas em como usar o meu link de convite.'
    )

  const openPrompt = () => {
    setCloseButton(false)
    handleModal(<SendInvitePrompt link={link} onCopy={onCopy} />)
  }

  return (
    <>
      <Head title="Convidar amigos" />
      <ToastContainer />
      <InvitePageLayout
        referees={referees}
        balance={balance}
        onClaim={onClaim}
        isClaimModalVisible={isClaimModalVisible}
        setClaimModalVisible={setClaimModalVisible}
        isDescriptionExpanded={isDescriptionExpanded}
        setDescriptionExpanded={setDescriptionExpanded}
        loading={loading}
        openSupportWhatsApp={openSupportWhatsApp}
        openPrompt={openPrompt}
      />
    </>
  )
}

export default InvitePage
