import { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Form, Button, Icon, Input } from '../elements'
import { APIContext } from '../../config/contexts/APIContext'
import Spinner from 'components/ui/Spinner'
import { fetchApi } from 'services/api'
import { WindowContext } from '../../config/contexts/WindowContext'
import { useModalContext } from 'contexts/ModalContext'

const ModalSubmitUserRule = ({
  loadUserRules,
  setLoading,
  patient,
  setOpen
}) => {
  const { register, handleSubmit, control, errors, setError, clearErrors } =
    useForm()
  const onSubmit = async data => {
    if (!data.date_until && !data.count) {
      return setError(
        'countAndDateUntil',
        { type: 'focus' },
        { shouldFocus: true }
      )
    }

    const dataPost = Object.fromEntries(
      Object.entries(data).filter(([key, val]) => {
        return val
      })
    )

    try {
      await fetchApi(`api/v1/user/${patient.id}/rules`, 'post', dataPost)
      loadUserRules()
      setLoading(false)
      setOpen(false)
    } catch (error) {
      setLoading(false)
      setOpen(false)
      throw new Error(error)
    }
  }

  return (
    <div className="overflow-auto bg-white w-full rounded-b-md">
      <form className="px-4 py-6" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-center font-medium text-black text-xl">
          Adicionar Notificação ao usuário
        </h1>
        <Controller
          customInput={Input}
          name="title"
          label="Título"
          defaultValue=""
          control={control}
          as={Input}
          type="text"
          rules={{ required: true }}
          required
        />
        {errors.title && (
          <span className="text-red-600 font-bold">
            O campo Título é obrigatório
          </span>
        )}

        <Input
          name="content"
          type="textarea"
          label="Conteúdo"
          defaultValue=""
          inputRef={register}
        />
        <div className="flex gap-2">
          <Input
            name="link_title"
            defaultValue=""
            label="Título do Link"
            inputRef={register}
          />
          <Input
            name="link"
            defaultValue=""
            type="text"
            label="Link"
            inputRef={register}
          />
        </div>

        <Controller
          customInput={Input}
          name="time_start"
          label="Hora de início"
          defaultValue=""
          control={control}
          as={Input}
          type="time"
          rules={{ required: true }}
          required
        />
        {errors.time_start && (
          <span className="text-red-600 font-bold">
            O campo Hora de início é obrigatório
          </span>
        )}

        <Controller
          customInput={Input}
          name="interval"
          label="Intervalo (em horas)"
          defaultValue=""
          control={control}
          as={Input}
          type="number"
          rules={{ required: true }}
          required
        />
        {errors.interval && (
          <span className="text-red-600 font-bold">
            O campo Intervalo é obrigatório
          </span>
        )}

        <div className="flex gap-2">
          <Input
            name="date_until"
            type="datetime-local"
            label="Data final"
            defaultValue=""
            inputRef={register}
            required
          />
          <Input
            name="count"
            defaultValue=""
            type="number"
            label="Número de ocorrências"
            inputRef={register}
            required
          />
        </div>
        {errors.countAndDateUntil && (
          <span className="w-full text-center text-red-600 font-bold">
            Selecione um dos campos acima (Data final ou Número de ocorrências)
          </span>
        )}
        <Button
          onClick={() => {
            clearErrors(['countAndDateUntil'])
          }}
          type="submit"
          color="primary"
        >
          Salvar
        </Button>
      </form>
    </div>
  )
}

const Notifications = () => {
  const { getUserRules, deleteUserRule } = useContext(APIContext)
  const { patient } = useContext(WindowContext)
  const { handleModal, setOpen } = useModalContext()

  const [userNotifications, setUserNotifications] = useState([])
  const [loading, setLoading] = useState(false)

  const deleteRule = async id => {
    const newNotifications = userNotifications.filter(item => item.value !== id)

    setUserNotifications(newNotifications)

    await deleteUserRule(id)
  }

  const loadUserRules = async () => {
    const res = await getUserRules()
    setUserNotifications(
      res.map(({ id, title }) => {
        return {
          label: title,
          value: id
        }
      })
    )
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      loadUserRules()
    }, 500)
    // eslint-disable-next-line
  }, [])

  return (
    <Form title="Notificações" hideButtons>
      <div className="monitoring">
        <div className="monitoring-div">
          {loading ? (
            <div className="flex justify-center my-4">
              <Spinner color="teal" />
            </div>
          ) : userNotifications.length > 0 ? (
            userNotifications.map(item => (
              <div className="item" key={item.value}>
                <label
                  name="user_rule"
                  className="mt-0 w-full py-4 px-2 bg-gray-400 text-gray-600 rounded font-medium"
                >
                  {item.label}
                </label>
                <Icon
                  name="trash"
                  onClick={() => deleteRule(item.value)}
                  className="trash-can-notification"
                />
              </div>
            ))
          ) : (
            <div className="text-center my-4 font-medium">
              <h2>Nenhuma regra cadastrada para este usuário</h2>
            </div>
          )}
        </div>

        <div>
          <Button
            color="white"
            onClick={() =>
              handleModal(
                <div className="max-w-lg overflow-auto">
                  <ModalSubmitUserRule
                    patient={patient}
                    loadUserRules={loadUserRules}
                    setLoading={setLoading}
                    setOpen={setOpen}
                  />
                </div>
              )
            }
          >
            Adicionar
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default Notifications
