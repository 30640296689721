import { useRef } from 'react'
import { MessageList, Input, Button } from 'react-chat-elements'
// RCE CSS
import 'react-chat-elements/dist/main.css'

const Chat = ({ sendMessage, ...props }) => {
  const ref = useRef(null)

  const handleButton = () => {
    sendMessage(ref.current.input.value)
    ref.current.input.value = ''
  }

  return (
    <div
      style={{ height: 450, minWidth: 350 }}
      className="overflow-y-auto flex flex-col justify-between px-2 pt-2"
    >
      <MessageList toBottomHeight={'100%'} {...props} />
      <Input
        placeholder="Digite..."
        multiline={true}
        ref={ref}
        rightButtons={
          <Button
            onClick={handleButton}
            color="white"
            backgroundColor="#D20E50"
            text="Enviar"
          />
        }
      />
    </div>
  )
}

export default Chat
