import { CloudUploadIcon } from '@heroicons/react/outline'

const DragNDrop = ({ value, rootProps, inputProps, disabled }) => {
  const rootProps_ = disabled ? {} : rootProps
  return (
    <div
      className={`flex flex-col w-full h-32 justify-center items-center ${
        disabled ? 'bg-gray-400' : 'bg-teal-100'
      } rounded-md cursor-pointer`}
      style={{
        border: `1.5px dashed ${disabled ? 'rgba(0, 0, 0, 0.12)' : '#008F8F'}`
      }}
      {...rootProps_}
    >
      <CloudUploadIcon
        className={`w-8 h-8 ${disabled ? 'text-gray-800' : 'text-teal-600'}`}
      />
      <h2 className="font-medium text-base text-gray-800">
        Toque ou arraste aqui
      </h2>
      <p className="text-sm text-gray-600">para enviar seus arquivos</p>
      {!disabled ? <input {...inputProps} value={value} /> : null}
    </div>
  )
}

export default DragNDrop
