import styled from 'styled-components'

const Svg = styled.svg`
  .st0 {
    clip-path: url(#SVGID_2_);
  }
  .st1 {
    clip-path: url(#SVGID_4_);
  }
  .st2 {
    fill: #822124;
  }
  .st3 {
    fill: #ffffff;
  }
`

function Hipercard({ size }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 71 72"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      enableBackground="new 0 0 71 70"
      version="1.1"
      xmlSpace="preserve"
    >
      <defs>
        <path id="SVGID_1_" d="M0.8 0H70.8V70H0.8z"></path>
      </defs>
      <clipPath>
        <use overflow="visible" xlinkHref="#SVGID_1_"></use>
      </clipPath>
      <g className="st0">
        <defs>
          <path id="SVGID_3_" d="M2 20.9H69V50.099999999999994H2z"></path>
        </defs>
        <clipPath>
          <use overflow="visible" xlinkHref="#SVGID_3_"></use>
        </clipPath>
        <g className="st1">
          <path
            d="M20.4 20.9h-6.9c-3.1.1-5.6 1.4-6.3 3.9-.4 1.3-.6 2.8-.9 4.2C4.8 36 3.4 43.2 2 50.1h54c4.2 0 7-.9 7.8-4.2.4-1.5.7-3.3 1-5 1.3-6.6 2.7-13.2 4.1-20H20.4z"
            className="st2"
          ></path>
          <path
            d="M19.9 31.3c.3-.2.7-1.1.2-1.5-.1-.1-.4-.2-.7-.1-.3 0-.5.1-.6.3-.2.3-.4 1-.1 1.3.3.2 1 .1 1.2 0zm-4.2-1.5c-.2 1.3-.4 2.7-.7 4-1.5 0-3 .1-4.5 0 .3-1.3.5-2.7.7-4H9.7c-.6 3.3-1.1 6.7-1.8 9.9h1.7c.3-1.7.5-3.3.8-4.9 1.4 0 3.1-.1 4.5 0-.3 1.6-.6 3.2-.9 4.9h1.7c.5-3.3 1.1-6.7 1.8-9.9h-1.8zm23.5 2.8c-1.3-.5-2.3.4-2.8 1.2.1-.4.2-.8.2-1.2h-1.3c-.3 2.4-.8 4.7-1.2 7h1.5c.2-1.4.3-3.2.7-4.5.4-1 1.3-1.9 2.7-1.5 0-.3.2-.6.2-1zM40 38c-.1-.3-.2-.9-.1-1.4.1-1 .4-2.2 1-2.7.8-.7 2.3-.6 3.5-.2 0-.4.1-.8.2-1.2-2-.3-3.8-.1-4.8.9s-1.6 3.3-1.2 4.8c.5 1.7 2.9 1.8 4.8 1.2.1-.3.1-.7.2-1.1-1.1.6-3.1.9-3.6-.3zm16.4-5.4c-1.3-.6-2.4.4-2.8 1.1.1-.3.1-.8.2-1.1h-1.3c-.3 2.4-.8 4.8-1.3 7h1.5c0-.9.2-1.6.3-2.5.3-2 .8-4.1 3-3.5.2-.3.3-.6.4-1zm-38 0v.1c-.3 2.4-.8 4.7-1.2 6.9h1.5c.3-2.4.8-4.8 1.3-7h-1.6zm12.8-.1c-1.1.1-1.9.4-2.6 1-.8.8-1.4 2.6-1.2 4.3.3 2.3 3.2 2.3 5.5 1.7 0-.4.1-.8.2-1.2-1 .4-2.6.9-3.6.2-.7-.5-.8-1.7-.5-2.7h4.8c.1-.7.4-1.5.1-2.3-.4-.9-1.5-1.1-2.7-1zm1.4 2.6h-3.4c.1-.8.8-1.7 1.9-1.7 1-.1 1.8.3 1.5 1.7zm-7-2.5c-1.2-.5-2.7.1-3.4.6 0-.2.1-.4.1-.6H21c-.5 3.5-1.1 6.9-1.8 10.2h1.5c.2-1.3.3-2.7.6-3.9.3 1.3 2.5 1 3.4.5 2-1 3.5-5.8.9-6.8zm-1.1 5.7c-.8.8-2.7.8-2.8-.6-.1-.6.2-1.2.3-1.9l.3-1.8c.8-.9 3-1.1 3.3.5.1 1.5-.5 3.2-1.1 3.8zm37.3-8.7c-.1 1.1-.3 2.1-.5 3.1-3.6-1.1-5.8 1.5-5.7 4.7 0 .6.1 1.2.5 1.7.7.8 2.6.9 3.6.3.2-.1.4-.4.5-.5.1-.1.2-.4.3-.3-.1.3-.1.7-.1 1h1.3c.3-3.7 1-6.8 1.6-10.1l-1.5.1zm-3.3 9.3c-1 0-1.5-.6-1.5-1.6 0-1.8.7-3.7 2.3-3.9.7-.1 1.3.1 1.8.3-.5 1.9-.3 5.1-2.6 5.2zM46 32.6l-.3 1.2c.9-.2 3.5-.9 3.8.3.1.4-.1.8-.2 1.1-2.4-.2-4.4.2-4.9 1.9-.3 1.2 0 2.3.8 2.6 1.4.6 3.2-.1 3.8-1-.1.3-.1.7-.1 1.1h1.3c0-1.2.2-2.2.4-3.3.2-.9.5-1.8.4-2.6-.2-2-3.3-1.3-5-1.3zm2.3 5.7c-.8.7-2.9.9-2.6-.8.2-1.5 1.8-1.8 3.5-1.6-.1.8-.3 1.8-.9 2.4z"
            className="st3"
          ></path>
        </g>
      </g>
    </Svg>
  )
}

export default Hipercard
