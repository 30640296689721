import { useCallback, useState } from 'react'

import PEPLayout from '../PEPLayout'
import VideoCallLayout from '../VideoCallLayout'
import WaitingRoomLayout from '../WaitingRoomLayout'

import useInterval from 'hooks/useInterval'

import { registerStatus } from 'services/appointmentService'

import { isUserDoctor } from 'utils/helpers'
import { APPOINTMENT_STATUS, STATUS_POLLING_INTERVAL } from 'utils/constants'

export default function VirtualRoomLayout({ appointment }: any) {
  const [canConnect, setCanConnect] = useState(false)
  const [isPatientUseTwilio, setisPatientUseTwilio] = useState(true)

  // checker to set patient on waiting room if the doctor hasnt connected
  const patientCantConnect = useCallback(
    () => !isUserDoctor() && !canConnect,
    [canConnect]
  )

  const handleVideoCallVersion = ({ data }: any) => {
    if (isPatientUseTwilio !== data.isPatientUseTwilio) {
      console.log('atualizou', isPatientUseTwilio)
      setisPatientUseTwilio(data.isPatientUseTwilio)
    }
  }

  useInterval(async () => {
    const data = await registerStatus(appointment.id)

    handleVideoCallVersion({ data })

    /*
      If patient cant connect, check if status is started to connect to video call
    */

    if (patientCantConnect()) {
      if (data.status && data.status === APPOINTMENT_STATUS.STARTED) {
        setCanConnect(true)
      }
    }
  }, STATUS_POLLING_INTERVAL)

  // If the user is patient and cant connect -> show waiting room
  if (patientCantConnect()) {
    return <WaitingRoomLayout />
  }

  return (
    <>
      <VideoCallLayout appointment={appointment} />
      {isUserDoctor() && <PEPLayout />}
    </>
  )
}
