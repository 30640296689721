const theme = {
  colors: {
    primary: '#D20E50',
    secondary: '#4DB1B1',
    gray1: '#171717',
    gray2: '#5e5e5e',
    gray3: '#a4a4a4',
    gray4: '#eaeaea',
    gray5: '#f2f2f2',
    gray6: '#fbfbfb',
    white1: '#ffffff',
    primaryPink1: '#540620',
    primaryPink2: '#7e0830',
    primaryPink3: '#d20e50',
    primaryPink4: '#e05685',
    primaryPink5: '#f2b7cb',
    primaryPink6: '#fbe7ee',
    teal1: '#003939',
    teal2: '#006464',
    teal3: '#008f8f',
    teal4: '#4db1b1',
    teal5: '#b3dddd',
    teal6: '#cbe0e0',
    teal7: '#e6f4f4',
    yellow1: '#b3842e',
    yellow2: '#e6a93b',
    yellow3: '#ffbc42',
    yellow4: '#ffc968',
    yellow5: '#ffdea1',
    yellow6: '#fff8ec',
    green1: '#244e35',
    green2: '#3f895c',
    green3: '#5ac484',
    green4: '#7bd09d',
    green5: '#ade2c2',
    green6: '#eff9f3',
    red1: '#570505',
    red2_5: '#FF5106',
    red2: '#ae0a0a',
    red3: '#d90d0d',
    red4: '#e45656',
    red5: '#f4b6b6',
    red6: '#fbe7e7'
  },
  height: {
    full: 'calc(100vh - 180px)'
  },
  mixins: {
    full_flex: 'display: flex; justify-content: center; align-items: center;',
    flex_height:
      'display: flex; flex-direction: column; justify-content: center;',
    flex_column: 'display: flex; flex-direction: column;',
    shadow_light: 'box-shadow: 0px 3px 10px 0px rgba(164, 164, 164, 0.5);',
    shadow_medium: 'box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);',
    round_light: 'border-radius: 5px;',
    assina_shadow_sm:
      'box-shadow: 0px 2.65625px 5.3125px 0.53125px rgba(0, 0, 0, 0.05);'
  },
  sizes: {
    xs: '320px',
    sm: '425px',
    md: '768px',
    lg: '1024px',
    xl: '1280px'
  }
}

export default theme
