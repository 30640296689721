import styled from 'styled-components'

export const PageControl = styled.div`
  bottom: 5%;
  left: 50%;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 4px;
  }
`

export const DocumentContainer = styled.div`
  min-height: ${({ maxHeight }) => maxHeight && maxHeight}px;
  min-width: calc(${({ maxWidth }) => maxWidth && maxWidth}px - 200px);
  max-height: ${({ maxHeight }) => maxHeight && maxHeight}px;
  max-width: calc(${({ maxWidth }) => maxWidth && maxWidth}px - 200px);

  overflow: auto;

  canvas {
    margin: 0 auto;
  }

  @media (max-width: 425px) {
    min-width: calc(${({ maxWidth }) => maxWidth && maxWidth}px - 115px);
    max-width: calc(${({ maxWidth }) => maxWidth && maxWidth}px - 115px);
  }

  @media (min-width: 1280px) {
    min-width: 1000px;
    max-width: 1000px;
  }
`
