import { useState } from 'react'

const useModal = () => {
  const [isOpen, setOpen] = useState(false)
  const [modalContent, setModalContent] = useState()

  const handleModal = content => {
    setOpen(!isOpen)
    if (content) {
      setModalContent(content)
    }
  }

  return {
    isOpen,
    setOpen,
    handleModal,
    modalContent
  }
}

export default useModal
