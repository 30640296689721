import { Switch, Route } from 'react-router-dom'
import { APP_URLS } from 'urls'

import {
  Anamnesis,
  BiometricsData,
  Hypothesis,
  Management,
  Prescriptions,
  Documents,
  Attachments,
  EvolucaoSimples,
  Notifications,
  SmartConsultation
} from '../components/layouts'

const MedicalRecord = ({ isMedicalRecord = false }) => {
  return isMedicalRecord ? (
    <Switch>
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS}
        component={Anamnesis}
        exact
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_PATIENT_SMART_CONSULTATION}
        component={SmartConsultation}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_BIOMETRICS}
        component={BiometricsData}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_MANAGEMENT}
        component={Management}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_HYPOTHESIS}
        component={Hypothesis}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_PRESCRIPTIONS}
        component={Prescriptions}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_DOCUMENTS}
        component={Documents}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_EXAMS}
        component={Attachments}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_VERSATILIS}
        component={EvolucaoSimples}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_NOTIFICATIONS}
        component={Notifications}
      />
    </Switch>
  ) : (
    <Switch>
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS}
        component={Anamnesis}
        exact
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_BIOMETRICS}
        component={BiometricsData}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_MANAGEMENT}
        component={Management}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_HYPOTHESIS}
        component={Hypothesis}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_PRESCRIPTIONS}
        component={Prescriptions}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_DOCUMENTS}
        component={Documents}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_EXAMS}
        component={Attachments}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_VERSATILIS}
        component={EvolucaoSimples}
      />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_NOTIFICATIONS}
        component={Notifications}
      />
    </Switch>
  )
}

export default MedicalRecord
