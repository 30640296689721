import { EyeIcon, CurrencyDollarIcon } from '@heroicons/react/solid'
import { CircularProgress } from '@material-ui/core'

import Button from 'components/ui/Button'
import Heading from 'components/ui/Heading'
import Link from 'components/ui/Link'
import Text from 'components/ui/Text'

import { AccRequest } from 'types/AccRequest'

interface RequestPaymentStatusProps {
  request: AccRequest
  isApproveVoucherLoading: boolean
  handleApproveVoucher: () => void
}

export default function RequestPaymentStatus({
  request: { bank_slip, bank_slip_payment_voucher, status },
  isApproveVoucherLoading,
  handleApproveVoucher
}: RequestPaymentStatusProps) {
  const isBankSlip = bank_slip != null
  const isBankSlipPaid = bank_slip_payment_voucher != null

  return (
    <div>
      <Heading className="text-lg font-semibold text-pink-600 mb-2">
        Pagamento
      </Heading>

      <div className="space-y-2">
        <div>
          <Text className="text-gray-700 text-base">Forma de pagamento:</Text>
          <Heading className="font-semibold text-lg">
            {isBankSlip ? 'Boleto' : 'Cartão de crédito'}
          </Heading>
        </div>

        {isBankSlip && (
          <div>
            <Text className="text-gray-700 text-base">Situação:</Text>
            {isBankSlipPaid ? (
              <>
                <Heading className="font-semibold text-lg">
                  {status === 'done' ? 'Pago' : 'Comprovante enviado'}
                </Heading>

                <div className="flex space-x-3 mt-1">
                  {bank_slip_payment_voucher && (
                    <Link
                      to={bank_slip_payment_voucher}
                      isExternal
                      className="w-full"
                    >
                      <Button
                        color="transparent"
                        className="text-pink-600 border-2 border-pink-600 w-full"
                      >
                        <EyeIcon className="h-5 mr-4" /> Ver comprovante
                      </Button>
                    </Link>
                  )}
                  {status === 'paying' && (
                    <Button
                      className="bg-green-500 text-gray-100 w-full"
                      style={{ maxWidth: '200px' }}
                      color="transparent"
                      onClick={handleApproveVoucher}
                    >
                      {isApproveVoucherLoading ? (
                        <CircularProgress
                          style={{ color: 'white' }}
                          size="24px"
                        />
                      ) : (
                        <>
                          <CurrencyDollarIcon className="h-5 mr-4" />
                          Aprovar pagamento
                        </>
                      )}
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <Heading className="font-semibold text-lg">Boleto gerado</Heading>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
