import { AnimatePresence } from 'framer-motion'
import { Switch, Route } from 'react-router-dom'

import { APP_URLS } from '../../../urls'

import { BillingView } from '../Billing'
import UserProfile from '../UserProfile'

import Head from 'components/common/Head'

const ProfileView = () => {
  return (
    <>
      <Head title="Perfil" />
      <AnimatePresence exitBeforeEnter>
        <Switch>
          <Route exact path={APP_URLS.PROFILE}>
            <UserProfile />
          </Route>
          <Route path={APP_URLS.BILLING}>
            <BillingView />
          </Route>
        </Switch>
      </AnimatePresence>
    </>
  )
}

export default ProfileView
