import { FormEvent } from 'react'
import { SearchIcon } from '@heroicons/react/outline'

import { Button, Input, SelectComponent } from 'components/ui'
import Heading from 'components/ui/Heading'

interface IFiltesLayout {
  status: string
  setStatus: (state: string) => void
  date: string
  setDate: (state: string) => void
  doctorName: string
  setDoctorName: (state: string) => void
  patientName: string
  setPatientName: (state: string) => void
  applyFilters: (e: FormEvent<HTMLFormElement>) => void
  cleanFilters: () => void
}

export default function FiltersLayout({
  status,
  setStatus,
  date,
  setDate,
  doctorName,
  setDoctorName,
  patientName,
  setPatientName,
  applyFilters,
  cleanFilters
}: IFiltesLayout) {
  return (
    <div className="bg-gray-100 p-6 flex justify-between w-full">
      <form
        className="flex space-x-4 w-full justify-between"
        onSubmit={applyFilters}
      >
        <div className="w-1/3 flex flex-col gap-2 justify-between">
          <Heading className="text-gray-900 font-semibold w-full">
            Status
          </Heading>
          <SelectComponent
            value={status}
            placeholder="Selecione"
            options={[
              'Agendada',
              'Cancelada',
              'Iniciada',
              'Realizada',
              'Falta'
            ]}
            name="status"
            className="w-48"
            classNameSelect="w-full h-10 cursor-pointer rounded-md tracking-widest text-gray-700 bg-gray-300 pl-2 placeholder-gray-600"
            // @ts-ignore
            onChange={e => {
              setStatus(e.target.value)
            }}
          />
        </div>
        <div className="w-48 flex flex-col gap-2 justify-between">
          <Heading className="text-gray-900 font-semibold w-full">Data</Heading>
          <Input
            type="date"
            placeholder="__/__/__"
            className="w-48 h-10 cursor-pointer rounded-md tracking-widest text-gray-700 bg-gray-300 p-2 placeholder-gray-600"
            value={date}
            // @ts-ignore
            onChange={e => {
              setDate(e.target.value)
            }}
          />
        </div>
        <div className="w-full flex flex-col gap-2 justify-between">
          <Heading className="text-gray-900 font-semibold w-full">
            Nome do médico contém
          </Heading>
          <Input
            type="text"
            placeholder="Escreva"
            className=" w-full h-10 cursor-pointer rounded-md tracking-widest text-gray-700 bg-gray-300 pl-2 placeholder-gray-600"
            value={doctorName}
            // @ts-ignore
            onChange={e => {
              setDoctorName(e.target.value)
            }}
          />
        </div>
        <div className="w-full flex flex-col gap-2 justify-between">
          <Heading className="text-gray-900 font-semibold w-full">
            Nome do paciente contém
          </Heading>
          <Input
            type="text"
            placeholder="Escreva"
            className=" w-full h-10 cursor-pointer rounded-md tracking-widest text-gray-700 bg-gray-300 pl-2 placeholder-gray-600"
            value={patientName}
            // @ts-ignore
            onChange={e => {
              setPatientName(e.target.value)
            }}
          />
        </div>
        <div className="flex flex-col justify-end">
          <Button
            color="pink"
            className="font-semibold text-base flex gap-2  px-6"
          >
            <SearchIcon className=" text-white mx-auto h-5" />
            Pesquisar
          </Button>
        </div>

        <div className="flex flex-col justify-end">
          <Button
            color="transparent"
            className="font-semibold text-base bg-gray-300 px-4"
            onClick={cleanFilters}
          >
            Limpar
          </Button>
        </div>
      </form>
    </div>
  )
}
