import { useEffect, useReducer } from 'react'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import { APP_URLS } from '../../../urls'

const initialState = {
  title: '',
  subtitle: ''
}

const reducer = (state, feature) => {
  if (feature === APP_URLS.PROFILE) {
    return {
      ...state,
      title: 'Meu perfil',
      subtitle: 'Informações sobre seu cadastro'
    }
  }
  if (feature === APP_URLS.BILLING) {
    return {
      ...state,
      title: 'Meu plano',
      subtitle: 'Informações sobre sua assinatura'
    }
  }
  if (feature.includes('detalhes')) {
    return {
      ...state,
      title: 'Detalhes do plano',
      subtitle: 'Informações sobre sua assinatura'
    }
  }
  if (feature.includes('alterar-pagamento')) {
    return {
      ...state,
      title: 'Alterar forma de pagamento',
      subtitle: 'Sem anuidades. Sem multas. Sem taxas escondidas.'
    }
  }
  if (feature === APP_URLS.HEALTH_CARD) {
    return {
      ...state,
      title: 'Carteirinha Virtual',
      subtitle:
        'A sua própria carteirinha digital para receber descontos em medicamentos e outros benefícios.'
    }
  }
}

const FeatureTitle = ({ feature }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch(feature)
  }, [feature])

  return (
    <div className="w-full bg-pink-600">
      <div className="container text-center py-8 mx-auto text-white">
        <Heading className="font-semibold text-2xl">{state.title}</Heading>
        <Text className="text-lg">{state.subtitle}</Text>
      </div>
    </div>
  )
}

export default FeatureTitle
