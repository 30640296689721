import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import { SummarySection, Button, Icon } from '../../elements'
import ExamRequest from '../../oldAppCommons/components/ExamRequest'
import { fullDate } from '../../../utils/Utils'

const PartialExams = ({ info, isMultiple, indexOnData, maxIndex }) => {
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  return (
    <>
      {Object.keys(info).length !== 0 && (
        <>
          <SummarySection
            createdBy={info.created_by.nome}
            date={info.data_cadastro.split(' ')[1]}
            title="Solicitação de Exames"
            isMultiple={isMultiple}
            indexOnData={indexOnData}
            maxIndex={maxIndex}
          >
            <h3>
              Indicação Clínica{' '}
              {info.indicacao_clinica ? info.indicacao_clinica : '-'}
            </h3>
            {info.exames.map(item => (
              <div className="summary-exam" key={item.id}>
                <span>{item.nome}</span>
                <br />
                <span>Quantidade: {item.quantidade}</span>
              </div>
            ))}
            <div className="print-document">
              <Button
                type="button"
                color="white"
                className="button-print"
                onClick={handlePrint}
              >
                <Icon name="printer" />
                Imprimir
              </Button>
            </div>
            <div className="hidden">
              <ExamRequest
                ref={componentRef}
                doctor={{
                  name: info.created_by.nome
                }}
                patient={{
                  name: info.usuario.nome
                }}
                indication={info.indicacao_clinica}
                exams={
                  info
                    ? info.exames.map(elm => ({
                        id: elm.id,
                        value: elm.nome,
                        quantidade: elm.quantidade
                      }))
                    : [
                        {
                          id: 0,
                          value: '',
                          quantidade: 0
                        }
                      ]
                }
                date={fullDate(info.data_cadastro.split(' ')[0])}
              />
            </div>
          </SummarySection>
        </>
      )}
    </>
  )
}

export default PartialExams
