import { Button } from 'components/ui'
import Heading from 'components/ui/Heading'
import { ModalContext } from 'contexts/ModalContext'
import { useContext, useState } from 'react'
import { fetchApi } from 'services/api'

interface IReportModal {
  id: number
  data_agenda: string
  hora_agenda?: string
  paciente: any
}

export default function ReportPrompt({
  id,
  data_agenda,
  hora_agenda = '',
  paciente
}: IReportModal) {
  const { setOpen } = useContext(ModalContext)

  const [report, setReport] = useState<string | null>(null)

  const sendReport = () => {
    fetchApi('/api/v1/consultation/report-problem', 'post', {
      consultation_id: id,
      title: `Report atendimento dia: ${data_agenda}, hora: ${hora_agenda}, paciente: ${paciente?.nome}`,
      description: report
    })
    setOpen(false)
  }

  return (
    <form
      className="flex flex-col bg-white h-auto rounded-b"
      onSubmit={() => sendReport()}
    >
      <Heading className="w-full font-semibold text-center text-lg text-pink-600">
        Reportar problemas na consulta
      </Heading>
      <textarea
        onChange={elm => setReport(elm.target.value)}
        className="mt-4 p-2 w-64 mx-2 bg-gray-300 h-48"
        required
      ></textarea>
      <Button type="submit" className=" mt-4 mx-2 mb-4">
        Enviar
      </Button>
    </form>
  )
}
