import styled from 'styled-components'

const Svg = styled.svg`
  .st0 {
    clip-path: url(#SVGID_2_);
  }
  .st1 {
    fill: #231f20;
  }
  .st2 {
    fill: #f79410;
  }
  .st3 {
    fill: #ff5f00;
  }
  .st4 {
    fill: #eb001b;
  }
  .st5 {
    fill: #f79e1b;
  }
`

function Mastercard({ size }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 71 72"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      enableBackground="new 0 0 70 70"
      version="1.1"
      xmlSpace="preserve"
    >
      <defs>
        <path id="SVGID_1_" d="M7 13H63V56.6H7z"></path>
      </defs>
      <clipPath>
        <use overflow="visible" xlinkHref="#SVGID_1_"></use>
      </clipPath>
      <g className="st0">
        <path
          d="M61.9 55.9v.1h.1l-.1-.1zm.1-.1h.1v.1H62l.1.2H62l-.1-.2v.2h-.1v-.4l.2.1zm0 .6h.1s.1 0 .1-.1l.1-.1v-.3s0-.1-.1-.1l-.1-.1H62h-.1s-.1 0-.1.1-.1.1-.1.2v.2s0 .1.1.1l.1.1h.1zm0-.8c.1 0 .2 0 .3.1l.1.1v.4c0 .1-.1.1-.1.1l-.1.1h-.4c-.1 0-.1-.1-.1-.1s-.1-.1-.1-.2v-.2-.2c0-.1.1-.1.1-.1l.1-.1c0 .1.1.1.2.1zM19.1 54c0-.8.5-1.5 1.4-1.5.8 0 1.4.6 1.4 1.5 0 .8-.6 1.5-1.4 1.5-.9-.1-1.4-.7-1.4-1.5zm3.7 0v-2.3h-1v.6c-.3-.4-.8-.7-1.5-.7-1.3 0-2.3 1-2.3 2.4s1 2.4 2.3 2.4c.7 0 1.1-.3 1.5-.7v.6h1V54zm33.8 0c0-.8.5-1.5 1.4-1.5.8 0 1.4.6 1.4 1.5 0 .8-.6 1.5-1.4 1.5-.9-.1-1.4-.7-1.4-1.5zm3.7 0v-4.1h-1v2.4c-.3-.4-.8-.7-1.5-.7-1.3 0-2.3 1-2.3 2.4s1 2.4 2.3 2.4c.7 0 1.1-.3 1.5-.7v.6h1V54zm-25.1-1.5c.6 0 1.1.4 1.2 1.1H34c.1-.7.5-1.1 1.2-1.1zm0-.9c-1.3 0-2.3 1-2.3 2.4 0 1.5 1 2.4 2.4 2.4.7 0 1.3-.2 1.9-.6l-.5-.8c-.4.3-.9.5-1.3.5-.6 0-1.2-.3-1.4-1.1h3.4V54c0-1.5-.9-2.4-2.2-2.4zM47.3 54c0-.8.5-1.5 1.4-1.5.8 0 1.4.6 1.4 1.5 0 .8-.6 1.5-1.4 1.5-.9-.1-1.4-.7-1.4-1.5zm3.7 0v-2.3h-1v.6c-.3-.4-.8-.7-1.5-.7-1.3 0-2.3 1-2.3 2.4s1 2.4 2.3 2.4c.7 0 1.1-.3 1.5-.7v.6h1V54zm-9.3 0c0 1.4 1 2.4 2.5 2.4.7 0 1.2-.2 1.7-.5l-.6-.9c-.4.3-.8.4-1.2.4-.8 0-1.4-.6-1.4-1.5s.6-1.5 1.4-1.5c.4 0 .8.1 1.2.4l.5-.8c-.5-.4-1-.5-1.7-.5-1.5.1-2.4 1.1-2.4 2.5zm12.8-2.4c-.6 0-1 .3-1.2.7v-.6h-1v4.6h1v-2.6c0-.8.3-1.2 1-1.2.2 0 .4 0 .6.1l.3-.9c-.2-.1-.4-.1-.7-.1zm-26.7.4c-.5-.3-1.1-.5-1.9-.5-1.2 0-1.9.6-1.9 1.5 0 .7.6 1.2 1.6 1.4l.5.1c.5.1.8.2.8.5 0 .4-.4.6-1 .6-.7 0-1.2-.2-1.5-.5l-.5.8c.5.4 1.2.6 2 .6 1.3 0 2.1-.6 2.1-1.5 0-.8-.6-1.2-1.6-1.4l-.5-.1c-.5-.1-.9-.2-.9-.5s.3-.5.9-.5c.6 0 1.2.2 1.4.4l.5-.9zm12.9-.4c-.6 0-1 .3-1.2.7v-.6h-1v4.6h1v-2.6c0-.8.3-1.2 1-1.2.2 0 .4 0 .6.1l.3-.9c-.3-.1-.5-.1-.7-.1zm-8.6.1h-1.6v-1.4h-1v1.4h-.9v.9h.9v2.1c0 1.1.4 1.7 1.6 1.7.4 0 .9-.1 1.3-.4l-.4-.8c-.3.2-.6.3-.9.3-.5 0-.7-.3-.7-.8v-2.1H32v-.9zm-14.9 4.6v-2.9c0-1.1-.7-1.8-1.8-1.8-.6 0-1.2.2-1.6.8-.3-.5-.8-.8-1.5-.8-.5 0-1 .1-1.3.7v-.6h-1v4.6h1v-2.5c0-.8.4-1.2 1.1-1.2.7 0 1 .4 1 1.2v2.6h1v-2.5c0-.8.5-1.2 1.1-1.2.7 0 1 .4 1 1.2v2.6l1-.2z"
          className="st1"
        ></path>
        <path
          d="M62.5 41v-.7h-.2l-.2.5-.2-.5h-.2v.7h.1v-.5l.2.4h.1l.2-.4v.5h.2zm-1.1 0v-.6h.2v-.1H61v.1h.2v.6h.2z"
          className="st2"
        ></path>
        <path d="M42.6 43.9H27.4V16.7h15.1l.1 27.2z" className="st3"></path>
        <path
          d="M28.4 30.3c0-5.5 2.6-10.4 6.6-13.6-3-2.4-6.8-3.7-10.7-3.7C14.7 12.9 7 20.7 7 30.3s7.7 17.3 17.3 17.3c3.9 0 7.6-1.3 10.7-3.7-4-3.2-6.6-8.1-6.6-13.6z"
          className="st4"
        ></path>
        <path
          d="M63 30.3c0 9.6-7.7 17.3-17.3 17.3-3.9 0-7.6-1.3-10.7-3.7 4-3.2 6.6-8.1 6.6-13.6S39 19.9 35 16.7c3-2.4 6.8-3.7 10.7-3.7C55.3 13 63 20.7 63 30.3"
          className="st5"
        ></path>
      </g>
    </Svg>
  )
}

export default Mastercard
