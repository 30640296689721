import { backendServices } from 'services/AxiosInstances'

export const getMedicalRecords = (patientId, page) =>
  backendServices
    .get(`/prontuario-v1/${patientId}?page=${page ?? 1}`)
    .then(({ data }) => {
      const records = data.data
      const totalPages = data.total_pages

      records.map(record => record.changes.reverse())

      return { totalPages, records }
    })
    .catch(error => {
      throw new Error(error)
    })
