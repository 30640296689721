import Head from 'components/common/Head'
import UserAppointments from 'components/Appointment/UserAppointments'
import Heading from 'components/ui/Heading'

export default function SchedulePage() {
  return (
    <>
      <Head title="Agenda" />
      <div className="flex flex-col w-full bg-gray-300">
        <div className="mx-auto w-full max-w-screen-sm">
          <Heading className="text-2xl font-semibold text-center text-pink-600 py-8">
            Sua agenda
          </Heading>
          <UserAppointments />
        </div>
      </div>
    </>
  )
}
