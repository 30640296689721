import ReactDOM from 'react-dom'

import Modal from 'react-modal'

import App from 'App'

import 'styles/index.css'

import posthog, { PostHogProvider, posthogClient } from 'lib/posthog'
import bugsnag, { BugsnagErrorBoundary } from 'lib/bugsnag'
import { OneSignalProvider } from 'lib/oneSignal'
import { isProduction } from 'utils/constants'

Modal.setAppElement('#menuRoot')

bugsnag.init()

posthog.init()

/*
  Only setup tracking providers or error boundaries
*/

ReactDOM.render(
  isProduction ? (
    <BugsnagErrorBoundary>
      <PostHogProvider client={posthogClient}>
        <OneSignalProvider>
          <App />
        </OneSignalProvider>
      </PostHogProvider>
    </BugsnagErrorBoundary>
  ) : (
    <PostHogProvider client={posthogClient}>
      <App />
    </PostHogProvider>
  ),
  document.getElementById('root')
)
