import { useState, useEffect } from 'react'

const Imc = ({ weight, height }) => {
  const [imc, setImc] = useState(0)

  useEffect(() => {
    setImc(weight / (height * height))
  }, [weight, height])

  return (
    <>
      <p>
        IMC de{' '}
        <strong>{(parseFloat(imc * 100).toFixed(4) * 100).toFixed(2)}</strong>
      </p>
    </>
  )
}
export default Imc
