import React, { useEffect, useState } from 'react'

import Symptoms from '../components/layouts/Karmen/Symptoms'
import { SectionDescriptions } from '../components/layouts/Karmen/Descriptions'
import Section from '../components/layouts/Karmen/Section'
import ImcChart from '../components/layouts/Karmen/ImcChart'
import HealthHistory from '../components/layouts/Karmen/HealthHistory'
import { SectionIds } from '../utils/karmenUtils'
import { useQuery } from 'react-query'
import { SPECIALTY_GP_ID } from '../utils/constants'
import theme from 'styles/theme'

import { getKarmenSpecialty } from '../services/karmenService'
import Loader from 'react-loader-spinner'

const ComponentKarmen1 = ({
  karmenData,
  patient,
  appointment,
  dataFetched,
  isLoadingKarmenData,
  isErrorKarmenData
}) => {
  const [imc, setImc] = useState(null)

  const {
    data: specialtyData,
    isLoading: isLoadingSpecialtyData,
    isError: isErrorSpecialtyData
  } = useQuery(
    ['specialtyData', patient ? patient.id : appointment.paciente.id],
    () =>
      getKarmenSpecialty(
        patient ? patient.id : appointment.paciente.id,
        patient ? SPECIALTY_GP_ID : appointment.especialidade.id
      ),
    {
      enabled: dataFetched && !!appointment
    }
  )

  useEffect(() => {
    if (karmenData && karmenData.client.imc) {
      const imcValue = karmenData.client.imc
      if (0 < imcValue && imcValue <= 18.8) {
        setImc({
          value: imcValue,
          chartPercentage: 10,
          color: theme.colors.secondary
        })
      }
      if (18.8 < imcValue && imcValue <= 24.9) {
        setImc({
          value: imcValue,
          chartPercentage: 30,
          color: theme.colors.green3
        })
      }
      if (25.0 < imcValue && imcValue <= 29.9) {
        setImc({
          value: imcValue,
          chartPercentage: 50,
          color: theme.colors.yellow3
        })
      }
      if (30.0 < imcValue && imcValue <= 39.9) {
        setImc({
          value: imcValue,
          chartPercentage: 70,
          color: theme.colors.red2_5
        })
      }
      if (40.0 <= imcValue) {
        setImc({
          value: imcValue,
          chartPercentage: 90,
          color: theme.colors.red3
        })
      }
    }
  }, [karmenData])

  if (isLoadingKarmenData || isLoadingSpecialtyData) {
    return (
      <div className="w-full loader-margin flex items-center justify-center">
        <Loader
          type="Oval"
          color="#D20E50"
          height={50}
          width={50}
          timeout={60000}
        />
      </div>
    )
  }

  if (isErrorKarmenData || isErrorSpecialtyData) {
    return (
      <div className="w-full loader-margin flex items-center justify-center text-xl font-semibold text-gray-900">
        <h1>Ocorreu um erro ao carregar informacoes da Karmen</h1>
      </div>
    )
  }

  return (
    karmenData &&
    karmenData.sessionId && (
      <div className="w-full">
        <Section id={SectionIds.anamnese} title="Anamnese Básica">
          <div className="border-t-1 border-l-0 border-b-0 border-r-0 border-gray-500 border-2 mt-2 p-2 pt-4 text-base">
            <div className="flex">
              <SectionDescriptions
                className="text-gray-900 w-1/2"
                descriptions={[
                  {
                    span: karmenData.client.FullName || '-',
                    label: 'Nome:',
                    labelStyle: 'text-gray-900 font-bold',
                    spanStyle: 'text-gray-700'
                  },
                  {
                    span: karmenData.client.Age
                      ? parseInt(karmenData.client.Age)
                      : '-',
                    label: 'Idade:',
                    labelStyle: 'text-gray-900 font-bold',
                    spanStyle: 'text-gray-700'
                  },
                  {
                    span: `${karmenData.client.Height || '-'} cm`,
                    label: 'Altura:',
                    labelStyle: 'text-gray-900 font-bold',
                    spanStyle: 'text-gray-700'
                  },
                  {
                    span: `${karmenData.client.Weight || '-'} kg`,
                    label: 'Peso:',
                    labelStyle: 'text-gray-900 font-bold',
                    spanStyle: 'text-gray-700'
                  },
                  {
                    span: karmenData.client.Gender || '-',
                    label: 'Sexo:',
                    labelStyle: 'text-gray-900 font-bold',
                    spanStyle: 'text-gray-700'
                  }
                ]}
              />
              <SectionDescriptions
                className="text-gray-900 w-1/2 space-y-2"
                descriptions={[
                  {
                    span: karmenData.Type || '-',
                    label: 'Medicamentos:',
                    spanStyle: 'text-gray-700',
                    labelStyle: 'font-semibold text-pink-600'
                  },
                  {
                    span: `${
                      karmenData.InFamily === 'não' || !karmenData.InFamily
                        ? ''
                        : 'diabetes'
                    }, ${
                      karmenData._InFamily === 'não' || !karmenData._InFamily
                        ? ''
                        : 'hipertensão'
                    }`,
                    label: 'Antecedentes Familiares:',
                    spanStyle: 'text-gray-700',
                    labelStyle: 'font-semibold text-pink-600'
                  }
                ]}
              />
            </div>
          </div>
          <div className="w-full bg-white rounded shadow-sm p-4">
            <h2 className="text-pink-600 font-medium text-md">IMC</h2>
            {imc ? (
              <div className="border-t-1 border-l-0 border-b-0 border-r-0 border-gray-500 border-2 mt-2 pt-4 w-full flex justify-between flex-col">
                <ImcChart
                  imc={imc}
                  colors={[
                    {
                      color: theme.colors.secondary,
                      label: 'Abaixo do peso'
                    },
                    {
                      color: theme.colors.green3,
                      label: 'Normal'
                    },
                    {
                      color: theme.colors.yellow3,
                      label: 'Sobrepeso'
                    },
                    {
                      color: theme.colors.red2_5,
                      label: 'Obesidade 1'
                    },
                    {
                      color: theme.colors.red3,
                      label: 'Obesidade 2'
                    }
                  ]}
                />
              </div>
            ) : (
              '-'
            )}
          </div>
          <div className="mt-4 w-full bg-white rounded shadow-sm p-4">
            <h2 className="text-pink-600 font-semibold text-xl">Hábitos</h2>
            <div className="pt-4 w-full flex flex-col space-y-4">
              <div className="w-full border-t-2 border-gray-500 pt-2">
                <h3 className="text-pink-600 font-medium text-lg">
                  Qualidade de vida
                </h3>
                <div className="mt-4 text-base grid grid-cols-2 gap-4">
                  <div className="space-y-4">
                    <p className="font-bold text-gray-900">
                      Frequência de exercício:{' '}
                      <span className="font-normal text-teal-700">
                        {karmenData.lifeQuality.exerciseFrequency || '-'}
                      </span>
                    </p>
                    <p className="font-bold text-gray-900">
                      Frequência de stress:{' '}
                      <span className="font-normal text-teal-700">
                        {karmenData.lifeQuality.stressFrequency || '-'}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="font-bold text-gray-900">
                      Qualidade do sono:{' '}
                      <span className="font-normal text-teal-700">
                        {karmenData.lifeQuality.sleepQuality || '-'}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full border-t-2 border-gray-500 pt-2">
                <h3 className="text-pink-600 font-medium text-lg">Tabagismo</h3>
                <div className="mt-4 text-base grid grid-cols-2 gap-4">
                  <div className="space-y-4">
                    <p className="font-bold text-gray-900">
                      Perfil tabagista:{' '}
                      <span className="font-normal text-teal-700">
                        {karmenData.smoking.Profile
                          ? karmenData.smoking.Profile
                          : '-'}
                      </span>
                    </p>
                    <p className="font-bold text-gray-900">
                      Ano de inicio:{' '}
                      <span className="font-normal text-teal-700">
                        {karmenData.smoking.InitialYear
                          ? parseInt(karmenData.smoking.InitialYear)
                          : '-'}
                      </span>
                    </p>
                    <p className="font-bold text-gray-900">
                      Ano de fim:{' '}
                      <span className="font-normal text-teal-700">
                        {karmenData.smoking.FinalYear
                          ? parseInt(karmenData.smoking.FinalYear)
                          : '-'}
                      </span>
                    </p>
                  </div>
                  <div className="space-y-4">
                    <p className="font-bold text-gray-900">
                      Nível de dependencia (1 - 5):{' '}
                      <span className="font-normal text-teal-700">
                        {karmenData.smoking.AddictionLevel
                          ? parseInt(karmenData.smoking.AddictionLevel)
                          : '-'}
                      </span>
                    </p>
                    <p className="font-bold text-gray-900">
                      Cigarros por dia:{' '}
                      <span className="font-normal text-teal-700">
                        {karmenData.smoking.CigarettesPerDay
                          ? parseInt(karmenData.smoking.CigarettesPerDay)
                          : '-'}
                      </span>
                    </p>
                    <p className="font-bold text-gray-900">
                      Dificuldade para parar de fumar (1 - 5):{' '}
                      <span className="font-normal text-teal-700">
                        {karmenData.smoking.QuittingDifficulty
                          ? parseInt(karmenData.smoking.QuittingDifficulty)
                          : '-'}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full border-t-2 border-gray-500 pt-2">
                <h3 className="text-pink-600 font-medium text-lg">
                  Alcoolismo
                </h3>
                <div className="mt-4 text-base grid grid-cols-2 gap-4">
                  <div className="space-y-4">
                    <p className="font-bold text-gray-900">
                      Com que frequência bebe:{' '}
                      <span className="font-normal text-teal-700">
                        {karmenData.alcohol.ConsumptionLevel || '-'}
                      </span>
                    </p>
                    <p className="font-bold text-gray-900">
                      Dose média:{' '}
                      <span className="font-normal text-teal-700">
                        {karmenData.alcohol.Dosage || '-'}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="font-bold text-gray-900">
                      Nível de dependencia (1 - 5):{' '}
                      <span className="font-normal text-teal-700">{'-'}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>

        {specialtyData && specialtyData.symptom ? (
          <Symptoms
            id={SectionIds.symptoms}
            sympt={{
              ...specialtyData.symptom,
              procedurePregnancyLast12Months:
                specialtyData.procedurePregnancyLast12Months,
              diabetesInFamilyStatus:
                specialtyData.diabetes.diabestsInFamilyPerson,
              neckSurgeryStatus: specialtyData.neckSurgery.Status,
              neckSurgeryType: specialtyData.neckSurgery.Type,
              neckSurgeryYear: specialtyData.neckSurgery.Year,
              radiologyTreatmentStatus: specialtyData.radiologyTreatmentStatus,
              WeightChangeStatus: specialtyData.symptom.WeightChangeValue
            }}
          />
        ) : null}

        <Section
          id={SectionIds.medicalHistory}
          className="mt-4"
          title="Histórico de saúde"
        >
          <HealthHistory data={karmenData} />
        </Section>

        <Section
          className="mt-4"
          title="Informações médicas complementares"
          id={SectionIds.additionalMedicalInformation}
        >
          <p className="mt-2">
            {karmenData.medicalComplimentaryInfo ||
              'Nenhuma informação adicional'}
          </p>
        </Section>
      </div>
    )
  )
}

export default ComponentKarmen1
