import { SetStateAction, useEffect } from 'react'
import {
  FilterIcon,
  XIcon,
  SearchIcon,
  CalendarIcon
} from '@heroicons/react/solid'
import cn from 'classnames'
import { CircularProgress } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'

import Dialog from 'components/ui/Dialog'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import FilterSection from 'components/elements/UsersPage/FilterSection'
import ControlledInput from 'components/elements/Forms/ControlledInput'
import ControlledSelect from 'components/elements/Forms/ControlledSelect'
import KompaSpinner from 'components/ui/KompaSpinner'
import { Button } from 'components/ui'
import ControlledCheckbox from 'components/elements/Forms/ControlledCheckbox'

import useMedicalProfessionals from 'hooks/useMedicalProfessionals'
import usePlans from 'hooks/usePlans'
import useCareLines from 'hooks/useCareLines'

import { removeEmptyProperties } from 'utils/helpers'
import { buyReasonOptions } from 'components/layouts/UserPage/OverviewLayout/enums'

import { AdminUsersFilters } from 'types/AdminUsers'

const cleanUndefinedFilters = (filters: AdminUsersFilters) => {
  let out = { ...filters }

  delete out.is_patient
  delete out.is_plan_active
  delete out.page
  delete out.results_per_page

  if (out.q == null) {
    out = { ...out, q: '' }
  }
  if (out.answered_karmen == null) {
    out = { ...out, answered_karmen: false }
  }
  if (out.first_consultation_with_nursing == null) {
    out = { ...out, first_consultation_with_nursing: false }
  }
  if (out.first_consultation_with_dedicated_doctor == null) {
    out = { ...out, first_consultation_with_dedicated_doctor: false }
  }
  if (out.dedicated_nurse_id == null) {
    out = { ...out, dedicated_nurse_id: '' }
  }
  if (out.dedicated_doctor_id == null) {
    out = { ...out, dedicated_doctor_id: '' }
  }
  if (out.start_date_of_joining_of_the_plan == null) {
    out = { ...out, start_date_of_joining_of_the_plan: '' }
  }
  if (out.end_date_of_joining_of_the_plan == null) {
    out = { ...out, end_date_of_joining_of_the_plan: '' }
  }
  if (out.default_on_debt == null) {
    out = { ...out, default_on_debt: '' }
  }
  if (out.plan_id == null) {
    out = { ...out, plan_id: '' }
  }
  if (out.buy_reason == null) {
    out = { ...out, buy_reason: '' }
  }
  if (out.care_plan_id == null) {
    out = { ...out, care_plan_id: '' }
  }

  return out
}

const bodyClasses = cn(
  'p-6 h-screen overflow-auto bg-gray-300 flex justify-between flex-col'
)

interface AdvancedFiltersPromptProps {
  isDialogOpen: boolean
  handleDialogClose: () => void
  filters: AdminUsersFilters
  setFilters: (value: SetStateAction<AdminUsersFilters>) => void
}

export default function AdvancedFiltersPrompt({
  isDialogOpen,
  handleDialogClose,
  filters,
  setFilters
}: AdvancedFiltersPromptProps) {
  const { handleSubmit, control, reset } = useForm<AdminUsersFilters>({
    defaultValues: {
      ...cleanUndefinedFilters(filters),
      credit_card: filters.payment_method === 'cartão',
      bank_slip: filters.payment_method === 'boleto'
    }
  })

  const { plans } = usePlans()

  const { careLines } = useCareLines()

  const {
    healthProfessionals,
    isHealthProfessionalsLoading,
    isHealthProfessionalsError
  } = useMedicalProfessionals()

  useEffect(() => {
    reset({
      ...cleanUndefinedFilters(filters),
      credit_card: filters.payment_method === 'cartão',
      bank_slip: filters.payment_method === 'boleto'
    })
  }, [filters, reset])

  const onSubmit: SubmitHandler<AdminUsersFilters> = data => {
    let finalData = { ...data }

    if (finalData.q === '') {
      finalData = { ...finalData, q: undefined }
    }

    if (finalData.dedicated_nurse_id === '') {
      finalData = { ...finalData, dedicated_nurse_id: undefined }
    }

    if (finalData.dedicated_doctor_id === '') {
      finalData = { ...finalData, dedicated_doctor_id: undefined }
    }

    if (finalData.care_plan_id === '') {
      finalData = { ...finalData, care_plan_id: undefined }
    }

    if (finalData.plan_id === '') {
      finalData = { ...finalData, plan_id: undefined }
    }

    if (finalData.buy_reason === '') {
      finalData = { ...finalData, buy_reason: undefined }
    }

    if (finalData.start_date_of_joining_of_the_plan === '') {
      finalData = { ...finalData, start_date_of_joining_of_the_plan: undefined }
    }

    if (finalData.end_date_of_joining_of_the_plan === '') {
      finalData = { ...finalData, end_date_of_joining_of_the_plan: undefined }
    }

    if (finalData.default_on_debt === '') {
      finalData = { ...finalData, default_on_debt: undefined }
    }

    if (finalData.credit_card || finalData.bank_slip) {
      const newData = {
        ...finalData,
        payment_method: finalData.credit_card ? 'cartão' : 'boleto'
      }

      finalData = {
        ...newData
      }
    }

    if (!finalData.credit_card && !finalData.bank_slip) {
      finalData = { ...finalData, payment_method: undefined }
    }

    delete finalData.bank_slip
    delete finalData.credit_card

    setFilters(prev => ({
      ...prev,
      ...removeEmptyProperties(finalData)
    }))
    handleDialogClose()
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="sm"
      bodyClassName={bodyClasses}
      bodyId="dialog-scrollbar"
    >
      {healthProfessionals && plans && careLines ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <XIcon
              className="h-4 text-pink-600 self-end cursor-pointer"
              onClick={handleDialogClose}
            />
            <div className="flex items-center space-x-4">
              <FilterIcon className="h-6 text-pink-600" />{' '}
              <Heading className="text-xl text-gray-900 font-semibold">
                Filtro de resultados avançado
              </Heading>
            </div>
            <div className="mt-6 mb-6 pb-8 border-b-2 border-gray-500">
              <ControlledInput
                control={control}
                name="q"
                placeholder="Pesquisa"
                label="Buscar por nome, CPF ou e-mail"
                startIcon={<SearchIcon className="h-6 text-pink-600" />}
                inputColor="white"
                fullWidth
              />
            </div>

            <div className="space-y-4">
              <FilterSection title="Equipe de saúde">
                {isHealthProfessionalsLoading && (
                  <CircularProgress color="primary" size="24" />
                )}
                {isHealthProfessionalsError && (
                  <Text>Erro ao buscar profissionais de saude</Text>
                )}
                {healthProfessionals && (
                  <div className="flex flex-col gap-3">
                    <ControlledSelect
                      control={control}
                      name="dedicated_nurse_id"
                      label="Enfermeira dedicada"
                      inputColor="white"
                      options={healthProfessionals.nurses.map(
                        ({ id, name }) => ({
                          value: id,
                          label: name
                        })
                      )}
                      defaultOption="Nenhum"
                      fullWidth
                      displayEmpty
                    />
                    <ControlledSelect
                      control={control}
                      name="dedicated_doctor_id"
                      label="Médico dedicado"
                      inputColor="white"
                      options={healthProfessionals.doctors.map(
                        ({ id, name }) => ({
                          value: id,
                          label: name
                        })
                      )}
                      defaultOption="Nenhum"
                      fullWidth
                      displayEmpty
                    />
                    <ControlledSelect
                      control={control}
                      name="care_plan_id"
                      label="Linha de cuidado"
                      inputColor="white"
                      options={careLines.map(({ id, description }) => ({
                        value: id,
                        label: description
                      }))}
                      defaultOption="Nenhum"
                      fullWidth
                      displayEmpty
                    />
                  </div>
                )}
              </FilterSection>

              <FilterSection title="Data">
                <div className="grid grid-cols-2 gap-3">
                  <ControlledInput
                    control={control}
                    name="start_date_of_joining_of_the_plan"
                    placeholder="Data inicial"
                    label="Data de adesão inicial"
                    startIcon={<CalendarIcon className="h-6 text-pink-600" />}
                    inputColor="white"
                    type="date"
                    fullWidth
                  />
                  <ControlledInput
                    control={control}
                    name="end_date_of_joining_of_the_plan"
                    label="Data de adesão final"
                    placeholder="Data final"
                    startIcon={<CalendarIcon className="h-6 text-pink-600" />}
                    inputColor="white"
                    fullWidth
                    type="date"
                    wrapperClassName="self-end"
                  />
                </div>
                <ControlledInput
                  control={control}
                  name="default_on_debt"
                  label="Data de inadimplência"
                  placeholder="Data de inadimplência"
                  startIcon={<CalendarIcon className="h-6 text-pink-600" />}
                  inputColor="white"
                  fullWidth
                  type="date"
                  wrapperClassName="mt-4"
                />
              </FilterSection>

              <FilterSection title="Plano">
                <div className="flex flex-col gap-4">
                  <ControlledSelect
                    control={control}
                    name="plan_id"
                    label="Tipo de assinatura"
                    inputColor="white"
                    options={plans.map(({ id, name }) => ({
                      value: id,
                      label: name
                    }))}
                    defaultOption="Nenhum"
                    fullWidth
                    displayEmpty
                  />
                  <ControlledSelect
                    control={control}
                    name="buy_reason"
                    label="Motivo da assinatura"
                    inputColor="white"
                    options={buyReasonOptions}
                    defaultOption="Nenhum"
                    fullWidth
                    displayEmpty
                  />
                </div>
              </FilterSection>

              <FilterSection title="Forma de pagamento">
                <div className="grid grid-cols-2 gap-3">
                  <ControlledCheckbox
                    control={control}
                    name="credit_card"
                    label="Cartão de crédito"
                    color="primary"
                  />
                  <ControlledCheckbox
                    control={control}
                    name="bank_slip"
                    label="Boleto bancário"
                    color="primary"
                  />
                </div>
              </FilterSection>
            </div>
          </div>
          <Button type="submit" className="w-full">
            Salvar filtro
          </Button>
        </form>
      ) : (
        <KompaSpinner />
      )}
    </Dialog>
  )
}
