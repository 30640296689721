import {
  ClockIcon,
  ExclamationIcon,
  DownloadIcon
} from '@heroicons/react/outline'
import { add, sub } from 'date-fns/esm'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'

import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import Button from 'components/ui/Button'

import { formatDate, parseDateHour, splitAndParseDate } from 'utils/formaters'
import { APP_URLS } from 'urls'
import { applyParams } from 'utils/helpers'

import { IAppointment } from 'types/Appointment'
interface IAppointmentItem {
  data: IAppointment
}

export default function AppointmentItem({
  data: { id, especialidade, medico, data_agenda, service_type, hora_agenda }
}: IAppointmentItem) {
  const { push } = useHistory()

  const dateNow = new Date()

  const appointmentDate = parseDateHour(`${data_agenda} ${hora_agenda}`)

  // Business Rule
  const minJoinHour = sub(appointmentDate, { minutes: 10 })
  const maxJoinHour = add(appointmentDate, { hours: 2 })

  const canJoinAppointment = () =>
    dateNow >= minJoinHour && dateNow <= maxJoinHour

  const openAppointment = () =>
    push(applyParams(APP_URLS.VIRTUAL_CLINIC_ROOM, ':appointmentId', id))

  return (
    <div className="bg-gray-100 assina-shadow-sm rounded p-4 w-full">
      <Heading as="h1" className="text-lg font-semibold text-gray-900">
        {especialidade && especialidade.nome}
      </Heading>

      <Text as="p" className="text-base font-normal text-gray-700">
        {medico && medico.nome}
      </Text>

      <div className="flex justify-between my-2">
        <Text as="p" className="text-base font-medium text-gray-900">
          {formatDate(splitAndParseDate(data_agenda), "eeee, dd 'de' MMMM")}
        </Text>

        <Text
          as="p"
          className="text-base font-normal text-gray-700 circle-before"
        >
          {service_type === 'telemedicine' ? 'Telemedicina' : 'Presencial'}
        </Text>
      </div>

      <hr />
      {canJoinAppointment() && (
        <div className="mb-3">
          <hr className="p-2" />
          <div
            className="border rounded flex flex-row px-2 py-3"
            style={{ borderColor: '#FFBC42', backgroundColor: '#FFF8EC' }}
          >
            <div className="mx-3 flex-shrink-0">
              <ExclamationIcon
                className="h-6"
                fill="#FFBC42"
                stroke="#FFF8EC"
              />
            </div>
            <div className="flex flex-col">
              <h1 className="font-medium text-lg">
                Mais praticidade na sua consulta!
              </h1>
              <span className="text-gray-800">
                Para melhorar sua experiência, suas consultas serão feitas
                somente pelo app da Kompa.
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="bg-gray-300 rounded flex justify-between items-center pl-2 pr-4">
        <div className="my-2 flex space-x-2 text-gray-700">
          <ClockIcon className="h-6" />
          <Text as="p" className="text-base font-semibold">
            {hora_agenda ? hora_agenda.slice(0, -3) : '-'}
          </Text>
        </div>
        <Button
          disabled={!canJoinAppointment()}
          color="transparent"
          className={cn(
            'font-semibold px-2',
            canJoinAppointment() && 'text-pink-600'
          )}
          style={{ background: 'none' }}
          onClick={() => {
            window.open(
              'https://kompasaude.page.link/?link=https://kompa.com.br&apn=br.com.kompa&isi=1578891651&ibi=br.com.kompa',
              '_blank'
            )
          }}
        >
          {canJoinAppointment() ? (
            <>
              <DownloadIcon className="h-5 mr-2" />
              <span>Abrir o app</span>
            </>
          ) : (
            'Indisponível'
          )}
        </Button>
      </div>
    </div>
  )
}
