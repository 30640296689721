import { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { APP_URLS } from '../../urls'
import AboutYou from './Partials/AboutYou'

import Message from './Partials/Message'

import { Container, Wrapper } from './Styles'

const Onboarding = ({
  inputValues,
  setInputValues,
  handleModal,
  changeValue,
  history,
  handleForm,
  loading,
  OcupationsResult,
  SearchOcupations
}) => {
  useEffect(() => {
    const mapsScript = document.createElement('script')

    mapsScript.src =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAOk5yr4NHJ6smX1B1mT6K5KHMxDFdMoHs&libraries=places&language=pt-BR'
    mapsScript.type = 'text/javascript'

    document.body.appendChild(mapsScript)

    return () => {
      document.body.removeChild(mapsScript)
    }
  }, [])

  return (
    <Container>
      <Wrapper>
        <Switch>
          <Route exact path={APP_URLS.ONBOARDING}>
            <Message welcome />
          </Route>
          <Route path={APP_URLS.ONBOARDING_QUESTIONS}>
            <AboutYou
              inputValues={inputValues}
              setInputValues={setInputValues}
              handleModal={handleModal}
              changeValue={changeValue}
              history={history}
              handleForm={handleForm}
              loading={loading}
              OcupationsResult={OcupationsResult}
              SearchOcupations={SearchOcupations}
            />
          </Route>
          <Route exact path={APP_URLS.ONBOARDING_THANKS}>
            <Message thanks />
          </Route>
        </Switch>
      </Wrapper>
    </Container>
  )
}

export default Onboarding
