/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { BACKGROUNDS } from '../../urls'

export const Container = styled.div`
  background-image: url(${BACKGROUNDS.BG_ONBOARDING_MOBILE});
  background-position: top right;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.white1};

  width: 100%;

  @media (min-width: 425px) {
    background-image: url(${BACKGROUNDS.BG_ONBOARDING_DESKTOP});
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 370px;
    background-origin: content-box;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 425px) {
    background-size: 190px;
    background-image: none;
    padding: 0;
  }

  background-image: url(${BACKGROUNDS.BG_GUY_STANDING});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 226px;
  background-origin: content-box;

  @media (min-width: 1440px) {
    background-size: 300px;
  }
`
