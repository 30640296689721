import { useLocation } from 'react-router-dom'
import cn from 'classnames'

import Heading from 'components/ui/Heading'
import Link from 'components/ui/Link'
import { getCookie } from 'utils/cookie'
import { NAVBAR_EXPANDED_COOKIE } from 'utils/constants'

interface NavbarMenuItemProps {
  link: string
  name: string
  icon: JSX.Element
}

export default function NavbarMenuItem({
  link,
  name,
  icon
}: NavbarMenuItemProps) {
  const browserLocation = useLocation()

  const navbarExtended = getCookie(NAVBAR_EXPANDED_COOKIE) === 'true'

  return (
    <Link to={link} rel="noopener noreferrer" key={name} className="w-full">
      <div
        className={cn(
          'flex items-center space-x-4 px-4 py-3 text-gray-100',
          browserLocation.pathname === link && 'bg-pink-700',
          navbarExtended ? 'justify-start' : 'justify-center'
        )}
      >
        {icon}
        <Heading
          as="h4"
          className={cn('font-semibold', navbarExtended ? 'inline' : 'hidden')}
        >
          {name}
        </Heading>
      </div>
    </Link>
  )
}
