/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
import { useContext, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import LocationPageContainer from '../../../containers/Location/Location'
import { ModalContext } from '../../../contexts/ModalContext'

import { Button, FormInput, Icon } from '../../ui'
import { formatComplete } from '../../../commons/utils/utils'

const formatCoords = coord => {
  const stringCoord = String(coord).split('.')
  const limit = stringCoord[1].substr(0, 6)

  return Number(`${stringCoord[0]}.${limit}`)
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white1};

  height: 100%;
  width: 100%;

  max-width: 700px;
  max-height: 560px;

  @media (max-width: 425px) {
    border-radius: 10px 10px 0 0;
  }

  border-radius: 0 0 10px 10px;

  max-width: 100%;

  padding: 0 35px 35px;

  display: flex;
  flex-direction: column;

  button {
    ${({ theme }) => theme.mixins.shadow_simple}
  }
`

const Address = styled.div`
  ${({ theme }) => theme.mixins.flex_height}

  font-size: 16px;
  margin-bottom: 15px;

  width: 600px;

  @media (min-width: 425px) {
    text-align: center;
    width: 100%;
  }
`

const Map = styled.div`
  height: 100%;
  margin: 0 -35px;

  min-height: 190px;

  background-color: ${({ theme }) => theme.colors.gray4};

  display: grid;
`

let autocomplete = null

const SearchAddress = ({
  autocompleteRef,
  handleModal,
  handleUseUserLocation,
  setHasAddress,
  setHasNumber,
  splitData,
  setSplitData,
  setUserQuery,
  setMapLatLng
}) => {
  useEffect(() => {
    if (autocompleteRef) {
      autocompleteRef.current.focus()
      autocomplete = new google.maps.places.Autocomplete(
        autocompleteRef.current
      )
      autocomplete.setFields([
        'address_components',
        'geometry',
        'name',
        'formatted_address'
      ])
      autocomplete.addListener('place_changed', () => {
        const selected = autocomplete.getPlace()

        let formatedData = {
          route: '',
          street_number: '',
          political: '',
          administrative_area_level_2: {},
          administrative_area_level_1: {},
          country: {},
          postal_code: '',
          complement: ''
        }

        if (!selected.address_components) return

        const {
          geometry: {
            location: { lat, lng }
          },
          address_components,
          formatted_address
        } = selected

        setMapLatLng({
          lat: lat(),
          lng: lng()
        })

        address_components.forEach(elm => {
          formatedData = {
            ...formatedData,
            [elm.types[0]]:
              elm.types[0] === 'administrative_area_level_1' ||
              elm.types[0] === 'administrative_area_level_2' ||
              elm.types[0] === 'country'
                ? elm
                : elm.long_name
          }
        })

        setSplitData({
          formatedData,
          complete_address: formatted_address,
          street_number_user: formatedData.street_number,
          latlng: {
            lat: formatCoords(lat()),
            lng: formatCoords(lng())
          }
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col justify-center h-full">
        <h3 className="text-xl font-medium text-gray-700 sm:text-2xl mb-6 sm:text-center">
          Pesquisar por rua, bairro ou local
        </h3>
        <FormInput haveInput>
          <Icon
            name="map"
            size={35}
            className="fill-current text-gray-600 mr-4"
          />
          <input
            name="address"
            type="text"
            autoComplete="off"
            ref={autocompleteRef}
            defaultValue={splitData.complete_address}
          />
        </FormInput>
        <div className="flex justify-center items-center mt-6 ">
          <Icon
            name="map-marker"
            size={30}
            className="fill-current text-pink-600"
          />
          <h4
            className="text-lg font-medium text-pink-600 sm:text-xl cursor-pointer tracking-wider"
            onClick={handleUseUserLocation}
          >
            Usar minha localização
          </h4>
        </div>
      </div>
      <div className="flex items-center mt-6">
        <Icon
          name="back"
          size={35}
          className="fill-current text-pink-600 mr-4 cursor-pointer"
          onClick={handleModal}
        />
        <Button
          className="rounded-lg w-full"
          disabled={!splitData.complete_address}
          onClick={() => {
            if (splitData.complete_address) {
              setHasAddress(true)
              if (splitData.formatedData.street_number) {
                setHasNumber(true)
                setTimeout(() => {
                  setUserQuery(
                    `${splitData.formatedData.route}, ${splitData.formatedData.street_number}, ${splitData.formatedData.postal_code}, ${splitData.formatedData.administrative_area_level_1.long_name}`
                  )
                }, 10)
              }
            }
          }}
        >
          Continuar
        </Button>
      </div>
    </div>
  )
}

const SetNumber = ({
  splitData,
  setSplitData,
  setUserQuery,
  setHasAddress,
  setHasNumber
}) => (
  <div className="flex flex-col justify-between h-full mt-6">
    <div>
      <h3 className="text-xl font-medium text-pink-600 my-6 sm:text-2xl sm:my-8 sm:text-center">
        Qual o número do endereço?
      </h3>
      <div className="flex flex-col">
        <Address>
          <span className="text-lg">{`${splitData.formatedData.route}`}</span>
          <span className="text-lg text-gray-600">
            {splitData.formatedData.postal_code &&
              `${splitData.formatedData.postal_code}, `}
            {splitData.formatedData.administrative_area_level_1.long_name}
          </span>
        </Address>
        <FormInput haveInput>
          <span
            name="map"
            size={35}
            className="fill-current text-gray-600 mr-4 text-xl pointer-events-none"
          >
            Nº
          </span>
          <input
            type="number"
            name="number"
            value={
              splitData.street_number_user
                ? splitData.street_number_user
                : splitData.formatedData.street_number
            }
            onChange={e => {
              const { value } = e.target
              setSplitData({
                ...splitData,
                street_number_user: value,
                formatedData: {
                  ...splitData.formatedData,
                  street_number: value
                }
              })
            }}
          />
        </FormInput>
      </div>
      <h4
        className="text-lg font-medium text-pink-600 flex justify-center mt-6 sm:text-xl cursor-pointer"
        onClick={() => {
          setHasNumber(true)
        }}
      >
        Endereço sem número
      </h4>
    </div>
    <div className="flex items-center mt-6">
      <Icon
        name="back"
        size={35}
        className="fill-current text-pink-600 mr-4 cursor-pointer"
        onClick={() => setHasAddress(false)}
      />
      <Button
        className="rounded-lg w-full"
        disabled={!splitData.formatedData.street_number}
        onClick={() => {
          if (splitData.formatedData.street_number) {
            setHasNumber(true)
            setTimeout(() => {
              setUserQuery(
                `${splitData.formatedData.route}, ${splitData.formatedData.street_number}, ${splitData.formatedData.postal_code}, ${splitData.formatedData.administrative_area_level_1.long_name}`
              )
            }, 10)
          }
        }}
      >
        Continuar
      </Button>
    </div>
  </div>
)

const PickAddressInMap = ({
  tryUserLocation,
  mapLatLng,
  setSplitData,
  userQuery,
  setComplement,
  complement,
  setHasNumber,
  finishAddress,
  splitData
}) => (
  <div className="flex flex-col h-full">
    <Map>
      <LocationPageContainer
        locationbyUser={{}}
        visible
        tryUserLocation={tryUserLocation}
        mapLatLng={mapLatLng}
        setResponseAddress={setSplitData}
        addressByUser={userQuery}
        splitedData={splitData}
      />
    </Map>
    <div className="hidden justify-center items-center sm:flex sm:py-6">
      <Icon
        name="drag_hand"
        size={60}
        className="fill-current text-gray-700 mr-4"
      />
      <h1 className="text-2xl font-medium text-gray-700 my-2">
        Confirme seu endereço no mapa
      </h1>
    </div>
    <div className="flex pb-2 flex-col">
      <Address>
        <span className="text-lg">Complemento</span>
        <span className="text-md text-gray-600">Bloco, apartamento, etc</span>
      </Address>
      <FormInput haveInput>
        <input
          name="number"
          onChange={e => {
            const { value } = e.target
            setComplement(value)
            setSplitData({
              ...splitData,
              complement: value
            })
          }}
          value={complement}
          placeholder="ex: Ap 1004"
        />
      </FormInput>
    </div>
    <div className="flex items-center mt-6">
      <Icon
        name="back"
        size={35}
        className="fill-current text-pink-600 mr-4 cursor-pointer"
        onClick={() => setHasNumber(false)}
      />
      <Button className="rounded-lg w-full" onClick={finishAddress}>
        Salvar endereço
      </Button>
    </div>
  </div>
)

const AddressPrompt = ({ changeValue }) => {
  const { handleModal } = useContext(ModalContext)

  const autocompleteRef = useRef(null)
  const [place, setPlace] = useState()

  const [complement, setComplement] = useState('')

  const [hasAddress, setHasAddress] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)

  const [splitData, setSplitData] = useState({})

  const [tryUserLocation, setTryUserLocation] = useState(false)
  const [mapLatLng, setMapLatLng] = useState()

  const [userQuery, setUserQuery] = useState()

  const finishAddress = () => {
    changeValue('address', {
      complete: formatComplete({
        ...splitData.formatedData,
        ...splitData
      }),
      logradouro: splitData.formatedData.route,
      numero: splitData.formatedData.street_number,
      bairro: splitData.formatedData.political,
      complemento: splitData.formatedData.complement,
      cep: splitData.formatedData.postal_code,
      lat: formatCoords(splitData.latlng.lat),
      lng: formatCoords(splitData.latlng.lng),
      administrative_area_level_1:
        splitData.formatedData.administrative_area_level_1,
      administrative_area_level_2:
        splitData.formatedData.administrative_area_level_2,
      country: splitData.formatedData.country
    })

    handleModal()
  }

  const onLocationSuccess = position => {
    setMapLatLng({
      lat: position.coords.latitude,
      lng: position.coords.longitude
    })
    setTryUserLocation(true)
    setHasNumber(true)
    setHasAddress(true)
  }

  const onLocationError = () => {
    const res = confirm(
      'Para utilizar sua localização por favor nos permita acessar a localização do seu dispositivo!'
    )

    if (res === true)
      navigator.geolocation.getCurrentPosition(onLocationSuccess)
  }

  const handleUseUserLocation = () => {
    navigator.geolocation.getCurrentPosition(onLocationSuccess, onLocationError)
  }

  return (
    <Container>
      {!hasNumber ? (
        <h1 className="mt-8 text-4xl font-medium text-pink-900 max-w-xs sm:max-w-full sm:text-5xl sm:text-center">
          Digite o seu endereço
        </h1>
      ) : (
        <div className="flex justify-center items-center sm:hidden">
          <Icon
            name="drag_hand"
            size={60}
            className="fill-current text-gray-700 mr-4"
          />
          <h1 className="text-2xl font-medium text-gray-700 my-2">
            Confirme seu endereço no mapa
          </h1>
        </div>
      )}

      {hasNumber ? (
        <PickAddressInMap
          tryUserLocation={tryUserLocation}
          mapLatLng={mapLatLng}
          setSplitData={setSplitData}
          userQuery={userQuery}
          setComplement={setComplement}
          complement={complement}
          setHasNumber={setHasNumber}
          finishAddress={finishAddress}
          splitData={splitData}
        />
      ) : hasAddress ? (
        <SetNumber
          splitData={splitData}
          setSplitData={setSplitData}
          setUserQuery={setUserQuery}
          setHasAddress={setHasAddress}
          setHasNumber={setHasNumber}
        />
      ) : (
        <SearchAddress
          autocompleteRef={autocompleteRef}
          handleModal={handleModal}
          place={place}
          handleUseUserLocation={handleUseUserLocation}
          setHasAddress={setHasAddress}
          setHasNumber={setHasNumber}
          setPlace={setPlace}
          setSplitData={setSplitData}
          splitData={splitData}
          setUserQuery={setUserQuery}
          setMapLatLng={setMapLatLng}
        />
      )}
    </Container>
  )
}

export default AddressPrompt
