export default function DragNDrop({
  rootProps,
  inputProps,
  children,
  outerClasses,
  innerClasses
}) {
  return (
    <div className={outerClasses} {...rootProps}>
      <div className={innerClasses}>{children}</div>

      <input {...inputProps} className="hidden invisible" />
    </div>
  )
}
