/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { usePostHogContext } from 'posthog-js/react'

import { GlobalContext } from '../../../contexts/GlobalContext'
import { useModalContext } from '../../../contexts/ModalContext'

import SelectAppointmentType from '../SelectAppointmentType'
import SelectMedicalUnit from '../SelectMedicalUnit'
import SelectProfessional from '../SelectProfessional'
import BookingOverview from '../BookingOverview'
import SelectPayment from '../SelectPayment'
import BookingConfirm from '../BookingConfirm'
import IsUnlimitedPrompt from '../IsUnlimitedPrompt'
import FAB from '../../ui/FAB'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'

import { APP_URLS } from '../../../urls'
import { getParameterByName, whatsappMessage } from '../../../utils'
import { SUPPORT_WHATSAPP } from 'utils/constants'

const BookingView = () => {
  const history = useHistory()
  const location = useLocation()

  const { user, isFree, isUnlimited } = useContext(GlobalContext)
  const { handleModal, notIgnorable } = useModalContext()

  const [bookingOptions, setBookingOptions] = useState(null)

  const [priceToPay, setPriceToPay] = useState(null)

  const posthogCtx = usePostHogContext()

  useEffect(() => {
    const banner = document.getElementById('banner-booking-online')

    if (banner) {
      banner.style.display = 'none'
    }

    if (process.env.APP_ENV === 'production') {
      posthogCtx.client.capture('[AGENDAMENTO] pagina inicial')
    }

    if (isUnlimited()) {
      notIgnorable()
      handleModal(<IsUnlimitedPrompt />)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addOptions = value => setBookingOptions({ ...bookingOptions, ...value })

  return (
    <div className="flex flex-col w-full">
      <div className="w-full bg-pink-600 rounded-b-md">
        <div className="container mx-auto text-center py-8 text-white">
          <Heading className="font-semibold text-2xl">Agendar consulta</Heading>
          <Text className="text-lg lg:text-lg xl:text-xl mt-2">
            Agende sua consulta por telemedicina ou presencialmente
          </Text>

          {getParameterByName('name') && (
            <Heading as="h2" className="text-xl mt-4 font-semibold underline ">
              Agendando para: {getParameterByName('name')}
            </Heading>
          )}
        </div>
      </div>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path={APP_URLS.BOOKING}>
            <SelectAppointmentType
              browserHistory={history}
              bookingOptions={bookingOptions}
              setPriceToPay={setPriceToPay}
              isFree={isFree}
              isUnlimited={isUnlimited}
              addOptions={addOptions}
              user={user}
            />
          </Route>
          <Route path={APP_URLS.BOOKING_CHOOSE_UNIT}>
            <SelectMedicalUnit
              browserHistory={history}
              bookingOptions={bookingOptions}
              addOptions={addOptions}
            />
          </Route>
          <Route path={APP_URLS.BOOKING_CHOOSE_TIME}>
            <SelectProfessional
              browserHistory={history}
              bookingOptions={bookingOptions}
              addOptions={addOptions}
            />
          </Route>
          <Route path={APP_URLS.BOOKING_OVERVIEW}>
            <BookingOverview
              browserHistory={history}
              bookingOptions={bookingOptions}
              addOptions={addOptions}
              user={user}
              priceToPay={priceToPay}
            />
          </Route>
          <Route path={APP_URLS.BOOKING_PAYMENT}>
            <SelectPayment
              browserHistory={history}
              bookingOptions={bookingOptions}
              priceToPay={priceToPay}
              addOptions={addOptions}
              isFree={isFree}
              user={user}
            />
          </Route>
          <Route path={APP_URLS.BOOKING_CONFIRM}>
            <BookingConfirm
              browserHistory={history}
              bookingOptions={bookingOptions}
              priceToPay={priceToPay}
              user={user}
            />
          </Route>
        </Switch>
      </AnimatePresence>
      <FAB
        href={whatsappMessage(
          SUPPORT_WHATSAPP,
          'Olá, preciso de ajuda para agendar minha consulta'
        )}
        id="whatsapp-agendar-ajuda"
      />
    </div>
  )
}

export default BookingView
