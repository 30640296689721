import { BaseHTMLAttributes } from 'react'

import { ReactComponent as IconPhone } from '../../../assets/svg/phone.svg'
import { ReactComponent as Comment } from '../../../assets/svg/comment-alt.svg'
import { ReactComponent as Video } from '../../../assets/svg/video.svg'
import { ReactComponent as Star } from '../../../assets/svg/icon_star.svg'
import { ReactComponent as Check } from '../../../assets/svg/icon_check.svg'
import { ReactComponent as AngleDown } from '../../../assets/svg/icon_angle-down.svg'
import { ReactComponent as AngleUp } from '../../../assets/svg/icon_angle-up.svg'
import { ReactComponent as Upload } from '../../../assets/svg/file-upload.svg'
import { ReactComponent as CheckCircle } from '../../../assets/svg/check-circle.svg'
import { ReactComponent as ClearCircle } from '../../../assets/svg/clear-circle.svg'
import { ReactComponent as PDFFile } from '../../../assets/svg/pdf-file.svg'
import { ReactComponent as CommentQuestion } from '../../../assets/svg/icon_comment-question.svg'
import { ReactComponent as ExclamationTriangle } from '../../../assets/svg/icon_exclamation-triangle.svg'
import { ReactComponent as ArrowDown } from '../../../assets/svg/arrow-down.svg'
import { ReactComponent as TrashCan } from '../../../assets/svg/delete-outline.svg'
import { ReactComponent as Pencil } from '../../../assets/svg/pencil-outline.svg'
import { ReactComponent as ArrowLeft } from '../../../assets/svg/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../../assets/svg/arrow-right.svg'
import { ReactComponent as Plus } from '../../../assets/svg/plus.svg'
import { ReactComponent as PlusCircle } from '../../../assets/svg/plus-circle-outline.svg'
import { ReactComponent as Clock } from '../../../assets/svg/clock-time-four-outline.svg'
import { ReactComponent as Back } from '../../../assets/svg/back.svg'
import { ReactComponent as Dropdown } from '../../../assets/svg/dropdown.svg'
import { ReactComponent as Map } from '../../../assets/svg/map.svg'
import { ReactComponent as MapMarker } from '../../../assets/svg/map-marker-outline.svg'
import { ReactComponent as Save } from '../../../assets/svg/save.svg'
import { ReactComponent as Drag } from '../../../assets/svg/drag-hand.svg'
import { ReactComponent as DirectionalLeft } from '../../../assets/svg/directional-left.svg'
import { ReactComponent as DirectionalRight } from '../../../assets/svg/directional-right.svg'
import { ReactComponent as Eye } from '../../../assets/svg/eye.svg'
import { ReactComponent as MagnifyPlus } from '../../../assets/svg/magnify-plus.svg'
import { ReactComponent as MagnifyMinus } from '../../../assets/svg/magnify-minus.svg'
import { ReactComponent as AlertCircle } from '../../../assets/svg/alert-circle.svg'
import { ReactComponent as QuestionMarkCircle } from '../../../assets/svg/question-mark-circle.svg'
import { ReactComponent as Cog } from '../../../assets/svg/cog.svg'
import { ReactComponent as ShieldDone } from '../../../assets/svg/shield-done.svg'
import { ReactComponent as ShieldError } from '../../../assets/svg/shield-error.svg'
import { ReactComponent as Profile } from '../../../assets/svg/profile.svg'
import { ReactComponent as CreditCard } from '../../../assets/svg/credit-card.svg'
import { ReactComponent as Calendar } from '../../../assets/svg/calendar.svg'
import { ReactComponent as CalendarDate } from '../../../assets/svg/calendar-date.svg'
import { ReactComponent as Lock } from '../../../assets/svg/lock.svg'
import { ReactComponent as Download } from '../../../assets/svg/download.svg'
import { ReactComponent as Whatsapp } from '../../../assets/svg/whatsapp.svg'
import { ReactComponent as WhatsappFilled } from '../../../assets/svg/whatsapp-filled.svg'
import { ReactComponent as FacebookFilled } from '../../../assets/svg/facebook-filled.svg'
import { ReactComponent as Copy } from '../../../assets/svg/copy.svg'
import { ReactComponent as Email } from '../../../assets/svg/email.svg'
import { ReactComponent as Alert } from '../../../assets/svg/alert-outline.svg'
import { ReactComponent as TreeDots } from '../../../assets/svg/three-dots.svg'
import { ReactComponent as MinusRounded } from '../../../assets/svg/minus-rounded.svg'
import { ReactComponent as TrashCanRounded } from '../../../assets/svg/trash-can-rounded.svg'
import { ReactComponent as ProfileOutlined } from '../../../assets/svg/profile-outlined.svg'
import { ReactComponent as Chat } from '../../../assets/svg/chat.svg'

// New Icons
import { ReactComponent as ChatOutline } from '../../../assets/svg/chat-outline.svg'
import { ReactComponent as CalendarOutline } from '../../../assets/svg/calendar-outline.svg'
import { ReactComponent as DoctorFilled } from '../../../assets/svg/doctor-filled.svg'
import { ReactComponent as HomeOutline } from '../../../assets/svg/home-outline.svg'
import { ReactComponent as ProfileOutline } from '../../../assets/svg/profile-outline.svg'
import { ReactComponent as ImagePink } from '../../../assets/svg/ImagePink.svg'
import { ReactComponent as VideoPink } from '../../../assets/svg/VideoPink.svg'
import { ReactComponent as ArrowLeftNew } from '../../../assets/svg/ArrowLeft.svg'
import { ReactComponent as ArrowRightNew } from '../../../assets/svg/ArrowRight.svg'
import { ReactComponent as ZoomInNew } from '../../../assets/svg/zoom-in-new.svg'
import { ReactComponent as ZoomOutNew } from '../../../assets/svg/zoom-out-new.svg'
import { ReactComponent as Paper } from '../../../assets/svg/Paper.svg'
import { ReactComponent as PaperGreen } from '../../../assets/svg/PaperGreen.svg'
import { ReactComponent as PaperPink } from '../../../assets/svg/PaperPink.svg'
import { ReactComponent as PaperRounded } from '../../../assets/svg/PaperRounded.svg'
import { ReactComponent as Folder } from '../../../assets/svg/Folder.svg'
import { ReactComponent as FolderGreen } from '../../../assets/svg/FolderGreen.svg'
import { ReactComponent as FolderPink } from '../../../assets/svg/FolderPink.svg'
import { ReactComponent as Show } from '../../../assets/svg/Show.svg'
import { ReactComponent as MoreVertical } from '../../../assets/svg/more-vertical.svg'
import { ReactComponent as ImageRounded } from '../../../assets/svg/image-rounded.svg'
import { ReactComponent as VideoRounded } from '../../../assets/svg/video-rounded.svg'
import { ReactComponent as Edit } from '../../../assets/svg/Edit.svg'
import { ReactComponent as Delete } from '../../../assets/svg/Delete.svg'
import { ReactComponent as CloseSquare } from '../../../assets/svg/close-square.svg'
import { ReactComponent as TickSquare } from '../../../assets/svg/tick-square.svg'
import { ReactComponent as ZoomIn } from '../../../assets/svg/zoom-in.svg'
import { ReactComponent as ZoomOut } from '../../../assets/svg/zoom-out.svg'
import { ReactComponent as ThreeDotsPink } from '../../../assets/svg/three-dots-pink.svg'
import { ReactComponent as CloseRounded } from '../../../assets/svg/close-rounded.svg'
import { ReactComponent as UploadCloud } from '../../../assets/svg/upload-cloud.svg'
import { ReactComponent as HealthCard } from '../../../assets/svg/health-card.svg'
import { ReactComponent as Circle } from '../../../assets/svg/circle.svg'
import { ReactComponent as userSave } from '../../../assets/svg/user-save.svg'
import { ReactComponent as userError } from '../../../assets/svg/user-error.svg'
import { ReactComponent as Karmen } from '../../../assets/svg/karmen.svg'
import { ReactComponent as Nurse } from '../../../assets/svg/nurse.svg'
import { ReactComponent as DedicatedDoctor } from '../../../assets/svg/dedicated-doctor.svg'

const BaseComponent =
  // @ts-ignore


    Component =>
    // @ts-ignore
    ({ size, className, ...rest }) =>
      (
        <Component
          width={size}
          height={size}
          className={'fill-current ' + className}
          style={{ minWidth: size, minHeight: size }}
          {...rest}
        />
      )

// TODO: remove repeated icons like arrow-down

const ICONS = {
  phone: {
    component: BaseComponent(IconPhone)
  },
  comment: {
    component: BaseComponent(Comment)
  },
  video: {
    component: BaseComponent(Video)
  },
  star: {
    component: BaseComponent(Star)
  },
  check: {
    component: BaseComponent(Check)
  },
  'angle-down': {
    component: BaseComponent(AngleDown)
  },
  'angle-up': {
    component: BaseComponent(AngleUp)
  },
  upload: {
    component: BaseComponent(Upload)
  },
  check_circle: {
    component: BaseComponent(CheckCircle)
  },
  clear_circle: {
    component: BaseComponent(ClearCircle)
  },
  pdf_file: {
    component: BaseComponent(PDFFile)
  },
  'comment-question': {
    component: BaseComponent(CommentQuestion)
  },
  'exclamation-triangle': {
    component: BaseComponent(ExclamationTriangle)
  },
  'arrow-down': {
    component: BaseComponent(ArrowDown)
  },
  'arrow-left': {
    component: BaseComponent(ArrowLeft)
  },
  'arrow-right': {
    component: BaseComponent(ArrowRight)
  },
  'trash-can': {
    component: BaseComponent(TrashCan)
  },
  pencil: {
    component: BaseComponent(Pencil)
  },
  plus: {
    component: BaseComponent(Plus)
  },
  plus_circle: {
    component: BaseComponent(PlusCircle)
  },
  clock: {
    component: BaseComponent(Clock)
  },
  back: {
    component: BaseComponent(Back)
  },
  dropdown: {
    component: BaseComponent(Dropdown)
  },
  map: {
    component: BaseComponent(Map)
  },
  'map-marker': {
    component: BaseComponent(MapMarker)
  },
  save: {
    component: BaseComponent(Save)
  },
  drag_hand: {
    component: BaseComponent(Drag)
  },
  directional_left: {
    component: BaseComponent(DirectionalLeft)
  },
  directional_right: {
    component: BaseComponent(DirectionalRight)
  },
  eye: {
    component: BaseComponent(Eye)
  },
  magnify_plus: {
    component: BaseComponent(MagnifyPlus)
  },
  magnify_minus: {
    component: BaseComponent(MagnifyMinus)
  },
  alert_circle: {
    component: BaseComponent(AlertCircle)
  },
  question_mark_circle: {
    component: BaseComponent(QuestionMarkCircle)
  },
  cog: {
    component: BaseComponent(Cog)
  },
  shield_done: {
    component: BaseComponent(ShieldDone)
  },
  shield_error: {
    component: BaseComponent(ShieldError)
  },
  profile: {
    component: BaseComponent(Profile)
  },
  'credit-card': {
    component: BaseComponent(CreditCard)
  },
  calendar: {
    component: BaseComponent(Calendar)
  },
  'Calendar-date': {
    component: BaseComponent(CalendarDate)
  },
  lock: {
    component: BaseComponent(Lock)
  },
  download: {
    component: BaseComponent(Download)
  },
  whatsapp: {
    component: BaseComponent(Whatsapp)
  },
  'whatsapp-filled': {
    component: BaseComponent(WhatsappFilled)
  },
  'facebook-filled': {
    component: BaseComponent(FacebookFilled)
  },
  copy: {
    component: BaseComponent(Copy)
  },
  email: {
    component: BaseComponent(Email)
  },
  'three-dots': {
    component: BaseComponent(TreeDots)
  },
  'alert-outline': {
    component: BaseComponent(Alert)
  },
  'minus-rounded': {
    component: BaseComponent(MinusRounded)
  },
  'trash-can-rounded': {
    component: BaseComponent(TrashCanRounded)
  },
  'profile-outlined': {
    component: BaseComponent(ProfileOutlined)
  },
  chat: {
    component: BaseComponent(Chat)
  },
  'chat-outline': {
    component: BaseComponent(ChatOutline)
  },
  'calendar-outline': {
    component: BaseComponent(CalendarOutline)
  },
  'doctor-filled': {
    component: BaseComponent(DoctorFilled)
  },
  'home-outline': {
    component: BaseComponent(HomeOutline)
  },
  'profile-outline': {
    component: BaseComponent(ProfileOutline)
  },
  folder: {
    component: BaseComponent(Folder)
  },
  'folder-pink': {
    component: BaseComponent(FolderPink)
  },
  'folder-green': {
    component: BaseComponent(FolderGreen)
  },
  paper: {
    component: BaseComponent(Paper)
  },
  'paper-green': {
    component: BaseComponent(PaperGreen)
  },
  'paper-pink': {
    component: BaseComponent(PaperPink)
  },
  'paper-rounded': {
    component: BaseComponent(PaperRounded)
  },
  show: {
    component: BaseComponent(Show)
  },
  'more-vertical': {
    component: BaseComponent(MoreVertical)
  },
  'image-rounded': {
    component: BaseComponent(ImageRounded)
  },
  'video-rounded': {
    component: BaseComponent(VideoRounded)
  },
  edit: {
    component: BaseComponent(Edit)
  },
  delete: {
    component: BaseComponent(Delete)
  },
  'close-square': {
    component: BaseComponent(CloseSquare)
  },
  'tick-square': {
    component: BaseComponent(TickSquare)
  },
  'zoom-in': {
    component: BaseComponent(ZoomIn)
  },
  'zoom-out': {
    component: BaseComponent(ZoomOut)
  },
  'three-dots-pink': {
    component: BaseComponent(ThreeDotsPink)
  },
  'close-rounded': {
    component: BaseComponent(CloseRounded)
  },
  'image-pink': {
    component: BaseComponent(ImagePink)
  },
  'video-pink': {
    component: BaseComponent(VideoPink)
  },
  'arrow-left-new': {
    component: BaseComponent(ArrowLeftNew)
  },
  'arrow-right-new': {
    component: BaseComponent(ArrowRightNew)
  },
  'zoom-in-new': {
    component: BaseComponent(ZoomInNew)
  },
  'zoom-out-new': {
    component: BaseComponent(ZoomOutNew)
  },
  'upload-cloud': {
    component: BaseComponent(UploadCloud)
  },
  'health-card': {
    component: BaseComponent(HealthCard)
  },
  circle: {
    component: BaseComponent(Circle)
  },
  userSave: {
    component: BaseComponent(userSave)
  },
  userError: {
    component: BaseComponent(userError)
  },
  karmen: {
    component: BaseComponent(Karmen)
  },
  nurse: {
    component: BaseComponent(Nurse)
  },
  'dedicated-doctor': {
    component: BaseComponent(DedicatedDoctor)
  }
}

interface IIcon extends BaseHTMLAttributes<HTMLElement> {
  name: string
  size?: number
  className?: string
}

export default function Icon({ name, size = 24, className, ...rest }: IIcon) {
  // @ts-ignore
  if (!ICONS[name])
    throw new Error(`The icon with name ${name} could not be found!`)

  // @ts-ignore
  return ICONS[name].component({ size, className, ...rest })
}
