// Components
import LoadingBackdrop from '../components/layouts/LoadingBackdrop'

// Hooks
import useMedicalRecord from 'hooks/useMedicalRecord'

// Routes
import Routes from './routes'

// TODO:
// Treatment if user is not authorized (eg. not his appointment)
// Treatment if appointment is invalid (eg. not available)
// Refactor these window contexts

const App = () => {
  return <Routes />
}

const AppWrapper = () => {
  const {
    isLoadingAppointment,
    isErrorAppointment,
    isLoadingPatient,
    isErrorPatient
  } = useMedicalRecord()

  if (isLoadingAppointment && isLoadingPatient) {
    return <LoadingBackdrop isOpen />
  }

  if (isErrorAppointment && isErrorPatient) {
    return <div>Error...</div>
  }

  return <App />
}

export default AppWrapper
