export const Wrapper = {
  initial: {
    opacity: 0,
    x: '+15vw'
  },
  in: {
    opacity: 1,
    x: '0'
  },
  exit: {
    opacity: 0,
    x: '-15vw'
  }
}

export const Item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
}
