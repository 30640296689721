const Divider = ({ vertical }) => {
  return (
    <>
      {vertical ? (
        <div
          className="h-full bg-gray-500 "
          style={{
            width: 1
          }}
        />
      ) : (
        <div
          className="w-full bg-gray-500 "
          style={{
            height: 1
          }}
        />
      )}
    </>
  )
}

export default Divider
