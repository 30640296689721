const Messages = [
  {
    name: 'onboarding',
    title: 'Preencha suas informações',
    message:
      'Termine seu cadastro com informações essenciais para personalizar o seu plano.',
    buttonTitle: 'Preencher'
  },
  {
    name: 'karmen',
    title: 'Faça sua avaliação de Saúde gratuita',
    message:
      'Converse com a Karmen, nossa enfermeira virtual. Ela será sua Kompanheira na sua jornada por mais saúde!',
    buttonTitle: 'Fazer'
  },
  {
    name: 'push',
    title: 'Habilitar notificações',
    message: 'Você receberá notificações para acompanhar melhor a sua agenda.',
    buttonTitle: 'Habilitar'
  }
]

export default Messages
