import styled from 'styled-components'

import { ID_SPECIALTY_NURSE, SUPPORT_WHATSAPP } from 'utils/constants'
import { openWhatsApp } from '../../../../utils'
import { formatDate, splitAndParseDate } from '../../../../utils/Dates'
import { Avatar } from '../../../Doctor'

import { Section } from '../../../ui'
import TimeChip from '../TimeChip'

const WorkHours = styled.div`
  margin-top: 16px;

  @media (min-width: 425px) {
    display: grid;

    grid-template-columns: repeat(7, minmax(0, 7fr));
    gap: 4px;
  }

  @media (max-width: 425px) {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;

    span + span {
      margin-left: 4px;
    }
  }
`

const MoreSchedules = styled.button(({ theme: { colors } }) => ({
  borderRadius: '4px',
  border: `1px solid ${colors.primaryPink4}`,
  color: `${colors.primaryPink4}`,
  padding: '2px 8px'
}))

const DoctorCard = ({
  data: { doctor, data },
  date,
  selectTime,
  specialty
}) => {
  return (
    <Section className="rounded assina-shadow-sm my-4" as="div">
      <div className="flex">
        <Avatar doctor={data[0].medico} size={58} />
        <div className="ml-6 flex flex-col justify-between">
          <h2 className="text-xl font-medium text-pink-600">
            {specialty.id === ID_SPECIALTY_NURSE ? doctor : `Dr (a). ${doctor}`}
          </h2>
          <span className="text-lg text-gray-800">
            {specialty.label} -{' '}
            {specialty.id === ID_SPECIALTY_NURSE ? '' : 'CRM'}{' '}
            {data[0].medico.crm}
          </span>
        </div>
      </div>
      <WorkHours>
        {data.map(item => (
          <TimeChip
            key={item.id}
            time={item.hora_agenda}
            onClick={() => selectTime(item)}
          />
        ))}
      </WorkHours>
      <MoreSchedules
        className="mt-4"
        type="button"
        onClick={() =>
          openWhatsApp(
            SUPPORT_WHATSAPP,
            `Olá, tentei agendar uma consulta online pela plataforma mas não encontrei um horário no dia ${formatDate(
              splitAndParseDate(date),
              "dd 'de' MMMM"
            )}, pode me ajudar?`
          )
        }
      >
        mais horários
      </MoreSchedules>
    </Section>
  )
}

export default DoctorCard
