import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Form, Input } from '../elements'

import { APIContext } from '../../config/contexts/APIContext'
import { WindowContext } from '../../config/contexts/WindowContext'

// Utils
import { checkStorage, formatDate, persistOnStorage } from '../../utils/Utils'

const Condutas = () => {
  const date = new Date()
  const { handleForms } = useContext(APIContext)
  const { persistManagement, setPersistManagement } = useContext(WindowContext)

  const { register, handleSubmit, reset } = useForm({
    defaultValues: persistManagement
  })
  const [save, setSave] = useState(false)
  const [hour, setHour] = useState(0)

  useEffect(() => {
    const check = checkStorage('conduta')
    if (!check) return
    if (check.save) {
      setSave(true)
      setHour(check.hour)
    }
    setPersistManagement(check)
    reset(check)
    // eslint-disable-next-line
  }, [])

  const handleBlur = data => {
    setPersistManagement(data)
    persistOnStorage(data, 'conduta')

    setSave(false)
  }

  const onSubmit = data => {
    setPersistManagement(data)
    const newHour = formatDate(date)
    setHour(newHour)
    persistOnStorage({ ...data, save: true, hour: newHour }, 'conduta')

    handleForms(data, 'conduta')
    setSave(true)
  }

  return (
    <Form
      title="Condutas"
      onSubmit={handleSubmit(onSubmit)}
      save={save}
      hour={hour}
    >
      <Input
        name="observacoes"
        label="Descrição"
        type="textarea"
        placeholder="Digite..."
        rows="4"
        inputRef={register}
        blurDiv={handleSubmit(handleBlur)}
      />
    </Form>
  )
}

export default Condutas
