import Head from 'components/common/Head'
import LoadingBackdrop from 'components/layouts/LoadingBackdrop'
import MedicalRecordLayout from 'components/layouts/MedicalRecord/MedicalRecordLayout'

import useMedicalRecord from 'hooks/useMedicalRecord'

export default function MedicalRecordPage() {
  const { patient, isLoadingPatient } = useMedicalRecord()

  if (isLoadingPatient) {
    return <LoadingBackdrop isOpen />
  }

  return (
    <>
      <Head title={`${patient.nome} - Prontuário`} />
      <MedicalRecordLayout />
    </>
  )
}
