import { Switch } from 'react-router-dom'

// Routes
import DoctorRoutes from './DoctorRoutes'
import PatientRoutes from './PatientRoutes'
import MedicalRecordRoutes from './MedicalRecordRoutes'

//Utils
import { isUserDoctor } from '../utils/Utils'
import { isMedicalRecord } from 'utils/helpers'

export default function Routes() {
  return (
    <Switch>
      {isUserDoctor() ? (
        isMedicalRecord() ? (
          <MedicalRecordRoutes />
        ) : (
          <DoctorRoutes />
        )
      ) : (
        <PatientRoutes />
      )}
    </Switch>
  )
}
