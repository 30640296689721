import cn from 'classnames'

import Icon from 'components/ui/Icon'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import Link from 'components/ui/Link'

import { APP_URLS } from 'urls'
import { applyParams } from 'utils/helpers'
import { formatCPF, formatMobilePhone } from 'utils/formaters'

import { AdminUsersIndex } from 'types/AdminUsers'

const iconClasses = 'h-6'

const doneClasses = 'text-green-600'
const missingClasses = 'text-warning-dark'

interface UsersTableItemProps {
  user: AdminUsersIndex
}

export default function UsersTableItem({
  user: {
    id,
    name,
    cpf,
    mobile_phone,
    answered_karmen,
    made_first_consultation_with_dedicated_nurse,
    made_first_consultation_with_dedicated_doctor,
    is_plan_active
  }
}: UsersTableItemProps) {
  const mobileNumber = mobile_phone ? formatMobilePhone(mobile_phone) : '-'
  const userCpf = formatCPF(cpf)

  return (
    <div>
      <Link
        to={applyParams(APP_URLS.ADMINISTRATIVE.USER_OVERVIEW, ':userId', id)}
      >
        <div className="pt-3 pb-1 border-t-2 border-gray-500 grid grid-cols-6 cursor-pointer items-center gap-3">
          <Text className="text-base font-semibold text-pink-600 col-span-2">
            {name}
          </Text>
          <Text className="text-gray-800">{userCpf}</Text>
          <Text className="text-gray-800">{mobileNumber}</Text>
          <div className="flex items-center space-x-8">
            <Icon
              name="karmen"
              className={cn(
                iconClasses,
                answered_karmen ? doneClasses : missingClasses
              )}
            />
            <Icon
              name="nurse"
              className={cn(
                iconClasses,
                made_first_consultation_with_dedicated_nurse
                  ? doneClasses
                  : missingClasses
              )}
            />
            <Icon
              name="dedicated-doctor"
              className={cn(
                iconClasses,
                made_first_consultation_with_dedicated_doctor
                  ? doneClasses
                  : missingClasses
              )}
            />
          </div>

          <div>
            <Heading
              as="p"
              className={cn(
                'rounded-md text-gray-100 font-semibold text-sm tracking-widest text-center py-1 w-min-content px-4',
                is_plan_active ? 'bg-green-600' : 'bg-warning-dark'
              )}
            >
              {is_plan_active ? 'ativo' : 'inativo'}
            </Heading>
          </div>
        </div>
      </Link>
    </div>
  )
}
