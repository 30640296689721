import { useEffect, useState } from 'react'

import theme from '../../../styles/theme'

import { Button } from 'components/ui'
import { FilterIcon, XIcon } from '@heroicons/react/solid'

import { getPhysicalExam, postPhysicalExam } from '../../../services/api'
import useMedicalRecord from 'hooks/useMedicalRecord'
import Dialog from 'components/ui/Dialog'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import ImcChart from './karmen2/ImcChart'

const Biometrics = () => {
  const { patient } = useMedicalRecord()

  const [errorMessage, setErrorMessage] = useState('')
  const [isFilterDialogOpen, setFilterDialogOpen] = useState(false)
  const [isErrorDialogOpen, setErrorDialogOpen] = useState(false)
  const [imc, setImc] = useState(0)
  const [selectedCheckBox, setSelectedCheckBox] = useState()
  const [showData, setShowData] = useState('peso')
  const [weightData, setWeightData] = useState([])
  const [heightData, setHeightData] = useState([])
  const [abdominalCircData, setAbdominalCircData] = useState([])
  const [capillaryBloodGlucoseData, setCapillaryBloodGlucoseData] = useState([])
  const [bloodGlucoseSystolicData, setBloodGlucoseSystolicData] = useState([])
  const [bloodGlucoseDiastolicData, setBloodGlucoseDiastolicData] = useState([])
  const [heartRateData, setHeartRateData] = useState([])
  const [respiratoryRateData, setRespiratoryRateData] = useState([])

  const [weight, setWeight] = useState('')
  const [height, setHeight] = useState('')
  const [abdominalCirc, setAbdominalCirc] = useState('')
  const [heartRate, setHeartRate] = useState('')
  const [respiratoryRate, setRespiratoryRate] = useState('')
  const [capillaryBloodGlucose, setCapillaryBloodGlucose] = useState('')
  const [bloodGlucoseSystolic, setBloodGlucoseSystolic] = useState('')
  const [bloodGlucoseDiastolic, setBloodGlucoseDiastolic] = useState('')

  const handlePostPhysicalExam = ({ data, param, cleaner }) => {
    postPhysicalExam({
      data: data,
      patientId: patient.id
    })
      .then(() => {
        handleGetPhysicalExam({ param: param })
        cleaner()
      })
      .catch(() => {
        setErrorMessage(POST_ERROR_MESSAGE)
        setErrorDialogOpen(true)
      })
  }

  //TODO: Find a better solution
  const handleGetPhysicalExam = ({ param, show }) => {
    getPhysicalExam({ patientId: patient.id, param: param })
      .then(response => {
        switch (param) {
          case BIOMETRIC_DATA.height.param:
            setHeightData(response.data.data.data.reverse())
            break

          case BIOMETRIC_DATA.abdominalCirc.param:
            setAbdominalCircData(response.data.data.data.reverse())
            break

          case BIOMETRIC_DATA.capillaryBloodGlucose.param:
            setCapillaryBloodGlucoseData(response.data.data.data.reverse())
            break

          case BIOMETRIC_DATA.heartRate.param:
            setHeartRateData(response.data.data.data.reverse())
            break

          case BIOMETRIC_DATA.respiratoryRate.param:
            setRespiratoryRateData(response.data.data.data.reverse())
            break

          case BIOMETRIC_DATA.bloodPressure.param:
            setBloodGlucoseSystolicData(response.data.data.data.reverse())
            getPhysicalExam({
              patientId: patient.id,
              param: 'pressao_diastolica'
            })
              .then(response => {
                setBloodGlucoseDiastolicData(response.data.data.data.reverse())
              })
              .catch(() => {
                setErrorMessage(GET_ERROR_MESSAGE)
                setErrorDialogOpen(true)
              })
            break

          default:
            setWeightData(response.data.data.data.reverse())
        }

        show && setShowData(param)
      })
      .catch(() => {
        setErrorMessage(GET_ERROR_MESSAGE)
        setErrorDialogOpen(true)
      })
  }

  const BIOMETRIC_DATA = {
    weight: {
      param: 'peso',
      label: 'Peso',
      value: weight,
      placeholder: `00 ${MEASURE_TYPES.kg}`,
      onChange: e => setWeight(e.target.value),
      onClick: () =>
        handlePostPhysicalExam({
          data: { peso: weight },
          cleaner: () => setWeight(''),
          param: 'peso'
        })
    },
    height: {
      param: 'altura',
      label: 'Altura',
      value: height,
      placeholder: `00 ${MEASURE_TYPES.cm}`,
      onChange: e => setHeight(e.target.value),
      onClick: () =>
        handlePostPhysicalExam({
          data: { altura: height },
          cleaner: () => setHeight(''),
          param: 'altura'
        })
    },
    abdominalCirc: {
      param: 'circunferencia_abdominal',
      label: 'Circ. Abdominal',
      value: abdominalCirc,
      placeholder: `00 ${MEASURE_TYPES.cm}`,
      onChange: e => setAbdominalCirc(e.target.value),
      onClick: () =>
        handlePostPhysicalExam({
          data: { circunferencia_abdominal: abdominalCirc },
          cleaner: () => setAbdominalCirc(''),
          param: 'circunferencia_abdominal'
        })
    },
    heartRate: {
      param: 'frequencia_cardiaca',
      label: 'F.C',
      value: heartRate,
      placeholder: `00 ${MEASURE_TYPES.bpm}`,
      onChange: e => setHeartRate(e.target.value),
      onClick: () =>
        handlePostPhysicalExam({
          data: { frequencia_cardiaca: heartRate },
          cleaner: () => setHeartRate(''),
          param: 'frequencia_cardiaca'
        })
    },
    respiratoryRate: {
      param: 'frequencia_respiratoria',
      label: 'F.R',
      value: respiratoryRate,
      placeholder: `00 ${MEASURE_TYPES.irpm}`,
      onChange: e => setRespiratoryRate(e.target.value),
      onClick: () =>
        handlePostPhysicalExam({
          data: { frequencia_respiratoria: respiratoryRate },
          cleaner: () => setRespiratoryRate(''),
          param: 'frequencia_respiratoria'
        })
    },
    capillaryBloodGlucose: {
      param: 'glicemia_jejum',
      label: 'Glicemia Capilar',
      value: capillaryBloodGlucose,
      placeholder: `00 ${MEASURE_TYPES.mgdl}`,
      onChange: e => setCapillaryBloodGlucose(e.target.value),
      onClick: () => {
        handlePostPhysicalExam({
          data: { glicemia_jejum: capillaryBloodGlucose },
          cleaner: () => setCapillaryBloodGlucose(''),
          param: 'glicemia_jejum'
        })
      }
    },
    bloodPressure: {
      param: 'pressao_sistolica',
      label: 'P.A',
      value: bloodGlucoseSystolic,
      value2: bloodGlucoseDiastolic,
      placeholder: `000 ${MEASURE_TYPES.mmhg}`,
      onChange: e => setBloodGlucoseSystolic(e.target.value),
      onChange2: e => setBloodGlucoseDiastolic(e.target.value),
      onClick: () =>
        handlePostPhysicalExam({
          data: {
            pressao_sistolica: bloodGlucoseSystolic,
            pressao_diastolica: bloodGlucoseDiastolic
          },
          cleaner: () => {
            setBloodGlucoseSystolic('')
            setBloodGlucoseDiastolic('')
          },
          param: 'pressao_sistolica'
        })
    }
  }

  const handleOnKeyUp = (e, inputValue, inputValue2, onButtonClick) => {
    if (inputValue2) {
      if (inputValue && inputValue2 && e.key === 'Enter') onButtonClick()
    } else {
      if (inputValue && e.key === 'Enter') onButtonClick()
    }
  }

  const handleApplyFilter = () => {
    handleGetPhysicalExam({ param: selectedCheckBox, show: true })

    setFilterDialogOpen(false)
  }

  const lastRecordedWeight =
    weightData.length !== 0 ? weightData[0].value.split('.')[0] : null

  const lastRecordedHeight =
    heightData.length !== 0 ? heightData[0].value : null

  useEffect(() => {
    handleGetPhysicalExam({ param: BIOMETRIC_DATA.weight.param, show: true })
    handleGetPhysicalExam({ param: BIOMETRIC_DATA.height.param, show: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const imcValue = (
      parseFloat(
        (lastRecordedWeight / (lastRecordedHeight * lastRecordedHeight)) * 100
      ).toFixed(4) * 100
    ).toFixed(2)

    if (0 < imcValue && imcValue <= 18.8) {
      setImc({
        value: imcValue,
        chartPercentage: 10,
        color: theme.colors.secondary
      })
    }
    if (18.8 < imcValue && imcValue <= 24.9) {
      setImc({
        value: imcValue,
        chartPercentage: 30,
        color: theme.colors.green3
      })
    }
    if (25.0 < imcValue && imcValue <= 29.9) {
      setImc({
        value: imcValue,
        chartPercentage: 50,
        color: theme.colors.yellow3
      })
    }
    if (30.0 < imcValue && imcValue <= 39.9) {
      setImc({
        value: imcValue,
        chartPercentage: 70,
        color: theme.colors.red2_5
      })
    }
    if (40.0 <= imcValue) {
      setImc({
        value: imcValue,
        chartPercentage: 90,
        color: theme.colors.red3
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heightData, weightData])

  const disableButton = item => {
    if (item.value2 !== undefined) {
      return !item.value || !item.value2
    } else {
      return !item.value
    }
  }

  return (
    <>
      <section className={SECTION_CLASSNAMES}>
        <ErrorDialog
          isErrorDialogOpen={isErrorDialogOpen}
          setErrorDialogOpen={setErrorDialogOpen}
          errorMessage={errorMessage}
        />
        <FilterDialog
          isFilterDialogOpen={isFilterDialogOpen}
          setFilterDialogOpen={setFilterDialogOpen}
          biometricData={Object.values(BIOMETRIC_DATA)}
          selectedCheckBox={selectedCheckBox}
          setSelectedCheckBox={setSelectedCheckBox}
          handleApplyFilter={handleApplyFilter}
        />

        <div className={`${ROW} mb-4`}>
          <Container
            valueName="Peso"
            value={lastRecordedWeight}
            measure={MEASURE_TYPES.kg}
            className="mr-2"
          />
          <Container
            valueName="Altura"
            value={lastRecordedHeight}
            measure={MEASURE_TYPES.cm}
          />
        </div>

        <ImcChart
          imc={imc}
          colors={[
            {
              color: theme.colors.secondary,
              label: 'Abaixo do peso'
            },
            {
              color: theme.colors.green3,
              label: 'Peso normal'
            },
            {
              color: theme.colors.yellow3,
              label: 'Sobrepeso'
            },
            {
              color: theme.colors.red2_5,
              label: 'Obesidade 1'
            },
            {
              color: theme.colors.red3,
              label: 'Obesidade 2'
            }
          ]}
        />
      </section>
      <section className={SECTION_CLASSNAMES}>
        {Object.values(BIOMETRIC_DATA).map((item, index) => (
          <BiometricItem
            key={index}
            item={item}
            biometricData={BIOMETRIC_DATA}
            disableButton={disableButton}
            handleOnKeyUp={handleOnKeyUp}
          />
        ))}
      </section>

      <section className={SECTION_CLASSNAMES}>
        <div className={`${ROW} justify-between`}>
          <span className="font-bold text-xl">Registros</span>
          <Button onClick={() => setFilterDialogOpen(true)} className="p-2">
            <FilterIcon className="h-5 mr-2" /> Filtrar
          </Button>
        </div>

        {/* TODO: Find a better solution */}
        <span className="font-bold">
          Exibindo registros de "
          {showData.includes('_') ? showData.replace('_', ' ') : showData}"
        </span>

        <div
          style={{ maxHeight: '550px' }}
          className={`${COLUMN} overflow-y-auto mt-4	p-2`}
        >
          {showData === BIOMETRIC_DATA.weight.param ? (
            <RenderRecordedData
              name="Peso"
              dataArray={weightData}
              measure={MEASURE_TYPES.kg}
              patientName={patient.nome}
            />
          ) : null}

          {showData === BIOMETRIC_DATA.height.param ? (
            <RenderRecordedData
              name="Altura"
              dataArray={weightData}
              measure={MEASURE_TYPES.cm}
              patientName={patient.nome}
            />
          ) : null}

          {showData === BIOMETRIC_DATA.abdominalCirc.param ? (
            <RenderRecordedData
              name="Circunferência Abdominal"
              dataArray={abdominalCircData}
              measure={MEASURE_TYPES.cm}
              patientName={patient.nome}
            />
          ) : null}

          {showData === BIOMETRIC_DATA.capillaryBloodGlucose.param ? (
            <RenderRecordedData
              name="Circunferência Abdominal"
              dataArray={capillaryBloodGlucoseData}
              measure={MEASURE_TYPES.mgdl}
              patientName={patient.nome}
            />
          ) : null}

          {showData === BIOMETRIC_DATA.respiratoryRate.param ? (
            <RenderRecordedData
              name="Frequência Respiratória"
              dataArray={respiratoryRateData}
              measure={MEASURE_TYPES.irpm}
              patientName={patient.nome}
            />
          ) : null}

          {showData === BIOMETRIC_DATA.heartRate.param ? (
            <RenderRecordedData
              name="Frequência Cardíaca"
              dataArray={heartRateData}
              measure={MEASURE_TYPES.bpm}
              patientName={patient.nome}
            />
          ) : null}

          {/*TODO: Modify RenderRecordedData and render here*/}
          {showData === BIOMETRIC_DATA.bloodPressure.param ? (
            bloodGlucoseSystolicData.length > 0 ? (
              bloodGlucoseSystolicData.map((data, index) => (
                <div key={index} className="bg-gray-400 rounded-lg p-2 mt-4">
                  <div className={`${ROW} justify-between`}>
                    <p className="font-bold text-xl">Pressão Arterial</p>
                    <div className={ROW}>
                      <div className={ROW}>
                        <p className="font-bold text-base mr-4">
                          {data.value + MEASURE_TYPES.mmhg}
                        </p>
                        <p className="mr-4">/</p>
                        <p className="font-bold text-base mr-4">
                          {bloodGlucoseDiastolicData.length ===
                          bloodGlucoseSystolicData.length
                            ? bloodGlucoseDiastolicData[index].value +
                              MEASURE_TYPES.mmhg
                            : null}
                        </p>
                      </div>
                      <p className="font-bold text-base">{data.date}</p>
                    </div>
                  </div>
                  <div className="h-full pt-px bg-gray-600 my-2" />
                  <CreatedBy
                    patientName={patient.nome}
                    createdBy={data.created_by}
                  />
                </div>
              ))
            ) : (
              <NoData />
            )
          ) : null}
        </div>
      </section>
    </>
  )
}

const MEASURE_TYPES = {
  kg: 'Kg',
  cm: 'Cm',
  bpm: 'BPM',
  irpm: 'IRPM',
  mgdl: 'mg/DL',
  mmhg: 'mmHG'
}

const GET_ERROR_MESSAGE =
  'Erro ao exibir registros, entre em contato com o time de suporte.'
const POST_ERROR_MESSAGE =
  'Erro ao cadastrar dados, entre em contato com o time de suporte.'

const SECTION_CLASSNAMES = 'bg-white p-4 rounded-lg mt-6'
const COLUMN = 'flex flex-col'
const ROW = 'flex flex-row'

const NoData = () => <span>Não existem registros para esse dado.</span>

const CreatedBy = ({ patientName, createdBy }) => {
  return patientName === createdBy ? (
    <p className="text-sm">
      Registrado pelo&nbsp;
      <span className="font-bold">paciente</span>
    </p>
  ) : (
    <p className="text-sm">
      Registrado por <span className="font-bold">{createdBy}</span>
    </p>
  )
}

const RenderRecordedData = ({ name, dataArray, patientName, measure }) => {
  return dataArray.length > 0 ? (
    dataArray.map((data, index) => (
      <RecordedData
        measure={measure}
        patientName={patientName}
        name={name}
        data={data}
        key={index}
      />
    ))
  ) : (
    <NoData />
  )
}

const BiometricItem = ({
  item,
  handleOnKeyUp,
  disableButton,
  biometricData
}) => {
  return (
    <div className="h-12 flex justify-between items-center mb-4 rounded-lg">
      <label
        className="flex h-full w-2/12 justify-center items-center cursor-pointer border-b-2	font-bold"
        htmlFor={item.label}
      >
        {item.label}
      </label>

      {item.param === biometricData.bloodPressure.param ? (
        <div className={`${ROW} h-12 w-8/12 mx-8`}>
          <input
            id={item.label}
            value={item.value}
            onChange={item.onChange}
            className="focus:border-red-500 focus:outline-none shadow-sm text-center rounded-lg h-full border-2 border-solid border-gray w-3/6 placeholder-gray-600"
            placeholder={item.placeholder}
            onKeyUp={e =>
              handleOnKeyUp(e, item.value, item.value2, item.onClick)
            }
          />
          <span className="mx-4 text-xl	pt-2">/</span>
          <input
            id={item.label}
            value={item.value2}
            onChange={item.onChange2}
            className="focus:border-red-500 focus:outline-none shadow-sm text-center rounded-lg h-full border-2 border-solid border-gray w-3/6 placeholder-gray-600"
            placeholder={item.placeholder}
            onKeyUp={e =>
              handleOnKeyUp(e, item.value, item.value2, item.onClick)
            }
          />
        </div>
      ) : (
        <input
          id={item.label}
          value={item.value}
          onChange={item.onChange}
          className="focus:border-red-500 focus:outline-none shadow-sm text-center rounded-lg h-full border-2 border-solid border-gray w-8/12 mx-8 placeholder-gray-600"
          placeholder={item.placeholder}
          onKeyUp={e => handleOnKeyUp(e, item.value, null, item.onClick)}
        />
      )}

      <Button
        disabled={disableButton(item)}
        onClick={item.onClick}
        className="w-2/12 h-full"
      >
        Adicionar
      </Button>
    </div>
  )
}

const RecordedData = ({ data, name, measure, patientName }) => {
  return (
    <div className="bg-gray-400 rounded-lg p-2 mt-4">
      <div className={`${ROW} justify-between`}>
        <p className="font-bold text-xl">{name}</p>
        <div className={ROW}>
          <p className="font-bold text-base mr-4">
            {String(data.value).includes('.')
              ? data.value.split('.')[0] + ' ' + measure
              : data.value + ' ' + measure}
          </p>
          <p className="font-bold text-base">{data.date}</p>
        </div>
      </div>
      <div className="h-full pt-px bg-gray-600 my-2" />
      <CreatedBy patientName={patientName} createdBy={data.created_by} />
    </div>
  )
}

const Container = ({ valueName, value, measure, className }) => {
  return (
    <div
      className={`flex bg-white w-1/2 border-outline-karmen border-12 p-8 justify-center items-center ${className}`}
    >
      <div className={`${COLUMN} justify-center items-center`}>
        <span>{valueName}</span>

        <div className={`${ROW} items-center`}>
          <span className="text-pink-600 font-bold text-2xl mr-1">{value}</span>
          <span className="text-pink-600 text-base">{measure}</span>
        </div>
      </div>
    </div>
  )
}

const ErrorDialog = ({
  errorMessage,
  isErrorDialogOpen,
  setErrorDialogOpen
}) => {
  return (
    <Dialog open={isErrorDialogOpen} bodyClassName="p-6 rounded-lg w-auto">
      <div className="flex flex-row justify-between mb-4">
        <span className="text-lg font-bold">ERRO</span>
        <XIcon
          color="#D20E50"
          onClick={() => setErrorDialogOpen(false)}
          className="h-5 cursor-pointer inline-block"
        />
      </div>
      <span className="text-lg">{errorMessage}</span>
    </Dialog>
  )
}

const FilterDialog = ({
  isFilterDialogOpen,
  setFilterDialogOpen,
  biometricData,
  selectedCheckBox,
  setSelectedCheckBox,
  handleApplyFilter
}) => {
  return (
    <Dialog open={isFilterDialogOpen} bodyClassName="p-6 rounded-lg w-auto">
      <div className={`${ROW} justify-between`}>
        <div className={ROW}>
          <FilterIcon className="h-8 mr-2" color="#D20E50" />
          <p className="text-2xl font-bold mb-8">Filtro de registros</p>
        </div>
        <XIcon
          color="#D20E50"
          onClick={() => setFilterDialogOpen(false)}
          className="h-5 cursor-pointer inline-block"
        />
      </div>

      {biometricData.map((item, index) => (
        <>
          <FormControlLabel
            key={index}
            control={<Checkbox color="primary" />}
            label={item.label}
            checked={selectedCheckBox === item.param}
            onClick={() => setSelectedCheckBox(item.param)}
          />
        </>
      ))}

      <Button onClick={handleApplyFilter} className="mt-8 w-full">
        Pesquisar
      </Button>
    </Dialog>
  )
}

export default Biometrics
