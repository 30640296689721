import React, { useContext } from 'react'
import cn from 'classnames'
import { ReactComponent as IconCheck } from '../../../assets/svg/Check.svg'
import { ReactComponent as CheckFalse } from '../../../assets/svg/Check_false.svg'
import { ReactComponent as CheckDisabled } from '../../../assets/svg/Check_disabled.svg'
import { ContextTemporaryPassword } from '../../../containers/TemporaryPassword/TemporaryPassword'
import IconText from '../IconText'

const CheckTextChangePassword = ({
  label,
  valid,
  className,
  wrapperPassword
}) => {
  const { firstPassword } = useContext(ContextTemporaryPassword)

  const password = firstPassword || wrapperPassword

  const colors = {
    isValid: 'text-green-500',
    noValid: 'text-red-600',
    disabled: 'text-gray-600'
  }
  return (
    <IconText
      Icon={password ? (valid ? IconCheck : CheckFalse) : CheckDisabled}
      className={cn(
        className,
        password ? (valid ? colors.isValid : colors.noValid) : colors.disabled
      )}
      width="1.1rem"
      height="1.1rem"
      valid={valid}
      label={label}
    />
  )
}
export default CheckTextChangePassword
