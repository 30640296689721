import { ChangeEvent, useState } from 'react'
import { CircularProgress, Tab, Tabs } from '@material-ui/core'
import { ArrowLeftIcon } from '@heroicons/react/outline'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom'
import { useQuery } from 'react-query'

import Head from 'components/common/Head'
import Text from 'components/ui/Text'
import UserProfileLayout from 'components/layouts/UserPage/UserProfileLayout'
import OverviewLayout from 'components/layouts/UserPage/OverviewLayout'
import HistoryLayout from 'components/layouts/UserPage/HistoryLayout'
import RulesLayout from 'components/layouts/UserPage/RulesLayout'
import Heading from 'components/ui/Heading'

import {
  getHealthProfessionals,
  getUserInfo
} from 'services/admnistrativeService'

import { APP_URLS } from 'urls'
import { applyParams } from 'utils/helpers'

const userParam = ':userId'

export default function UserPage() {
  const [value, setValue] = useState(0)

  const history = useHistory()
  const { pathname } = useLocation()

  const { userId } = useParams<{ userId: string }>()

  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError
  } = useQuery(['user', userId], () => getUserInfo(Number(userId)), {
    refetchOnWindowFocus: false
  })

  const {
    data: healthProfessionals,
    isLoading: isHealthProfessionalLoading,
    isError: isHealthProfessionaError
  } = useQuery('health-professionals', getHealthProfessionals, {
    enabled: !!user,
    refetchOnWindowFocus: false
  })

  const handleChangeTab = (_: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)

    if (newValue === 0) {
      const newPath = applyParams(
        APP_URLS.ADMINISTRATIVE.USER_OVERVIEW,
        userParam,
        userId
      )

      if (newPath === pathname) {
        return
      }

      return history.replace(newPath)
    }
    if (newValue === 1) {
      const newPath = applyParams(
        APP_URLS.ADMINISTRATIVE.USER_HISTORY,
        userParam,
        userId
      )

      if (newPath === pathname) {
        return
      }

      return history.replace(newPath)
    }

    const newPath = applyParams(
      APP_URLS.ADMINISTRATIVE.USER_RULES,
      userParam,
      userId
    )

    if (newPath === pathname) {
      return
    }

    history.replace(newPath)
  }

  if (isUserLoading || isHealthProfessionalLoading) {
    return (
      <div className="flex items-center justify-center mb-4 mt-8 h-full">
        <CircularProgress color="primary" size={35} />
      </div>
    )
  }

  if (isUserError || isHealthProfessionaError) {
    return (
      <Text className="text-center flex items-center justify-center mb-4 mt-8 h-full">
        Não foi possível carregar as informações do usuário.
      </Text>
    )
  }

  return (
    user &&
    healthProfessionals && (
      <>
        <Head title={user.name} />

        <div className="flex flex-col w-full h-full p-3">
          <button
            className="text-gray-800 flex space-x-2 cursor-pointer ml-4 mt-2"
            onClick={() => history.goBack()}
          >
            <ArrowLeftIcon className="h-5" />
            <Text as="span" className="text-sm">
              Voltar à lista
            </Text>
          </button>

          <UserProfileLayout user={user} />

          <div className="bg-gray-100 rounded-t-md w-min-content">
            <Tabs
              value={value}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Geral" />
            </Tabs>
          </div>

          <div className="bg-gray-100 rounded-r-md rounded-bl-md assina-shadow-sm p-4">
            <Switch>
              <Route
                exact
                path={APP_URLS.ADMINISTRATIVE.USER_OVERVIEW}
                component={() => (
                  <OverviewLayout
                    user={user}
                    healthProfessionals={healthProfessionals}
                  />
                )}
              />
              <Route
                exact
                path={APP_URLS.ADMINISTRATIVE.USER_HISTORY}
                component={HistoryLayout}
              />
              <Route
                exact
                path={APP_URLS.ADMINISTRATIVE.USER_RULES}
                component={RulesLayout}
              />
            </Switch>
          </div>

          <div className="py-4 text-center text-gray-600 select-none">
            <Heading>Administrativo Kompa Saúde</Heading>
          </div>
        </div>
      </>
    )
  )
}
