/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from 'react'
import Tippy from '@tippyjs/react'

import Icon from 'components/ui/Icon'
import { isWorkingHour } from '../../../utils'
import { FEATURES, GlobalContext } from '../../../contexts/GlobalContext'
import Text from 'components/ui/Text'
import Heading from 'components/ui/Heading'

const CardServiceChannels = () => {
  const { user, isLoggedIn, isFree, navigateToFeature } =
    useContext(GlobalContext)
  const [isExpanded, setExpanded] = useState(false)
  const isCustomerServiceAvailable = isWorkingHour(new Date())

  return (
    <>
      {isExpanded && (
        <div className="my-4">
          <div
            className="bg-teal-600 rounded-t p-4"
            style={{ minHeight: '10rem' }}
          >
            <Heading className="text-xl text-white font-semibold">
              Equipe de saúde dedicada digital
            </Heading>
            <Text className="text-base text-white font-medium">
              Escolha seu meio de contato preferido para falar com sua equipe de
              saúde dedicada. De segunda à sexta (exceto feriados), das 8h às
              18h.
            </Text>
          </div>
          <div className="border border-t-0 border-gray-500 rounded-b p-4 bg-white">
            {isFree() && (
              <div>
                <div className="flex-grow font-medium">
                  <div className="flex items-center justify-center">
                    <div className="w-12 h-12 flex-shrink-0 rounded-full bg-teal-600 flex items-center justify-center">
                      <Icon name="star" className="fill-current text-white" />
                    </div>
                    <div className="ml-4">
                      <Heading>Exclusivo para Assinantes Premium</Heading>
                      <Text>
                        <a
                          onClick={() => navigateToFeature(FEATURES.SUBSCRIBE)}
                          className="cursor-pointer text-pink-600 tracking-wide"
                        >
                          Assine agora
                        </a>
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!isFree() && (
              <div className="sm:flex">
                <div className="flex-grow font-medium">
                  <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-teal-600 flex items-center justify-center">
                      <Icon name="phone" className="fill-current text-white" />
                    </div>
                    <div className="ml-4">
                      <Text>Telefone</Text>
                      <Text>
                        <a
                          href={
                            isCustomerServiceAvailable
                              ? 'tel:+5501128426411'
                              : 'tel:+5508007710617'
                          }
                          className="text-pink-600 tracking-wide log-acao loga-aest"
                        >
                          {isCustomerServiceAvailable
                            ? '(011) 2842-6411'
                            : '0800 771 0617'}
                        </a>
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="flex-grow font-medium mt-4 sm:mt-0">
                  <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-teal-600 flex items-center justify-center">
                      <Icon
                        name="comment"
                        className="fill-current text-white"
                      />
                    </div>
                    <div className="ml-4">
                      <Text>WhatsApp</Text>
                      {isCustomerServiceAvailable ? (
                        <Text>
                          <a
                            href="https://wa.me/55011992230894?text=Ol%C3%A1%2C%20estou%20precisando%20de%20orienta%C3%A7%C3%B5es%20de%20sa%C3%BAde"
                            className="text-pink-600 tracking-wide log-acao loga-aesmwc"
                          >
                            (011) 99223-0894
                          </a>
                        </Text>
                      ) : (
                        <Unavailable />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex-grow font-medium mt-4 sm:mt-0">
                  <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-teal-600 flex items-center justify-center">
                      <Icon name="video" className="fill-current text-white" />
                    </div>
                    <div className="ml-4">
                      <p>Videochamada</p>
                      {isLoggedIn() && isCustomerServiceAvailable && (
                        <Text>
                          <a
                            href={`https://atendimentos.kompa.com.br/?internalId=${user.id}&name=${user.name}`}
                            className="text-pink-600 tracking-wide cursor-pointer log-acao loga-aesmwc"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Iniciar
                          </a>
                        </Text>
                      )}
                      {isLoggedIn() && !isCustomerServiceAvailable && (
                        <Unavailable />
                      )}
                      {!isLoggedIn() && (
                        <Text>
                          <a
                            href="https://app.assinasaude.com.br/app/cadastro/login?next=/app"
                            className="text-pink-600 tracking-wide log-acao loga-aesmwc"
                          >
                            Acesse sua conta
                          </a>
                        </Text>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {!isExpanded && (
        <div className="my-4">
          <div className="bg-teal-600 rounded p-4 flex justify-between items-center">
            <Heading className="text-xl text-white font-semibold pr-2">
              Precisa de atendimento imediato?
            </Heading>
            <Heading
              className="text-center text-base font-semibold bg-white text-teal-600 px-4 py-2 rounded tracking-wider flex items-center justify-center cursor-pointer log-acao loga-aesvc"
              onClick={() => setExpanded(true)}
            >
              Acessar pronto atendimento digital
            </Heading>
          </div>
        </div>
      )}
    </>
  )
}

const Unavailable = () => (
  <p className="flex">
    <Text className="tracking-wide log-acao loga-aesmwc">Indisponível</Text>
    <Tippy
      interactive
      placement="bottom"
      theme="light"
      content={
        <Text>
          Esse canal só está disponível em dias úteis, das 8h às 18h. Em caso de
          emergência, ligue para a nossa central emergencial:{' '}
          <a href="tel:+5508007710617" className="font-medium text-pink-600">
            0800 771 0617
          </a>
        </Text>
      }
    >
      <a className="ml-1 cursor-pointer">
        <svg
          width="24"
          height="24"
          className="fill-current text-pink-600"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M11.29,15.29a1.58,1.58,0,0,0-.12.15.76.76,0,0,0-.09.18.64.64,0,0,0-.06.18,1.36,1.36,0,0,0,0,.2.84.84,0,0,0,.08.38.9.9,0,0,0,.54.54.94.94,0,0,0,.76,0,.9.9,0,0,0,.54-.54A1,1,0,0,0,13,16a1,1,0,0,0-.29-.71A1,1,0,0,0,11.29,15.29ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM12,7A3,3,0,0,0,9.4,8.5a1,1,0,1,0,1.73,1A1,1,0,0,1,12,9a1,1,0,0,1,0,2,1,1,0,0,0-1,1v1a1,1,0,0,0,2,0v-.18A3,3,0,0,0,12,7Z" />
        </svg>
      </a>
    </Tippy>
  </p>
)

export default CardServiceChannels
