import { Chip } from '@material-ui/core'

export default function FilterChip({
  label,
  onDelete
}: {
  label: string
  onDelete: () => void
}) {
  return <Chip label={label} onDelete={onDelete} />
}
