/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const Container = styled.div`
  height: 7rem;

  padding: 0 35px;

  &:not(:first-child) {
    margin: -20px 0 0 0;

    box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.15);
  }

  &:last-child {
    height: 100px;
  }

  border-radius: 20px 20px 0 0;

  color: ${props => props.theme.colors.gray6};

  background: ${props =>
    props.index % 2 === 0
      ? props.theme.colors.secondary
      : props.theme.colors.primary};

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 92px;
  }
`
