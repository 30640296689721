import React from 'react'

export const Descriptions = ({
  span,
  label,
  spanStyle,
  labelStyle,
  conditionSpan
}) => {
  return (
    <p>
      <span className={labelStyle}>{label}</span>{' '}
      <span
        className={`${spanStyle} ${
          conditionSpan
            ? span && span !== 'não' && span !== '-' && span !== 'unknown'
              ? 'text-green-600'
              : 'text-warning-dark'
            : ''
        }`}
      >
        {span}
      </span>
    </p>
  )
}

export const SectionDescriptions = ({
  descriptions = [],
  className,
  conditionSpan = false
}) => {
  return (
    <div className={className}>
      {descriptions.map(elm => {
        return elm.label ? (
          <Descriptions
            key={elm.label}
            span={elm.span}
            label={elm.label}
            spanStyle={elm.spanStyle}
            labelStyle={elm.labelStyle}
            conditionSpan={conditionSpan}
          />
        ) : null
      })}
    </div>
  )
}
