/* eslint-disable no-underscore-dangle */
import { createContext, useState, useEffect, useContext } from 'react'

import { WindowContext } from './WindowContext'
import ModelConfirm from '../../components/layouts/ModalConfirm'
import { getAllLocalStorageProntuarios } from '../../utils/Utils'
import { useModalContext } from 'contexts/ModalContext'

import { ACTIVE_PATIENT_COOKIE } from 'utils/constants'
import { getCookie } from 'utils/cookie'

export const PatientContext = createContext()

const PatientProvider = props => {
  const { children } = props
  const { patient, resetAllInfo } = useContext(WindowContext)
  const { handleModal, setCloseButton } = useModalContext()

  const [changing, setChanging] = useState(false)

  useEffect(() => {
    const active = getCookie(ACTIVE_PATIENT_COOKIE)

    setChanging(true)
    if (patient && active) {
      if (
        Number(patient.id) !== Number(active) &&
        getAllLocalStorageProntuarios().length > 0
      ) {
        setCloseButton(false)
        handleModal(<ModelConfirm resetAllInfo={resetAllInfo} />)
      }
    }

    setTimeout(() => {
      setChanging(false)
    }, 1)
    // eslint-disable-next-line
  }, [patient])

  const providerValue = {}

  return (
    <PatientContext.Provider value={providerValue}>
      {!changing && children}
    </PatientContext.Provider>
  )
}

export default PatientProvider
