import { ReactComponent as PersonArrowDown } from 'assets/svg/personArrowDown.svg'

const ImcChart = ({ colors, imc }) => {
  return (
    <div className="w-full flex ">
      {colors
        ? colors.map(({ color, label }) => {
            return (
              <div className="w-1/5 flex justify-end flex-col">
                {color === imc.color ? (
                  <div
                    style={{
                      color: imc.color
                    }}
                    className="flex flex-col items-center"
                  >
                    {imc ? (
                      <PersonArrowDown
                        width="36px"
                        height="36px"
                        className="fill-current stroke-current "
                      />
                    ) : null}
                    <p className="font-medium">{parseInt(imc.value)}</p>
                  </div>
                ) : null}

                <div
                  style={{
                    backgroundColor: color
                  }}
                  className="flex justify-end h-3"
                ></div>
                <p className="p-4 pb-1 w-full text-center">{label}</p>
              </div>
            )
          })
        : null}
    </div>
  )
}

export default ImcChart
