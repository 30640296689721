import { IFRAME_URLS } from '../utils/urls'

export default function Versatilis() {
  return (
    <iframe
      src={IFRAME_URLS.VERSATILIS}
      title="Prontuário do Versatilis"
      className="versatilis-medical-record-iframe"
    ></iframe>
  )
}
