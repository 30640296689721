const Chip = ({ label, children, id }) => {
  return (
    <div
      className="bg-teal-400 py-1 px-2 rounded-md text-gray-900 text-base inline-flex"
      id={id}
    >
      <span>{label}</span>
      {children}
    </div>
  )
}

export default Chip
