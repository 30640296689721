import { useQuery } from 'react-query'

import Head from 'components/common/Head'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import Spinner from 'components/ui/Spinner'
import TableLayout from 'components/layouts/PromptMedicalCare/TableLayout'

import { fetchApi } from 'services/api'

import { IAppointment } from 'types/Appointment'
import { format } from 'date-fns'

const TYPE_APPOINTMENT_ONBOARDING = 'prontoatendimento'

export default function PromptMedicalCare() {
  const getConsultations = (): Promise<IAppointment[]> => {
    try {
      const url = `/api/v1/consultation/adm?type=${TYPE_APPOINTMENT_ONBOARDING}&agenda_date=${format(
        new Date(),
        'yyyy-MM-dd'
      )}`
      return fetchApi(url).then(json => json.data)
    } catch (error) {
      throw error
    }
  }

  const {
    data: appointments,
    isError,
    isLoading
  } = useQuery('promptCareAppointments', () => getConsultations(), {
    refetchInterval: 5000
  })

  return (
    <>
      <Head title="Pronto atendimento" />
      <div className="flex flex-col w-full bg-gray-100">
        <div className=" py-8 pl-4">
          <Heading className="text-teal-700 font-semibold  text-xl">
            Fila de Pronto atendimento
          </Heading>
        </div>

        {isLoading && (
          <div className="flex items-center justify-center mb-4 mt-8 h-full">
            <Spinner color="pink" size={35} />
          </div>
        )}

        {!isLoading && isError && (
          <Text className="text-center flex items-center justify-center mb-4 mt-8 h-full">
            Não foi possível carregar suas consultas.
          </Text>
        )}

        {!isLoading && !isError ? (
          appointments && appointments.length !== 0 ? (
            <div className="py-6 px-4">
              <TableLayout appointments={appointments} />
            </div>
          ) : (
            <div className="w-full text-center py-16 text-lg font-semibold">
              <Heading>Sem consultas agendadas!</Heading>
            </div>
          )
        ) : null}
      </div>
    </>
  )
}
