import moment from 'moment'
import 'moment/locale/pt-br'

import { BlockContent, Div, H1, P, Section, TextIndent } from './styles'
import ExamRequest from '../../commons/components/ExamRequest'
import { fullDate } from '../../commons/utils/utils'
import { Spinner, Button } from '../ui'

const ExamPage = ({
  exams,
  loading,
  history,
  handlePrint,
  componentRef,
  user
}) => {
  return (
    <Div>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner color="teal" />
        </div>
      ) : exams ? (
        <div>
          <TextIndent>
            <header>
              <H1>Dr (a): {exams.created_by.nome}</H1>
              <p className="mt-4 text-lg text-gray-700">
                {exams.doctor_crm ? `CRM: ${exams.doctor_crm}` : null}
              </p>
              <p className="text-lg text-gray-700">
                (11) 2774-2035 &nbsp; &nbsp; &nbsp; (11) 99223-0894
              </p>
            </header>
          </TextIndent>
          <Section>
            <BlockContent>
              <P>
                {moment(exams.data_cadastro.split(' ')[0], 'DD-MM-YYYY')
                  .locale('pt-br')
                  .format('LL')}
              </P>
              <P gray>
                Solicito a <span>{exams.usuario.nome}</span>
              </P>
              <P gray>
                Indicação clínica:
                <span>{exams.indicacao_clinica}</span>
              </P>
            </BlockContent>
            <div className="mt-4 flex flex-col gap-4">
              <TextIndent>
                <p>Exames e Procedimentos</p>
              </TextIndent>
              {exams.exames.map(elm => (
                <BlockContent key={elm.id}>
                  <P>{elm.nome}</P>
                  <p className="font-medium text-lg text-gray-700 mt-2">
                    Quantidade: {elm.quantidade}
                  </p>
                </BlockContent>
              ))}
              <div className="flex w-full gap-4 mt-4">
                <Button
                  onClick={() => {
                    history.replace('/')
                  }}
                  className="w-full button"
                  color="white"
                >
                  Voltar
                </Button>
                <Button onClick={handlePrint} className="w-full">
                  Baixar
                </Button>
              </div>
            </div>
          </Section>
          <div className="hidden">
            <ExamRequest
              ref={componentRef}
              doctor={{
                name: exams.created_by.nome,
                phone: exams.doctor_phone,
                mobilePhone: exams.doctor_cell
              }}
              patient={user}
              indication={exams.indicacao_clinica}
              exams={
                exams
                  ? exams.exames.map(elm => ({
                      id: elm.id,
                      value: elm.nome,
                      quantidade: elm.quantidade
                    }))
                  : [
                      {
                        id: 0,
                        value: '',
                        quantidade: 0
                      }
                    ]
              }
              date={fullDate(exams.data_cadastro.split(' ')[0])}
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <h1 className="text-xl font-medium text-center">
            Prescrição de exame não encontrada!
          </h1>
        </div>
      )}
    </Div>
  )
}

export default ExamPage
