import { BaseHTMLAttributes, FC, JSXElementConstructor } from 'react'
import styled from 'styled-components'

interface IText extends BaseHTMLAttributes<HTMLElement> {
  as?: string | JSXElementConstructor<any>
  className?: string
  onClick?: () => any
  htmlFor?: string
}

const Component = styled.p`
  font-family: 'Lato';
`

const Text: FC<IText> = ({ as = 'p', className, children, ...rest }) => {
  return (
    <Component as={as} className={className} {...rest}>
      {children}
    </Component>
  )
}

export default Text
