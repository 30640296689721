import { EyeIcon } from '@heroicons/react/solid'
import { DownloadIcon } from '@heroicons/react/outline'

import PDF from '../../assets/images/pdf.png'

import { useModalContext } from 'contexts/ModalContext'
import { Button } from 'components/ui'
import FilePreviewPrompt from 'components/layouts/FilePreviewPrompt'

const SummaryFilePreview = ({
  file,
  url,
  description,
  uploadType = 'attach',
  examType = 'null',
  otherDescription = 'null'
}) => {
  const { handleModal, setCloseButton } = useModalContext()

  const buttonStyle = 'bg-gray-300 font-semibold text-pink-600 px-4'
  const imageClasses = 'w-full h-full object-cover rounded-md'
  return (
    <div className="preview flex justify-between">
      <div className="flex space-x-4">
        <div className="space-y-1">
          <div>
            <h3>Tipo de Arquivo</h3>
            <p className="break-words">
              {uploadType && uploadType !== 'null'
                ? uploadType === 'exam'
                  ? 'Exame'
                  : 'Anexo'
                : '-'}
            </p>
          </div>
          {examType &&
            examType !== 'Outro' &&
            description &&
            description !== 'null' && (
              <div>
                <h3>Descrição</h3>
                <p className="break-words">{description}</p>
              </div>
            )}
          {examType && examType === 'Outro' && (
            <div>
              <h3>Descrição</h3>
              <p className="break-words">
                {otherDescription && otherDescription !== 'null'
                  ? otherDescription
                  : '-'}
              </p>
            </div>
          )}
        </div>
        {uploadType && uploadType === 'exam' && (
          <>
            <div>
              <h3>Tipo de Exame</h3>
              <p className="break-words">
                {examType && examType !== 'null' ? examType : '-'}
              </p>
            </div>
          </>
        )}
      </div>
      <div className="flex">
        <div className="h-24 w-24">
          {url.includes('pdf') ? (
            <img src={PDF} alt="preview" className={imageClasses} />
          ) : (
            <img src={url} alt="preview" className={imageClasses} />
          )}
        </div>
        <div className="flex flex-col ml-6 mr-2 space-y-4">
          <Button
            className={buttonStyle}
            color="transparent"
            onClick={() => {
              setCloseButton(false)
              handleModal(<FilePreviewPrompt file={file} />)
            }}
          >
            <EyeIcon className="h-5 mr-2" />
            Ver anexo
          </Button>
          <Button
            className={buttonStyle}
            color="transparent"
            onClick={() => {
              const link = document.createElement('a')
              link.download = file.nome
              link.href = file.arquivo
              link.target = '_blank'
              link.style.display = 'none'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }}
          >
            <DownloadIcon className="h-5 mr-2" />
            Baixar
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SummaryFilePreview
