import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { APP_URLS } from 'urls'

import Home from './Home'
import MedicalRecord from './MedicalRecord'

import { isMedicalRecord } from 'utils/helpers'

export default function PEP() {
  return isMedicalRecord() ? (
    <Switch>
      <Route exact path={APP_URLS.VIRTUAL_CLINIC_PATIENT} component={Home} />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS}
        render={() => <MedicalRecord isMedicalRecord />}
      />
    </Switch>
  ) : (
    <Switch>
      <Route exact path={APP_URLS.VIRTUAL_CLINIC_ROOM} component={Home} />
      <Route
        path={APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS}
        component={MedicalRecord}
      />
    </Switch>
  )
}
