import { HTMLAttributes } from 'react'
import cn from 'classnames'

export default function Card({
  className,
  children
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn('assina-shadow-sm bg-gray-100', className)}>
      {children}
    </div>
  )
}
