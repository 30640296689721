import { useEffect, useState } from 'react'

import Section from './Section'
import SubjectDisplay from './SubjectDisplay'

import { SymptomsLabels } from '../../../utils/Utils'
import { formatAnswersByPriority } from '../../../utils/karmenUtils'
import { range } from 'utils'

const formatSymtoms = data => {
  const formated = data.map(([label, value]) => ({
    label: SymptomsLabels[label],
    data: value ? value : '-'
  }))

  return [formated.slice(0, 8), formated.slice(8, formated.length)]
}

const Symptoms = ({ sympt, id }) => {
  const [symptoms, setsymptoms] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [showSymptoms, setShowSymptoms] = useState(null)
  const numberPages = 2

  useEffect(() => {
    setsymptoms(formatAnswersByPriority(sympt))
  }, [sympt])

  useEffect(() => {
    const sym = symptoms.slice(
      currentPage === 1 ? 0 : 17,
      currentPage === 1 ? 16 : symptoms.length
    )

    setShowSymptoms(sym)
  }, [currentPage, symptoms])

  return showSymptoms ? (
    <Section className="mt-4" title="Sintomas" id={id}>
      <div className="border-t-1 border-l-0 border-b-0 border-r-0 border-gray-500 border-2 mt-2 p-2 pt-4 ">
        <SubjectDisplay subjects={formatSymtoms(showSymptoms)} />
      </div>

      <div className="flex justify-center gap-2">
        <div className="flex items-center">
          {range(1, numberPages + 1).map(elm => {
            return (
              <div
                className={`cursor-pointer py-1 px-3 tracking-wider rounded-lg ${
                  currentPage === elm ? 'bg-pink-600 text-white shadow-md ' : ''
                }`}
                onClick={() => {
                  setCurrentPage(elm)
                }}
                key={elm}
              >
                <p className="font-medium">{elm}</p>
              </div>
            )
          })}
        </div>
      </div>
    </Section>
  ) : null
}

export default Symptoms
