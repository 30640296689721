/* eslint-disable react/jsx-pascal-case */
import Loader_ from 'react-loader-spinner'

const Loader = ({ type, color, height, width, margin }) => {
  return (
    <div className={`loader ${margin && 'loader-margin'}`}>
      <Loader_
        type={type}
        color={color}
        height={height}
        width={width}
        timeout={60000}
      />
    </div>
  )
}

export default Loader
