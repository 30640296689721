import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Spinner } from '../ui'
import UploadTypePill from './Partials/UploadTypePill'
import { AnimatedDiv, Section } from '../ui'
import { Parent } from '../Booking/SelectAppointmentType/Animate'
import PreviewFileComponent from './PreviewFileComponent'
import UploadFormTitle from './Partials/UploadFormTitle'
import UploadFormExamType from './Partials/UploadFormExamType'
import { APP_URLS } from 'urls'

// TODO: Move it to some utils.js file
function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

const UploadForm = ({
  action,
  file,
  onUpload = () => {},
  onEdit = () => {},
  onDelete = () => {}
}) => {
  const [examType, setExamType] = useState('')
  const [otherExam, setOtherExam] = useState('')
  const [uploadType, setUploadType] = useState('exam')
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const confirmUpload = () => {
    setLoading(true)
    const theObject = {
      file: file,
      type: uploadType,
      examType: examType,
      otherExam: otherExam
    }
    onUpload(theObject)
  }

  const confirmEdit = () => {
    setLoading(true)
    const theObject = {
      file: file,
      examType: examType,
      otherExam: otherExam
    }
    onEdit(theObject)
  }

  const confirmDelete = () => {
    setLoading(true)
    onDelete(file)
  }

  useEffect(() => {
    if (action === 'edit' || action === 'delete') {
      if (file) setExamType(file.exam_type)
      if (file) setOtherExam(file.other_exam_description)
      if (file) setUploadType(file.upload_type)
    }
    if (!file) {
      history.push(APP_URLS.EXAM_UPLOAD)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, file])

  return (
    <div className="flex flex-col w-full h-auto">
      <AnimatedDiv
        variants={Parent}
        initial="initial"
        animate="in"
        exit="exit"
        className="container py-8 max-w-xl mx-auto"
      >
        <Section
          className="flex flex-col rounded assina-shadow-sm px-6"
          as="div"
        >
          <UploadFormTitle action={action} uploadType={uploadType} />
          <div className="border rounded w-full my-4" />
          {file && (
            <PreviewFileComponent
              file={file}
              fileType={action === '' ? 'file' : 'model'}
              controls={false}
              widthWrapper={600}
              heightWrapper={600}
              scale={0.8}
            />
          )}
          {action === '' && (
            <div className="flex flex-col">
              <div className="my-4 text-center">
                Esse arquivo é um exame ou um anexo?
              </div>
              <div className="grid grid-cols-2 gap-2">
                <UploadTypePill
                  type="Exame"
                  active={uploadType === 'exam'}
                  onClick={() => setUploadType('exam')}
                />
                <UploadTypePill
                  type="Anexo"
                  active={uploadType === 'attach'}
                  onClick={() => setUploadType('attach')}
                />
              </div>
              {uploadType === 'exam' && (
                <UploadFormExamType
                  examType={examType}
                  setExamType={setExamType}
                  otherExam={otherExam}
                  setOtherExam={setOtherExam}
                />
              )}
            </div>
          )}
          {action === 'edit' && file && (
            <div className="flex flex-col">
              <div
                className="mt-4 text-xl font-medium break-words"
                style={{
                  color: '#006464'
                }}
              >
                {file.nome}
              </div>
              <div className="mt-2 text-base font-medium">
                Tamanho: {bytesToSize(file.tamanho)}
              </div>
              <UploadFormExamType
                examType={examType}
                setExamType={setExamType}
                otherExam={otherExam}
                setOtherExam={setOtherExam}
              />
            </div>
          )}
          {action === 'delete' && file && (
            <div className="flex flex-col">
              <div className="mt-4 text-xl font-medium text-red-600">
                Deseja mesmo deletar o{' '}
                {uploadType === 'exam' ? 'exame' : 'anexo'}?
              </div>
              <div className="mt-2 text-base font-normal">
                O arquivo será excluído do seu prontuário e sua equipe de saúde
                não poderá mais acessá-lo.
              </div>
              <div className="border rounded w-full my-2" />
              <div
                className="mt-4 text-xl font-medium break-words"
                style={{
                  color: '#006464'
                }}
              >
                {file.nome}
              </div>
              <div className="mt-2 text-base font-medium">
                Tamanho: {bytesToSize(file.tamanho)}
              </div>
            </div>
          )}
          {action === '' && (
            <div className="flex flex-col">
              {loading ? (
                <div className="flex flex-row w-full justify-center m-4">
                  <Spinner color="pink" size={32} />
                </div>
              ) : (
                <>
                  <Button
                    className="w-full mt-5"
                    onClick={() => confirmUpload()}
                    disabled={
                      (examType === '' && uploadType === 'exam') ||
                      (examType === 'Outro' && otherExam.trim() === '')
                    }
                  >
                    Enviar
                  </Button>
                  <Button
                    color="white"
                    className="w-full mt-5"
                    onClick={() => history.goBack()}
                  >
                    Cancelar
                  </Button>
                </>
              )}
            </div>
          )}
          {action === 'edit' && (
            <div className="flex flex-col">
              <Button
                className="w-full mt-5"
                onClick={() => confirmEdit()}
                disabled={
                  (examType === '' && uploadType === 'exam') ||
                  (examType === 'Outro' && otherExam.trim() === '')
                }
              >
                Enviar
              </Button>
              <Button
                color="white"
                className="w-full mt-5"
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
            </div>
          )}
          {action === 'delete' && (
            <div className="flex flex-col">
              <Button
                color="white"
                className="w-full mt-5"
                onClick={() => confirmDelete()}
              >
                Sim, deletar o {uploadType === 'exam' ? 'exame' : 'anexo'}
              </Button>
              <Button className="w-full mt-5" onClick={() => history.goBack()}>
                Cancelar
              </Button>
            </div>
          )}
        </Section>
      </AnimatedDiv>
    </div>
  )
}

export default UploadForm
