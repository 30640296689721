import { SummarySection, SummaryFilePreview } from '../../elements'

const PartialAttachments = ({ info, isMultiple, indexOnData, maxIndex }) => {
  return (
    <>
      {Object.keys(info).length !== 0 && info.anexos.length !== 0 && (
        <SummarySection
          createdBy={info.created_by.nome}
          date={info.data_cadastro.split(' ')[1]}
          title="Exames e Anexos"
          className="space-y-4"
          isMultiple={isMultiple}
          indexOnData={indexOnData}
          maxIndex={maxIndex}
        >
          {info.anexos.map(item => (
            <SummaryFilePreview
              file={item}
              url={item.arquivo}
              description={item.observacoes}
              key={item.id}
              uploadType={item.upload_type}
              examType={item.exam_type}
              otherDescription={item.other_exam_description}
            />
          ))}
        </SummarySection>
      )}
    </>
  )
}

export default PartialAttachments
