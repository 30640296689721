import { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { ModalContext } from '../../contexts/ModalContext'
import { WindowContext } from '../../contexts/WindowContext'

import Component from '../../components/Onboarding/Onboarding'
import Head from 'components/common/Head'

import { getOcupations, postOnboardingData } from '../../services/api'
import { APP_URLS } from '../../urls'

import useAuth from 'hooks/useAuth'

const Onboarding = () => {
  const { handleModal, setIsBottom, setCloseButton } = useContext(ModalContext)
  const { width } = useContext(WindowContext)
  const { updateUser } = useAuth()

  const [inputValues, setInputValues] = useState({})
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const changeValue = (name, value) =>
    setInputValues({ ...inputValues, [name]: value })

  useEffect(() => {
    if (width <= 425) {
      setCloseButton(false)
      setIsBottom(true)
    } else {
      setCloseButton(true)
      setIsBottom(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  const [OcupationsResult, setOcupationsResults] = useState([])

  const SearchOcupations = async value => {
    const res = await getOcupations(value)
    if (!res) return
    if (res.length !== 0) setOcupationsResults(res)
  }

  const handleForm = () => {
    if (Object.keys(inputValues).length < 6) {
      return
    }

    setLoading(true)

    const data = {
      sexo: inputValues.biologicalSex,
      genero: inputValues.gender,
      estado_civil: inputValues.maritalStatus,
      qtd_filhos: inputValues.hasChildren,
      logradouro: inputValues.address.logradouro,
      numero: inputValues.address.numero,
      bairro: inputValues.address.bairro,
      complemento: inputValues.address.complemento,
      cep: inputValues.address.cep,
      tem_plano_de_saude: inputValues.hasHealthCarePlan,
      lat: inputValues.address.lat,
      lng: inputValues.address.lng,
      administrative_area_level_1:
        inputValues.address.administrative_area_level_1,
      administrative_area_level_2:
        inputValues.address.administrative_area_level_2,
      country: inputValues.address.country
    }

    setTimeout(async () => {
      try {
        await postOnboardingData(data)
        history.push(APP_URLS.ONBOARDING_THANKS)
        setLoading(false)
        setInputValues({})
        updateUser({ gender: inputValues.gender })
      } catch (err) {
        history.push(APP_URLS.REQUEST_ERROR)
        setLoading(false)
        throw new Error(err)
      }
    }, 1000)
  }

  return (
    <>
      <Head title="Integração" />
      <Component
        inputValues={inputValues}
        setInputValues={setInputValues}
        handleModal={handleModal}
        changeValue={changeValue}
        history={history}
        loading={loading}
        handleForm={handleForm}
        OcupationsResult={OcupationsResult}
        SearchOcupations={SearchOcupations}
      />
    </>
  )
}

export default Onboarding
