import posthogClient from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

import { isProduction, POSTHOG_KEY, POSTHOG_URL } from 'utils/constants'

import { IFormatedUser } from 'types/User'
import { getCurrentUser } from 'utils/helpers'

const posthog = {
  init: () => {
    if (isProduction) posthogClient.init(POSTHOG_KEY, { api_host: POSTHOG_URL })
  },
  capture: (eventName: string, properties: any) => {
    if (isProduction) posthogClient.capture(eventName, properties)
  },
  identify: (userId: string, userProps: IFormatedUser) => {
    if (isProduction) posthogClient.identify(userId, userProps)
  },
  reset: () => {
    if (isProduction) posthogClient.reset()
  },
  reloadFeatureFlags: () => {
    if (isProduction) posthogClient.reloadFeatureFlags()
  },
  identifyOrOptOut: () => {
    if (isProduction) {
      const currentUser = getCurrentUser()

      if (currentUser) {
        if (currentUser.isMedico || currentUser.isStaff) {
          return posthogClient.reset()
        }

        posthogClient.identify(String(currentUser.id), currentUser)
        return posthogClient.reloadFeatureFlags()
      }
    }
  }
}

export { posthogClient, PostHogProvider }

export default posthog
