import KompaSpinner from 'components/ui/KompaSpinner'
import Backdrop from '../../../medicalRecord/components/layouts/Backdrop'

export default function LoadingBackdrop({ isOpen = false }) {
  return (
    <Backdrop openBackdrop={isOpen}>
      <KompaSpinner size="50px" />
    </Backdrop>
  )
}
