import { SummarySection, Imc } from '../../elements'

const EXAMS_PHYSICAL = [
  {
    label: 'Hemoglobina',
    unit: 'g/dL',
    name: 'hemoglobina'
  },
  { label: 'Hematócrito', unit: '%', name: 'hematocrito' },
  { label: 'Leucócitos', unit: 'mg/dL', name: 'leucocitos' },
  { label: 'Ureia', unit: '/ mL', name: 'ureia' },
  { label: 'Creatinina', unit: 'mg/dL', name: 'creatinina' }
]

const SimpleBox = ({ title, label }) => {
  return (
    <div className="p-3 text-gray-900 bg-teal-400 rounded-md text-center">
      <h4>{title}</h4>
      <p>{label}</p>
    </div>
  )
}

const PartialBiometrics = ({ info, isMultiple, indexOnData, maxIndex }) => {
  return (
    <>
      {Object.keys(info).length !== 0 ? (
        <>
          <SummarySection
            createdBy={info.created_by.nome}
            date={info.data_cadastro.split(' ')[1]}
            title="Dados Biométricos"
            isMultiple={isMultiple}
            indexOnData={indexOnData}
            maxIndex={maxIndex}
          >
            {info.peso && info.altura && (
              <div className="p-2 bg-teal-600 text-white rounded-md inline-flex mb-2">
                <Imc height={info.altura} weight={info.peso} />
              </div>
            )}
            <div className="space-y-2">
              <div className="flex space-x-2">
                {info.peso && (
                  <SimpleBox
                    title="Peso"
                    label={`${info.peso.replace('.', ',')}kg`}
                  />
                )}
                {info.altura && (
                  <SimpleBox title="Altura" label={`${info.altura}cm`} />
                )}
                {info.circunferencia_abdominal && (
                  <SimpleBox
                    title="Cir. abdominal"
                    label={`${info.circunferencia_abdominal}cm`}
                  />
                )}
                {info.pressao_sistolica && (
                  <SimpleBox
                    title="Sistólica"
                    label={`${info.pressao_sistolica}mmHg`}
                  />
                )}
              </div>
              <div className="flex space-x-2">
                {info.pressao_diastolica && (
                  <SimpleBox
                    title="Diastólica"
                    label={`${info.pressao_diastolica}mmHg`}
                  />
                )}
                {info.colesterol_total && (
                  <SimpleBox
                    title="Colesterol Total"
                    label={`${info.colesterol_total}mg/dL`}
                  />
                )}
                {info.colesterol_hdl && (
                  <SimpleBox
                    title="Colesterol HDL"
                    label={`${info.colesterol_hdl}mg/dL`}
                  />
                )}
                {info.colesterol_ldl && (
                  <SimpleBox
                    title="Colesterol LDL"
                    label={`${info.colesterol_ldl}mg/dL`}
                  />
                )}
                {info.ttog && (
                  <SimpleBox title="TTOG" label={`${info.ttog}mg/dL`} />
                )}
              </div>
              <div className="flex space-x-2">
                {EXAMS_PHYSICAL.map(exam => {
                  return info[exam.name] ? (
                    <SimpleBox
                      title={exam.label}
                      label={`${info[exam.name]} ${exam.unit}`}
                    />
                  ) : null
                })}
              </div>

              <div className="flex space-x-2">
                {info.colesterol_vldl && (
                  <SimpleBox
                    title="Colesterol VLDL"
                    label={`${info.colesterol_vldl}mg/dL`}
                  />
                )}
                {info.glicemia_jejum && (
                  <SimpleBox
                    title="Gli. em Jejum"
                    label={`${info.glicemia_jejum}mg/dL`}
                  />
                )}
                {info.glicemia_sem_jejum && (
                  <SimpleBox
                    title="Gli. sem Jejum"
                    label={`${info.glicemia_sem_jejum}mg/dL`}
                  />
                )}
                {info.hemoglobina_a1c && (
                  <SimpleBox
                    title="Hem. A1C"
                    label={`${info.hemoglobina_a1c}g/dL`}
                  />
                )}
                {info.triglicerideos && (
                  <SimpleBox
                    title="Triglicerideos"
                    label={`${info.triglicerideos}mg/dL`}
                  />
                )}
              </div>
            </div>
          </SummarySection>
        </>
      ) : null}
    </>
  )
}

export default PartialBiometrics
