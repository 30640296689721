import { Ref } from 'react'
import { Control, Controller, RegisterOptions } from 'react-hook-form'
import { Checkbox, CheckboxProps, FormControl } from '@material-ui/core'
import mergeRefs from 'react-merge-refs'

import Text from 'components/ui/Text'
import KompaFormControlLabel from 'components/ui/KompaFormControlLabel'

interface ControlledCheckboxProps extends Omit<CheckboxProps, ''> {
  label?: string
  name: string
  control: Control
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  error?: boolean
  errorMessage?: string
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end'
  inputRef?: Ref<any>
}

export default function ControlledCheckbox({
  label,
  control,
  name,
  rules,
  onChange,
  error,
  errorMessage,
  inputRef,
  labelPlacement,
  color,
  ...props
}: ControlledCheckboxProps) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          onChange: controllerOnChange,
          ref: controllerRef,
          value: controllerChecked,
          ...controllerProps
        }) => (
          <FormControl error={error}>
            <KompaFormControlLabel
              label={label}
              labelPlacement={labelPlacement}
              control={
                <Checkbox
                  data-testid={`${name}-testId`}
                  inputRef={
                    inputRef
                      ? mergeRefs([controllerRef, inputRef])
                      : controllerRef
                  }
                  color={color}
                  checked={controllerChecked}
                  onChange={e => {
                    controllerOnChange(e.target.checked)
                    onChange && onChange(e, e.target.checked)
                  }}
                  {...controllerProps}
                  {...props}
                />
              }
            />
            {error && (
              <Text
                as="span"
                className="text-sm select-none font-normal"
                style={{ color: '#b00020' }}
              >
                {errorMessage}
              </Text>
            )}
          </FormControl>
        )}
      />
    </>
  )
}

/*
  Example Usage

  const {
    control,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: { testCheckbox: '' }
  })

  return (
    <ControlledCheckbox
      name="testCheckbox"
      control={control}
      label="Test Checkbox"
      color="primary"
      rules={{ required: true }}
      error={!!errors.testCheckbox}
      errorMessage={`${
        errors.testCheckbox?.type === 'required' &&
        FORM_FIELD_MESSAGES.REQUIRED_FIELD
      }`}
    />
  )
*/
