import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'

import { AutoCompleteSelectSearch, Layout, Loader } from '../elements'
import { Button } from 'components/ui'

import { ToastInvalidStyles, ToastSuccessStyles } from 'utils/ToastStyles'
import bugsnag from 'lib/bugsnag'
import { finishForm, getHypothesis } from 'medicalRecord/services/api'
import useMedicalRecord from 'hooks/useMedicalRecord'
import Divider from './Attachments/Divider'
import { Chip, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const DateInput = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 48,
      '& fieldset': {
        borderRadius: 8
      }
    }
  }
})(TextField)

const Hypothesis = () => {
  const { appointment, patient } = useMedicalRecord()

  const [cidValue, setCidValue] = useState([])
  const [ciapValue, setCiapValue] = useState([])
  const [loading, setLoading] = useState(false)
  const [dateStartAt, setDateStartAt] = useState('')
  const [dateEndAt, setDateEndAt] = useState('')

  const [hypothesis, setHypothesis] = useState([])
  const [getLoading, setGetLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    try {
      await finishForm(patient ? patient.id : appointment.paciente.id, {
        hipotese_diagnostica: {
          cid: cidValue.map(cid => cid.split(' - ')[0]),
          ciap: ciapValue.map(ciap => ciap.split(' - ')[0])
        }
      })
      toast('Hipótese diagnóstica enviada com sucesso!', ToastSuccessStyles)
      setCidValue([])
      setCiapValue([])
      handleGetHypothesis()
    } catch (error) {
      toast('Erro ao enviar Hipótese diagnóstica ', ToastInvalidStyles)
      bugsnag.notify(`Erro ao enviar Hipótese diagnóstica  error: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const handleGetHypothesis = async (params = {}) => {
    setGetLoading(true)
    try {
      const response = await getHypothesis({
        patientId: patient ? patient.id : appointment.paciente.id,
        params
      })
      setHypothesis(response.data.data)
      setDateStartAt('')
      setDateEndAt('')
    } catch (error) {
      toast('Erro ao carregar as Hipóteses diagnósticas', ToastInvalidStyles)
      bugsnag.notify(
        `Erro ao carregar as Hipóteses diagnósticas, error: ${error}`
      )
    } finally {
      setGetLoading(false)
    }
  }

  const handleFilterHypothesis = () => {
    const params = {
      start_at: dateStartAt,
      end_at: dateEndAt
    }
    handleGetHypothesis(params)
  }

  useEffect(() => {
    handleGetHypothesis()
  }, [])

  return (
    <Layout className="mt-4" title="Hipótese diagnóstica">
      <ToastContainer />
      <div className="p-3 mt-4 flex flex-col gap-6">
        <AutoCompleteSelectSearch
          name="CID"
          url="enums/CID"
          value={cidValue}
          setValue={setCidValue}
        />
        <AutoCompleteSelectSearch
          name="CIAP"
          url="enums/CIAP"
          value={ciapValue}
          setValue={setCiapValue}
        />
        <Button
          disabled={!(cidValue.length > 0 || ciapValue.length > 0)}
          loading={loading}
          onClick={onSubmit}
          className="w-full text-center"
        >
          <p>Adicionar</p>
        </Button>
      </div>

      <div>
        <div className="my-4">
          <Divider />
        </div>
        <div className="flex items-center justify-between">
          <h3 className="text-xl">Registros</h3>
          <div className="flex gap-2">
            <DateInput
              value={dateStartAt}
              onChange={elm => setDateStartAt(elm.target.value)}
              type="date"
              id="birthdate"
              variant="outlined"
              label="Data inicio"
              InputLabelProps={{
                shrink: true
              }}
            />
            <DateInput
              value={dateEndAt}
              onChange={elm => setDateEndAt(elm.target.value)}
              id="date"
              type="date"
              variant="outlined"
              label="Data final"
              InputLabelProps={{
                shrink: true
              }}
            />
            <Button
              onClick={handleFilterHypothesis}
              className="h-12 p-3 rounded-lg w-32"
              disabled={!(dateStartAt && dateEndAt)}
            >
              Filtrar
            </Button>
          </div>
        </div>

        {getLoading ? (
          <Loader
            type="Oval"
            color="#00BFFF"
            height={28}
            width={28}
            timeout={60000}
          />
        ) : hypothesis.length > 0 ? (
          <div className="flex flex-col gap-2 mt-4 overflow-y-auto h-64">
            {hypothesis.map(h => (
              <div className="border-2 border-gray-400 rounded-lg p-3 flex flex-col gap-2">
                <div className="flex justify-between">
                  <div className="flex">
                    <h3>Registrado por:&nbsp;</h3>
                    <p>{h.created_by.nome}</p>
                  </div>
                  <div className="flex">
                    <h3>Data:&nbsp;</h3>
                    <p>{h.data_cadastro.split(' ')[0]}</p>
                  </div>
                </div>

                <div>
                  {h.cid.length > 0 ? (
                    <div className="mt-1">
                      <h3>CID(s)</h3>
                      <div className="flex gap-1 mt-1">
                        {h.cid.map(item => (
                          <Chip label={item} color="secondary" />
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div>
                  {h.ciap.length > 0 ? (
                    <div className="mt-1">
                      <h3>CIAP(s)</h3>
                      <div className="flex gap-1 mt-1">
                        {h.ciap.map(item => (
                          <Chip label={item} color="secondary" />
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <h3>Nenhuma Hipótese diagnóstica cadastrada.</h3>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Hypothesis
