import Heading from 'components/ui/Heading'

export default function TableHeading() {
  const columnTitleStyle = 'text-base font-semibold text-teal-700'

  return (
    <div className="my-4 grid grid-cols-6 text-center">
      <Heading className={columnTitleStyle}>Paciente</Heading>
      <Heading className={columnTitleStyle}>Médico</Heading>
      <Heading className={columnTitleStyle}>Data/hora</Heading>
      <Heading className={columnTitleStyle}>Nota (0 a 10)</Heading>
      <Heading className={columnTitleStyle}>Sentimento</Heading>
      <Heading className={columnTitleStyle}>Mais detalhes...</Heading>
    </div>
  )
}
