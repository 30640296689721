/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'

import useMedicalRecord from 'hooks/useMedicalRecord'

import { NavLink } from '../elements'

import { APIContext } from '../../config/contexts/APIContext'
import { WindowContext } from '../../config/contexts/WindowContext'

import { fetchApi } from 'services/api'
import { SectionIds } from '../../utils/karmenUtils'
import { initMemed, removeMemedScripts } from '../../utils/Utils'
import {
  preserveAppointmentIdParam,
  preservePatientIdParam
} from 'utils/helpers'
import { APP_URLS } from 'urls'
import useResize from 'hooks/useResize'

const navLinkStyle = 'py-4 text-gray-900'

const PEPNav = () => {
  const { getMemedToken } = useContext(APIContext)
  const { setOpenBackdrop } = useContext(WindowContext)
  const { width } = useResize()

  const { patient, appointment } = useMedicalRecord()

  const history = useHistory()

  const runMemed = async () => {
    if (width < 770) {
      setOpenBackdrop(true)

      if (window.MdSinapsePrescricao) {
        removeMemedScripts(patient)
      }
      const token = await getMemedToken()

      const patientInfos = await fetchApi(
        `/adm/user?patient_id=${patient.id || appointment.paciente.id}`
      )

      initMemed(token, patientInfos.data.data, true)

      setTimeout(() => {
        setOpenBackdrop(false)
      }, 3000)
    } else {
      history.push(
        patient
          ? preservePatientIdParam(
              APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_PRESCRIPTIONS
            )
          : preserveAppointmentIdParam(
              APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_PRESCRIPTIONS
            )
      )
    }
  }

  return (
    <>
      <NavLink
        className={cn(
          navLinkStyle,
          'pb-4 border-b-2 border-t-2 border-gray-400'
        )}
        to={
          patient
            ? preservePatientIdParam(APP_URLS.VIRTUAL_CLINIC_PATIENT)
            : preserveAppointmentIdParam(APP_URLS.VIRTUAL_CLINIC_ROOM)
        }
      >
        Histórico
      </NavLink>

      <NavLink
        className={cn(navLinkStyle, 'pb-4 border-b-2 border-gray-400')}
        to={
          patient
            ? preservePatientIdParam(
                APP_URLS.VIRTUAL_CLINIC_PATIENT_SMART_CONSULTATION
              )
            : preserveAppointmentIdParam(
                APP_URLS.VIRTUAL_CLINIC_ROOM_SMART_CONSULTATION
              )
        }
      >
        Consulta inteligente
      </NavLink>

      <NavLink
        className={cn(navLinkStyle, 'pb-4 border-b-2 border-gray-400')}
        to={
          patient
            ? preservePatientIdParam(
                APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS
              )
            : preserveAppointmentIdParam(
                APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS
              )
        }
      >
        Anamnese
      </NavLink>

      <NavLink
        className={cn(navLinkStyle, 'pb-4 border-b-2 border-gray-400')}
        to={
          patient
            ? preservePatientIdParam(
                APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_EXAMS
              )
            : preserveAppointmentIdParam(
                APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_EXAMS
              )
        }
      >
        Exame Clínico
      </NavLink>

      <NavLink
        className={cn(navLinkStyle, 'pb-4 border-b-2 border-gray-400')}
        to={
          patient
            ? preservePatientIdParam(
                APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_BIOMETRICS
              )
            : preserveAppointmentIdParam(
                APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_BIOMETRICS
              )
        }
      >
        Exame físico (O)
      </NavLink>

      <NavLink
        className={cn(navLinkStyle, 'pb-4 border-b-2 border-gray-400')}
        to={
          patient
            ? preservePatientIdParam(
                APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_HYPOTHESIS
              )
            : preserveAppointmentIdParam(
                APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_HYPOTHESIS
              )
        }
      >
        Hipótese Diagnóstica
      </NavLink>

      <NavLink
        className={cn(navLinkStyle, 'pb-4 border-b-2 border-gray-400')}
        to={
          patient
            ? preservePatientIdParam(
                APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_MANAGEMENT
              )
            : preserveAppointmentIdParam(
                APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_MANAGEMENT
              )
        }
      >
        Condutas
      </NavLink>

      <p
        className={cn(
          'open-memed',
          navLinkStyle,
          'pb-4 border-b-2 border-gray-400 cursor-pointer'
        )}
        onClick={runMemed}
      >
        Prescrições
      </p>

      <NavLink
        className={cn(navLinkStyle, 'pb-4 border-b-2 border-gray-400')}
        to={
          patient
            ? preservePatientIdParam(
                APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_DOCUMENTS
              )
            : preserveAppointmentIdParam(
                APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_DOCUMENTS
              )
        }
      >
        Documentos
      </NavLink>

      <NavLink
        className={cn(navLinkStyle, 'pb-4 border-b-2 border-gray-400')}
        to={
          patient
            ? preservePatientIdParam(
                APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_VERSATILIS
              )
            : preserveAppointmentIdParam(
                APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_VERSATILIS
              )
        }
      >
        Evoluções Versatilis
      </NavLink>

      <NavLink
        className={cn('rounded-b-md', navLinkStyle)}
        to={
          patient
            ? preservePatientIdParam(
                APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_NOTIFICATIONS
              )
            : preserveAppointmentIdParam(
                APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_NOTIFICATIONS
              )
        }
      >
        Notificações
      </NavLink>
    </>
  )
}

const KarmenNav = () => {
  const [active, setActive] = useState('')

  const { patient } = useMedicalRecord()

  const navigateToFeature = featureSectionId => {
    setActive(featureSectionId)
    const element = document.querySelector(`#${featureSectionId}`)

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <button
        className={cn('pb-4 py-4 tracking-7 flex')}
        onClick={() => navigateToFeature(SectionIds.anamnese)}
      >
        {active === SectionIds.anamnese && (
          <div className="border-2 rounded-xl border-pink-600 border-solid" />
        )}
        <p className="w-full font-semibold text-base text-pink-600">Anamnese</p>
      </button>

      <button
        className={cn(
          'pb-4 py-4 font-semibold text-base text-pink-600 tracking-7 flex'
        )}
        onClick={() => navigateToFeature(SectionIds.medicalHistory)}
      >
        {active === SectionIds.medicalHistory && (
          <div className="border-2 rounded-xl border-pink-600 border-solid" />
        )}
        <p className="w-full font-semibold text-base text-pink-600">
          Historico médico
        </p>
      </button>

      <NavLink
        className={cn(
          navLinkStyle,
          'pb-4 py-4 font-semibold text-base text-pink-600 tracking-7'
        )}
        to={
          patient
            ? preservePatientIdParam(
                APP_URLS.VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_EXAMS
              )
            : preserveAppointmentIdParam(
                APP_URLS.VIRTUAL_CLINIC_ROOM_PEP_RECORDS_EXAMS
              )
        }
      >
        Exame Clínico
      </NavLink>
    </>
  )
}

const Nav = () => {
  const location = useLocation()

  const isPEP = !location.pathname.includes('karmen')

  return (
    <nav id="nav-content" className="nav-content-close">
      <ul className="rounded-b-md flex flex-col text-center">
        {isPEP ? <PEPNav /> : <KarmenNav />}
      </ul>
    </nav>
  )
}

export default Nav
