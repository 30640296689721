import { useContext, useState, useEffect } from 'react'

// Contexts
import { APIContext } from '../../config/contexts/APIContext'

// Components
import { Form, Loader } from '../elements'

// Utils
import { initMemed, removeMemedScripts } from '../../utils/Utils'
import { fetchApi } from 'services/api'
import { SPECIALTY_ENDOCRINE_ID } from '../../utils/constants'

import useAuth from 'hooks/useAuth'
import useMedicalRecord from 'hooks/useMedicalRecord'

const Prescricoes = () => {
  const { getMemedToken } = useContext(APIContext)
  const { user } = useAuth()
  const { appointment, patient } = useMedicalRecord()

  const [loading, setLoading] = useState(true)
  const [recommendExams, setRecommendExams] = useState(null)

  const getRecommendExams = async () => {
    try {
      const res = await fetchApi(
        `/api/v1/karmen/recommend_exams_endocrine?patient_id=${patient.id}`
      )
      setRecommendExams(res)
    } catch (error) {
      throw new Error(error)
    }
  }

  const runMemed = async () => {
    if (window.MdSinapsePrescricao) {
      removeMemedScripts()
    }

    const token = await getMemedToken()

    initMemed(token, patient ? patient : appointment.paciente, true)

    setTimeout(() => {
      setLoading(false)
    }, 2300)
  }

  const isEndocrine = patient
    ? user.specialties?.some(({ id }) => id === SPECIALTY_ENDOCRINE_ID)
    : appointment.especialidade.id === SPECIALTY_ENDOCRINE_ID

  useEffect(() => {
    runMemed()
    if (isEndocrine) {
      getRecommendExams()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="w-full mt-4">
      <div className="flex items-center w-full">
        {recommendExams ? (
          <div className="bg-white mx-4 w-full p-4 rounded shadow">
            <h2 className="text-xl text-pink-600 font-semibold ">
              Potencial diagnóstico
            </h2>
            <div className="flex gap-4">
              {recommendExams.potential_diseases.map(elm => (
                <div>
                  <p className="text-gray-900 font-medium ">{elm}</p>
                </div>
              ))}
            </div>

            <h2 className="text-xl text-pink-600 font-semibold mt-4">
              Exames sugeridos
            </h2>
            <div className="h-48 flex flex-col flex-wrap">
              {recommendExams.requested_exams.map(elm => (
                <div>
                  <p className="text-gray-900 font-medium ">{elm}</p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="loader w-full">
            <Loader
              type="Oval"
              color="#00BFFF"
              height={50}
              width={50}
              timeout={60000}
            />
          </div>
        )}
      </div>

      <Form className="mb-0" title="Prescrições" hideButtons>
        {loading && <Loader type="Oval" color="#00BFFF" />}
        <div id="memed-container" />
      </Form>
    </div>
  )
}

export default Prescricoes
