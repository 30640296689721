import {
  BookOpenIcon,
  UsersIcon,
  VideoCameraIcon,
  BadgeCheckIcon,
  CalendarIcon
} from '@heroicons/react/solid'

import Head from 'components/common/Head'
import HomeCardSmartConsultation from 'components/HomeCardSmartConsultation/HomeCardSmartConsultation'
import Heading from 'components/ui/Heading'
import Link from 'components/ui/Link'
import Text from 'components/ui/Text'

import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import { getSmartConsultations } from 'services/api'
import { ISmartConsultation } from 'types/Appointment'

import { APP_URLS } from 'urls'
import { AUTH_COOKIE } from 'utils/constants'
import { getCookie } from 'utils/cookie'

export default function AdminHomePage() {
  const { user } = useAuth()

  const menuItemStyle = {
    height: 'min-content'
  }

  const handleGetConsultations = (): Promise<ISmartConsultation[]> => {
    return getSmartConsultations({
      doctor_attendant_id: user.id
    }).then(response => response.data.data)
  }

  const { data: smartConsultations } = useQuery(
    'home-smart-consultation',
    handleGetConsultations,
    {
      refetchInterval: 5000
    }
  )

  const storedToken = getCookie(AUTH_COOKIE)

  return (
    <>
      <Head title="Início" />
      <div
        className="w-full flex flex-wrap gap-3 p-3 max-w-screen-lg mr-auto"
        style={menuItemStyle}
      >
        <Link
          to={APP_URLS.ADMINISTRATIVE.PRESENTIAL_DASHBOARD}
          style={menuItemStyle}
        >
          <div className="bg-gray-100 border-l-4 border-green-600 h-full w-full rounded assina-shadow-sm p-3 space-y-2">
            <div className="flex items-center justify-between text-green-600">
              <Heading className="text-xl font-bold">Credenciamento</Heading>
              <BookOpenIcon className="h-10" />
            </div>
            <Text className="text-gray-800">
              Solicitações de agendamento presencial.
            </Text>
          </div>
        </Link>
        <Link to={APP_URLS.ADMINISTRATIVE.USERS} style={menuItemStyle}>
          <div className="bg-gray-100 border-l-4 border-blue-600 h-full w-full rounded assina-shadow-sm p-3 space-y-2">
            <div className="flex items-center justify-between text-blue-600">
              <Heading className="text-xl font-bold">Usuários</Heading>
              <UsersIcon className="h-10" />
            </div>
            <Text className="text-gray-800">
              Usuários cadastrados, status e informações.
            </Text>
          </div>
        </Link>
        <Link style={menuItemStyle} to={APP_URLS.USER_REVIEWS}>
          <div className="bg-gray-100 border-l-4 border-orange-600 h-full w-full rounded assina-shadow-sm p-3 space-y-2">
            <div className="flex items-center justify-between text-orange-600">
              <Heading className="text-xl font-bold">Avaliações</Heading>
              <BadgeCheckIcon className="h-10" />
            </div>
            <Text className="text-gray-800">
              Veja as avaliações dos usuários.
            </Text>
          </div>
        </Link>
        {user.isMedico && (
          <>
            <Link to={APP_URLS.VIRTUAL_CLINIC} style={menuItemStyle}>
              <div className="bg-gray-100 border-l-4 border-red-600 h-full w-full rounded assina-shadow-sm p-3 space-y-2">
                <div className="flex items-center justify-between text-red-600">
                  <Heading className="text-xl font-bold">Consultório</Heading>
                  <VideoCameraIcon className="h-10" />
                </div>
                <Text className="text-gray-800">
                  Suas consultas e histórico de pacientes.
                </Text>
              </div>
            </Link>

            <HomeCardSmartConsultation />

            <a
              href={`https://miquella-portal.vercel.app/?token=${storedToken}`}
              target="_blank"
              rel="noreferrer"
              style={menuItemStyle}
            >
              <div className="bg-gray-100 border-l-4 border-teal-600 h-full w-full rounded assina-shadow-sm p-3 space-y-2">
                <div className="flex items-center justify-between text-teal-600">
                  <Heading className="text-xl font-bold">
                    Pronto atendimento
                  </Heading>
                  <VideoCameraIcon className="h-10" />
                </div>
                <Text className="text-gray-800">
                  Veja as consultas de embarque pendentes.
                </Text>
              </div>
            </a>
          </>
        )}
      </div>
    </>
  )
}
