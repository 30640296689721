export const SIZES = {
  xs: 320,
  sm: 425,
  md: 768,
  lg: 1024,
  xl: 1280
}

export const JWT_SECRET = process.env.REACT_APP_JWT_SECRET

export const BROKER_URL =
  process.env.REACT_APP_BROKER_URL || 'http://localhost:5000'

export const ID_SPECIALTY_NURSE = 55

export const isProduction = process.env.REACT_APP_ENV === 'production'

export const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL || 'http://localhost:8000'

export const GOOGLE_API_URL = process.env.REACT_APP_GOOGLE_API_URL
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

export const SUPPORT_WHATSAPP = '+55 (11) 99223-0894'

export const AUTH_COOKIE = 'kompa.token'

export const USER_COOKIE = 'kompa.user'

export const MAX_AGE_COOKIE = 60 * 60 * 24 // 24 hours

export const ACTIVE_APPOINTMENT_COOKIE = 'kompa.consulta_atual'

export const ACTIVE_PATIENT_COOKIE = 'kompa.paciente_atual'

export const NAVBAR_EXPANDED_COOKIE = 'kompa.navbar_expanded'

export const APPOINTMENT_STATUS = {
  STARTED: 4,
  NOT_STARTED: 1
}

export const APPOINTMENT_STATUS_ENTITY = {
  DOCTOR: 'D',
  PATIENT: 'P'
}

export const STATUS_POLLING_INTERVAL = 5000 // 5 seconds

export const POSTHOG_KEY = 'phc_a06NYgFanZVYmVcNA8weH3bwaTVVCTfyO0XUAiHC66U'
export const POSTHOG_URL = 'https://app.posthog.com'

export const BUGSNAG_KEY = 'a7570d52ebdf0dffaa72593722e371a6'

export const ONESIGNAL_ID = '192e08ab-9685-49ce-81d7-edef3a68f71d'

export const ACCREDITATION_WAITING_STATUS = {
  AHEAD: 6,
  ATTENTION: 12,
  MISSING: 18,
  MISSED: 24
}

export const VINDI_PUBLIC_URL =
  process.env.REACT_APP_VINDI_PUBLIC_URL || 'https://sandbox-app.vindi.com.br'

export const VINDI_PUBLIC_KEY = process.env.REACT_APP_VINDI_PUBLIC_KEY || ''

export const CEP_PROMISE_404_ERROR = 'Todos os serviços de CEP retornaram erro.'

export const FORM_FIELD_MESSAGES = {
  REQUIRED_FIELD: 'Campo obrigatório',
  INVALID_FIELD: 'Campo parece estar inválido'
}

export const REGEXP = {
  FORMAT_CPF: /^(\d{3})\D*(\d{3})\D*(\d{3})\D*(\d{2})$/g
}
