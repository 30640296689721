/* eslint-disable camelcase */
import cn from 'classnames'

import { Section, Icon, Button } from '../../../ui'

import { statusTypes } from '../BillingUtils'

const Subscription = ({
  details: { plan, product_items, price_with_discount, id },
  browserHistory,
  className,
  seeDetails
}) => {
  return (
    <>
      <Section
        className={cn('max-w-xl rounded-md text-center px-4 mb-4', className)}
      >
        <div className="flex justify-between mx-auto text-lg">
          <span className="text-gray-900">Tipo</span>
          <span className="text-pink-700 font-medium">{plan}</span>
        </div>

        <div className="flex justify-between mx-auto mt-2 text-lg">
          <span className="text-gray-900">Preço</span>
          <span className="text-pink-700 font-medium">
            {price_with_discount.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL'
            })}
          </span>
        </div>
        <div className="flex justify-between mx-auto mt-2 text-lg">
          <span className="text-gray-900">Status</span>
          <span className="text-pink-700 font-medium">
            {statusTypes[product_items[0].status].label}
          </span>
        </div>

        <hr className="bg-gray-100 my-4" />

        <div className="flex" style={{ justifyContent: 'flex-end' }}>
          {seeDetails ? (
            <button
              type="button"
              className="flex items-center p-2 text-pink-600 hover:bg-gray-200"
              onClick={() => {
                browserHistory.push(`/meu-perfil/assinaturas/detalhes/${id}`)
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
              }}
            >
              <span>Ver detalhes</span>
              <Icon
                size={20}
                name="arrow-right"
                className="ml-2 stroke-current"
              />
            </button>
          ) : (
            <Button
              color="white"
              className="text-sm px-2 font-semibold"
              onClick={() =>
                window.open('https://forms.gle/3aoJy5zu3Tc4ZJRE6', '_blank')
              }
            >
              Cancelar plano
            </Button>
          )}
        </div>
      </Section>
    </>
  )
}

export default Subscription
