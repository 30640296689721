import { SummarySection } from '../../elements'

const PartialManagement = ({ info, isMultiple, indexOnData, maxIndex }) => {
  return (
    <>
      {Object.keys(info).length !== 0 ? (
        <>
          <SummarySection
            createdBy={info.created_by.nome}
            date={info.data_cadastro.split(' ')[1]}
            title="Condutas"
            isMultiple={isMultiple}
            indexOnData={indexOnData}
            maxIndex={maxIndex}
          >
            <h3>Observações</h3>
            <p>{info.observacoes}</p>
          </SummarySection>
        </>
      ) : null}
    </>
  )
}

export default PartialManagement
