import { ReactNode } from 'react'

import Button from 'components/ui/Button'
import Heading from 'components/ui/Heading'

const buttonStyle =
  'w-full bg-gray-300 text-pink-600 h-8 text-base font-semibold flex items-center gap-2'

interface ITableItemAction {
  label: string
  title: string
  icon: ReactNode
  action: () => void
}

export default function TableItemAction({
  label,
  title,
  icon,
  action
}: ITableItemAction) {
  return (
    <div className="space-y-2">
      <Heading className="font-semibold">{title}</Heading>
      <Button
        className={buttonStyle}
        color="transparent"
        type="button"
        onClick={action}
      >
        {icon}
        {label}
      </Button>
    </div>
  )
}
