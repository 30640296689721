import styled from 'styled-components'
import React from 'react'

import Logo from '../../assets/backgrounds/logo.png'

const DocTemplate = styled.div`
  padding: 50px 100px;

  font-family: Arial, Helvetica, sans-serif;

  .doc-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 15px 10px;
    border-bottom: 2px solid $gray1;

    .doc-info {
      display: flex;
      flex-direction: column;

      font-weight: 500;
      font-size: 22px;
    }

    img {
      height: 65px;
    }
  }

  .doc-body {
    min-height: 400px;
    padding: 15px;

    h3 {
      padding: 25px 0;
      font-size: 22px;
    }

    p {
      font-size: 22px;
      line-height: 40px;
    }

    .doc-table {
      font-size: 22px;

      > div {
        margin-bottom: 30px;
      }

      table {
        th,
        td {
          border: 1px solid black;
          border-collapse: collapse;
        }

        th,
        td {
          padding: 5px;
          text-align: left;
        }

        .exams {
          width: 100%;
        }
      }
    }
  }

  .doc-footer {
    font-size: 22px;
    text-align: center;
    padding: 8px 10px;
    border-top: 2px solid $gray1;
  }
`

// eslint-disable-next-line react/prefer-stateless-function
class MedicalCertificate extends React.Component {
  render() {
    const { doctor, date, children } = this.props

    return (
      <DocTemplate>
        <div className="doc-header">
          <div className="doc-info">
            <span>{doctor.name}</span>
            <span>Telefone: (11) 2774-2035</span>
            <span>Celular: (11) 99223-1931</span>
          </div>
          <div>
            <img src={Logo} alt="logotype" />
          </div>
        </div>
        <div className="doc-body">
          <h3>{date}</h3>
          <div>{children}</div>
        </div>
        <div className="doc-footer">
          <span>
            Documento gerado pelo sistema de Prontuário Médico Eletrônico da
            AssinaSaúde.
          </span>
        </div>
      </DocTemplate>
    )
  }
}

export default MedicalCertificate
