import Modal from 'react-modal'

// App
import App from './App'

import './assets/scss/global.scss'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

Modal.setAppElement('#modal-root')

/*
  Test comment
*/

export default function PEP() {
  const location = useLocation()
  const divRef = useRef()

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [location])

  return (
    <div ref={divRef} id="pep-main" className="w-full h-full">
      <App />
    </div>
  )
}
