import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { Summary, Loader } from '../components/elements'

import useMedicalRecord from 'hooks/useMedicalRecord'

import { getMedicalRecords } from '../services/medicalRecordService'
import { Pagination } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'

const Home = () => {
  const { appointment, patient } = useMedicalRecord()
  const history = useHistory()
  const [page, setPage] = useState(history.location.search.split('=')[1] ?? 1)
  const { data, isLoading, isError } = useQuery(
    ['medicalRecords', patient ? patient.id : appointment.paciente.id, page],
    () =>
      getMedicalRecords(patient ? patient.id : appointment.paciente.id, page)
  )

  const medicalRecords = data?.records ?? []

  if (isLoading) {
    return (
      <div className="loader">
        <Loader
          type="Oval"
          color="#00BFFF"
          height={50}
          width={50}
          timeout={60000}
          margin
        />
      </div>
    )
  }

  if (isError) {
    return <div className="loader">Aconteceu um erro...</div>
  }

  return (
    <div className="home">
      {medicalRecords.length !== 0 ? (
        <>
          {medicalRecords.map(item => (
            <Summary info={item} key={item.date} />
          ))}
          <Pagination
            page={parseInt(page)}
            count={data.totalPages}
            onChange={(e, value) => {
              history.push(`?page=${value}`)
              setPage(value)
            }}
            color="secondary"
          />
        </>
      ) : (
        <div className="loader">
          <p>Nenhum prontuário adicionado ainda</p>
        </div>
      )}
    </div>
  )
}

export default Home
