export const usernameTypeSelectStyles = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: '#7E0830',
    border: 0,
    borderRadius: '5px 0 0 5px',
    boxShadow: 'none',
    padding: '5px',
    width: 100,
    height: 48
  }),
  container: (styles: any) => ({
    ...styles,
    color: '#171717',
    fontSize: '16px',
    fontWeight: '700',
    fontFamily: 'Titillium Web'
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#FBFBFB',
    fontSize: '16px',
    fontWeight: '700',
    fontFamily: 'Titillium Web'
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fill: '#03030',
    fontSize: '16px',
    fontWeight: '700'
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: '#FBFBFB'
  })
}

export const usernameTypeSelectTheme = (theme: any) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary25: '#F2B7CB',
    primary: '#D20E50'
  }
})
