import styled from 'styled-components'
import Button from '../elements/Button'
import { ReactComponent as Icon } from '../../assets/icons/alert-triangle.svg'
import { useModalContext } from 'contexts/ModalContext'
import { ACTIVE_PATIENT_COOKIE } from 'utils/constants'
import { getCookie } from 'utils/cookie'

const H1 = styled.h1`
  font-weight: 500;
  font-size: 34px;
  line-height: 41px;
  color: #e6a93b;
  margin-top: 1.5rem;
  text-align: center;
`

const P = styled.p`
  font-size: 21px;
  line-height: 25px;
  color: #5e5e5e;
  text-align: center;
  margin-top: 1.8rem;
`

const Span = styled.span`
  color: #d20e50;
`

const Div = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.8rem;
  width: 75%;
`

export default function ModalConfirm({ resetAllInfo }) {
  const { setOpen } = useModalContext()

  const patientId = getCookie(ACTIVE_PATIENT_COOKIE)

  return (
    <div className="flex flex-col items-center p-10 bg-white rounded-lg">
      <Icon width="10rem" heigth="10rem" />
      <H1>Você está entrando em um novo prontuário</H1>
      <P>
        Se clicar em <Span>continuar</Span> você irá apagar todos os dados{' '}
        <br /> salvos de outros prontuários.
      </P>
      <Div>
        <Button
          type="button"
          onClick={() => {
            window.history.back()
            setOpen(false)
          }}
          color="white"
        >
          Voltar
        </Button>
        <Button
          type="button"
          onClick={() => {
            resetAllInfo()
            localStorage.setItem('active_patient', patientId)
            window.location.reload()
            setOpen(false)
          }}
          color="primary"
        >
          Continuar
        </Button>
      </Div>
    </div>
  )
}
