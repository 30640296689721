import {
  ACTIVE_APPOINTMENT_COOKIE,
  ACTIVE_PATIENT_COOKIE,
  USER_COOKIE
} from './constants'
import { getCookie } from './cookie'

import { IFormatedUser } from 'types/User'

export const getCurrentUser = (): IFormatedUser | undefined => {
  const storedUser = getCookie(USER_COOKIE)

  if (storedUser) {
    const user: IFormatedUser = JSON.parse(storedUser)
    return user
  }
}

export const isUserDoctor = (): boolean | undefined => {
  const currentUser = getCurrentUser()
  if (currentUser) {
    return currentUser.isMedico
  }
}

export const isUserAdmin = (): boolean | undefined => {
  const currentUser = getCurrentUser()
  if (currentUser) {
    return currentUser.isStaff
  }
}

export const isMedicalRecord = (): boolean =>
  window.location.pathname.includes('paciente')

export const preserveAppointmentIdParam = (path: string) => {
  const appointmentId = getCookie(ACTIVE_APPOINTMENT_COOKIE) || ''

  return path.replace(':appointmentId', appointmentId)
}

export const preservePatientIdParam = (path: string) => {
  const patientId = getCookie(ACTIVE_PATIENT_COOKIE) || ''

  return path.replace(':patientId', patientId)
}

export const applyParams = (
  pathname: string,
  param: string,
  value: string | number
) => pathname.replace(param, String(value))

export const copyToClipboard = (data: string) => {
  navigator.clipboard.writeText(data)

  alert('Copiado!')
}

/*
  Function for React Hook Form.
  Get only the dirty fields from the submited value.

  https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-351784
*/

type UnknownArrayOrObject = unknown[] | Record<string, unknown>

export const dirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject
): UnknownArrayOrObject => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [
      key,
      //@ts-ignore
      dirtyValues(dirtyFields[key], allValues[key])
    ])
  )
}

export const removeEmptyProperties = (obj: any) => {
  return Object.keys(obj).reduce((acc, key) => {
    // @ts-ignore
    if (obj[key] !== '') acc[key] = obj[key]
    return acc
  }, {})
}

export const isObjectEmpty = (obj: any) => Object.keys(obj).length === 0
