import cn from 'classnames'
import { useLocation } from 'react-router-dom'

import Heading from 'components/ui/Heading'
import Link from 'components/ui/Link'

import navItems from 'utils/navbarItems'

export default function MobileNavbar() {
  const browserLocation = useLocation()

  return (
    <div className="fixed bottom-0 z-10 w-full">
      <div className="flex justify-center items-center bg-gray-100 p-2 rounded-t assina-shadow-sm">
        {navItems.map(({ name, icon, link, onlyDoctor, onlyAdmin }) => {
          // eslint-disable-next-line array-callback-return
          if (onlyDoctor || onlyAdmin) return
          return (
            <Link to={link} rel="noopener noreferrer" key={name}>
              <div
                className={cn(
                  'flex justify-center items-center space-x-2',
                  browserLocation.pathname === link
                    ? 'text-pink-600 rounded-lg bg-pink-100 p-2'
                    : 'text-gray-600'
                )}
                style={{
                  minWidth: '82px',
                  minHeight: '40px'
                }}
              >
                {icon}
                {browserLocation.pathname === link && (
                  <Heading as="h4" className="font-semibold ">
                    {name}
                  </Heading>
                )}
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
