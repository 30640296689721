import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import { formatDistanceToNow } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import cn from 'classnames'

import { Icon } from 'components/ui'
import Text from 'components/ui/Text'
import { parseDateHour } from 'utils/formaters'

const textStyle =
  'text-base font-bold text-gray-900 flex items-center justify-center'

interface ICircleIcon {
  color: string
}

const CirceIcon = ({ color }: ICircleIcon) => {
  return (
    <Icon name="circle" className={cn('fill-current mr-2', color)} size={18} />
  )
}

interface IAppointmentStatus {
  status: number
  date?: string
}

export default function AppointmentStatus({
  status,
  date
}: IAppointmentStatus) {
  switch (status) {
    case 8:
      return (
        <Text className={textStyle}>
          <CirceIcon color="text-gray-600" />
          Paciente desconectado
        </Text>
      )
    case 2:
      return (
        <Text className={textStyle}>
          <CirceIcon color="text-new_yellow-500" />
          Pré-atendimento
        </Text>
      )
    case 0:
      return (
        <Text className={`${textStyle} text-left`}>
          <CirceIcon color="text-new_yellow-500" />
          Testes do dispositivo
        </Text>
      )
    case 3:
      return (
        <Text className={textStyle}>
          <CirceIcon color="text-new_yellow-500" />
          Recepcionista
        </Text>
      )
    case 4:
      return (
        <Text className={textStyle}>
          <CirceIcon color="text-new_green-500" />
          Paciente entrou:{' '}
          {date && formatDistanceToNow(parseDateHour(date), { locale: ptBR })}
        </Text>
      )
    case 5:
      return (
        <Text className={textStyle}>
          <CirceIcon color="text-new_green-500" />
          Em Atendimento
        </Text>
      )
    case 6:
      return (
        <Text className={textStyle}>
          <CheckCircleIcon className="h-5 text-new_green-500 mr-2" />
          Concluído
        </Text>
      )
    case 7:
      return (
        <Text className={textStyle}>
          <XCircleIcon className="h-5 text-new_red-500 mr-2" />
          Saiu
        </Text>
      )
    default:
      return (
        <Text className={textStyle}>
          <CirceIcon color="text-new_yellow-500" />
          Pré-atendimento
        </Text>
      )
  }
}
