import Header from '../../layouts/Header'

import ServiceNav from '../ServiceNav'

export default function Layout({ children }) {
  return (
    <div className="flex px-6 pt-6 w-full" style={{ paddingBottom: '380px' }}>
      <Header label="Prontuário Eletrônico" />

      <div className="flex flex-col w-full pl-6">
        <ServiceNav />

        {children}
      </div>
    </div>
  )
}
