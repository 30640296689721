export const Wrapper = {
  initial: {
    opacity: 0,
    x: '+15vw'
  },
  in: {
    opacity: 1,
    x: '0'
  },
  exit: {
    opacity: 0,
    x: '-15vw'
  }
}

export const Item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
}

export const ScheduleParent = {
  initial: { scale: 0 },
  in: {
    scale: 1,
    transition: {
      staggerChildren: 0.3,
      type: 'spring'
    }
  },
  exit: { scale: 0 }
}

export const ScheduleItem = {
  initial: { scale: 0 },
  in: { scale: 1 },
  exit: { scale: 0 }
}
