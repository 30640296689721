import React, { useEffect, useRef } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { Dropdown, Spinner } from '../../ui'

const DropdownLoader = ({ color, show }) => {
  const ref = useRef()

  useEffect(() => {
    if (show) {
      disableBodyScroll(ref.current)
    } else {
      enableBodyScroll(ref.current)
    }
  }, [show])

  return (
    <Dropdown ref={ref} show={show}>
      <Spinner color={color} />
    </Dropdown>
  )
}

export default DropdownLoader
