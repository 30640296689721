import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { TextField, withStyles } from '@material-ui/core'
import bugsnag from 'lib/bugsnag'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline'

import { Layout, Loader } from 'medicalRecord/components/elements'
import Divider from './Divider'
import { Button } from 'components/ui'
import { APIContext } from 'medicalRecord/config/contexts/APIContext'
import { removeEmpty } from 'utils'
import { ToastInvalidStyles, ToastSuccessStyles } from 'utils/ToastStyles'

const EXAMS = [
  {
    label: 'Hemoglobina',
    unit: 'g/dL',
    name: 'hemoglobina'
  },
  { label: 'Hemoglobina glicada', unit: '%', name: 'hemoglobina_a1c' },
  { label: 'Hematócrito', unit: '%', name: 'hematocrito' },
  { label: 'Glicemia em jejum', unit: 'mg/dL', name: 'glicemia_jejum' },
  { label: 'Colesterol total', unit: 'mg/dL', name: 'colesterol_total' },
  { label: 'Leucócitos', unit: 'mg/dL', name: 'leucocitos' },
  { label: 'Ureia', unit: '/ mL', name: 'ureia' },
  { label: 'Creatinina', unit: 'mg/dL', name: 'creatinina' },
  { label: 'TTOG', unit: 'mg/dL', name: 'ttog' },
  { label: 'Triglicérides', unit: 'mg/dL', name: 'triglicerideos' },
  { label: 'LDL', unit: 'mg/dL', name: 'colesterol_ldl' },
  { label: 'HDL', unit: 'mg/dL', name: 'colesterol_hdl' }
]

const PHYSICAL_EXAMS_TYPE = 'exam_laboratorial'

const Input = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 40,
      '& fieldset': {
        borderRadius: 8,
        textAlign: 'center'
      },
      '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
        {
          display: 'none'
        }
    }
  }
})(TextField)

const PhysicalExam = () => {
  const { sendHistoricalExam, getHistoricalExams_ } = useContext(APIContext)

  const [historialExams, setHistorialExams] = useState([])
  const [examsValues, setExamsValues] = useState({})
  const [examsErros, setExamsErrors] = useState({})
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loadingSend, setLoadingSend] = useState(false)
  const [loadingGet, setLoadingGet] = useState(false)

  const onChange = (elm, name) => {
    setExamsValues({
      ...examsValues,
      [name]: elm.target.value
    })
    setExamsErrors({
      ...examsErros,
      [name]: ''
    })
  }

  const handleGetHistoricalExam = async ({ page }) => {
    setLoadingGet(true)
    try {
      const historialExams_ = await getHistoricalExams_({
        tipo: PHYSICAL_EXAMS_TYPE,
        results_per_page: 4,
        page: page
      })
      const body = historialExams_.data
      setPage(body.page)
      setTotalPages(body.total_pages)
      setHistorialExams(body.data)
    } catch (error) {
      toast('Erro ao buscar os últimos exames físicos!', ToastInvalidStyles)
      bugsnag.notify(
        `Erro ao buscar os últimos exames físicos! error: ${error}`
      )
    } finally {
      setLoadingGet(false)
    }
  }

  const handleSendHistoricalExam = async () => {
    setLoadingSend(true)
    try {
      await sendHistoricalExam({
        ...removeEmpty(examsValues),
        tipo: PHYSICAL_EXAMS_TYPE
      })
      setExamsValues({})
      toast('Exames enviados com sucesso!', ToastSuccessStyles)
      handleGetHistoricalExam({ page: 1 })
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error
      ) {
        Object.entries(error.response.data.error).forEach(([key, value]) => {
          setExamsErrors({
            ...examsErros,
            [key]: value.join('\r\n')
          })
        })
      }
      toast('Erro ao enviar os exames!', ToastInvalidStyles)
      bugsnag.notify(`Erro ao enviar os exames! error: ${error}`)
    } finally {
      setLoadingSend(false)
    }
  }

  useEffect(() => {
    handleGetHistoricalExam({ page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const isHistoricalValid =
    Object.keys(removeEmpty(examsValues)).length > 0 &&
    examsValues.data &&
    Object.keys(removeEmpty(examsErros)).length === 0

  return (
    <div className="flex mt-4 gap-3">
      <Layout className="w-32 flex flex-col gap-5">
        <div className="h-10" />
        <div style={{ height: 1 }} />
        {EXAMS.map(exam => (
          <div className="h-10 flex items-center justify-center">
            <p className={`font-bold text-base text-black text-center`}>
              {exam.label}
            </p>
          </div>
        ))}
        <Divider />
      </Layout>
      <Layout className="w-full gap-5 flex flex-col">
        <div className="flex">
          <Input
            type="date"
            id="birthdate"
            variant="outlined"
            style={{
              minWidth: 152,
              maxWidth: 152
            }}
            value={examsValues.data || ''}
            error={examsErros.data}
            helperText={examsErros.data}
            onChange={elm => onChange(elm, 'data')}
          />

          <div className="mx-3">
            <Divider vertical />
          </div>

          {page > 1 ? (
            <ChevronLeftIcon
              onClick={() => setPage(page => page - 1)}
              className="w-6 text-gray-600 cursor-pointer"
            />
          ) : (
            <div className="w-6" />
          )}

          <div className="flex justify-around w-full">
            {loadingGet
              ? null
              : historialExams.length > 0
              ? historialExams.map(exam => (
                  <p className="font-bold w-32 text-base text-gray-700 text-center flex items-center justify-center ">
                    {exam.data}
                  </p>
                ))
              : '-'}
          </div>

          {page < totalPages ? (
            <ChevronRightIcon
              onClick={() => setPage(page => page + 1)}
              className="w-6 text-gray-600 cursor-pointer"
            />
          ) : (
            <div className="w-6" />
          )}
        </div>

        <Divider />

        <div className="flex w-full">
          <div className="gap-5 flex flex-col">
            {EXAMS.map(exam => (
              <div className="flex" style={{ minHeight: 40 }}>
                <Input
                  id="outlined-number"
                  type="number"
                  variant="outlined"
                  className="rounded-lg"
                  style={{
                    minWidth: 152,
                    maxWidth: 152
                  }}
                  placeholder={exam.unit}
                  inputProps={{ min: 0, style: { textAlign: 'center' } }}
                  value={examsValues[exam.name] || ''}
                  onChange={elm => onChange(elm, exam.name)}
                  error={examsErros[exam.name]}
                  helperText={examsErros[exam.name]}
                />
                <div className="mx-3">
                  <Divider vertical />
                </div>
              </div>
            ))}
          </div>
          <div className="gap-5 flex flex-col w-full">
            {loadingGet ? (
              <Loader
                type="Oval"
                color="#D20E50"
                height={32}
                width={32}
                timeout={60000}
              />
            ) : historialExams.length > 0 ? (
              EXAMS.map(exam => (
                <div className="flex" style={{ minHeight: 40 }}>
                  <div className="w-6" />
                  <div className="flex justify-around w-full">
                    {historialExams.map(historicalExam => (
                      <p
                        className="font-bold text-base text-gray-700 text-center flex items-center justify-center w-32 rounded-lg"
                        style={{
                          border: !historicalExam[exam.name]
                            ? '1px solid rgba(0, 0, 0, 0.12)'
                            : ''
                        }}
                      >
                        {historicalExam[exam.name]
                          ? `${historicalExam[exam.name]} ${exam.unit}`
                          : '-'}
                      </p>
                    ))}
                  </div>
                  <div className="w-6" />
                </div>
              ))
            ) : (
              <div className="w-full flex items-center justify-center">
                <p className="font-semibold text-xl">
                  Sem cadastro de exames físicos.
                </p>
              </div>
            )}
          </div>
        </div>

        <Divider />
        <div className="flex justify-end">
          <Button
            className="w-20"
            onClick={handleSendHistoricalExam}
            loadingSend={loadingSend}
            disabled={!isHistoricalValid}
          >
            <p className="font-bold text-sm text-white p-3">Salvar</p>
          </Button>
        </div>
      </Layout>
    </div>
  )
}

export default PhysicalExam
