import { FormControlLabel, withStyles } from '@material-ui/core'

const KompaFormControlLabel = withStyles({
  root: {
    fontFamily: 'Titillium Web',
    fontSize: '16px',
    color: '#5E5E5E'
  }
})(FormControlLabel)

export default KompaFormControlLabel
