import styled from 'styled-components'

const Svg = styled.svg`
  .st0 {
    clip-path: url(#SVGID_2_);
  }
  .st1 {
    clip-path: url(#SVGID_4_);
  }
  .st2 {
    fill: #ffcb05;
  }
  .st3 {
    fill: #00a4e0;
  }
  .st4 {
    fill: #ef4123;
  }
  .st5 {
    fill: #ffffff;
  }
`

function Elo({ size }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      enableBackground="new 0 0 71 70"
      version="1.1"
      viewBox="0 0 71 70"
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <defs>
        <path id="SVGID_1_" d="M0.2 0H70.2V70H0.2z"></path>
      </defs>
      <clipPath>
        <use overflow="visible" xlinkHref="#SVGID_1_"></use>
      </clipPath>
      <g className="st0">
        <defs>
          <path id="SVGID_3_" d="M0.9 18.7H69.9V51.3H0.9z"></path>
        </defs>
        <clipPath>
          <use overflow="visible" xlinkHref="#SVGID_3_"></use>
        </clipPath>
        <g className="st1">
          <path d="M.9 18.7h69v32.6H.9V18.7z"></path>
          <path
            d="M15.2 28.6c.7-.2 1.4-.3 2.1-.3 3.2 0 5.9 2.3 6.5 5.3l4.6-.9c-1-5.2-5.6-9-11.1-9-1.3 0-2.5.2-3.6.6l1.5 4.3z"
            className="st2"
          ></path>
          <path
            d="M9.8 43.4l3.1-3.5c-1.4-1.2-2.2-3-2.2-5s.9-3.8 2.2-5l-3.1-3.5C7.4 28.6 6 31.6 6 34.9c0 3.4 1.4 6.4 3.8 8.5"
            className="st3"
          ></path>
          <path
            d="M23.8 36.3c-.6 3-3.3 5.3-6.5 5.3-.7 0-1.4-.1-2.1-.3l-1.5 4.4c1.1.4 2.3.6 3.6.6 5.5 0 10-3.9 11.1-9l-4.6-1z"
            className="st4"
          ></path>
          <path
            d="M32.3 40.1c-.2-.2-.4-.6-.5-.9-.7-1.7-.8-3.4-.1-5.1.7-1.8 2-3.2 3.6-3.9 2.1-.9 4.4-.7 6.4.5 1.3.7 2.2 1.8 2.8 3.4.1.2.2.4.2.6l-12.4 5.4zm4.2-7.3c-1.5.6-2.2 2-2.1 3.6l6.3-2.7c-1.1-1.2-2.5-1.6-4.2-.9zm4.9 5.9l-.1-.1c-.4.6-1 1.1-1.7 1.4-1.4.6-2.7.5-3.6-.4l-.1.1-1.6 2.4c.4.3.8.5 1.3.7 1.8.7 3.5.7 5.3-.1 1.3-.6 2.3-1.4 3-2.5l-2.5-1.5zm7.7-12v13.2l2.1.8-1.2 2.7-2.3-.9c-.5-.2-.9-.6-1.1-.9-.3-.4-.4-.9-.4-1.6V26.7h2.9zm5.3 9.8c0-1.1.5-2.1 1.3-2.8l-2.1-2.3c-1.4 1.3-2.3 3.1-2.3 5.2s.9 3.9 2.3 5.2l2.1-2.3c-.8-.9-1.3-1.9-1.3-3zm3.8 3.7c-.4 0-.8-.1-1.2-.2l-1 3c.7.2 1.4.4 2.2.4 3.3 0 6.1-2.4 6.8-5.5l-3.1-.6c-.4 1.7-1.9 2.9-3.7 2.9zm0-10.6c-.8 0-1.5.1-2.2.4l1 3c.4-.1.8-.2 1.2-.2 1.8 0 3.3 1.3 3.7 3l3.1-.6c-.7-3.2-3.5-5.6-6.8-5.6z"
            className="st5"
          ></path>
        </g>
      </g>
    </Svg>
  )
}

export default Elo
