import { CircularProgress, CircularProgressProps } from '@material-ui/core'

interface KompaSpinnerProps extends Omit<CircularProgressProps, 'color'> {
  color?: string
}

export default function KompaSpinner({
  color = 'primary',
  size = '24px',
  style,
  ...rest
}: KompaSpinnerProps) {
  return (
    <CircularProgress
      color={color === 'primary' || color === 'secondary' ? color : undefined}
      size={size}
      style={{
        color: color !== 'primary' && color !== 'secondary' ? color : '',
        ...style
      }}
      {...rest}
    />
  )
}
