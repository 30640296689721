/* eslint-disable no-unused-expressions */
import Bugsnag from '@bugsnag/js'
import 'moment/locale/pt-br'

import { fetchApi } from 'services/api'
import { USER_COOKIE } from './constants'
import { getCookie } from './cookie'
import { formatComplete } from 'commons/utils/utils'

export {
  formatToStandard,
  formatDate,
  formatToData,
  dateNow,
  fullDate,
  shortFormat,
  formatAgeToString
} from 'commons/utils/utils'

// Memed

export const removeMemedScripts = () => {
  window.MdHub.server.unbindEvents()
  delete window.MdHub
}

export const initEvents = ({ patient, fullscreen }) => {
  const endereco = formatComplete({
    route: patient.logradouro || '',
    street_number: patient.numero || '',
    complement: patient.complemento || '',
    political: patient.bairro || '',
    postal_code: patient.cep || '',
    city: patient.cidade || ''
  })

  const metaDadosHeader = patient.genero
    ? [
        // cada item do array se transforma em uma linha.
        {
          Sexo: patient.genero,
          'Data de Nasc': patient.data_nascimento
        },
        {
          Endereço: endereco
        },
        {
          Telefone: patient.celular || ''
        }
      ]
    : []
  window.MdSinapsePrescricao.event.add(
    'core:moduleInit',
    function moduleInitHandler(module) {
      if (module.name === 'plataforma.prescricao') {
        window.MdHub.event.add(
          'prescricaoImpressa',
          async function (prescriptionData) {
            try {
              const prescriptionId = prescriptionData.prescricao.id
              await fetchApi(
                `api/v1/user/${patient.id}/prescriptions`,
                'post',
                {
                  prescription_id: prescriptionId,
                  prescription_data: prescriptionData
                }
              )
            } catch (error) {
              Bugsnag.notify(error)
            }
          }
        )

        window.MdHub.command.send('plataforma.prescricao', 'setPaciente', {
          nome: patient.nome,
          idExterno: patient.id,
          endereco,
          cidade: patient.cidade ? patient.cidade.nome : ''
        })

        window.MdHub.command.send(
          'plataforma.prescricao',
          'setAdditionalData',
          {
            // Campos de exemplo para sair na impressão.
            header: metaDadosHeader,
            footer:
              'Unidade: ASSINA TECNOLOGIA GESTAO EM SAUDE S.A. \nCNPJ: 35.521.757/0001-05'
          }
        )
        window.MdHub.module.show('plataforma.prescricao')
        window.MdHub.command.send(
          'plataforma.prescricao',
          'setFeatureToggle',
          featuresToToggle(fullscreen)
        )
      }
    }
  )
}

const featuresToToggle = fullscreen => {
  if (fullscreen)
    return {
      deletePatient: false,
      removePatient: false,
      editPatient: false,
      buttonClose: false
    }

  return {
    deletePatient: false,
    removePatient: false,
    editPatient: false
  }
}

export const initMemed = (token, patient, fullscreen) => {
  const script = document.createElement('script')
  script.setAttribute('type', 'text/javascript')
  script.setAttribute('data-color', '#D20E50')
  script.setAttribute('data-token', token)

  fullscreen && script.setAttribute('data-container', 'memed-container')

  const scriptPath =
    '/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js'

  const memedUrl = process.env.REACT_APP_MEMED_URL // || process.env.SANDBOX_MEMED_URL

  script.src = memedUrl + scriptPath

  const memedApiUrl =
    process.env.REACT_APP_MEMED_API_URL || 'https://api.memed.com.br' // || process.env.SANDBOX_MEMED_API_URL

  script.onload = () => {
    initEvents({ patient, fullscreen, token, memedApiUrl })
  }

  document.body.appendChild(script)
}

// Data structure

export const removeEmpty = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== '') acc[key] = obj[key]
    return acc
  }, {})
}

export const removeComma = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key].includes(',')) acc[key] = obj[key].replace(',', '.')
    return acc
  }, obj)
}

export const persistOnStorage = (data, name) => {
  const formData = JSON.stringify(data)
  localStorage.setItem(name, formData)
}

export const checkStorage = name => {
  const formData = localStorage.getItem(name)
  return JSON.parse(formData)
}

export const getInfos = (data, name) => {
  if (data.length !== 0) {
    const filtered = data.filter(item => item.type === name)[0]
    if (!filtered) return {}
    return filtered
  }
  return false
}

export const getAllLocalStorageProntuarios = () => {
  const array = [
    localStorage.getItem('prontuario'),
    localStorage.getItem('anamnese'),
    localStorage.getItem('biometria'),
    localStorage.getItem('documento'),
    localStorage.getItem('solicitacao_exames'),
    localStorage.getItem('hipotese_diagnostica'),
    localStorage.getItem('anexos'),
    localStorage.getItem('conduta'),
    localStorage.getItem('evolucao_simples')
  ]

  return array.filter(elm => {
    return elm != null
  })
}

// User

export const isUserDoctor = () => {
  const storedUser = getCookie(USER_COOKIE)
  const user = JSON.parse(storedUser)
  return user && user.isMedico
}

export const SymptomsLabels = {
  BlurryVision: 'Vista embaçada',
  ColdPhalanxes: 'Extremidades frias',
  FeelingBloated: 'Sente-se inchado',
  FeelingSleepyStatus: 'Sente-se sonolento',
  FeelingTiredStatus: 'Sente-se cansado',
  FootSoleSensivity: 'Possui sensibilidade na sola dos pés',
  HairLoss: 'Perda de cabelo',
  LastRegularMenstruation: 'Última menstruação',
  MenstruationRegularity: 'Menstruação regular',
  MuscularSoreness: 'Dores musculares',
  TremblingHands: 'Tremor nas mãos',
  BrittleNail: 'Unhas frágeis',
  Constipation: 'Constipação',
  DrySkin: 'Pele seca',
  InsomniaStatus: 'Insônia',
  MoodChange: 'Mudança de humor',
  Polydipsia: 'Polidipsia',
  Polyphagy: 'Polifagia',
  Polyuria: 'Poliúria',
  StrengthLoss: 'Perda de força muscular',
  SwollenNeck: 'Bócio',
  Tachycardia: 'Taquicardia',
  TemperatureSensivity: 'Sensibilidade à temperatura',
  TinglingSensation: 'Formigamento frequente',
  WeightChangeStatus: 'Mudança de peso nos últimos 3 meses',
  WeightChangeValue: 'Peso perdido nos últimos 3 meses',
  procedurePregnancyLast12Months: 'Gestação prévia nos últimos 12 meses',
  diabetesInFamilyStatus: 'Histórico de diabetes na família',
  neckSurgeryStatus: 'Realizou cirurgia no pescoço',
  neckSurgeryType: 'Tipo de procedimento',
  neckSurgeryYear: 'Ano do procedimento',
  radiologyTreatmentStatus: 'Realizou tratamento com radioterapia'
}
