import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'

const ExamHeader = () => {
  return (
    <div className="flex flex-col w-full h-auto bg-pink-600 rounded-b-md">
      <div className="container text-center py-8 mx-auto text-white">
        <Heading className="font-semibold text-2xl">Exames e Anexos</Heading>
        <Text as="span" className="text-lg">
          Envie exames e anexe fotos e vídeos das suas queixas para sua equipe
          de saúde e agilize seu atendimento.
        </Text>
      </div>
    </div>
  )
}

export default ExamHeader
