import { useState } from 'react'
import cn from 'classnames'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import {
  ClipboardIcon,
  ExternalLinkIcon,
  ExclamationIcon
} from '@heroicons/react/outline'
import { useHistory } from 'react-router-dom'

import Button from 'components/ui/Button'
import Text from 'components/ui/Text'
import Link from 'components/ui/Link'
import TableItemAction from '../../VirtualClinic/TableItemAction'
import AppointmentStatus from '../../VirtualClinic/StatusComponent'
import ReportPrompt from 'components/layouts/VirtualClinic/ReportPrompt'

import { useModalContext } from 'contexts/ModalContext'

import { getAppointmentType } from 'utils/appointmentUtils'
import { copyToClipboard } from 'utils/helpers'
import { BASE_API_URL } from 'utils/constants'
import { APP_URLS } from 'urls'

import { IAppointment } from 'types/Appointment'
import { formatDistance } from 'date-fns'
import { parseToUniversalDate } from 'utils/formaters'
import pt from 'date-fns/locale/pt'

const calcWaitingTime = (date: string | null) => {
  if (date) {
    return formatDistance(
      new Date(parseToUniversalDate(date.substr(0, 10)) + date.substr(10)),
      Date.now(),
      { addSuffix: true, locale: pt }
    )
  }
  return '-'
}

const normalColumnStyle = 'text-gray-900 text-base self-center'

export interface ITableItem {
  appointment: IAppointment
}

export default function TableItem({
  appointment: {
    id,
    data_agenda,
    hora_agenda,
    especialidade,
    paciente,
    tipo,
    principal_queixa,
    status_label,
    patient_status,
    link_paciente,
    paciente_entrou,
    patient_plan,
    data_cadastro
  }
}: ITableItem) {
  const { handleModal } = useModalContext()
  const [showExtra, setShowExtra] = useState<boolean>(false)

  const history = useHistory()

  const openExtraInfo = () => setShowExtra(prev => !prev)

  const patientStatus =
    // If there is no patient service status return 8 which equals Patient disconnected
    patient_status && patient_status.length > 0
      ? // Specific condition for status 4 that checks last_ping_is_ok
        patient_status[0].status === 4
        ? // condition that checks last_ping_is_ok if true, return status if not return 7 which equals patient Quit
          patient_status[0].last_ping_is_ok
          ? patient_status[0].status
          : 7
        : patient_status[0].status
      : paciente_entrou
      ? 4
      : 8

  const patientStatusDate =
    patient_status && patient_status.length > 0
      ? patient_status[0].datetime
      : paciente_entrou || ''

  return (
    <div className="my-3">
      <div className="grid grid-cols-8 text-center border-t-2 border-gray-400 pt-4">
        <Text className={normalColumnStyle}>
          {calcWaitingTime(data_cadastro)}
        </Text>
        <Text className={normalColumnStyle}>{especialidade?.nome}</Text>
        <Text className={normalColumnStyle}>
          {paciente?.nome} <Text as="span">{paciente?.cpf}</Text>
        </Text>
        <Text className={normalColumnStyle}>{getAppointmentType(tipo)}</Text>
        <Text className={normalColumnStyle}>{patient_plan}</Text>
        <Text className={cn(normalColumnStyle, 'font-bold')}>
          {status_label}
        </Text>

        <AppointmentStatus
          status={status_label === 'Realizada' ? 8 : patientStatus}
          date={patientStatusDate}
        />

        <div className="flex items-center justify-center space-x-2">
          <Link to={`${APP_URLS.VIRTUAL_CLINIC}/sala/${id}`}>
            <Button className="px-2">Entrar</Button>
          </Link>
          <button onClick={openExtraInfo}>
            {showExtra ? (
              <ChevronUpIcon className="h-6 text-gray-900" />
            ) : (
              <ChevronDownIcon className="h-6 text-gray-900" />
            )}
          </button>
        </div>
      </div>

      {showExtra && (
        <div className="grid grid-cols-4 gap-2 mt-4 mx-10">
          <TableItemAction
            label="Acessar"
            title="Prontuário"
            icon={<ExternalLinkIcon className=" text-pink-600 h-5" />}
            action={() =>
              history.push(
                `${APP_URLS.VIRTUAL_CLINIC}/paciente/${paciente?.id}`
              )
            }
          />
          <TableItemAction
            label="Acessar"
            title="Pré atendimento"
            icon={<ExternalLinkIcon className=" text-pink-600 h-5" />}
            action={() =>
              history.push(
                `${APP_URLS.VIRTUAL_CLINIC}/paciente/${paciente?.id}/karmen`
              )
            }
          />
          <TableItemAction
            label="Reportar"
            title="Problema de conexão"
            icon={<ExclamationIcon className=" text-pink-600 h-5" />}
            action={() => {
              handleModal(
                <ReportPrompt
                  id={id}
                  data_agenda={data_agenda}
                  hora_agenda={hora_agenda}
                  paciente={paciente}
                />
              )
            }}
          />
        </div>
      )}
    </div>
  )
}
