import { UsersIcon, VideoCameraIcon } from '@heroicons/react/solid'

import Head from 'components/common/Head'
import HomeCardSmartConsultation from 'components/HomeCardSmartConsultation/HomeCardSmartConsultation'
import Heading from 'components/ui/Heading'
import Link from 'components/ui/Link'
import Text from 'components/ui/Text'

import { APP_URLS } from 'urls'

export default function DoctorHomePage() {
  const menuItemStyle = {
    height: 'min-content'
  }
  return (
    <>
      <Head title="Início" />
      <div className="w-full flex flex-wrap gap-3 p-3 max-w-screen-lg mr-auto">
        <Link style={menuItemStyle} to={APP_URLS.VIRTUAL_CLINIC}>
          <div className="bg-gray-100 border-l-4 border-green-600 h-full w-full rounded assina-shadow-sm p-3 space-y-2">
            <div className="flex items-center justify-between text-green-600">
              <Heading className="text-xl font-bold">Consultório</Heading>
              <VideoCameraIcon className="h-10" />
            </div>
            <Text className="text-gray-800">
              Suas consultas e histórico de pacientes.
            </Text>
          </div>
        </Link>
        <Link style={menuItemStyle} to={APP_URLS.PROMPT_MEDICAL_CARE}>
          <div className="bg-gray-100 border-l-4 border-teal-600 h-full w-full rounded assina-shadow-sm p-3 space-y-2">
            <div className="flex items-center justify-between text-teal-600">
              <Heading className="text-xl font-bold">
                Pronto atendimento
              </Heading>
              <VideoCameraIcon className="h-10" />
            </div>
            <Text className="text-gray-800">
              Veja as consultas de embarque pendentes.
            </Text>
          </div>
        </Link>
        <Link to={APP_URLS.ADMINISTRATIVE.USERS} style={menuItemStyle}>
          <div className="bg-gray-100 border-l-4 border-blue-600 h-full w-full rounded assina-shadow-sm p-3 space-y-2">
            <div className="flex items-center justify-between text-blue-600">
              <Heading className="text-xl font-bold">Usuários</Heading>
              <UsersIcon className="h-10" />
            </div>
            <Text className="text-gray-800">
              Usuários cadastrados, status e informações.
            </Text>
          </div>
        </Link>

        <HomeCardSmartConsultation />
      </div>
    </>
  )
}
