import styled from 'styled-components'

import Icon from '../Icon'

const FABButton = styled.a`
  position: fixed;

  bottom: 24px;
  right: 24px;

  z-index: 20;
`

export default function FAB({ href }) {
  return (
    <FABButton href={href}>
      <Icon name="whatsapp-filled" size={50} />
    </FABButton>
  )
}
