import { backendServices } from './AxiosInstances'

import { IAppointment, IAppointmentQuery } from 'types/Appointment'
import { appointmentStatusToId } from 'utils/appointmentUtils'
import { isUserDoctor } from 'utils/helpers'
import { APPOINTMENT_STATUS, APPOINTMENT_STATUS_ENTITY } from 'utils/constants'

export const getAppointments = ({
  status = 'all',
  ...query
}: IAppointmentQuery): Promise<IAppointment[]> =>
  backendServices
    .get('/api/v1/consultation', {
      params: {
        status,
        ...query
      }
    })
    .then(({ data }) => data.data)
    .catch(error => {
      throw error
    })

export const getAppointment = async (appointmentId: string) =>
  backendServices
    .get(`/api/v1/consultation${isUserDoctor() ? '/adm' : ''}`, {
      params: { id: appointmentId, type: 'all' }
    })
    .then(({ data }) => data.data[0])
    .catch(error => {
      throw new Error(error)
    })

export const registerStatus = async (appointmentId: string) =>
  backendServices
    .post(`/api/v1/consultation/${appointmentId}/service/status`, {
      status: APPOINTMENT_STATUS.STARTED,
      entity: isUserDoctor()
        ? APPOINTMENT_STATUS_ENTITY.DOCTOR
        : APPOINTMENT_STATUS_ENTITY.PATIENT
    })
    .then(({ data }) => {
      return {
        status: appointmentStatusToId(data.data.appointment_status),
        isPatientUseTwilio: data.data.is_patient_use_twilio
      }
    })
    .catch(error => {
      throw new Error(error)
    })

export const updateStatus = async (appointmentId: string, status: string) =>
  backendServices
    .post(`/api/v1/appointment/${appointmentId}/status`, { status })
    .catch(error => {
      throw new Error(error)
    })
