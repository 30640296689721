import styled from 'styled-components'

import { BACKGROUNDS } from '../../urls'

export const Div = styled.div`
  width: 100%;
  background: #fbfbfb;
  min-height: calc(100vh - var(--height-header-footer));
  background-image: url(${BACKGROUNDS.BG_LOGO});
  background-size: auto;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 36px;
`

export const H1 = styled.h1`
  color: #7e0830;
  font-size: 16px;
  font-weight: 500;
`

export const TextIndent = styled.div`
  text-indent: 8px;
`

export const P = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: ${({ gray }) => (gray ? '#5e5e5e' : '#540620')};
  span {
    color: #d20e50;
  }
`

export const Section = styled.section`
  margin-top: 1rem;
  border-top: 1.5px solid #eaeaea;
  padding-top: 16px;
`

export const BlockContent = styled.div`
  box-shadow: 0px 2px 4px 0.5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 1rem;
  background-color: white;
`
