import SummaryItem from './Summary/SummaryItem'

// Utils
import { shortFormat } from '../../utils/Utils'

export const removeEmptyAttachments = recordsGroup => {
  /* 
    This loops through recordsGroup > records > record

    > recordsGroup:
          1 - maps through all records
    > records: 
          1 - maps through all record.
          2 - filters all record that has no length
    > record: 
          1 - it checks if has 'conjunto_anexos', then checks 'anexos' property 
              if its empty, returns null, if not return the normal record
          2 - filters record that is null and removes it

    Then finally, if the recordsGroup is empty, it returns undefined.
    Else return the filtered recordGroup
  */
  const removeEmptyRecords = recordsGroup
    .map(records =>
      records
        .map(record =>
          record.type === 'conjunto_anexos' && record.anexos.length === 0
            ? null
            : { ...record }
        )
        .filter(record => record)
    )
    .filter(records => records.length)

  if (removeEmptyRecords.length === 0) {
    return
  }

  return removeEmptyRecords
}

const Summary = ({ info }) => {
  const recordsGroup = removeEmptyAttachments(info.changes)
  const [day, month, year] = shortFormat(info.date).split(' ')

  if (!recordsGroup) {
    return null
  }

  return (
    <div className="summary">
      <div className="summary-date text-base">
        <h1 className="font-semibold leading-5">{day}</h1>
        <p className="leading-5">{month}</p>
        <p className="leading-5">{year}</p>
      </div>
      <div className="summary-container flex flex-col space-y-4">
        {recordsGroup.map((item, index) => (
          <SummaryItem data={item} key={index} />
        ))}
      </div>
    </div>
  )
}

export default Summary
