/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
import React from 'react'
import { ZoomInIcon } from '@heroicons/react/outline'

import { useModalContext } from 'contexts/ModalContext'
import FilePreviewPrompt from 'components/layouts/FilePreviewPrompt'

import PDF from '../../../assets/images/pdf.png'

const Summary = ({ info }) => {
  const { handleModal, setCloseButton } = useModalContext()
  return (
    <>
      {info.changes.map(elm =>
        elm.map(elm_ =>
          elm_.anexos.map(exam => {
            return (
              <div className="flex gap-3 mt-3 justify-between">
                <div
                  className="relative cursor-pointer"
                  onClick={() => {
                    setCloseButton(false)
                    handleModal(<FilePreviewPrompt file={exam} />)
                  }}
                >
                  <img
                    src={exam.arquivo.includes('pdf') ? PDF : exam.arquivo}
                    alt="preview"
                    className="object-cover rounded-md "
                    style={{
                      maxHeight: 80,
                      maxWidth: 80,
                      minHeight: 80,
                      minWidth: 80
                    }}
                  />
                  <ZoomInIcon
                    className="w-6 h-6 text-white absolute"
                    style={{
                      top: 28,
                      right: 28
                    }}
                  />
                </div>
                <div className="w-full">
                  <p className="text-sm text-gray-900 ">Tipo de exame</p>
                  <p className="text-base text-gray-900">{exam.exam_type}</p>
                </div>
                <div className="w-full">
                  <p className="text-sm text-gray-900 ">Data de realização</p>
                  <p className="text-base text-gray-900">
                    {exam.data_cadastro.split(' ')[0]}
                  </p>
                </div>
                <div className="w-full">
                  <p className="text-sm text-gray-900 ">Adicionado por</p>
                  <p className="text-base text-gray-900">
                    {elm_.created_by.nome}
                  </p>
                </div>
              </div>
            )
          })
        )
      )}
    </>
  )
}

export default Summary
