import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import throttle from 'lodash.throttle'
import { InputAdornment, MenuItem } from '@material-ui/core'
import {
  OfficeBuildingIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  PlusCircleIcon
} from '@heroicons/react/solid'
import { ChevronDownIcon } from '@heroicons/react/outline'

import Button from 'components/ui/Button'
import { KompaWhiteInput } from 'components/ui/KompaInput'
import KompaAutocomplete from 'components/ui/KompaAutocomplete'
import Text from 'components/ui/Text'
import Heading from 'components/ui/Heading'
import { KompaWhiteSelect } from 'components/ui/KompaSelect'

import { getMedicalUnits } from 'services/medicalUnitService'

import { parseInputDate } from 'utils/formaters'
import bugsnag from 'lib/bugsnag'

import { IOffer, OfferInputs } from 'types/Offer'
import { MedicalUnit } from 'types/MedicalUnit'

const formatValue = (value: string) =>
  Number(value.replace('R$ ', '').replace(',', '.'))

const isCurrentOfferValid = ({
  clinic,
  date,
  period,
  appointment_price,
  original_price
}: OfferInputs) => {
  if (clinic && date && period && appointment_price && original_price) {
    return true
  }
  return false
}

interface NewOfferFormProps {
  handleAddOffer: (newOffer: IOffer) => void
  handleCurrentOffer: (offer: OfferInputs | null) => void
  currentOffer: OfferInputs | null
}

export default function NewOfferForm({
  handleCurrentOffer,
  currentOffer,
  handleAddOffer
}: NewOfferFormProps) {
  const [medicalUnits, setMedicalUnits] = useState<MedicalUnit[] | []>([])
  const [isMedicalUnitsError, setIsMedicalUnitsError] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors }
  } = useForm<OfferInputs>({
    mode: 'onChange',
    defaultValues: {
      clinic: null,
      date: '',
      period: '',
      appointment_price: '',
      original_price: ''
    }
  })

  const inputs = watch()

  useEffect(() => {
    if (isCurrentOfferValid(inputs)) {
      if (!currentOffer) {
        return handleCurrentOffer(inputs)
      }
      if (
        currentOffer &&
        JSON.stringify(currentOffer) !== JSON.stringify(inputs)
      ) {
        return handleCurrentOffer(inputs)
      }
    }
    if (!isCurrentOfferValid(inputs) && currentOffer) {
      return handleCurrentOffer(null)
    }
  }, [currentOffer, handleCurrentOffer, inputs])

  const onSubmit: SubmitHandler<OfferInputs> = data => {
    const formatedPrice = formatValue(data.appointment_price)
    const formatedOriginalPrice = formatValue(data.original_price)

    const clinic = {
      id: data.clinic?.id,
      nome_razao_social: data.clinic?.name,
      bairro: data.clinic?.district,
      logradouro: data.clinic?.address
    }

    handleAddOffer({
      ...data,
      date: parseInputDate(data.date),
      clinic,
      appointment_price: formatedPrice,
      original_price: formatedOriginalPrice
    })

    reset()
  }

  const loadOptions = async (_: any, data: string) => {
    if (data.length >= 3) {
      try {
        const res = await getMedicalUnits({ q: data })

        if (isMedicalUnitsError) setIsMedicalUnitsError(false)

        return setMedicalUnits(res)
      } catch (error) {
        bugsnag.notify(
          `Error on fetching <Administrative> medical units: ${JSON.stringify(
            error
          )}`
        )
        setIsMedicalUnitsError(true)
      }
    }
    setMedicalUnits([])
  }

  return (
    <>
      <Heading className="text-lg text-pink-600 mt-2 font-semibold">
        Adicionar oferta
      </Heading>
      {isMedicalUnitsError && (
        <div className="bg-pink-700 text-center text-gray-100 font-medium text-lg p-4 mb-8 rounded">
          <Text>Não foi possível carregar as unidades credenciadas.</Text>
        </div>
      )}
      <form className="space-y-4 my-2" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Text as="label" className="text-base text-gray-700" htmlFor="clinic">
            Unidade credenciada:
          </Text>
          <div className="flex space-x-2 mt-1">
            <Controller
              name="clinic"
              control={control}
              rules={{
                required: true
              }}
              render={props => (
                <KompaAutocomplete
                  {...props}
                  options={medicalUnits}
                  getOptionLabel={option =>
                    // @ts-ignore
                    `${option.name} - ${option.city}/${option.state}, ${option.district}`
                  }
                  fullWidth
                  onChange={(_, data) => props.onChange(data)}
                  onInputChange={throttle(loadOptions, 1000)}
                  noOptionsText="Nenhuma unidade encontrada"
                  renderInput={params => (
                    <KompaWhiteInput
                      {...params}
                      variant="filled"
                      fullWidth
                      placeholder="Pesquise clinicas"
                      error={!!errors.clinic}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <OfficeBuildingIcon className="h-6 text-pink-600" />
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              )}
            />
          </div>
        </div>

        <div>
          <Text as="label" className="text-base text-gray-700" htmlFor="date">
            Data e período:
          </Text>
          <div className="flex space-x-2 mt-1">
            <Controller
              id="date"
              name="date"
              type="date"
              placeholder="Data da consulta"
              as={KompaWhiteInput}
              control={control}
              fullWidth
              variant="filled"
              error={!!errors.date}
              rules={{
                required: true
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarIcon className="h-6 text-pink-600" />
                  </InputAdornment>
                )
              }}
            />
            <Controller
              name="period"
              control={control}
              rules={{
                required: true
              }}
              render={props => (
                <KompaWhiteSelect
                  {...props}
                  id="period"
                  error={!!errors.period}
                  placeholder="Período da consulta"
                  variant="filled"
                  displayEmpty
                  fullWidth
                  style={{
                    backgroundColor: '#fbfbfb'
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <CurrencyDollarIcon className="h-6 text-pink-600" />
                    </InputAdornment>
                  }
                  IconComponent={props => (
                    <ChevronDownIcon {...props} style={{ height: '24px' }} />
                  )}
                >
                  <MenuItem value="" disabled>
                    <em>Selecione</em>
                  </MenuItem>
                  <MenuItem value="morning">Manhã</MenuItem>
                  <MenuItem value="afternoon">Tarde</MenuItem>
                </KompaWhiteSelect>
              )}
            />
          </div>
        </div>

        <div>
          <Text
            as="label"
            className="text-base text-gray-700"
            htmlFor="original_price"
          >
            Preço:
          </Text>
          <div className="flex space-x-2 mt-1">
            <Controller
              id="original_price"
              name="original_price"
              placeholder="Preço original"
              as={NumberFormat}
              customInput={KompaWhiteInput}
              decimalSeparator={','}
              prefix={'R$ '}
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              control={control}
              fullWidth
              variant="filled"
              error={!!errors.original_price}
              rules={{
                required: true
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyDollarIcon className="h-6 text-pink-600" />
                  </InputAdornment>
                )
              }}
            />
            <Controller
              id="appointment_price"
              name="appointment_price"
              placeholder="Preço no plano"
              className="col-span-2"
              as={NumberFormat}
              customInput={KompaWhiteInput}
              decimalSeparator={','}
              prefix={'R$ '}
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              control={control}
              fullWidth
              variant="filled"
              error={!!errors.appointment_price}
              rules={{
                required: true
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyDollarIcon className="h-6 text-pink-600" />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>
        <Button
          type="submit"
          color="transparent"
          className="mt-4 text-pink-600 text-lg"
        >
          <PlusCircleIcon className="h-8 mr-2" /> Adicionar nova oferta
        </Button>
      </form>
    </>
  )
}
