import { MonitoringBg } from './MonitoringMenuItem'

export default function MonitoringItem({
  value,
  date,
  created_by,
  type,
  height
}) {
  return (
    <MonitoringBg className="my-2" height={height}>
      <div>
        <h1 className="text-3xl text-pink-600 font-semibold">{`${value
          .toString()
          .replace('.', ',')} ${type}`}</h1>
        <span className="text-xl text-teal-600">{date}</span>
        <br />
        <span className="text-gray-700 text-lg leading-6 font-medium">
          Atualizado por: <br /> {created_by}
        </span>
      </div>
    </MonitoringBg>
  )
}
