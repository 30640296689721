import { APPOINTMENT_STATUS } from './constants'

export const appointmentStatusToId = (status: string) => {
  switch (status) {
    case 'Agendada':
      return APPOINTMENT_STATUS.NOT_STARTED
    case 'Iniciada':
      return APPOINTMENT_STATUS.STARTED
  }
}

export const getAppointmentType = (type: string) => {
  switch (type) {
    case 'N':
      return 'Normal'
    case 'R':
      return 'Retorno'
    case 'A':
      return 'Acolhimento'
    case 'M':
      return 'Monitoramento'
    case 'E':
      return 'Embarque'
    case 'P':
      return 'Pronto atendimento'
    default:
      return 'normal'
  }
}
