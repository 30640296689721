import { formatUser } from 'contexts/AuthContext'

import { backendServices } from './AxiosInstances'

import { setCookie, destroyCookie } from 'utils/cookie'
import { AUTH_COOKIE, MAX_AGE_COOKIE, USER_COOKIE } from 'utils/constants'

import posthog from 'lib/posthog'
import oneSignal from 'lib/oneSignal'

import ISignIn from 'types/SignIn'
import IUser from 'types/User'
import bugsnag from 'lib/bugsnag'

interface SignIn {
  data: IUser | null
  error: string | null
}

interface ISignInResponse {
  ok: boolean
  data: IUser
}

export const handleSignIn = async ({
  username,
  password
}: ISignIn): Promise<SignIn> =>
  backendServices
    .post<ISignInResponse>('/api/v1/login', {
      username,
      password
    })
    .then(({ data }) => {
      backendServices.defaults.headers[
        'Authorization'
      ] = `Token ${data.data.token}`

      setCookie(AUTH_COOKIE, data.data.token, { expires: MAX_AGE_COOKIE })

      const formatedUser = formatUser(data.data)

      setCookie(USER_COOKIE, JSON.stringify(formatedUser), {
        expires: MAX_AGE_COOKIE
      })

      posthog.identifyOrOptOut()
      oneSignal.identify()

      return { data: data.data, error: null }
    })
    .catch(error => {
      if (error.response.status === 401) {
        return { data: null, error: error.response.data.error }
      }

      bugsnag.notify(`Error on login ${error}`)
      return { data: null, error: 'Ops, algo deu errado' }
    })

export const handleSignOut = () => {
  delete backendServices.defaults.headers['Authorization']
  destroyCookie(AUTH_COOKIE)
  destroyCookie(USER_COOKIE)
  posthog.reset()
  oneSignal.reset()
}
