import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  makeStyles,
  withStyles
} from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles({
  root: {
    padding: '8px 0',
    fontWeight: 600,
    fontFamily: 'Titillium Web',
    textTransform: 'none',
    fontSize: '16px'
  }
})

interface MuiButtonProps extends MUIButtonProps {}

export default function MuiButton({
  className,
  disableElevation = true,
  children,
  ...rest
}: MuiButtonProps) {
  const Button = withStyles({})(MUIButton)
  const classes = useStyles()
  return (
    <Button
      disableElevation={disableElevation}
      className={cn(classes.root, className)}
      {...rest}
    >
      {children}
    </Button>
  )
}
