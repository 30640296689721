import styled from 'styled-components'

const Svg = styled.svg`
  .st0 {
    clip-path: url(#SVGID_2_);
  }
  .st1 {
    clip-path: url(#SVGID_4_);
  }
  .st2 {
    fill: #fcaf26;
  }
  .st3 {
    fill: #231f20;
  }
  .st4 {
    fill: #204595;
  }
  .st5 {
    fill: #ffffff;
  }
  .st6 {
    fill: none;
    stroke: #231f20;
    stroke-width: 9.21747e-3;
    stroke-miterlimit: 3.864;
  }
`

function JCB({ size }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 71 71"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0"
      y="0"
      enableBackground="new 0 0 71 70"
      version="1.1"
      xmlSpace="preserve"
    >
      <defs>
        <path id="SVGID_1_" d="M0.5 0H70.5V70H0.5z"></path>
      </defs>
      <clipPath>
        <use overflow="visible" xlinkHref="#SVGID_1_"></use>
      </clipPath>
      <g className="st0">
        <defs>
          <path id="SVGID_3_" d="M1.4 21.7H69.7V48.4H1.4z"></path>
        </defs>
        <clipPath>
          <use overflow="visible" xlinkHref="#SVGID_3_"></use>
        </clipPath>
        <g className="st1">
          <path d="M1.4 21.7h68.3v26.7H1.4V21.7z" className="st2"></path>
          <path
            d="M65.7 43.7c0 .6-.4 1.1-.8 1.1H6c-.5 0-.8-.5-.8-1.1V26.6c0-.6.4-1.1.8-1.1h58.8c.5 0 .8.5.8 1.1l.1 17.1z"
            className="st3"
          ></path>
          <path d="M59.3 37.6v.1-.1c0 .1 0 0 0 0" className="st4"></path>
          <path
            d="M20.5 41.5c-1 1-2.5 1.2-4.6 1.2h-3.1c-4.3 0-5.7-1.1-5.7-4.7v-2h4.1v.5c0 1.8.4 2.4 2.2 2.4h2c1.6 0 1.9-.6 1.9-2.2v-9.4h4.4v10.9c-.1 1.4-.6 2.6-1.2 3.3zm17.7-8.8c0-1.2-.7-1.7-2.3-1.7h-3.5c-2.8 0-3.5.4-3.5 3v2.1c0 2.4 1 2.9 3.5 2.9H35c2.4 0 3.4-.3 3.4-2.1v-.2h4.5v1.1c0 1.9-.4 3.1-1.4 3.9-1.1.9-2.8 1-5.3 1h-4.9c-2.3 0-3.9-.1-5.1-1.1-1.2-1-1.7-2.4-1.7-4.5V33c0-4.6 1.9-5.9 6.8-5.9H36c2.5 0 4.2.1 5.3 1 .9.8 1.3 2 1.3 3.8v.9h-4.4v-.1zm7.4 9.9V27.4h13c3.2 0 4.8 1.4 4.8 4.2 0 1.6-.7 2.6-2.1 3.2 1.8.3 2.6 1.4 2.6 3.6 0 2.9-1.4 4.1-4.6 4.1H45.6v.1zM58.9 32c0-.8-.5-1.3-1.1-1.3H50v2.5h7.7c.8.1 1.2-.4 1.2-1.2zm.4 5.7c0-.8-.5-1.3-1.2-1.3H50v2.7h8.1c.8 0 1.2-.5 1.2-1.4z"
            className="st5"
          ></path>
          <path d="M7.1 27.3h6v6.1h-6v-6.1z" className="st2"></path>
          <path
            d="M11.5 30.3h.1s.1 0 .1.1l.1.2v.1h-.1l-.2-.4zm-.2-.7H11.6l.1.2v.1h-.1l-.3-.3zm-.5-.4s.5.1.6.1c.2 0 .3.2.3.3 0 0 .1.2.1.3.1.2-.1.3-.1.3s.2 0 .2.2c0 0 .1.3.1.4 0 0 .1.3-.2.4l-.5.1-.5-2.1zm-2.1-.4l.8.2.1.5h-.2s.5 1.7.5 1.8c.1.2.1.4-.2.5l-.3.1c-.2 0-.4 0-.4-.2l-.3-1.1h.4s.2.5.2.6c0 .1.1.2.2.1.1 0 .1-.1.1-.1l-.5-1.7h-.2l-.2-.7z"
            className="st3"
          ></path>
          <path
            d="M8.7 28.8l.8.2.1.5h-.2s.5 1.7.5 1.8c.1.2.1.4-.2.5l-.3.1c-.2 0-.4 0-.4-.2l-.3-1.1h.4s.2.5.2.6c0 .1.1.2.2.1.1 0 .1-.1.1-.1l-.5-1.7h-.2l-.2-.7z"
            className="st6"
          ></path>
          <path
            d="M10 29s.3.1.4.1c0 0 .3.1.4.3 0 .1.2.5.2.5h-.3s-.1-.2-.1-.3c0-.1 0-.1-.1-.1s-.1 0-.1.1l.3 1.1c0 .1 0 .2.2.1.1 0 .1-.1.1-.2s-.1-.2-.1-.3h.3l.2.6s.1.3-.1.4h-.1l-.3.1s-.3.1-.4-.2c0 0-.5-1.9-.6-2-.2-.1-.1-.2.1-.2"
            className="st3"
          ></path>
          <path
            d="M10 29s.3.1.4.1c0 0 .3.1.4.3 0 .1.2.5.2.5h-.3s-.1-.2-.1-.3c0-.1 0-.1-.1-.1s-.1 0-.1.1l.3 1.1c0 .1 0 .2.2.1.1 0 .1-.1.1-.2s-.1-.2-.1-.3h.3l.2.6s.1.3-.1.4h-.1l-.3.1s-.3.1-.4-.2c0 0-.5-1.9-.6-2-.2-.1-.1-.2.1-.2z"
            className="st6"
          ></path>
          <path
            d="M8.3 28.5c.2 0 3 .5 3.2.5.1 0 .3.1.4.3 0 .1.4 1.3.4 1.3 0 .1.1.3-.2.4 0 0-2.7.9-2.8.9-.1 0-.3.1-.4-.3 0 0-.8-2.8-.9-2.9 0 .2 0 .1 0 0 0-.2.1-.2.2-.2h.1zm-.1-.1c.1 0 3.2.6 3.3.6.1 0 .4 0 .5.4 0 .1.4 1.3.4 1.4.1.2 0 .5-.2.6-.1 0-2.8.9-2.8.9-.1 0-.5.2-.6-.3 0 0-.9-2.9-.9-3 0-.1-.1-.2-.1-.3 0-.4.4-.3.4-.3z"
            className="st3"
          ></path>
        </g>
      </g>
    </Svg>
  )
}

export default JCB
