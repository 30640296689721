import cn from 'classnames'

import {
  PartialAnamnesis,
  PartialBiometrics,
  PartialManagement,
  PartialEvolucaoSimples,
  PartialHypothesis,
  PartialExams,
  PartialDocuments,
  PartialAttachments,
  PartialSmartConsultation
} from '../../../layouts/partials'

export const orderByTypes = records => {
  const arrayOfTypes = records.map(({ type }) => type)

  let recordsByTypes = []

  arrayOfTypes.forEach(availableType =>
    recordsByTypes.push({
      type: availableType,
      data: records.find(({ type }) => type === availableType)
    })
  )

  return recordsByTypes
}

const RecordItem = ({ type, index, maxIndex, record }) => {
  switch (type) {
    case 'anamnese':
      return (
        <PartialAnamnesis
          info={record}
          indexOnData={index}
          maxIndex={maxIndex}
          isMultiple={maxIndex !== 1}
        />
      )
    case 'biometria':
      return (
        <PartialBiometrics
          info={record}
          indexOnData={index}
          maxIndex={maxIndex}
          isMultiple={maxIndex !== 1}
        />
      )
    case 'hipotese_diagnostica':
      return (
        <PartialHypothesis
          info={record}
          indexOnData={index}
          maxIndex={maxIndex}
          isMultiple={maxIndex !== 1}
        />
      )
    case 'conduta':
      return (
        <PartialManagement
          info={record}
          indexOnData={index}
          maxIndex={maxIndex}
          isMultiple={maxIndex !== 1}
        />
      )
    case 'solicitacao_exames':
      return (
        <PartialExams
          info={record}
          indexOnData={index}
          maxIndex={maxIndex}
          isMultiple={maxIndex !== 1}
        />
      )
    case 'documento':
      return (
        <PartialDocuments
          info={record}
          indexOnData={index}
          maxIndex={maxIndex}
          isMultiple={maxIndex !== 1}
        />
      )
    case 'conjunto_anexos':
      return (
        <PartialAttachments
          info={record}
          indexOnData={index}
          maxIndex={maxIndex}
          isMultiple={maxIndex !== 1}
        />
      )
    case 'evolucao_simples':
      return (
        <PartialEvolucaoSimples
          info={record}
          indexOnData={index}
          maxIndex={maxIndex}
          isMultiple={maxIndex !== 1}
        />
      )
    case 'smart_consultation':
      return (
        <PartialSmartConsultation
          info={record}
          indexOnData={index}
          maxIndex={maxIndex}
          isMultiple={maxIndex !== 1}
        />
      )
    default:
      return null
  }
}

export default function SummaryItem({ data }) {
  const records = orderByTypes(data)

  const isMultiple = records.length !== 1
  const maxIndex = records.length

  return (
    <div
      className={cn(
        'summary-card assina-shadow-sm',
        isMultiple && 'flex flex-col'
      )}
    >
      {records.map(({ type, data }, recordIndex) => (
        <RecordItem
          type={type}
          index={recordIndex + 1}
          maxIndex={maxIndex}
          record={data}
          key={data.id}
        />
      ))}
    </div>
  )
}
