import { useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'
import { toast, ToastContainer } from 'react-toastify'
import {
  IdentificationIcon,
  LocationMarkerIcon,
  PencilIcon,
  PhoneIcon,
  PlusCircleIcon,
  UserGroupIcon,
  UserIcon,
  DuplicateIcon
} from '@heroicons/react/solid'
import { DeepMap, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { Prompt } from 'react-router'
import searchCep, { CEP as ICEP } from 'cep-promise'
import cn from 'classnames'

import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import Button from 'components/ui/Button'
import KompaSpinner from 'components/ui/KompaSpinner'
import ControlledInput from 'components/elements/Forms/ControlledInput'
import ControlledMaskedInput from 'components/elements/Forms/ControlledMaskedInput'
import ControlledSelect from 'components/elements/Forms/ControlledSelect'
import ControlledSwitch from 'components/elements/Forms/ControlledSwitch'

import { updateUser } from 'services/admnistrativeService'

import bugsnag from 'lib/bugsnag'
import {
  formatOnlyNumbers,
  parseToUniversalDate,
  parseInputDate
} from 'utils/formaters'
import { dirtyValues, isObjectEmpty } from 'utils/helpers'
import { CEP_PROMISE_404_ERROR, FORM_FIELD_MESSAGES } from 'utils/constants'
import ToastStyles from 'utils/ToastStyles'
import {
  buyReasonOptions,
  dependencyRelationOptions,
  genderOptions,
  maritalStatusOptions,
  sexOptions
} from './enums'

import { AdminUser, AdminUserDependant } from 'types/AdminUsers'
import { AdminProfessional } from 'types/AdminProfessionals'

const newDependent = {
  id: -1,
  name: '',
  dependency_relationship: '',
  cpf: '',
  birthdate: '',
  email: ''
}

const valueOrEmptyString = (value: string | null | undefined) => value || ''

const atLeastNineValidator = (value: string) => {
  const formatedValue = value.replaceAll(/\D/g, '')

  if (formatedValue.length === 8) {
    return true
  }
  return false
}

interface OverviewFields
  extends Omit<
    AdminUser,
    | 'id'
    | 'plan'
    | 'subscription'
    | 'answered_karmen'
    | 'made_first_consultation_with_dedicated_doctor'
    | 'made_first_consultation_with_dedicated_nurse'
    | 'city'
  > {
  city?: string
  state?: string
  country?: string
  city_name?: string
  ded_doctor?: string
  ded_nurse?: string
}

interface OverviewLayoutProps {
  user: AdminUser
  healthProfessionals: {
    nurses: AdminProfessional[]
    doctors: AdminProfessional[]
  }
}

export default function OverviewLayout({
  user,
  healthProfessionals
}: OverviewLayoutProps) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, dirtyFields: dirtyData }
  } = useForm<OverviewFields>({
    mode: 'all',
    defaultValues: {
      ...user,
      name: valueOrEmptyString(user.name),
      cpf: valueOrEmptyString(user.cpf),
      rg: valueOrEmptyString(user.rg),
      birthdate: valueOrEmptyString(parseToUniversalDate(user.birthdate)),
      sex: valueOrEmptyString(user.sex),
      gender: valueOrEmptyString(user.gender),
      marital_status: valueOrEmptyString(user.marital_status),
      comments: valueOrEmptyString(user.comments),
      email: valueOrEmptyString(user.email),
      residential_phone: valueOrEmptyString(user.residential_phone),
      mobile_phone: valueOrEmptyString(user.mobile_phone),
      cep: valueOrEmptyString(user.cep),
      street: valueOrEmptyString(user.street),
      number: valueOrEmptyString(user.number),
      complement: valueOrEmptyString(user.complement),
      neighbour: valueOrEmptyString(user.neighbour),
      city: valueOrEmptyString(user.city?.name),
      state: valueOrEmptyString(user.city?.uf.name),
      country: valueOrEmptyString(user.city?.country.name),
      ded_doctor: user.dedicated_doctor?.id.toString() || '',
      ded_nurse: user.dedicated_nurse?.id.toString() || '',
      buy_reason: valueOrEmptyString(user.buy_reason)
    }
  })

  const { fields: dependentsFields, append: appendNewDependent } =
    useFieldArray<AdminUserDependant, 'idx'>({
      keyName: 'idx',
      control,
      name: 'dependents'
    })

  const [isCEPFilled, setIsCEPFilled] = useState<boolean>(!!user.cep)
  const [isCEPLoading, setIsCEPLoading] = useState<boolean>(false)
  const [cepError, setCepError] = useState<string>('')
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false)
  const [saveError, setSaveError] = useState<string>('')

  const queryClient = useQueryClient()

  const onSubmit: SubmitHandler<OverviewFields> = async data => {
    if (isSaveLoading || isCEPLoading) return

    setIsSaveLoading(true)
    setSaveError('')

    let allFields = data
    let dirtyFields = dirtyData

    if (allFields.dependents?.length !== 0) {
      const cleanDependents = allFields.dependents?.filter(
        ({ name, cpf, email, birthdate, dependency_relationship }) =>
          name !== '' &&
          cpf !== '' &&
          email !== '' &&
          birthdate !== '' &&
          dependency_relationship !== ''
      )

      const formatDependents = cleanDependents?.map(depedents => {
        return {
          ...depedents,
          cpf: formatOnlyNumbers(depedents.cpf)
        }
      })

      allFields = { ...allFields, dependents: formatDependents }
    }

    if (
      allFields.dependents?.length === 0 ||
      typeof allFields.dependents == 'undefined'
    ) {
      const newFields = allFields
      const newDirty = dirtyFields

      delete newFields.dependents
      delete newDirty.dependents

      allFields = newFields
      dirtyFields = newDirty
    }

    if (dirtyFields.city) {
      const newFields = { ...allFields, city_name: allFields.city }
      const newDirty: DeepMap<OverviewFields, true> = {
        ...dirtyFields,
        city_name: true
      }

      delete newFields.city
      delete newDirty.city

      allFields = newFields
      dirtyFields = newDirty
    }

    if (dirtyFields.ded_doctor) {
      const newFields = {
        ...allFields,
        dedicated_doctor: { id: Number(allFields.ded_doctor) }
      }
      const newDirty: DeepMap<OverviewFields, true> = {
        ...dirtyFields,
        dedicated_doctor: true
      }

      delete newFields.ded_doctor
      delete newDirty.ded_doctor

      allFields = newFields
      dirtyFields = newDirty
    }

    if (dirtyFields.ded_nurse) {
      const newFields = {
        ...allFields,
        dedicated_nurse: { id: Number(allFields.ded_nurse) }
      }
      const newDirty: DeepMap<OverviewFields, true> = {
        ...dirtyFields,
        dedicated_nurse: true
      }

      delete newFields.ded_nurse
      delete newDirty.ded_nurse

      allFields = newFields
      dirtyFields = newDirty
    }

    if (dirtyFields.cpf) {
      allFields = {
        ...allFields,
        cpf: formatOnlyNumbers(allFields.cpf) ?? ''
      }
    }

    if (dirtyFields.mobile_phone) {
      allFields = {
        ...allFields,
        mobile_phone: `+${formatOnlyNumbers(allFields.mobile_phone)}`
      }
    }

    if (dirtyFields.cep && allFields.cep) {
      allFields = {
        ...allFields,
        cep: formatOnlyNumbers(allFields.cep)
      }
    }

    const cleanFields: any = dirtyValues(
      dirtyFields,
      //@ts-ignore
      allFields
    )

    try {
      if (isObjectEmpty(cleanFields)) {
        return toast('Usuário atualizado com sucesso!', ToastStyles)
      }

      await updateUser({
        id: user.id,
        ...cleanFields
      })

      await queryClient.invalidateQueries(['user', String(user.id)])

      toast('Usuário atualizado com sucesso!', ToastStyles)
    } catch (error: any) {
      bugsnag.notify(`Error on updating user: ${user.id} - Error: ${error})}`)

      toast('Erro ao atualizar usuário!', ToastStyles)
    } finally {
      setIsSaveLoading(false)
    }
  }

  const isFormDirty = useCallback(
    () => Object.keys(dirtyData).length !== 0,
    [dirtyData]
  )

  const isFormError = useCallback(
    () => Object.keys(errors).length !== 0,
    [errors]
  )

  const handleSearchCep = async (inputCEP: string) => {
    if (isCEPLoading) return

    setIsCEPLoading(true)
    setCepError('')

    try {
      const { cep, street, neighborhood, city, state }: ICEP = await searchCep(
        inputCEP
      )

      setIsCEPLoading(false)
      setIsCEPFilled(true)

      setValue('cep', cep, { shouldDirty: true })
      setValue('street', street, { shouldDirty: true })
      setValue('neighbour', neighborhood, { shouldDirty: true })
      setValue('city', city, { shouldDirty: true })
      setValue('state', state, { shouldDirty: true })
      setValue('country', 'Brasil', { shouldDirty: true })
    } catch (error: any) {
      if (error.message === CEP_PROMISE_404_ERROR) {
        setIsCEPFilled(false)

        setValue('cep', '', { shouldDirty: false })
        setValue('street', '', { shouldDirty: false })
        setValue('neighbour', '', { shouldDirty: false })
        setValue('city', '', { shouldDirty: false })
        setValue('state', '', { shouldDirty: false })
        setValue('country', '', { shouldDirty: false })

        return toast('CEP não encontrado!', ToastStyles)
      }
      bugsnag.notify(
        `Error on fetching <Administrative> cep: ${JSON.stringify(error)}`
      )
      return toast('Não foi possível pesquisar esse CEP!', ToastStyles)
    } finally {
      setIsCEPLoading(false)
    }
  }

  const copyHolderData = () => {
    const dependents = getValues().dependents

    const newDependents = dependents?.map(
      ({ id, name, dependency_relationship, cpf, birthdate, email }) => ({
        id,
        name,
        dependency_relationship,
        cpf,
        birthdate: birthdate && parseInputDate(birthdate),
        email: user.email
      })
    )

    setValue('dependents', newDependents, { shouldDirty: true })
  }

  const doctors = healthProfessionals.doctors.map(({ id, name }) => ({
    value: id,
    label: name
  }))

  const nurses = healthProfessionals.nurses.map(({ id, name }) => ({
    value: id,
    label: name
  }))

  // TODO: Refactor these into components with the controller

  return (
    <>
      <ToastContainer />

      <Prompt
        when={isFormDirty()}
        message="Você tem alterações não salvas, deseja mesmo sair?"
      />

      <div className="flex w-full justify-end">
        {saveError && (
          <Text className="self-end mb-2 font-semibold">{saveError}</Text>
        )}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {isFormError() && (
          <div className="flex w-full justify-end text-warning-dark font-semibold">
            <Heading>Corrija os erros do formulário</Heading>
          </div>
        )}
        <div className="flex justify-between items-center mt-4 pb-6 border-b-2 border-gray-500">
          <div className="flex space-x-4">
            <IdentificationIcon className="h-6 text-pink-600" />
            <Heading className="font-semibold text-gray-800 text-base">
              Informações básicas
            </Heading>
          </div>
          <Button type="submit" disabled={!isFormDirty()} className="px-4">
            {!isSaveLoading && <PencilIcon className="h-5 mr-2" />}
            {isSaveLoading ? (
              <KompaSpinner color="white" />
            ) : (
              <span>Salvar alterações</span>
            )}
          </Button>
        </div>

        <div className="mt-6 mb-10 space-y-4">
          <div className="grid grid-cols-3 gap-3">
            <ControlledInput
              control={control}
              name="name"
              label="Nome"
              placeholder="Nome"
              fullWidth
              inputColor="gray"
              rules={{
                required: true
              }}
              error={!!errors.name}
              errorMessage={`${
                errors.name?.type === 'required' &&
                FORM_FIELD_MESSAGES.REQUIRED_FIELD
              }`}
            />
            <ControlledMaskedInput
              control={control}
              name="cpf"
              label="CPF"
              placeholder="CPF"
              fullWidth
              format="###.###.###-##"
              mask="_"
              inputColor="gray"
              rules={{
                required: true
              }}
              error={!!errors.cpf}
              errorMessage={`${
                errors.cpf?.type === 'required' &&
                FORM_FIELD_MESSAGES.REQUIRED_FIELD
              }`}
            />
            <ControlledInput
              control={control}
              name="rg"
              label="RG"
              placeholder="RG"
              fullWidth
              inputColor="gray"
            />
          </div>

          <div className="grid grid-cols-4 gap-3">
            <ControlledInput
              control={control}
              name="birthdate"
              label="Data de nascimento"
              placeholder="Data de nascimento"
              type="date"
              fullWidth
              inputColor="gray"
              rules={{
                required: true
              }}
              error={!!errors.birthdate}
              errorMessage={`${
                errors.birthdate?.type === 'required' &&
                FORM_FIELD_MESSAGES.REQUIRED_FIELD
              }`}
            />
            <ControlledSelect
              control={control}
              name="sex"
              label="Sexo biológico"
              options={sexOptions}
              defaultOption="Nenhum"
              displayEmpty
              fullWidth
              inputColor="gray"
            />
            <ControlledSelect
              control={control}
              name="gender"
              label="Gênero"
              options={genderOptions}
              defaultOption="Nenhum"
              displayEmpty
              fullWidth
              inputColor="gray"
            />
            <ControlledSelect
              control={control}
              name="marital_status"
              label="Estado civil"
              options={maritalStatusOptions}
              defaultOption="Nenhum"
              displayEmpty
              fullWidth
              inputColor="gray"
            />
          </div>

          <div className="grid grid-cols-1">
            <ControlledInput
              control={control}
              name="comments"
              label="Observações"
              placeholder="Observações"
              fullWidth
              inputColor="gray"
              multiline
              rows={4}
              inputProps={{ maxLength: 300 }}
            />
          </div>
        </div>

        <div className="flex items-center mt-4 pb-6 border-b-2 border-gray-500 space-x-4">
          <PhoneIcon className="h-6 text-pink-600" />
          <Heading className="font-semibold text-gray-800 text-base ">
            Contato
          </Heading>
        </div>

        <div className="mt-6 mb-10 space-y-4">
          <div className="grid grid-cols-4 gap-3">
            <ControlledInput
              wrapperClassName="col-span-2"
              control={control}
              name="email"
              label="E-mail"
              placeholder="E-mail"
              fullWidth
              inputColor="gray"
              rules={{
                required: true
              }}
              error={!!errors.email}
              errorMessage={`${
                errors.email?.type === 'required' &&
                FORM_FIELD_MESSAGES.REQUIRED_FIELD
              }`}
            />
            <ControlledInput
              control={control}
              name="residential_phone"
              label="Telefone"
              placeholder="Telefone"
              fullWidth
              inputColor="gray"
            />
            <ControlledMaskedInput
              control={control}
              name="mobile_phone"
              label="Celular"
              placeholder="Celular"
              fullWidth
              inputColor="gray"
              format="+## (##) #####-####"
              mask="_"
              rules={{
                required: true
              }}
              error={!!errors.mobile_phone}
              errorMessage={`${
                errors.mobile_phone?.type === 'required' &&
                FORM_FIELD_MESSAGES.REQUIRED_FIELD
              }`}
            />
          </div>
        </div>

        <div className="flex items-center mt-4 pb-6 border-b-2 border-gray-500 space-x-4">
          <LocationMarkerIcon className="h-6 text-pink-600" />
          <Heading className="font-semibold text-gray-800 text-base ">
            Endereço
          </Heading>
        </div>

        <div className="mt-6 mb-10">
          <div className="space-x-4 flex">
            <ControlledMaskedInput
              control={control}
              name="cep"
              label="CEP"
              placeholder="CEP"
              fullWidth
              inputColor="gray"
              format="#####-###"
              mask="_"
              rules={{
                validate: {
                  atLeastNine: atLeastNineValidator
                }
              }}
              error={!!errors.cep}
              errorMessage={`${
                errors.cep?.type === 'atLeastNine' &&
                FORM_FIELD_MESSAGES.INVALID_FIELD
              }`}
              onChange={e => {
                const formatedValue = e.target.value.replaceAll(/\D/g, '')
                if (formatedValue.length === 8) handleSearchCep(formatedValue)
              }}
            />
            {isCEPLoading && (
              <KompaSpinner
                color="primary"
                style={{ alignSelf: 'flex-end', marginBottom: '10px' }}
              />
            )}
            {cepError && (
              <Text className="self-end mb-2 font-semibold">{cepError}</Text>
            )}
          </div>
          <div
            className={cn(
              'space-y-4 mt-4',
              !isCEPFilled && 'opacity-25 pointer-events-none'
            )}
          >
            <div className="grid grid-cols-4 gap-3">
              <ControlledInput
                control={control}
                name="street"
                label="Logradouro"
                placeholder="Logradouro"
                fullWidth
                inputColor="gray"
                wrapperClassName="col-span-2"
              />
              <ControlledInput
                control={control}
                name="number"
                label="Número"
                placeholder="Número"
                fullWidth
                inputColor="gray"
              />
              <ControlledInput
                control={control}
                name="complement"
                label="Complemento"
                placeholder="Complemento"
                fullWidth
                inputColor="gray"
              />
            </div>

            <div className="grid grid-cols-4 gap-3">
              <ControlledInput
                control={control}
                name="neighbour"
                label="Bairro"
                placeholder="Bairro"
                fullWidth
                inputColor="gray"
              />
              <ControlledInput
                control={control}
                name="city"
                label="Cidade"
                placeholder="Cidade"
                fullWidth
                inputColor="gray"
              />
              <ControlledInput
                control={control}
                name="state"
                label="Estado"
                placeholder="Estado"
                fullWidth
                inputColor="gray"
              />
              <ControlledInput
                control={control}
                name="country"
                label="País"
                placeholder="País"
                fullWidth
                inputColor="gray"
              />
            </div>
          </div>
        </div>

        <div className="flex items-center mt-4 pb-6 border-b-2 border-gray-500 space-x-4">
          <UserIcon className="h-6 text-pink-600" />
          <Heading className="font-semibold text-gray-800 text-base ">
            Equipe de saúde
          </Heading>
        </div>

        <div className="mt-6 mb-10 space-y-4">
          <div className="grid grid-cols-3 gap-3">
            <ControlledSelect
              control={control}
              name="ded_doctor"
              label="Médico dedicado"
              options={doctors}
              defaultOption="Nenhum"
              displayEmpty
              fullWidth
              inputColor="gray"
            />
            <ControlledSelect
              control={control}
              name="ded_nurse"
              label="Enfermeira dedicada"
              options={nurses}
              defaultOption="Nenhum"
              displayEmpty
              fullWidth
              inputColor="gray"
            />
            <ControlledSelect
              control={control}
              name="buy_reason"
              label="Motivo da assinatura"
              options={buyReasonOptions}
              defaultOption="Nenhum"
              displayEmpty
              fullWidth
              inputColor="gray"
            />
          </div>
        </div>

        {/* If the user has holder, its a dependent, else is a holder */}
        {user.holder ? (
          <>
            <div className="flex items-center mt-4 pb-6 border-b-2 border-gray-500 space-x-4">
              <UserGroupIcon className="h-6 text-pink-600" />
              <Heading className="font-semibold text-gray-800 text-base ">
                Titular
              </Heading>
            </div>
            <div className="mt-6 mb-10 space-y-4">
              <Text>Nome: {user.holder.name}</Text>
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center mt-4 pb-6 border-b-2 border-gray-500 space-x-4">
              <UserGroupIcon className="h-6 text-pink-600" />
              <Heading className="font-semibold text-gray-800 text-base ">
                Dependentes
              </Heading>
            </div>

            <div className="mt-6 mb-10 space-y-4">
              {dependentsFields.length !== 0 ? (
                <Button
                  color="transparent"
                  className="text-pink-600"
                  type="button"
                  onClick={copyHolderData}
                >
                  <DuplicateIcon className="h-6 mr-2" />
                  Copiar dados do titular
                </Button>
              ) : (
                <Text>Usuário não possui dependentes</Text>
              )}
              {dependentsFields.map((item, index) => (
                <div key={item.idx} className="space-y-2">
                  <div className="grid grid-cols-2 gap-3">
                    <input
                      type="hidden"
                      name={`dependents[${index}].id`}
                      value={item.id}
                      ref={register}
                    />
                    <ControlledInput
                      control={control}
                      name={`dependents[${index}].name`}
                      label="Nome"
                      placeholder="Nome"
                      fullWidth
                      inputColor="gray"
                      controllerDefaultValue={item.name}
                      rules={{ required: true }}
                      error={
                        errors.dependents && !!errors.dependents[index]?.name
                      }
                      errorMessage={`${
                        errors.dependents &&
                        errors.dependents[index]?.name?.type === 'required' &&
                        FORM_FIELD_MESSAGES.REQUIRED_FIELD
                      }`}
                    />
                    <ControlledSelect
                      control={control}
                      name={`dependents[${index}].dependency_relationship`}
                      label="Relação de dependencia"
                      options={dependencyRelationOptions}
                      defaultOption="Nenhum"
                      displayEmpty
                      fullWidth
                      inputColor="gray"
                      controllerDefaultValue={item.dependency_relationship}
                      rules={{ required: true }}
                      error={
                        errors.dependents &&
                        !!errors.dependents[index]?.dependency_relationship
                      }
                      errorMessage={`${
                        errors.dependents &&
                        errors.dependents[index]?.dependency_relationship
                          ?.type === 'required' &&
                        FORM_FIELD_MESSAGES.REQUIRED_FIELD
                      }`}
                    />
                  </div>

                  <div className="grid grid-cols-3 gap-3">
                    <ControlledMaskedInput
                      control={control}
                      name={`dependents[${index}].cpf`}
                      label="CPF"
                      placeholder="CPF"
                      fullWidth
                      inputColor="gray"
                      format="###.###.###-##"
                      mask="_"
                      controllerDefaultValue={item.cpf}
                      rules={{ required: true }}
                      error={
                        errors.dependents && !!errors.dependents[index]?.cpf
                      }
                      errorMessage={`${
                        errors.dependents &&
                        errors.dependents[index]?.cpf?.type === 'required' &&
                        FORM_FIELD_MESSAGES.REQUIRED_FIELD
                      }`}
                    />
                    <ControlledInput
                      control={control}
                      name={`dependents[${index}].birthdate`}
                      label="Data nasc."
                      placeholder="Data nasc."
                      type="date"
                      fullWidth
                      inputColor="gray"
                      controllerDefaultValue={
                        item.birthdate && parseToUniversalDate(item.birthdate)
                      }
                      rules={{ required: true }}
                      error={
                        errors.dependents &&
                        !!errors.dependents[index]?.birthdate
                      }
                      errorMessage={`${
                        errors.dependents &&
                        errors.dependents[index]?.birthdate?.type ===
                          'required' &&
                        FORM_FIELD_MESSAGES.REQUIRED_FIELD
                      }`}
                    />
                    <ControlledInput
                      control={control}
                      name={`dependents[${index}].email`}
                      label="E-mail"
                      placeholder="E-mail"
                      fullWidth
                      inputColor="gray"
                      controllerDefaultValue={item.email}
                      rules={{ required: true }}
                      error={
                        errors.dependents && !!errors.dependents[index]?.email
                      }
                      errorMessage={`${
                        errors.dependents &&
                        errors.dependents[index]?.email?.type === 'required' &&
                        FORM_FIELD_MESSAGES.REQUIRED_FIELD
                      }`}
                    />
                  </div>
                </div>
              ))}

              <Button
                color="transparent"
                className="text-pink-600"
                type="button"
                onClick={() => appendNewDependent(newDependent, true)}
              >
                <PlusCircleIcon className="h-6 mr-2" />
                {user.dependents?.length === 0
                  ? 'Adicionar dependente'
                  : 'Adicionar outro dependente'}
              </Button>
            </div>
          </>
        )}

        <div className="flex items-center mt-4 pb-6 border-b-2 border-gray-500 space-x-4">
          <UserGroupIcon className="h-6 text-pink-600" />
          <Heading className="font-semibold text-gray-800 text-base ">
            Acessos
          </Heading>
        </div>

        <div className="mt-6 mb-10 space-y-4">
          <ControlledSwitch
            control={control}
            name="is_active"
            label="Habilitado"
            labelPlacement="top"
            color="primary"
          />
          <Text className="text-base text-gray-700">
            Define se o usuário pode acessar o sistema. Desabilite isto em vez
            de excluir o usuário.
          </Text>
        </div>

        <Button type="submit" disabled={!isFormDirty()} className="px-4">
          {!isSaveLoading && <PencilIcon className="h-5 mr-2" />}
          {isSaveLoading ? (
            <KompaSpinner size="24px" color="white" />
          ) : (
            'Salvar alterações'
          )}
        </Button>
      </form>
    </>
  )
}
