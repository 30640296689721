import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { AnimatedDiv, Section, Check } from '../../../ui'
import SectionTitle from '../../SectionTitle'
import Subscription from '../Subscription'
import { GlobalContext } from '../../../../contexts/GlobalContext'

import { Parent, Item } from './Animate'
import Skeleton from './Skeleton'

import { PremiumCard } from '../../../Plans'
import { APP_URLS } from 'urls'
import DetailsView from '../DetailsView'
import { PaymentView } from 'components/Profile/Payment'
import { ModalContext } from 'contexts/ModalContext'
import { getUserSubscriptions } from 'services/vindi'
import MessagePrompt from 'components/Profile/MessagePrompt'
import FeatureTitle from 'components/common/FeatureTitle'

const FreePlan = {
  plan: 'Assinatura Free',
  product_items: [{ status: 'active' }],
  price_with_discount: 0,
  id: 'Free'
}

const Billing = ({ browserHistory, userSubscriptions, freeUser, holder }) => {
  const {
    user: { name }
  } = useContext(GlobalContext)

  return (
    <div className="flex flex-col justify-center items-center py-6 px-4">
      <AnimatedDiv
        layout
        variants={Parent}
        initial="hidden"
        animate="show"
        className="max-w-xl w-full"
      >
        {holder ? (
          <div className="text-center px-6">
            <h1 className="text-2xl text-pink-700">
              Para ter acesso às informações sobre o seu plano acesse como o
              usuário titular!
            </h1>
          </div>
        ) : freeUser ? (
          <AnimatedDiv layout variants={Item}>
            <SectionTitle
              title="Planos"
              description="Seus planos contratados"
              className="pb-4"
            />
            <Subscription details={FreePlan} browserHistory={browserHistory} />
            <Section className="rounded-md px-4">
              <H1 className="font-semibold text-xl text-center">
                Plano Gratuito
              </H1>
              <Check className="text-gray-800">
                Apoio Agendamento de consultas por telemedicina
              </Check>
              <Check className="text-gray-800">
                Agendamento de consultas presenciais
              </Check>
              <Check className="text-gray-800">Receitas médicas online</Check>
              <Check className="text-gray-800">Pedidos de exames online</Check>
              <Check className="text-gray-800">Historico de saúde online</Check>
              <Check className="text-gray-800">Alerta de medicamentos</Check>
            </Section>
            <PremiumCard className="mt-4 rounded-md" full />
          </AnimatedDiv>
        ) : userSubscriptions ? (
          <>
            <AnimatedDiv layout variants={Item}>
              <Section className="rounded-md text-center">
                <h1 className="text-xl text-teal-600">{name}</h1>
                <div className="flex justify-between mx-auto max-w-xs mt-4 px-4 text-lg text-gray-900">
                  <span>Assinante desde:</span>
                  <span>
                    {format(
                      new Date(userSubscriptions[0].start_at),
                      'dd/MM/yyyy'
                    )}
                  </span>
                </div>
              </Section>
            </AnimatedDiv>

            <AnimatedDiv layout variants={Item}>
              <SectionTitle
                title="Planos"
                description="Seus planos contratados"
                className="pt-6 pb-4"
              />
              <AnimatedDiv layout variants={Parent}>
                {userSubscriptions.map(item => (
                  <AnimatedDiv layout variants={Item} key={item.id}>
                    <Subscription
                      details={item}
                      browserHistory={browserHistory}
                      seeDetails
                    />
                  </AnimatedDiv>
                ))}
              </AnimatedDiv>
            </AnimatedDiv>
          </>
        ) : (
          <Skeleton />
        )}
      </AnimatedDiv>
    </div>
  )
}

const BillingView = () => {
  const { handleModal, setCloseButton } = useContext(ModalContext)
  const { isFree, holder } = useContext(GlobalContext)

  const [userSubscriptions, setUserSubscriptions] = useState()

  const history = useHistory()
  const location = useLocation()

  const loadUserSubscriptions = async () => {
    setCloseButton(false)

    try {
      const res = await getUserSubscriptions()
      setUserSubscriptions(res)
    } catch (error) {
      handleModal(
        <MessagePrompt
          error
          message="Ocorreu um erro durante a sua requisição."
          errorAction={() => window.location.reload()}
        />
      )
    }
  }

  useEffect(() => {
    if (isFree() || holder) return
    loadUserSubscriptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex flex-col h-full w-full bg-gray-300">
      <FeatureTitle feature={location.pathname} />
      <Switch>
        <Route exact path={APP_URLS.BILLING}>
          <Billing
            freeUser={isFree()}
            holder={holder}
            browserHistory={history}
            userSubscriptions={userSubscriptions}
          />
        </Route>
        <Route path={APP_URLS.BILLING_DETAILS}>
          <DetailsView
            browserHistory={history}
            userSubscriptions={userSubscriptions}
          />
        </Route>
        <Route path={APP_URLS.CHANGE_PAYMENT_METHOD}>
          <PaymentView
            userSubscriptions={userSubscriptions}
            browserHistory={history}
          />
        </Route>
      </Switch>
    </div>
  )
}

export default BillingView

const H1 = styled.div`
  color: #540620;
`
