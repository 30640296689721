import { SummarySection, Chip } from '../../elements'

const PartialSmartConsultation = ({
  info,
  isMultiple,
  indexOnData,
  maxIndex
}) => {
  return Object.keys(info).length !== 0 ? (
    <>
      <SummarySection
        createdBy={info.created_by.nome}
        date={info.created_at.split(' ')[1]}
        title="Consulta inteligente"
        isMultiple={isMultiple}
        indexOnData={indexOnData}
        maxIndex={maxIndex}
      >
        <div className="description-fields">
          {info.status ? (
            <div>
              <h3>Status</h3>
              <p>{info.status}</p>
            </div>
          ) : null}
          {info.doctor_observation ? (
            <div>
              <h3>Avaliação médica</h3>
              {info.doctor_observation.split('\r\n\r\n').map(t => (
                <p>{t}</p>
              ))}
            </div>
          ) : null}
        </div>
      </SummarySection>
    </>
  ) : null
}

export default PartialSmartConsultation
