import {
  differenceInMilliseconds,
  format,
  intervalToDuration,
  parseISO
} from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { REGEXP } from './constants'

export const parseDateHour = (date: string) => {
  const splitHours = date.split(' ')
  const splitDashes = splitHours[0].split('/')

  return parseISO(
    `${splitDashes[2]}-${splitDashes[1]}-${splitDashes[0]} ${splitHours[1]}`
  )
}

export const splitAndParseDate = (date: string): Date => {
  const splited = date.split('/')
  return parseISO(`${splited[2]}-${splited[1]}-${splited[0]}`)
}

export const formatDate = (date: Date, formatTo: string): string => {
  const formated = format(date, formatTo, { locale: ptBR })

  return formated
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .replaceAll('De', 'de')
    .replaceAll('Às', 'às')
}

export const parseInputDate = (date: string) => {
  const splited = date.split('-')
  return `${splited[2]}/${splited[1]}/${splited[0]}`
}

export const parseToUniversalDate = (date: string) => {
  const splited = date.split('/')
  return `${splited[2]}-${splited[1]}-${splited[0]}`
}

export const parseMillisecondsIntoHours = (milliseconds: number) => {
  //Get hours from milliseconds
  let hours = milliseconds / (1000 * 60 * 60)
  let absoluteHours = Math.floor(hours)
  let h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours

  //Get remainder from hours and convert to minutes
  let minutes = (hours - absoluteHours) * 60
  let absoluteMinutes = Math.floor(minutes)
  let m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes

  //Get remainder from minutes and convert to seconds
  let seconds = (minutes - absoluteMinutes) * 60
  let absoluteSeconds = Math.floor(seconds)
  let s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds

  return h + ':' + m + ':' + s
}

export const elapsedHours = (date: string) => {
  const createdDate = parseDateHour(date)
  const dateNow = new Date()

  const millisecondsDifference = differenceInMilliseconds(dateNow, createdDate)

  const splited = parseMillisecondsIntoHours(millisecondsDifference).split(':')

  return `${splited[0]}:${splited[1]}`
}

export const getFirstName = (name: string | undefined) => name?.split(' ')[0]

const mobilePrefix = '9'
const countryCodePrefix = '55'

export const formatMobilePhone = (number: string) => {
  // Remove special characters
  let formated: string = number.replaceAll(/\D/g, '')

  if (formated.length === 11) {
    formated = `${countryCodePrefix}${formated}`
  }

  if (number.length === 9) {
    const restNumbers = formated.slice(1, formated.length)
    const firstFour = restNumbers.slice(0, 4)
    const lastFour = restNumbers.slice(4, restNumbers.length)

    formated = `${mobilePrefix} ${firstFour}-${lastFour}`

    return formated
  }

  formated = formated.slice(2, formated.length)

  const areaCode = formated.slice(0, 2)

  const restNumbers = formated.slice(3, formated.length)
  const firstFour = restNumbers.slice(0, 4)
  const lastFour = restNumbers.slice(4, restNumbers.length)

  formated = `(${areaCode}) ${mobilePrefix} ${firstFour}-${lastFour}`

  return formated
}

export const formatFullAge = (age: string) => {
  const now = new Date()

  const { years, months, days } = intervalToDuration({
    start: now,
    end: splitAndParseDate(age)
  })

  return `${years} anos, ${months} meses e ${days} dias`
}

export const formatFileName = (name: string) => {
  if (name && name.length > 17) {
    const len = name.length
    const idx = name.lastIndexOf('.')
    return (
      name.substring(0, idx - 1).substr(0, 14) +
      '...' +
      name.substr((len - 1 - idx) * -1)
    )
  }
  return name
}

export const formatOnlyNumbers = (field: string | null | undefined) => {
  return field ? field.replaceAll(/\D/g, '') : null
}

export const formatCPF = (cpfNumber: string) =>
  cpfNumber
    ? cpfNumber.length === 11
      ? cpfNumber.replace(REGEXP.FORMAT_CPF, '$1.$2.$3-$4')
      : '-'
    : '-'
