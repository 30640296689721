/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { backendServices, vindiAPI } from './AxiosInstances'

export const getUserSubscriptions = async () => {
  const res = await backendServices
    .get('api/v1/user/subscriptions')
    .then(({ data }) => data.data)
    .catch(error => {
      throw new Error(error)
    })

  return res
}

export const getUserBillsById = async (subscription_id, offsets) => {
  let params = { subscription_id }

  if (offsets) params = { ...params, offsets }

  const res = await backendServices
    .get('/api/v1/subscriptions/bills', {
      params
    })
    .then(({ data }) => data.data)
    .catch(error => {
      throw new Error(error)
    })

  return res
}

export const subscribeCreditCard = async data => {
  try {
    const vindiToken = await vindiAPI
      .post('/payment_profiles', data)
      .then(({ data }) => data)

    const confirmChange = await backendServices
      .post('/api/v1/subscriptions/payment-profile/upgrade', {
        payment_method_code: 'credit_card',
        gateway_token: vindiToken.payment_profile.gateway_token,
        customer_id: data.customer_id,
        subscriptions_id: data.subscriptions_id
      })
      .then(({ data }) => data)

    return confirmChange
  } catch (error) {
    throw new Error(error)
  }
}

export const addNewCreditCard = async data => {
  try {
    const confirmChange = await vindiAPI.post('/public/payment_profiles', data)
    const { gateway_token } = confirmChange.data.payment_profile
    const confirmedData = await backendServices.post(
      '/api/v1/user/payment-profiles',
      {
        gateway_token: gateway_token,
        payment_method_code: 'credit_card'
      }
    )
    return { ...confirmedData.data, ok: true }
  } catch (error) {
    throw new Error(error)
  }
}
