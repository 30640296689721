import { useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import Select from 'react-select'
import MaskedInput from 'react-input-mask'
import cn from 'classnames'
import { EyeIcon } from '@heroicons/react/outline'

import Head from 'components/common/Head'
import LogoHorizontal from 'components/ui/Logo/LogoHorizontal'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import Button from 'components/ui/Button'

import LoginImage from 'assets/images/LoginPage/login-side-image.png'

import useAuth from 'hooks/useAuth'

import ISignIn from 'types/SignIn'

import { usernameTypeSelectStyles, usernameTypeSelectTheme } from './styles'

const enterOptions = [
  { label: 'CPF', value: '0' },
  { label: 'Email', value: '1' }
]

const trimRegexp = /[^0-9]/g

export default function LoginPage() {
  const { register, handleSubmit, setValue, errors, control } =
    useForm<ISignIn>({
      mode: 'onSubmit'
    })

  const { signIn, isLoading, error } = useAuth()

  const [usernameType, setUsernameType] = useState<string>(
    enterOptions[0].label
  )
  const [peepPassword, setPeeppassword] = useState<boolean>(false)

  const onSubmit: SubmitHandler<ISignIn> = ({ username, password }) =>
    signIn({
      username:
        usernameType === 'CPF'
          ? username.replace(trimRegexp, '')
          : username.trim(),
      password
    })

  const changeUsernameType = (newType: string) => {
    setUsernameType(newType)
    setValue('username', '')
  }

  const errorStyle = 'text-base text-gray-100 font-medium ml-2'

  return (
    <>
      <Head title="Entre na sua conta" />
      <div className="bg-pink-600 min-h-screen grid grid-cols-1 md:grid-cols-2">
        <div className="hidden md:inline h-full max-h-screen">
          <img
            src={LoginImage}
            alt="Bem vindo"
            className="object-cover object-right-top h-full max-h-full"
          />
        </div>
        <div className="flex items-center justify-center">
          <div className="max-w-md px-8">
            <LogoHorizontal className="mx-auto" />

            <div className="py-8 text-center">
              <Heading className="text-2xl text-gray-200 font-bold pb-4">
                É bom ter você aqui!
              </Heading>

              <Text className="text-sm text-gray-200 font-medium leading-snug">
                Faça login com a sua conta e facilite a forma de cuidar da sua
                saúde.
              </Text>
            </div>

            {error && (
              <div className="bg-pink-700 text-center text-gray-100 font-medium text-lg p-4 mb-8 rounded">
                <Text>{error}</Text>
              </div>
            )}

            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
              <div className="flex flex-col">
                <label htmlFor="username">
                  <Text className="text-base text-gray-200 font-base">
                    CPF ou E-mail
                  </Text>
                </label>
                <div className="flex mt-1">
                  <Select
                    options={enterOptions}
                    defaultValue={enterOptions[0]}
                    isSearchable={false}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    styles={usernameTypeSelectStyles}
                    theme={usernameTypeSelectTheme}
                    // @ts-ignore
                    onChange={({ label }) => changeUsernameType(label)}
                  />
                  {usernameType === 'CPF' ? (
                    <Controller
                      as={MaskedInput}
                      control={control}
                      mask="999.999.999-99"
                      id="username"
                      type="text"
                      placeholder="123.456.789-10"
                      className="bg-gray-100 w-full h-12 rounded-r placeholder-gray-600 p-4 text-base focus:outline-none"
                      name="username"
                      rules={{
                        required: true,
                        validate: {
                          atLeastEleven: (value = '') =>
                            value.replace(trimRegexp, '').length === 11
                        }
                      }}
                    />
                  ) : (
                    <input
                      id="username"
                      type="text"
                      placeholder="email@exemplo.com.br"
                      className="bg-gray-100 w-full h-12 rounded-r placeholder-gray-600 p-4 text-base focus:outline-none"
                      name="username"
                      ref={register({
                        required: true,
                        validate: {
                          atLeastTen: (value = '') => value.trim().length > 10
                        }
                      })}
                    />
                  )}
                </div>
              </div>

              {errors.username?.type === 'required' && (
                <Text className={errorStyle}>Campo obrigatório</Text>
              )}

              {errors.username?.type === 'atLeastEleven' && (
                <Text className={errorStyle}>CPF não parece válido</Text>
              )}

              {errors.username?.type === 'atLeastTen' && (
                <Text className={errorStyle}>Email não parece válido</Text>
              )}

              <div className="mt-4 mb-10">
                <label>
                  <Text className="text-base text-gray-200 font-base">
                    Senha
                  </Text>
                  <div className="flex items-center bg-gray-100 rounded mt-1">
                    <input
                      type={peepPassword ? 'text' : 'password'}
                      placeholder="***********"
                      className="bg-gray-100 w-full h-12 rounded placeholder-gray-600 p-4 text-base focus:outline-none"
                      name="password"
                      ref={register({ required: true })}
                    />
                    <EyeIcon
                      className={cn(
                        'stroke-current cursor-pointer mx-4 h-8',
                        peepPassword ? 'text-pink-600' : 'text-gray-600'
                      )}
                      onClick={() => setPeeppassword(!peepPassword)}
                    />
                  </div>
                </label>

                {errors.password && (
                  <Text className={errorStyle}>Campo obrigatório</Text>
                )}
              </div>

              <Button
                type="submit"
                color="white"
                className="font-semibold mx-auto px-8"
                loading={isLoading}
              >
                Entrar
              </Button>
            </form>

            <div className="text-center mt-8 space-y-4">
              <Heading
                className="text-base text-gray-200 font-semibold cursor-pointer"
                as="h4"
              >
                Recuperar minha senha
              </Heading>

              <hr
                className="bg-gray-200 opacity-50 mx-auto"
                style={{ maxWidth: '150px' }}
              />

              <Heading
                className="text-base text-gray-200 font-base cursor-pointer"
                as="h4"
              >
                Não tem um plano?{' '}
                <span className="font-semibold">Saiba mais</span>
              </Heading>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
