import { Input, Icon } from '.'

import PDF from '../../assets/images/pdf.png'

import FilePreviewPrompt from 'components/layouts/FilePreviewPrompt'
import { useModalContext } from 'contexts/ModalContext'
import { ZoomInIcon, XIcon } from '@heroicons/react/outline'

const ImagePreview = ({ url, name, exameType, key, file, exclude }) => {
  const { handleModal, setCloseButton } = useModalContext()

  return (
    <div key={key}>
      <div className="flex gap-3">
        <div
          className="relative cursor-pointer"
          onClick={() => {
            setCloseButton(false)
            handleModal(
              <FilePreviewPrompt file={{ arquivo: file.preview, nome: name }} />
            )
          }}
        >
          <img
            src={url.includes('pdf') ? PDF : url}
            alt="preview"
            className="object-cover rounded-md "
            style={{
              maxHeight: 48,
              maxWidth: 48,
              minHeight: 48,
              minWidth: 48,
              background:
                'linear-gradient(180deg, rgba(23, 23, 23, 0) 0%, rgba(0, 0, 0, 0.38) 118%)'
            }}
          />
          <ZoomInIcon
            className="w-6 h-6 text-white absolute"
            style={{
              top: 15,
              left: 15
            }}
          />
        </div>
        <div className="w-full">
          <p className="font-bold text-sm text-gray-900">{name}</p>
          <p className="text-base text-gray-700">{exameType}</p>
        </div>
        <div
          className="w-8 h-8 bg-gray-100 flex items-center justify-center cursor-pointer"
          style={{
            minWidth: 32,
            minHeight: 32,
            borderRadius: 25.6
          }}
          onClick={exclude}
        >
          <XIcon className="w-4 h-4 text-pink-600" />
        </div>
      </div>
    </div>
  )
}

export default ImagePreview
