import {
  Dialog as MUIDialog,
  DialogProps as MUIDialogProps
} from '@material-ui/core'

interface DialogProps extends MUIDialogProps {
  bodyClassName?: string
  bodyId?: string
}

export default function Dialog({
  bodyClassName,
  bodyId,
  children,
  ...rest
}: DialogProps) {
  return (
    <MUIDialog {...rest}>
      <div id={bodyId} className={bodyClassName}>
        {children}
      </div>
    </MUIDialog>
  )
}
