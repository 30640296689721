import { useState } from 'react'

import Component from '../../components/WelcomeModal/WelcomeModal'
import { eraseCookie } from '../../utils'

import useAuth from 'hooks/useAuth'

const WelcomeModal = ({ isOpen }) => {
  const [open, setOpen] = useState(isOpen)
  const { user } = useAuth()

  const close = () => {
    setOpen(false)
    eraseCookie('first_login')
  }

  return (
    <Component
      isOpen={open}
      onRequestClose={close}
      name={user.name.split(' ')[0]}
    />
  )
}

export default WelcomeModal
