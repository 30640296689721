import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars'

import { MonitoringItem } from '../MenuItem'

export const HistoryContainer = styled.div``

export const HistoryItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 425px) {
    div {
      width: 100%;
    }
  }
`

const Scrollbar = styled(Scrollbars)`
  height: 350px !important;
  margin-bottom: 25px;

  > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`

const Button = styled.button`
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 3px;
  color: ${props => props.theme.colors.white1};

  border-radius: 30px;

  padding: 6px 45px;

  background: ${props =>
    props.index % 2 !== 0
      ? props.theme.colors.secondary
      : props.theme.colors.primary};

  transition: all 0.2s ease;

  &:hover {
    background: ${props =>
      props.index % 2 !== 0
        ? props.theme.colors.teal3
        : props.theme.colors.primaryPink2};
  }
`

export default function History({ historyItems, width, goBack, index, type }) {
  return (
    <HistoryContainer>
      <h1 className="text-4xl font-bold text-gray-100 mb-4">Histórico</h1>
      <HistoryItems>
        {width < 769 ? (
          historyItems.map(item => (
            <div key={item.date} className="mx-2 my-2">
              <MonitoringItem
                value={item.value}
                date={item.date}
                created_by={item.created_by}
                type={type}
              />
            </div>
          ))
        ) : (
          <>
            <Scrollbar>
              {historyItems.map(item => (
                <div key={item.date} className="ml-1 my-1">
                  <MonitoringItem
                    value={item.value}
                    date={item.date}
                    created_by={item.created_by}
                    type={type}
                  />
                </div>
              ))}
            </Scrollbar>
            <Button onClick={goBack} index={index}>
              Voltar
            </Button>
          </>
        )}
      </HistoryItems>
    </HistoryContainer>
  )
}
