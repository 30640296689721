import { Suspense } from 'react'

import LoadingBackdrop from 'components/layouts/LoadingBackdrop'

import PEP from 'medicalRecord'

export default function PEPLayout() {
  return (
    <Suspense fallback={<LoadingBackdrop isOpen />}>
      <PEP />
    </Suspense>
  )
}
