export default function Logo({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="45"
      fill="none"
      viewBox="0 0 86 45"
      {...rest}
    >
      <g
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.421"
        clipPath="url(#clip0)"
      >
        <path d="M83.371 26.296c-.548-1.266-1.237-2.453-1.996-3.594.81-1.071 1.56-2.183 2.116-3.422 1.473-3.278 2.065-7.066.734-10.516C83.1 5.849 81.058 3.45 78.2 2.148 75.442.892 72.483.382 69.46.382c-2.532 0-5.1.336-7.482 1.228-2.4.9-4.648 2.094-6.713 3.621-3.952 2.92-6.525 5.74-9.499 9.646-.003.002-.005.005-.005.008l4.859 6.713a66.057 66.057 0 011.674-2.317c2.196-2.82 3.934-4.75 6.717-6.99 1.484-1.113 3.058-2.1 4.742-2.871a17.555 17.555 0 013.411-.967 22.44 22.44 0 014.712.021 14.51 14.51 0 012.626.687c.407.2.795.423 1.171.677.224.2.434.407.632.63.158.241.3.492.432.749.13.387.234.779.313 1.181.033.467.033.933.002 1.4-.11.564-.264 1.112-.462 1.653-.472.997-1.062 1.925-1.715 2.814-.398.503-.807.998-1.2 1.505-.644.83-1.207 1.7-1.156 2.817.045.354.093.705.142 1.059a3.98 3.98 0 00.947 1.686c1.082 1.477 2.065 3.033 2.827 4.701.224.598.409 1.205.536 1.83.015.305.01.608-.01.91a8.843 8.843 0 01-.295 1.113 7.28 7.28 0 01-.416.72 8.067 8.067 0 01-.61.608c-.368.248-.754.469-1.153.666-.823.302-1.671.513-2.535.659a24.542 24.542 0 01-4.34.036c-.168-.013-.333-.031-.5-.047a21.5 21.5 0 01-4.435-1.248c-1.951-.89-3.78-2.022-5.494-3.312-2.718-2.18-5.136-4.724-7.292-7.469a50.408 50.408 0 01-.75-1.028c.006-.01.013-.018.018-.028l-4.9-6.772c-1.165 1.58-2.275 3.215-3.23 4.927-.655 1.176-.33 2.37.533 3.483 4.725 6.07 8.311 12.342 16.1 16.587 3.11 1.694 6.424 2.591 9.95 2.878 2.387.177 4.8.049 7.147-.453 2.326-.498 4.427-1.546 6.258-3.058 1.773-1.466 3.055-3.745 3.53-5.996.353-1.676.381-3.168.117-4.85-.223-1.37-.747-2.63-1.293-3.893z"></path>
        <path d="M22.748 36.203c-3.757.033-7.114-1.49-9.58-3.948-2.47-2.46-3.828-5.887-3.828-9.65 0-7.962 5.669-13.742 13.477-13.742 3.553 0 6.867 1.433 9.336 4.032.373.393 3.665 4.835 7.587 9.938a.053.053 0 01-.01.015l5.092 6.662c.023-.028.043-.059.066-.087.02.023.038.049.059.072 1.94-3.11 4.19-6.026 4.19-6.026S38.536 8.282 34.762 5.037C31.21 1.984 27.202.55 22.362.55c-6 0-11.572 2.248-15.656 6.329C2.632 10.947.382 16.458.382 22.397c0 5.97 2.342 11.555 6.598 15.72 4.128 4.038 9.728 6.332 15.776 6.263 10.098-.116 14.304-5.355 20.169-13.109l-5.044-6.598c-5.131 7.493-10.5 11.491-15.133 11.53z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H85.345V45H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
