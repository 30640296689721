import styled from 'styled-components'
import { Icon } from '../ui'
import FloatMenu from './Partials/FloatMenu'
import PreviewFileComponent from './PreviewFileComponent'

const Container = styled.div`
  border-radius: 8px 8px 8px 8px;
  padding-left: 9px;
  padding-right: 9px;

  ${({ theme }) => theme.mixins.full_flex}

  background-color: ${({ theme }) => theme.colors.white1};
`

const PreviewFileContainer = ({
  file,
  uploadType = 'exam',
  OnAction = () => {}
}) => {
  const OpenUploadForm = (file, action) => {
    OnAction(file, action)
  }

  const OnCloseModal = (file, action) => {
    OnAction(file, action)
  }

  const formatName = ({ nome }) => {
    if (nome && nome.length > 17) {
      const len = nome.length
      const idx = nome.lastIndexOf('.')
      return (
        nome.substring(0, idx - 1).substr(0, 14) +
        '...' +
        nome.substr((len - 1 - idx) * -1)
      )
    }
    return nome
  }

  return (
    <Container>
      <div className="flex flex-col">
        <div className="flex flex-row mb-5 mt-5 relative">
          <h1 className="flex-grow text-center text-base font-medium">
            {formatName(file)}
          </h1>
          <FloatMenu
            type={uploadType}
            portal={false}
            onEdit={() => OpenUploadForm(file, 'edit')}
            onDelete={() => OpenUploadForm(file, 'delete')}
          />
          <Icon
            className="flex-none mx-8 cursor-pointer"
            name="close-rounded"
            size={18}
            onClick={() => OnCloseModal(file, 'close')}
          />
        </div>
        <PreviewFileComponent file={file} />
      </div>
    </Container>
  )
}

export default PreviewFileContainer
