import React from 'react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'

import SubjectDisplay from './SubjectDisplay'

import { hasAnsweredQuestions } from '../../../utils/karmenUtils'

const formatDiabetes = data => [
  {
    label: 'Status:',
    data: data.Status || '-'
  },
  {
    label: 'Tipo:',
    data: data.Type || '-'
  },
  {
    label: 'Índice glicêmico:',
    data: data.GlycemicLevel || '-'
  },
  {
    label: 'Antecedente familiar:',
    data: data.InFamily || '-'
  },
  {
    label: 'Faz acompanhamento médico:',
    data: data.MedicalFollowUp || '-'
  },
  {
    label: 'Faz uso de medicamento:',
    data: data.MedicationUse || '-'
  },
  {
    label: 'Tipo de medicamento:',
    data: data.MedicationType || '-'
  },
  {
    label: 'Faz acompanhamento nutricional:',
    data: data.NutritionalFollowUp || '-'
  }
]

const formatHypertension = data => [
  {
    label: 'Status:',
    data: data.Status || '-'
  },
  {
    label: 'Nivel:',
    data: data.Level || '-'
  },
  {
    label: 'Tipo de medicamento:',
    data: data.MedicationType || '-'
  },
  {
    label: 'Antecedente familiar:',
    data: data.InFamily || '-'
  },
  {
    label: 'Faz acompanhamento médico:',
    data: data.MedicalFollowUp || '-'
  },
  {
    label: 'Faz uso de medicamento:',
    data: data.MedicationUse || '-'
  }
]

const formatPain = data => [
  {
    label: 'Status:',
    data: data.Status || '-'
  },
  {
    label: 'Tipo:',
    data: data.Type || '-'
  },
  {
    label: 'Circunstância:',
    data: data.Circumstance || '-'
  },
  {
    label: 'Localização:',
    data: data.Location || '-'
  }
]

const formatMentalDisorder = data => [
  {
    label: 'Status:',
    data: data.Status || '-'
  },
  {
    label: 'Tipo:',
    data: data.Type || '-'
  }
]

const formatAllergy = data => [
  {
    label: 'Status:',
    data: data.Status || '-'
  },
  {
    label: 'Tipo:',
    data: data.Type || '-'
  }
]

const formatAutoimmuneDisease = data => [
  {
    label: 'Status:',
    data: data.Status || '-'
  },
  {
    label: 'Tipo:',
    data: data.Type || '-'
  }
]

const formatRespiratoryDisease = data => [
  {
    label: 'Status:',
    data: data.Status || '-'
  },
  {
    label: 'Nivel:',
    data: data.Level || '-'
  }
]

const HistoryItem = ({ title, subjects, checked }) => {
  return (
    <div className="mt-2 w-full bg-white rounded shadow-sm p-4">
      <h2 className="text-gray-900 font-semibold text-lg mb-8 flex items-center">
        {checked ? (
          <CheckCircleIcon className="h-5 text-green-500 mr-2" />
        ) : (
          <XCircleIcon className="h-5 text-warning-dark mr-2" />
        )}
        {title}
      </h2>

      <SubjectDisplay subjects={subjects} />
    </div>
  )
}

export default function HealthHistory({ data }) {
  const diabetes = formatDiabetes(data.diabetes)
  const hypertension = formatHypertension(data.hypertension)
  const pain = formatPain(data.pain)
  const mentalDisorder = formatMentalDisorder(data.mentalDisorder)
  const allergy = formatAllergy(data.allergy)
  const autoimmuneDisease = formatAutoimmuneDisease(data.autoimmuneDisease)
  const respiratoryDisease = formatRespiratoryDisease(data.respiratoryDisease)

  return (
    <>
      <div className="flex flex-col gap-2 border-t-1 border-l-0 border-b-0 border-r-0 border-gray-500 border-2 mt-4">
        <HistoryItem
          title="Diabetes"
          checked={hasAnsweredQuestions(diabetes)}
          subjects={[diabetes.slice(0, 4), diabetes.slice(4, diabetes.length)]}
        />

        <HistoryItem
          title="Hipertensão"
          checked={hasAnsweredQuestions(hypertension)}
          subjects={[
            hypertension.slice(0, 3),
            hypertension.slice(3, hypertension.length)
          ]}
        />

        <HistoryItem
          title="Dores"
          checked={hasAnsweredQuestions(pain)}
          subjects={[pain]}
        />
      </div>
      <div className="flex gap-2">
        <HistoryItem
          title="Transtornos mentais"
          checked={hasAnsweredQuestions(mentalDisorder)}
          subjects={[mentalDisorder]}
        />

        <HistoryItem
          title="Alergia"
          checked={hasAnsweredQuestions(allergy)}
          subjects={[allergy]}
        />
      </div>
      <div className="flex gap-2">
        <HistoryItem
          title="Doença autoimune"
          checked={hasAnsweredQuestions(autoimmuneDisease)}
          subjects={[autoimmuneDisease]}
        />

        <HistoryItem
          title="Doenças respiratórias"
          checked={hasAnsweredQuestions(respiratoryDisease)}
          subjects={[respiratoryDisease]}
        />
      </div>
    </>
  )
}
