import { SUPPORT_WHATSAPP } from 'utils/constants'
import { openWhatsApp } from '../../utils'

export const formatValue = value =>
  value ? `R$ ${value.replace('.', ',')}` : 'Indisponível'

export const openSupportWhatsApp = () =>
  openWhatsApp(
    SUPPORT_WHATSAPP,
    'Olá, tentei agendar uma consulta online pela plataforma mas não encontrei agendas disponíveis, pode me ajudar?'
  )

export const openSpecialtySupport = s =>
  openWhatsApp(
    SUPPORT_WHATSAPP,
    `Olá, tentei agendar uma consulta online pela plataforma para ${s} mas não encontrei, pode me ajudar?`
  )
