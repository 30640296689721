import { ReactNode } from 'react'

import Heading from 'components/ui/Heading'

export default function FormLabel({
  label,
  forInput,
  className,
  children
}: {
  label: string
  forInput: string
  className?: string
  children: ReactNode
}) {
  return (
    <div className={className}>
      <Heading
        as="label"
        className="text-base text-gray-800 font-semibold select-none"
        htmlFor={forInput}
      >
        {label}
      </Heading>
      <div className="mt-1">{children}</div>
    </div>
  )
}
