import styled from 'styled-components'

const Dropdown = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  display: ${({ show }) => (show ? 'flex' : 'none')};

  justify-content: center;
  align-items: center;

  overflow: none;

  background: rgba(0, 0, 0, 0.8);

  z-index: 20;

  div {
    width: 50px;
    height: 50px;
  }
`

export default Dropdown
