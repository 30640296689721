import { useState, useRef, useEffect } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars'

const AsyncPopperSelect = ({
  placeholder,
  options,
  type,
  name,
  onChange,
  searchFunction
}) => {
  const [showPopper, setShowPopper] = useState(false)

  const buttonRef = useRef(null)
  const popperRef = useRef(null)
  // the ref for the arrow must be a callback ref
  const [arrowRef, setArrowRef] = useState(null)

  const { styles, attributes } = usePopper(
    buttonRef.current,
    popperRef.current,
    {
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowRef
          }
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10]
          }
        }
      ]
    }
  )

  const [inputValue, setInputValue] = useState('')

  const [selected, setSelected] = useState({})

  useEffect(() => {
    if (inputValue === '') return
    onChange(name, selected.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  useEffect(() => {
    if (inputValue.length >= 3) {
      setShowPopper(true)
    }
  }, [inputValue])

  return (
    <>
      <Input
        type={type || 'text'}
        onBlur={() => {
          setTimeout(() => {
            setShowPopper(false)
          }, 200)
        }}
        placeholder={placeholder}
        value={inputValue}
        onChange={e => {
          const { value } = e.target
          setInputValue(value)
          searchFunction(value)
        }}
        name={name}
        autoComplete="off"
        ref={buttonRef}
      />
      {showPopper ? (
        <PopperContainer
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
        >
          <div ref={setArrowRef} style={styles.arrow} id="arrow" />
          <DropdownItems>
            <Scrollbar>
              {options.length !== 0 ? (
                options.map(item => (
                  <DropdownItem
                    key={item.id}
                    onClick={() => {
                      setInputValue(item.nome.replace(/"/g, ''))
                      setSelected(item)
                    }}
                  >
                    {item.nome}
                  </DropdownItem>
                ))
              ) : (
                <NotFound>Nenhum resultado encontrado</NotFound>
              )}
            </Scrollbar>
          </DropdownItems>
        </PopperContainer>
      ) : null}
    </>
  )
}

const DropdownItems = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 220px;
  height: 200px;
  max-height: 200px;

  padding: 10px 0;

  overflow-y: hidden;
`

const DropdownItem = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.gray6};

  padding: 4px 10px;

  cursor: pointer;

  z-index: 3;

  &:hover {
    background: ${({ theme }) => theme.colors.teal4};
  }
`

const NotFound = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.gray6};

  padding: 4px 10px;
`

const Scrollbar = styled(Scrollbars)`
  height: 100px;
  width: 100px;
  div {
    display: flex;
    flex-direction: column;
  }
`

const Input = styled.input`
  cursor: pointer;
  background: none;

  width: 100%;
`

const PopperContainer = styled.div`
  ${({ theme }) => theme.mixins.shadow_medium}
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.teal3};
  padding: 20px;
  text-align: center;

  #arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    &:after {
      content: ' ';
      background-color: ${({ theme }) => theme.colors.teal3};
      ${({ theme }) => theme.mixins.shadow_medium}
      position: absolute;
      top: -25px; // padding + popper height
      left: 0;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
    }
  }

  &[data-popper-placement^='top'] > #arrow {
    bottom: -30px;
    :after {
      ${({ theme }) => theme.mixins.shadow_medium}
    }
  }
`

export default AsyncPopperSelect
