import styled, { keyframes } from 'styled-components'

const spin = keyframes`
    0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const COLORS = {
  white: {
    path: 'rgba(255, 255, 255, 0.2)',
    dash: '#ffffff'
  },
  teal: {
    path: '#4DB1B1A0',
    dash: '#008F8F'
  },
  pink: {
    path: 'rgba(210, 14, 80, 0.26)',
    dash: '#D20E50'
  }
}

interface props {
  color: 'white' | 'teal' | 'pink'
  size?: number
}

const Spinner = styled.div<props>`
  width: ${props => (props.size ? `${props.size}px` : '1.5rem')};
  height: ${props => (props.size ? `${props.size}px` : '1.5rem')};
  border-radius: 50%;
  position: relative;
  border-top: 0.4em solid ${props => COLORS[props.color || 'white'].path};
  border-right: 0.4em solid ${props => COLORS[props.color || 'white'].path};
  border-bottom: 0.4em solid ${props => COLORS[props.color || 'white'].path};
  border-left: 0.4em solid ${props => COLORS[props.color || 'white'].dash};
  transform: translateZ(0);
  animation: ${spin} 1.1s infinite linear;
`

export default Spinner
