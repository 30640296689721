/* eslint-disable no-return-await */
import { useEffect, useState } from 'react'

import { Layout, AutoCompleteSelectSearch } from '../elements'

import { finishForm, getEnum } from '../../services/api'
import { toast, ToastContainer } from 'react-toastify'
import {
  makeStyles,
  MenuItem,
  TextareaAutosize,
  TextField
} from '@material-ui/core'
import useMedicalRecord from 'hooks/useMedicalRecord'
import { ToastInvalidStyles, ToastSuccessStyles } from 'utils/ToastStyles'
import bugsnag from 'lib/bugsnag'
import { Button } from 'components/ui'
import { withStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  area: {
    width: '100%',
    backgroundColor: 'rgba(23, 23, 23, 0.04)',
    padding: '12px 16px',
    borderRadius: 5,
    color: 'black',
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.38)'
    },
    marginTop: 16
  }
})

const Select = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 52,
      '& fieldset': {
        borderRadius: 8
      }
    }
  }
})(TextField)

const Anamnese = () => {
  const { appointment, patient } = useMedicalRecord()

  const [cidValue, setCidValue] = useState([])
  const [ciapValue, setCiapValue] = useState([])
  const [HPMA, setHPMA] = useState('')
  const [loading, setLoading] = useState(false)

  const [cidValueHistory, setCidValueHistory] = useState([])
  const [ciapValueHistory, setCiapValueHistory] = useState([])
  const [observation, setObservation] = useState('')
  const [loadingHistory, setLoadingHistory] = useState(false)

  const [habitObservation, setHabitObservation] = useState('')
  const [habits, setHabits] = useState([])
  const [habit, setHabit] = useState([])
  const [loadingHabits, setLoadingHabits] = useState(false)

  const [AllergyValue, setAllergyValue] = useState([])
  const [AllergyMedicationValue, setAllergyMedicationValue] = useState([])
  const [loadingAllergy, setLoadingAllergy] = useState(false)

  const classes = useStyles()

  const getHabits = async () => {
    try {
      const response = await getEnum('habitos')
      setHabits(response)
    } catch (error) {
      toast('Erro ao carregar hábitos ', ToastInvalidStyles)
      bugsnag.notify(`Erro ao carregar hábitos, error: ${error}`)
    }
  }

  const onSubmitQueixa = async () => {
    setLoading(true)
    try {
      await finishForm(patient ? patient.id : appointment.paciente.id, {
        anamnese: {
          cid: cidValue.map(cid => cid.split(' - ')[0]),
          ciap: ciapValue.map(ciap => ciap.split(' - ')[0]),
          HPMA: HPMA
        }
      })
      toast('Queixa enviada com sucesso!', ToastSuccessStyles)
      setCidValue([])
      setCiapValue([])
      setHPMA('')
    } catch (error) {
      toast('Erro ao enviar Queixa ', ToastInvalidStyles)
      bugsnag.notify(`Erro ao enviar Queixa  error: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const onSubmithistory = async () => {
    setLoadingHistory(true)
    try {
      await finishForm(patient ? patient.id : appointment.paciente.id, {
        anamnese: {
          historico_familiar_cid: cidValueHistory.map(
            cid => cid.split(' - ')[0]
          ),
          historico_familiar_ciap: ciapValueHistory.map(
            ciap => ciap.split(' - ')[0]
          ),
          observacoes: observation
        }
      })
      toast('Histórico enviado com sucesso!', ToastSuccessStyles)
      setCidValueHistory([])
      setCiapValueHistory([])
      setObservation('')
    } catch (error) {
      toast('Erro ao enviar Histórico ', ToastInvalidStyles)
      bugsnag.notify(`Erro ao enviar Histórico, error: ${error}`)
    } finally {
      setLoadingHistory(false)
    }
  }

  const onSubmithabits = async () => {
    setLoadingHabits(true)
    try {
      await finishForm(patient ? patient.id : appointment.paciente.id, {
        anamnese: {
          habitos: [habit],
          observacoes: habitObservation
        }
      })
      toast('Hábito enviado com sucesso!', ToastSuccessStyles)
      setHabit([])
      setHabitObservation('')
    } catch (error) {
      toast('Erro ao enviar Hábito ', ToastInvalidStyles)
      bugsnag.notify(`Erro ao enviar Hábito, error: ${error}`)
    } finally {
      setLoadingHabits(false)
    }
  }

  const onSubmitAllergy = async () => {
    setLoadingAllergy(true)
    try {
      await finishForm(patient ? patient.id : appointment.paciente.id, {
        anamnese: {
          alergia_ids: AllergyValue.map(a => a.value),
          alergias_medicamentos_ids: AllergyMedicationValue.map(a => a.value)
        }
      })
      toast('Alergias enviadas com sucesso!', ToastSuccessStyles)
      setAllergyMedicationValue([])
      setAllergyValue([])
    } catch (error) {
      toast('Erro ao enviar Alergias ', ToastInvalidStyles)
      bugsnag.notify(`Erro ao enviar Alergias, error: ${error}`)
    } finally {
      setLoadingAllergy(false)
    }
  }

  useEffect(() => {
    getHabits()
  }, [])

  return (
    <div>
      <Layout className="mt-4" title="Queixa principal">
        <ToastContainer />
        <p className="text-gray-700">Preencha ao menos um campo</p>
        <div className="p-3 mt-4 flex flex-col gap-6">
          <AutoCompleteSelectSearch
            name="CID"
            url="enums/CID"
            value={cidValue}
            setValue={setCidValue}
          />
          <AutoCompleteSelectSearch
            name="CIAP"
            url="enums/CIAP"
            value={ciapValue}
            setValue={setCiapValue}
          />

          <div className="mt-4">
            <h3 className="font-semibold text-xl">HPMA</h3>
            <p className="text-base text-gray-700">
              História Pregressa da Moléstia atual
            </p>
            <p className="text-base text-gray-700">
              Descreva em ordem cronologica os detalhes referente a
              doença/condição do paciente
            </p>
            <TextareaAutosize
              className={classes.area}
              minRows={3}
              placeholder="Escreva aqui..."
              value={HPMA}
              onChange={elm => setHPMA(elm.target.value)}
            />
          </div>

          <Button
            disabled={!(cidValue.length > 0 || ciapValue.length > 0)}
            loading={loading}
            onClick={onSubmitQueixa}
            className="text-center w-20 h-12 self-end"
          >
            <p>Salvar</p>
          </Button>
        </div>
      </Layout>

      <Layout className="mt-4" title="Histórico familiar">
        <ToastContainer />
        <p className="text-gray-700">Preencha ao menos um campo</p>
        <div className="p-3 mt-4 flex flex-col gap-6">
          <AutoCompleteSelectSearch
            name="CID"
            url="enums/CID"
            value={cidValueHistory}
            setValue={setCidValueHistory}
          />
          <AutoCompleteSelectSearch
            name="CIAP"
            url="enums/CIAP"
            value={ciapValueHistory}
            setValue={setCiapValueHistory}
          />

          <div className="mt-4">
            <h3 className="font-semibold text-xl">Observações</h3>
            <TextareaAutosize
              className={classes.area}
              minRows={3}
              placeholder="Escreva aqui..."
              value={observation}
              onChange={elm => setObservation(elm.target.value)}
            />
          </div>

          <Button
            disabled={
              !(cidValueHistory.length > 0 || ciapValueHistory.length > 0)
            }
            loading={loadingHistory}
            onClick={onSubmithistory}
            className="text-center w-20 h-12 self-end"
          >
            <p>Salvar</p>
          </Button>
        </div>
      </Layout>
      <Layout className="mt-4" title="Hábitos">
        <div className="p-3 mt-4 flex flex-col">
          {habits.length > 0 ? (
            <Select
              className="w-full"
              defaultValue="none"
              value={habit}
              onChange={elm => setHabit(elm.target.value)}
              variant="outlined"
              placeholder="Selecione uma opção"
              select
            >
              <MenuItem value="none" disabled>
                Selecione uma opção
              </MenuItem>
              {habits.map(habit => (
                <MenuItem key={habit.value} value={habit.value}>
                  {habit.label}
                </MenuItem>
              ))}
            </Select>
          ) : null}

          <TextareaAutosize
            className={classes.area}
            minRows={3}
            placeholder="Escreva aqui..."
            value={habitObservation}
            onChange={elm => setHabitObservation(elm.target.value)}
          />
          <Button
            disabled={!(habit || habitObservation)}
            loading={loadingHabits}
            onClick={onSubmithabits}
            className="text-center w-20 h-12 self-end mt-6"
          >
            <p>Salvar</p>
          </Button>
        </div>
      </Layout>

      <Layout className="mt-4" title="Alergias">
        <div className="p-3 mt-4 flex flex-col">
          <div>
            <AutoCompleteSelectSearch
              name="A medicamentos"
              url="alergia"
              value={AllergyValue}
              setValue={setAllergyValue}
            />
          </div>
          <div className="mt-6">
            <AutoCompleteSelectSearch
              name="Outras"
              url="alergia"
              value={AllergyMedicationValue}
              setValue={setAllergyMedicationValue}
            />
          </div>

          <Button
            disabled={
              !(AllergyMedicationValue.length > 0 || AllergyValue.length > 0)
            }
            loading={loadingAllergy}
            onClick={onSubmitAllergy}
            className="text-center w-20 h-12 self-end mt-6"
          >
            <p>Salvar</p>
          </Button>
        </div>
      </Layout>
    </div>
  )
}

export default Anamnese
