import { Link } from 'react-router-dom'

import Head from 'components/common/Head'
import Icon from 'components/ui/Icon'
import Button from 'components/ui/Button'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'

import WelcomeImage from 'assets/images/WelcomePage/welcome-side-image.png'

import { APP_URLS, quickService, SITE_URLS } from 'utils/urls'
import { whatsappMessage } from 'utils'
import { SUPPORT_WHATSAPP } from 'utils/constants'

export default function WelcomePage() {
  return (
    <>
      <Head title="Bem vindo" />
      <div className="bg-pink-600 min-h-screen grid grid-cols-1 md:grid-cols-2">
        <div className="hidden md:inline h-full max-h-screen">
          <img
            src={WelcomeImage}
            alt="Bem vindo"
            className="object-cover object-right-top h-full max-h-full"
          />
        </div>
        <div className="flex items-center justify-center">
          <div className="max-w-md px-8">
            <div className="text-gray-100 space-y-4">
              <Heading className="text-5xl font-bold">Olá!</Heading>

              <Heading as="h2" className="text-2xl font-semibold">
                Vamos nos conhecer melhor?
              </Heading>

              <Text className="text-lg">
                Responda algumas perguntas rápidas sobre você e nós montaremos
                seu mapa de saúde, uma agenda onde você terá todas as
                informações sobre sua saúde.
              </Text>
            </div>

            <div className="my-6">
              <a href={APP_URLS.CHAT}>
                <Button color="white" className="font-semibold w-full">
                  Iniciar
                </Button>
              </a>

              <Link to={APP_URLS.LOGIN}>
                <Button
                  color="transparent"
                  className="font-semibold w-full mt-4 text-gray-100 border-2 border-gray-100"
                >
                  Já tenho uma conta
                </Button>
              </Link>
            </div>

            <div className="bg-gray-300 rounded assina-shadow-sm p-4">
              <Heading
                as="h3"
                className="text-2xl font-semibold text-teal-600 text-center"
              >
                Ou selecione uma opção
              </Heading>

              <div className="space-y-4 text-gray-700 text-lg mt-2">
                <a
                  href={SITE_URLS.BOOK}
                  className="text-gray-700 flex cursor-pointer"
                >
                  <Icon
                    name="calendar-outline"
                    className="mr-4 fill-current"
                    size={24}
                  />
                  <Heading as="h4">Agendar uma consulta</Heading>
                </a>
                <a
                  href={quickService()}
                  className="text-gray-700 flex cursor-pointer"
                >
                  <Icon
                    name="doctor-filled"
                    className="mr-4 fill-current"
                    size={24}
                  />
                  <Heading as="h4">Falar com um médico</Heading>
                </a>
                <a
                  href={whatsappMessage(
                    SUPPORT_WHATSAPP,
                    'Olá, tenho uma dúvida'
                  )}
                  about="_blank"
                  className="text-gray-700 flex cursor-pointer"
                >
                  <Icon
                    name="chat-outline"
                    className="mr-4 fill-current"
                    size={24}
                  />
                  <Heading as="h4">Fazer uma pergunta</Heading>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
