import { ReactNode } from 'react'

import Heading from 'components/ui/Heading'

export default function FilterSection({
  title,
  children
}: {
  title: string
  children: ReactNode
}) {
  return (
    <div>
      <Heading className="text-lg text-gray-900 font-semibold">{title}</Heading>
      <div className="p-2">{children}</div>
    </div>
  )
}
