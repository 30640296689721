import { ButtonHTMLAttributes, JSXElementConstructor, ReactNode } from 'react'
import cn from 'classnames'

import Spinner from '../Spinner'

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
  as?: string | JSXElementConstructor<any>
  color?:
    | 'white'
    | 'white_green_text'
    | 'pink'
    | 'disabled'
    | 'outlined'
    | 'green'
    | 'transparent'
  loading?: boolean
}

export default function Button({
  children,
  color = 'pink',
  className,
  as = 'button',
  disabled = false,
  loading,
  style,
  ...rest
}: IButton) {
  const TagName = as

  const colorClasses = {
    white: 'bg-white hover:bg-gray-300 text-pink-600',
    white_green_text: 'bg-white hover:bg-gray-300 text-green-800',
    pink: 'bg-pink-600 hover:bg-pink-700 text-white',
    disabled: 'bg-gray-500 text-gray-600',
    outlined:
      'bg-white hover:bg-gray-400 text-pink-600 border-2 border-pink-600',
    green: 'bg-teal-600 hover:bg-teal-700 text-white',
    transparent: 'bg-transparent'
  }

  const baseClasses = cn(
    `h-10 ${
      disabled || 'cursor-pointer'
    } rounded text-center flex items-center justify-center tracking-wider focus:outline-none font-semibold text-base`,
    disabled ? colorClasses.disabled : colorClasses[color],
    className
  )

  return (
    <TagName
      className={baseClasses}
      disabled={disabled}
      style={{
        transition: 'all 0.2s ease',
        fontFamily: 'Titillium Web',
        ...style
      }}
      {...rest}
    >
      {loading ? (
        <Spinner color={color === 'pink' ? 'white' : 'pink'} />
      ) : (
        children
      )}
    </TagName>
  )
}
