import { Suspense } from 'react'
import { Route } from 'react-router-dom'

// Components
import LoadingBackdrop from 'components/layouts/LoadingBackdrop'

// Utils
import { APP_URLS } from 'urls'

// Pages
import PresentialDashboardPage from 'pages/Administrative/PresentialDashboardPage'
import UsersPage from 'pages/Administrative/UsersPage'
import UsersReviews from 'pages/UsersReviews/UsersReviews'
import UserPage from 'pages/Administrative/UserPage'

export default function AdministrativeRoutes() {
  return (
    <Suspense fallback={<LoadingBackdrop isOpen />}>
      <Route
        path={APP_URLS.ADMINISTRATIVE.PRESENTIAL_DASHBOARD}
        component={PresentialDashboardPage}
      />
      <Route path={APP_URLS.ADMINISTRATIVE.USERS} component={UsersPage} />
      <Route
        path={APP_URLS.ADMINISTRATIVE.USER_OVERVIEW}
        component={UserPage}
      />
      <Route exact path={APP_URLS.USER_REVIEWS} component={UsersReviews} />
    </Suspense>
  )
}
