import { createTheme } from '@material-ui/core/styles'

import palette from './palette'

const theme = createTheme({
  palette,
  overrides: {
    MuiFormControlLabel: {
      root: {
        '& .MuiTypography-body1': {
          fontFamily: 'Titillium Web',
          fontWeight: 600,
          fontSize: '16px'
        }
      }
    },
    MuiInputAdornment: {
      positionStart: {
        marginTop: '2px !important'
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '4px'
      },
      underline: {
        '&:before': {
          borderBottom: 'none',
          borderRadius: '4px'
        },
        '&:hover:not($disabled):before': {
          borderBottom: 'none',
          borderRadius: '4px'
        }
      }
    },
    MuiTab: {
      root: {
        fontFamily: 'Titillium Web',
        fontWeight: 600,
        textTransform: 'none',
        fontSize: '16px',
        letterSpacing: '1px',

        '&.MuiTab-textColorPrimary': {
          color: 'rgba(210, 14, 80, 0.55)'
        }
      }
    }
  }
})

export default theme
