import styled from 'styled-components'

import { BACKGROUNDS } from '../../../../urls'
import { Icon } from '../../../ui'
import RoundButton from '../RoundButton'

export const SuccessContainer = styled.div`
  background-image: url(${BACKGROUNDS.BG_SUCCESS});
  background-position: bottom;
  background-repeat: no-repeat;

  height: 100%;

  background-color: ${props => props.theme.colors.white1};

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 35px;
`

export default function Success({ backToBeggining, width }) {
  return (
    <SuccessContainer>
      <h1 className="font-semibold text-5xl text-pink-600">Sucesso!</h1>
      <h3 className="text-xl text-gray-900 tracking-wider my-6">
        Seus dados foram adicionados e você poderá acessá-los na aba{' '}
        <span className="font-semibold text-2xl text-pink-600">
          Seu Histórico
        </span>
      </h3>
      <div className="text-center">
        {width < 769 ? (
          <RoundButton primary onClick={backToBeggining}>
            <Icon name="back" size={27} />
          </RoundButton>
        ) : (
          <span
            className="text-3xl text-pink-600 tracking-wider cursor-pointer"
            onClick={backToBeggining}
          >
            Voltar
          </span>
        )}
      </div>
    </SuccessContainer>
  )
}
