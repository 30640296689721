import { FC, JSXElementConstructor } from 'react'
import styled from 'styled-components'

interface IHeading {
  as?: string | JSXElementConstructor<any>
  className?: string
  htmlFor?: string
}

const Component = styled.h1`
  font-family: 'Titillium Web';
`

const Heading: FC<IHeading> = ({ as = 'h1', className, children, ...rest }) => {
  return (
    <Component as={as} className={className} {...rest}>
      {children}
    </Component>
  )
}

export default Heading
