import { WindowContext } from '../../config/contexts/WindowContext'
import { useContext } from 'react'
import WindowOpener from '../elements/WindowOpen'
import { BASE_API_URL } from 'utils/constants'
import { useQuery } from 'react-query'
import { Spinner } from 'components/ui'
import useSesame from '../../../hooks/useSesame'
import { getSmartConsultations } from 'services/api'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { STATUS_SMART_CONSULTATION } from 'pages/SmartConsultation/SmartConsultation'

const SmartConsultationElement = ({ item, index }) => {
  const { loading, sesame } = useSesame()

  const status = STATUS_SMART_CONSULTATION.find(
    s => s.value === item.status
  ) || {
    value: 'without-value',
    text: 'Sem status',
    color: 'gray-600'
  }

  return (
    <div
      key={`${item.id}+${index}`}
      className="flex flex-col rounded-lg p-2 my-4 w-full bg-white"
    >
      <h1>Diagnóstico em andamento</h1>
      <div className="p-2 border-2 border-gray-200 rounded-lg">
        <div className="flex">
          <div className="w-full">
            <h3>Nome</h3>
            <p className="text-gray-700 text-sm">{item.user.nome}</p>
          </div>
          <div className="w-full">
            <h3>Queixa principal</h3>
            <p className="text-gray-700 text-sm">
              {item.conversation ? item.conversation.symptom.name : '-'}
            </p>
          </div>
          <div className="w-full">
            <h3>Data de realização</h3>
            <p className="text-gray-700 text-sm">
              {item.conversation
                ? item.conversation.created_at
                : item.conversation}
            </p>
          </div>
        </div>

        <h2 className="mt-2">Status</h2>

        <div className="p-2 border-2 border-gray-200 rounded-lg mt-2 flex justify-center items-center">
          <div
            className={`rounded-full h-4 w-4 bg-${
              status ? status.color : ''
            } mr-4`}
          />
          <p className={`w-full text-${status ? status.color : ''} `}>
            {item.status}
          </p>
        </div>
        {loading ? (
          <div className="flex w-full justify-center items-center">
            <Spinner color="pink" size={35} />
          </div>
        ) : sesame ? (
          <WindowOpener
            opts="width=600,height=600"
            name="popup"
            url={`${BASE_API_URL}/admin/smart_consultation/smartconsultation/${item.id}/change/${sesame}`}
            className="w-full mt-2"
          >
            <div className="w-full h-12 justify-center items-center flex bg-pink-600 rounded-lg">
              <p className="text-center text-gray-100">
                Editar Consulta inteligente
              </p>
            </div>
          </WindowOpener>
        ) : (
          <p className="text-center mb-4 mt-8 h-12 w-full">
            Não foi possível gerar o link para a consulta inteligente entrar em
            contato com o administrativo
          </p>
        )}

        <div className="mt-4">
          <div>
            <h2>Possíveis causas</h2>
            <p>{item.conversation ? item.conversation.possible_causes : '-'}</p>
          </div>

          {!loading && sesame && item.conversation && (
            <WindowOpener
              opts="width=600,height=600"
              name="popup"
              url={`${BASE_API_URL}/admin/symptom_checker/conversation/${item.conversation.id}/change/${sesame}`}
            >
              <div className="flex items-center gap-2">
                <p className="text-pink-600 font-bold">
                  Acessar triagem completa
                </p>
                <ChevronRightIcon className="text-pink-600 h-5" />
              </div>
            </WindowOpener>
          )}
        </div>
      </div>
    </div>
  )
}

const SmartConsultation = () => {
  const { doctor, patient } = useContext(WindowContext)

  const {
    data: smartConsultations,
    isError,
    isLoading
  } = useQuery(
    'smart-consultation-pep',
    () =>
      getSmartConsultations({
        patient_id: patient.id,
        doctor_attendant_id: doctor.id
      }).then(response => response.data.data),
    {
      refetchInterval: 5000
    }
  )

  if (!isLoading && isError)
    return (
      <p className="text-center mb-4 mt-8 h-12 w-full">
        Não foi possível carregar as consultas inteligentes.
      </p>
    )

  if (isLoading)
    return (
      <div className="flex items-center justify-center mb-4 mt-8 h-12 w-full">
        <Spinner color="pink" size={35} />
      </div>
    )

  return !isLoading && !isError && smartConsultations.length > 0 ? (
    smartConsultations.map((item, index) => (
      <>
        {index > 0 && <div className="mt-2" />}
        <SmartConsultationElement item={item} index={index} />
      </>
    ))
  ) : (
    <div className="flex flex-col rounded-xl my-4 w-full h-16 justify-center items-center">
      <h1>Sem consulta inteligente.</h1>
    </div>
  )
}

export default SmartConsultation
