import React from 'react'
import cn from 'classnames'

const Section = ({
  children,
  className,
  as = 'section',
  isContainer = true,
  ...rest
}) => {
  const s = cn(
    'max-w-xl bg-white p-4',
    className,
    isContainer ? 'container' : ''
  )

  const Tag = as

  return (
    <Tag className={s} {...rest}>
      {children}
    </Tag>
  )
}

export default Section
