import { APP_URLS } from 'urls'
import Warning from 'assets/images/aviso.png'

export default function WaitingRoomLayout() {
  return (
    <div
      id="lay_aviso"
      className="rounded bg-teal-200 border border-teal-300 m-10 center-align aviso_inicial flex flex-wrap"
      style={{ maxHeight: 'min-content' }}
    >
      <div className="col-sm-6  ">
        <div className="m-4">
          <h3 className="text-xl font-medium">Você está na sala de espera.</h3>
          <div className="text-lg">
            <div className="loader"></div>
            <p>
              Aguarde aqui nessa tela, que o médico já irá lhe atender. Você é o
              próximo a ser chamado!
            </p>
          </div>
          <p className="mt-4">
            Quando solicitado, não esqueça de liberar o acesso a sua câmera e
            áudio.
          </p>
          <a
            href={APP_URLS.HOME}
            className="btn btn-raised btn-outline-primary waves-effect waves-light mt-4"
            id="leave"
          >
            SAIR
          </a>
        </div>
      </div>
      <div className="col-sm-6  ">
        <div className="my-3 mx-auto text-center">
          <img
            src={Warning}
            style={{ border: 'solid 1px #0C5360' }}
            alt="aviso"
          />
        </div>
      </div>
    </div>
  )
}
