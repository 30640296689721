import { useLocation } from 'react-router'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function useQueryParams(param: string) {
  let query = useQuery()

  return query.get(`${param}`)
}
