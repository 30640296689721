import { isProduction } from 'utils/constants'

export const PORTAL_URL = isProduction
  ? 'https://portal.kompa.com.br'
  : 'http://localhost:3000'

const BACKEND_PREFIX = isProduction
  ? 'https://app.kompa.com.br'
  : 'http://localhost:8000'

export const APP_URLS = {
  HOME: '/',
  KARMEN: '/karmen',
  VERSATILIS: '/versatilis',
  CLINIC: `${BACKEND_PREFIX}/atendimento/consultas/`
}

export const PORTAL_URLS = {
  VIRTUAL_CLINIC: '/consultorio-virtual'
}

export const IFRAME_URLS = {
  VERSATILIS: 'https://sistema.globalmedclinica.com.br/globalmed/login3.aspx'
}
