import moment from 'moment'
import 'moment/locale/pt-br'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts'

const formatXAxis = tickItem => {
  return moment(tickItem, 'DD-MM-YYYY').format('DD/MMM')
}

export default function HealthChart({ subjectData }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={subjectData}
        margin={{
          top: 10,
          right: 0,
          left: 30,
          bottom: 0
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          stroke="#b5b5b5"
          dataKey="date"
          width={0}
          tickFormatter={formatXAxis}
          interval="preserveStartEnd"
        />
        <YAxis
          stroke="#A4A4A4"
          type="number"
          orientation="right"
          domain={['dataMin - 15', 'dataMax + 15']}
          tickSize={2}
          axisLine={false}
          tickMargin={5}
        />
        <Tooltip
          formatter={value => {
            return [value, 'Valor']
          }}
        />
        <Line
          type="monotone"
          dataKey="value"
          stroke="#8884d8"
          strokeWidth={2}
          // dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
