import {
  BookOpenIcon,
  UsersIcon,
  VideoCameraIcon,
  BadgeCheckIcon,
  CalendarIcon
} from '@heroicons/react/solid'

import Head from 'components/common/Head'
import Heading from 'components/ui/Heading'
import Link from 'components/ui/Link'
import Text from 'components/ui/Text'

import useAuth from 'hooks/useAuth'
import { useQuery } from 'react-query'
import { getSmartConsultations } from 'services/api'
import { ISmartConsultation } from 'types/Appointment'

import { APP_URLS } from 'urls'

const NOTIFICATION_STYLES = {
  top: -15,
  right: -24
}

export default function HomeCardSmartConsultation() {
  const { user } = useAuth()

  const menuItemStyle = {
    height: 'min-content'
  }

  const handleGetConsultations = (): Promise<ISmartConsultation[]> => {
    return getSmartConsultations({
      doctor_attendant_id: user.id
    }).then(response => response.data.data)
  }

  const { data: smartConsultations } = useQuery(
    'home-smart-consultation',
    handleGetConsultations,
    {
      refetchInterval: 5000
    }
  )

  return (
    <Link to={APP_URLS.SMART_CONSULTATIONS} style={menuItemStyle}>
      <div className="bg-gray-100 border-l-4 border-yellow-500 h-full w-full rounded assina-shadow-sm p-3 space-y-2 relative">
        <div className="flex items-center justify-between text-yellow-500">
          <Heading className="text-xl font-bold">
            Consultas inteligentes
          </Heading>
          <CalendarIcon className="h-10" />
        </div>
        <Text className="text-gray-800">
          Suas consultas inteligentes e histórico de pacientes.
        </Text>
        {smartConsultations && smartConsultations.length > 0 ? (
          <div
            style={NOTIFICATION_STYLES}
            className="rounded-full h-6 w-6 bg-new_red-500 mr-4 flex items-center justify-center absolute"
          >
            <p className="text-white">{smartConsultations?.length}</p>
          </div>
        ) : null}
      </div>
    </Link>
  )
}
