import { useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import Text from 'components/ui/Text'
import { IUserReview } from 'types/UserReviews'
import { ThumbUpIcon, ThumbDownIcon } from '@heroicons/react/outline'

const normalColumnStyle = 'text-gray-900 text-base self-center'

export interface ITableItem {
  data: IUserReview
}

export default function TableItem({
  data: {
    data_cadastro,
    type,
    commentary,
    rating,
    consultation: { paciente, medico }
  }
}: ITableItem) {
  const [showExtra, setShowExtra] = useState<boolean>(false)

  const openExtraInfo = () => setShowExtra(prev => !prev)

  return (
    <div className="my-3">
      <div className="grid grid-cols-6 text-center border-t-2 border-gray-400 pt-4">
        <Text className={normalColumnStyle}>{paciente?.nome}</Text>
        <Text className={normalColumnStyle}>{medico?.nome}</Text>
        <Text className={normalColumnStyle}>{data_cadastro}</Text>
        <Text className={normalColumnStyle}>{rating}</Text>
        <div className="flex items-center justify-center space-x-2">
          {type === 'positive' ? (
            <ThumbUpIcon className="h-5" stroke="green" />
          ) : (
            <ThumbDownIcon className="h-5" stroke="red" />
          )}
        </div>
        <div className="flex items-center justify-center space-x-2">
          <button onClick={openExtraInfo}>
            {showExtra ? (
              <ChevronUpIcon className="h-6 text-gray-900" />
            ) : (
              <ChevronDownIcon className="h-6 text-gray-900" />
            )}
          </button>
        </div>
      </div>
      {showExtra && (
        <div className="grid grid-cols-1 gap-2 mt-4 mx-10">
          <label className="text-xl font-medium text-gray-800">
            Dados do Paciente
          </label>
          <div className="grid grid-cols-2 gap-2 mt-4 mx-10">
            <label className="text-lg font-medium text-gray-800">Nome</label>
            <label className="text-lg font-medium text-gray-800">Celular</label>
            <Text className={normalColumnStyle}>{paciente?.nome}</Text>
            <Text className={normalColumnStyle}>{paciente?.celular}</Text>
          </div>
          <label className="text-xl font-medium text-gray-800">
            Comentários
          </label>
          <div className="grid grid-cols-1 gap-2 mt-4 mx-10">
            <Text className={normalColumnStyle}>{commentary}</Text>
          </div>
        </div>
      )}
    </div>
  )
}
