import axios from 'axios'

import {
  BASE_API_URL,
  GOOGLE_API_URL,
  VINDI_PUBLIC_URL,
  VINDI_PUBLIC_KEY
} from 'utils/constants'

import { getToken } from '../commons/utils/utils'

export const backendServices = axios.create({
  baseURL: BASE_API_URL
})

export const quizAPI = axios.create({
  baseURL: process.env.QUESTIONARIO_API_URL
})

export const apiTP = axios.create({
  baseURL: process.env.BASE_URL_ADM,
  headers: {
    Authorization: `Token ${getToken()}`
  }
})

export const apiGoogle = axios.create({
  baseURL: GOOGLE_API_URL
})

export const vindiAPI = axios.create({
  baseURL: VINDI_PUBLIC_URL,
  auth: {
    username: VINDI_PUBLIC_KEY,
    password: ''
  }
})
