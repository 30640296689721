import styled, { css } from 'styled-components'

const Button = styled.button`
  ${props =>
    props.fixed &&
    css`
      position: fixed;
      z-index: 2;

      bottom: 130px;
    `}

  padding: 8px;

  border-radius: 50%;

  ${props =>
    props.index % 2 === 0
      ? css`
          background: ${props.theme.colors.primary};
        `
      : css`
          background: ${props.theme.colors.secondary};
        `}

  ${props =>
    props.primary &&
    css`
      background: ${props.theme.colors.primary};
    `}

    svg {
    zoom: 1.5;
    fill: ${props => props.theme.colors.gray6};
  }

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`

export default function RoundButton({
  onClick,
  children,
  primary,
  fixed,
  ...rest
}) {
  return (
    <Button onClick={onClick} primary={primary} fixed={fixed} {...rest}>
      {children}
    </Button>
  )
}
