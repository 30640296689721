import { BACKGROUNDS } from '../../urls'

const Call0800Page = () => (
  <div className="container mx-auto overflow-auto">
    <div className="my-4 p-4 bg-white relative rounded">
      <div
        className="bg-right-bottom bg-no-repeat absolute opacity-50 w-full h-full top-0 left-0"
        style={{ backgroundImage: `url(${BACKGROUNDS.CALL0800})` }}
      />
      <div className="relative">
        <h1 className="text-xl font-medium">0800 Saúde</h1>
        <h3 className="text-sm font-medium">
          Auxílio médico 24h para você e sua família.
        </h3>
        <p className="mt-4">
          Telefones:{' '}
          <a
            className="text-pink-600 hover:underline font-medium"
            href="tel:+5540021260"
          >
            4002-1260
          </a>{' '}
          (São Paulo capital) ou{' '}
          <a
            className="text-pink-600 hover:underline font-medium"
            href="tel:+5508007710617"
          >
            0800 771 0617
          </a>{' '}
          (para localidades fora de São Paulo)
        </p>
        <p />
        <p className="mt-4">
          Nossos profissionais da saúde estão disponíveis para lhe atender 24h
          por dia, todos os dias da semana. Confira alguns dos atendimentos
          disponíveis:
        </p>
        <div className="text-lg mt-4 font-medium text-teal-600">
          Orientação Médica (OMT)
        </div>
        <ul className="ml-6 list-disc">
          <li>
            Orientar quanto ao período de jejum e preparo adequado para exames;
          </li>
          <li>
            Identificar demanda por necessidade de especialidades para exames;
          </li>
          <li>
            Indicar especialista adequado às necessidades, evitando consultas
            múltiplas e desnecessárias;
          </li>
          <li>
            Instruir objetivamente como proceder à frente a situações adversas à
            saúde;
          </li>
          <li>
            Orientações em primeiros socorros e apoio no suporte ao risco
            iminente em saúde.
          </li>
        </ul>
        <b>Importante</b>: O conteúdo transmitido pelo serviço é informativo e
        não substitui a consulta presencial de um médico.
        <div className="text-lg mt-4 font-medium text-teal-600">
          Orientação Farmacêutica
        </div>
        <ul className="ml-6 list-disc">
          <li>
            Esclarecer dúvidas sobre medicamentos previamente receitados ao
            beneficiário;
          </li>
          <li>Informações sobre indicações e contra-indicações;</li>
          <li>Alertas a reações adversas e interações medicamentosas;</li>
          <li>‘Tradução’ ou interpretação sobre bula;</li>
          <li>Informações sobre precificação de medicamentos;</li>
        </ul>
        <div className="text-lg font-medium text-teal-600 mt-4">
          Orientação Anti-stress (psicológica)
        </div>
        <ul className="ml-6 list-disc">
          <li>Provê atenção preventiva primária;</li>
          <li>
            Foco no controle do estresse e seus condicionantes que envolvam a
            postura de atitudes e de consciência;
          </li>
          <li>
            Aplicação de breve questionário para identificação do nível de
            estresse do indivíduo;
          </li>
          <li>
            Fornece orientação primária, de caráter geral e de direcionamento.
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default Call0800Page
