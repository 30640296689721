import { useCallback } from 'react'
import { useQuery } from 'react-query'

import { getHealthProfessionals } from 'services/admnistrativeService'

export default function useMedicalProfessionals() {
  const {
    data: healthProfessionals,
    isLoading: isHealthProfessionalsLoading,
    isError: isHealthProfessionalsError
  } = useQuery('health-professionals', getHealthProfessionals, {
    refetchOnWindowFocus: false
  })

  const getHealthProfessional = useCallback(
    (professionalId: string, type: 'DOCTOR' | 'NURSE') => {
      if (type === 'DOCTOR') {
        return healthProfessionals?.doctors.find(
          ({ id }) => id === Number(professionalId)
        )
      }
      return healthProfessionals?.nurses.find(
        ({ id }) => id === Number(professionalId)
      )
    },
    [healthProfessionals]
  )

  return {
    healthProfessionals,
    isHealthProfessionalsLoading,
    isHealthProfessionalsError,
    getHealthProfessional
  }
}
