/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
import React, { useState, useContext, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import Loader from 'react-loader-spinner'
import { TextField, withStyles } from '@material-ui/core'
import bugsnag from 'lib/bugsnag'
import MenuItem from '@material-ui/core/MenuItem'

import { Button } from 'components/ui'
import { ToastInvalidStyles, ToastSuccessStyles } from 'utils/ToastStyles'
import { APIContext } from 'medicalRecord/config/contexts/APIContext'
import { WindowContext } from 'medicalRecord/config/contexts/WindowContext'
import { checkStorage } from 'medicalRecord/utils/Utils'
import { ImagePreview, Layout } from 'medicalRecord/components/elements'
import PhysicalExam from './PhysicalExam'
import Summary from './Summary'
import DragNDrop from './DragNDrop'
import Divider from './Divider'

const DateInput = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 48,
      '& fieldset': {
        borderRadius: 8
      }
    }
  }
})(TextField)

const Select = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 48,
      '& fieldset': {
        borderRadius: 8
      }
    }
  }
})(TextField)

export const OTHER_TYPE = 'Outro'

export const EXAM_TYPES = [
  'Hemograma',
  'Triglicerides',
  'Ureia',
  'TSH Ultra Sensível',
  'T4 Livre',
  'Colesterol Total e Frações',
  'Transaminase Glutamica Piruvica TGP',
  'Transaminase Glutamica Oxalacetica FGO',
  'Gama Glutamil Transferase',
  'Creatina',
  'Urina e Glicemia',
  'Múltiplos exames',
  OTHER_TYPE
]

const Imagens = () => {
  const { attachmentHandler, handleGetRecords } = useContext(APIContext)

  const { loadingRecords } = useContext(WindowContext)

  const [files, setFiles] = useState([])
  const [date, setDate] = useState('')
  const [loading, setLoading] = useState(false)
  const [examType, setExamType] = useState('none')

  const [patientRecords, setPatientRecords] = useState({ data: [] })

  useEffect(() => {
    const check = checkStorage('anexos')
    if (!check) return

    setFiles(check.anexos)
    // eslint-disable-next-line
  }, [])

  const getRecordsImagens = async () => {
    const records = await handleGetRecords('conjunto_anexos')
    setPatientRecords(records)
  }

  useEffect(() => {
    getRecordsImagens()
    // eslint-disable-next-line
  }, [])

  const handleFileInput = async file => {
    setFiles([
      ...files,
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        exam_type: examType,
        date: date
      })
    ])
    setDate('')
    setExamType('none')
  }

  const handleDelete = async fileId => {
    const filteredFiles = files.filter(file => file.preview !== fileId)
    setFiles(filteredFiles)
  }

  const handleUpload = async () => {
    setLoading(true)
    try {
      const uploadFilePromises = files.map(file => {
        const data = new FormData()
        data.append('arquivo', file)
        data.append('exam_type', file.exam_type)
        data.append('upload_type', 'exam')
        data.append('date', file.date)
        return attachmentHandler('post', data)
      })
      await Promise.all(uploadFilePromises)
      await getRecordsImagens()
      setFiles([])
      toast('Exames enviados com sucesso!', ToastSuccessStyles)
    } catch (error) {
      toast('Erro ao enviar os exames!', ToastInvalidStyles)
      bugsnag.notify(`Erro ao enviar os exames! error: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const allowedTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf'
  ]

  const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    accept: allowedTypes,
    onDrop: acceptedFiles => {
      handleFileInput(acceptedFiles[0])
    }
  })

  const isUploadValid = date && examType ? true : false

  return (
    <div className="w-full pr-4">
      <ToastContainer />

      <PhysicalExam />

      <div className="mt-4">
        <>
          <Layout
            title="Histórico"
            className="overflow-y-scroll"
            style={{
              maxHeight: 268
            }}
          >
            {!loadingRecords ? (
              !patientRecords.empty ? (
                patientRecords.data.map(item => (
                  <Summary
                    className="summary_anexos"
                    info={item}
                    key={item.date}
                  />
                ))
              ) : (
                <div className="loader">
                  <p>Nenhum exame adicionado ainda</p>
                </div>
              )
            ) : (
              <div className="w-full flex items-center justify-center">
                <Loader
                  type="Oval"
                  color="#00BFFF"
                  height={28}
                  width={28}
                  timeout={60000}
                />
              </div>
            )}
          </Layout>
        </>
      </div>

      <Layout title="Adicionar exame" className="mt-8">
        <div className="flex gap-3">
          <div className="flex flex-col w-full">
            <label className="text-sm text-gray-900 mb-2" htmlFor="exams">
              Tipo de exame
            </label>
            <Select
              defaultValue="none"
              value={examType}
              onChange={elm => setExamType(elm.target.value)}
              variant="outlined"
              select
            >
              <MenuItem value="none" disabled>
                Selecione o tipo de exame
              </MenuItem>
              {EXAM_TYPES.map(exam => (
                <MenuItem value={exam}>{exam}</MenuItem>
              ))}
            </Select>
          </div>

          <div className="flex flex-col">
            <label className="text-sm text-gray-900" htmlFor="exams">
              Data de realização
            </label>
            <div className="mt-2">
              <DateInput
                value={date}
                onChange={elm => setDate(elm.target.value)}
                type="date"
                id="birthdate"
                variant="outlined"
              />
            </div>
          </div>
        </div>

        <div className="mt-8">
          <DragNDrop
            onClick={open}
            rootProps={getRootProps()}
            inputProps={getInputProps()}
            disabled={!isUploadValid}
          />
        </div>

        <div className="mt-8">
          {fileRejections.map(({ file, errors }) =>
            errors.map(e => (
              <span key={e.code}>Tipo de arquivo não suportado</span>
            ))
          )}

          {files.map((file, index) => {
            return (
              <>
                {index !== 0 && (
                  <div className="mt-4 mb-4">
                    <Divider />
                  </div>
                )}
                <ImagePreview
                  url={file.preview}
                  name={file.name}
                  exameType={file.exam_type}
                  key={file.preview}
                  file={file}
                  exclude={() => handleDelete(file.preview)}
                />
              </>
            )
          })}
        </div>
        <div className="mt-4 mb-4">
          <Divider />
        </div>
        <Button
          className="w-20"
          onClick={handleUpload}
          loading={loading}
          disabled={files.length === 0}
        >
          <p className="font-bold text-sm text-white p-3">Salvar</p>
        </Button>
      </Layout>
    </div>
  )
}

export default Imagens
