import { backendServices } from 'services/AxiosInstances'

export const api = backendServices

export const fetchApi = async (url, method, data, api = backendServices) => {
  let res
  switch (method) {
    case 'post':
      res = await api.post(url, data)
      break
    case 'put':
      res = await api.put(url, data)
      break
    case 'delete':
      res = await api.delete(url, data)
      break
    default: {
      res = await api.get(url)
    }
  }
  return res.data
}

// TODO: Criar utils de api e chama-los.

// Get Memed token

export const memedToken = async () => {
  return await backendServices
    .get('/adm/token_medico')
    .then(({ data }) => data.data)
    .catch(error => {
      throw new Error(error)
    })
}

export const rules = async () => {
  return await backendServices
    .get('/api/v1/rules')
    .then(({ data }) => data.data)
    .catch(error => {
      throw new Error(error)
    })
}

export const userRules = async id =>
  backendServices
    .get(`/api/v1/user/${id}/rules`, { params: { active: true } })
    .then(({ data }) => data.data)

export const setRule = async (id, rule_id) =>
  backendServices
    .post(`/api/v1/user/${id}/rules`, { notification_rule_id: rule_id })
    .then(({ data }) => data.data)

export const deleteRule = async (id, rule) =>
  backendServices.patch(`/api/v1/user/${id}/rules`, { user_rule_id: rule })

// ===

// Info handlers

export const getUserQuiz = async patientCpf => {
  return await backendServices
    .get(`/prontuario-v1/questionario/cpf/${patientCpf}`)
    .then(({ data }) => data.data)
    .catch(error => {
      throw new Error(error)
    })
}

export const getRecords = async (patientId, filterRecords) => {
  const res = backendServices
    .get(
      `/prontuario-v1/${patientId}${
        filterRecords === 'todos' ? '' : `?filter_type=${filterRecords}`
      }`
    )
    .then(({ data }) => data.data)
    .catch(error => {
      throw new Error(error)
    })

  return res
}

export const createEnum = async (name, type) => {
  const res = await backendServices
    .post(`/prontuario-v1/${type}`, { nome: name })
    .then(({ data }) => {
      if (typeof data.data === 'undefined') return false
      return {
        label: data.data.label,
        value: data.data.value
      }
    })
    .catch(error => {
      throw new Error(error)
    })

  return res
}

export const searchEnums = async (url, search, app_url = '/prontuario-v1') => {
  const res = await backendServices
    .get(`${app_url}/${url}?q=${search}`)
    .then(({ data }) => {
      if (typeof data.data === 'undefined') return false
      return data.data.map(item => ({
        label: item.label,
        value: item.value
      }))
    })
    .catch(error => {
      throw new Error(error)
    })

  return res
}

export const getEnums = async (url, search, app_url = '/prontuario-v1') =>
  await backendServices.get(`${app_url}/${url}?q=${search}`)

export const getHypothesis = ({ patientId, params }) =>
  backendServices.get(`/prontuario-v1/${patientId}/hipoteses`, {
    params
  })

export const getEnum = async (enumName, app_url = '/prontuario-v1') => {
  return backendServices
    .get(`${app_url}/enums/${enumName}`)
    .then(({ data }) => data.data)
    .catch(error => {
      throw new Error(error)
    })
}

// ===

// Multipart handlers

export const handleMultipart = async (
  patientId,
  method,
  formData,
  isDelete
) => {
  const url = isDelete
    ? `/prontuario-v1/${patientId}/delete_anexo`
    : `/prontuario-v1/${patientId}/anexos`

  const options = {
    method,
    url,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  const res = await backendServices(options)
    .then(({ data }) => data.data)
    .catch(error => {
      throw new Error(error)
    })

  return res
}

export const postHistoricalExam = async ({ data, patientId }) => {
  return backendServices({
    method: 'POST',
    url: `/prontuario-v1/${patientId}/historico`,
    data
  })
}

export const getHistoricalExams = async ({ params, patientId }) => {
  return backendServices({
    method: 'GET',
    url: `/prontuario-v1/${patientId}/historico`,
    params
  })
}

export const getFiles = async (patientId, fileSet) => {
  const res = await backendServices
    .get(`/prontuario-v1/anexos/${patientId}?conjunto_id=${fileSet}`)
    .then(({ data }) => data.data)
    .catch(error => console.error(error))

  return res
}

// ===

// Form handlers

export const finishForm = async (patientId, data) => {
  return backendServices
    .post(`/prontuario-v1/${patientId}`, data)
    .then(res => res)
    .catch(error => {
      throw new Error(error)
    })
}

// ===

export const getCareTasks = async id => {
  return backendServices
    .get(`/care/tasks/${id}`)
    .then(({ data }) => data.data)
    .catch(error => {
      throw new Error(error)
    })
}

export const getUserCareTasks = async id => {
  return backendServices
    .get(`/care/user/tasks/${id}`)
    .then(({ data }) => data.data)
    .catch(error => {
      throw new Error(error)
    })
}

export const getCarePeriods = async () => {
  return backendServices
    .get(`/care/periods`)
    .then(({ data }) => data.data)
    .catch(error => {
      throw new Error(error)
    })
}

export const postCarePlan = async data => {
  return backendServices
    .post(`/care/care-plan`, data)
    .then(res => res.data)
    .catch(error => {
      throw new Error(error)
    })
}
