import { useContext, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import { GlobalContext } from '../../../contexts/GlobalContext'
import { ModalContext } from '../../../contexts/ModalContext'

import MessagePrompt from '../../Profile/MessagePrompt'

import { Button, Section, FormInput, AnimatedDiv } from '../../ui'

import SelectType from './SelectType'
import TypeSwitch from './TypeSwitch/TypeSwitch'
import PricesDisplay from './PricesDisplay'
import FreeAppointmentCard from './FreeAppointmentCard'
import DedicatedDoctorCard from './DedicatedDoctorCard'

import { getSpecialties, appointmentsService } from '../../../services/booking'
import { openSpecialtySupport, openSupportWhatsApp } from '../BookingUtils'
import { SITE_URLS } from '../../../urls'
import { getParameterByName, removeCountryCode } from '../../../utils'

import { Parent, Item } from './Animate'

export const AppointmentType = [
  {
    label: 'Telemedicina',
    value: 'telemedicina'
  },
  {
    label: 'Presencial',
    value: 'presencial'
  }
]

const SelectAppointmentType = ({
  browserHistory,
  bookingOptions,
  setPriceToPay,
  isFree,
  isUnlimited,
  addOptions,
  user
}) => {
  const { handleModal, setCloseButton } = useContext(ModalContext)
  const { setLoading } = useContext(GlobalContext)

  const [canProceed, setCanProceed] = useState(false)
  const [prices, setPrices] = useState(null)

  const [specialties, setSpecialties] = useState(null)
  const [unavailableSpecialty, setUnavailableSpecialty] = useState(false)

  const [freeAppointment, setFreeAppointment] = useState(false)

  const handleChange = (name, value) => addOptions({ [name]: value })

  const setValues = () => {
    const {
      telehealth_price,
      telehealth_price_free,
      presential_consultation,
      presential_consultation_free
    } = bookingOptions.specialty.value

    switch (bookingOptions.type) {
      case 'telemedicina': {
        setPrices([telehealth_price, telehealth_price_free])
        if (!isFree()) {
          return setPriceToPay(telehealth_price)
        }
        return setPriceToPay(telehealth_price_free)
      }
      case 'presencial':
        setPrices([presential_consultation, presential_consultation_free])
        if (!isFree()) {
          return setPriceToPay(presential_consultation)
        }
        return setPriceToPay(presential_consultation_free)
      default:
        break
    }
  }

  useEffect(() => {
    if (!bookingOptions) return
    if (bookingOptions.type && bookingOptions.specialty !== undefined) {
      setValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingOptions])

  useEffect(() => {
    if (prices) {
      if (prices[0] === null && prices[1] === null) {
        setUnavailableSpecialty(true)
        return setCanProceed(false)
      }
      setUnavailableSpecialty(false)
      setCanProceed(true)
    }
  }, [prices])

  const handleProceed = () => {
    const url = `/agendamento/${bookingOptions.type}/${bookingOptions.specialty.id}`

    if (bookingOptions.type === 'telemedicina') {
      if (freeAppointment && !isFree()) addOptions({ free_booking: true })
      return browserHistory.push(`${url}/consultorio-virtual`)
    }

    const { id, name, cpf, birth, mobilePhone, email, onboarding_done } = user

    const params = `id=${id}&name=${name}&cpf=${cpf}&birth=${birth}&phone=${removeCountryCode(
      mobilePhone
    )}&email=${email}&isFree=${isFree()}&onboarding_done=${onboarding_done}&specialty=${
      bookingOptions.specialty.label
    }`

    return window.open(`${SITE_URLS.BOOK_APPOINTMENT}?${params}#/`, '_self')
  }

  const checkFirstFreeAppointment = async () => {
    const params =
      user.isStaff && getParameterByName('patient_id')
        ? {
            query: 'my_consultations',
            patient_id: getParameterByName('patient_id')
          }
        : { query: 'my_consultations' }

    const res = await appointmentsService(params)

    if (res.length === 0) setFreeAppointment(true)
  }

  const loadFirstInfos = async () => {
    setLoading(true)
    setCloseButton(false)

    try {
      const res = await getSpecialties()
      setSpecialties(res)
      await checkFirstFreeAppointment()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      handleModal(
        <MessagePrompt
          error
          message="Ocorreu um erro durante a sua requisição."
          errorAction={() => {
            window.location.reload()
            throw new Error(error)
          }}
        />
      )
    }
  }

  const handleDedicatedDoctor = () => {
    const specialty = specialties.find(
      ({ label }) => label === 'Saúde da familia'
    )

    addOptions({
      specialty,
      type: 'telemedicina'
    })

    setPriceToPay(specialty.value.telehealth_price)

    const url = `/agendamento/telemedicina/${specialty.id}`

    if (freeAppointment && !isFree()) addOptions({ free_booking: true })
    return browserHistory.push(`${url}/consultorio-virtual`)
  }

  useEffect(() => {
    loadFirstInfos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AnimatedDiv
      variants={Parent}
      initial="hidden"
      animate="show"
      out="hidden"
      className="container py-8 max-w-xl mx-auto"
    >
      <FreeAppointmentCard isFree={isFree} freeAppointment={freeAppointment} />

      {!isFree() && (
        <DedicatedDoctorCard handleDedicatedDoctor={handleDedicatedDoctor} />
      )}

      <AnimatedDiv layout variants={Item}>
        <Section className="rounded assina-shadow-sm">
          <h2 className="text-xl text-gray-900">
            Selecione o tipo de consulta
          </h2>

          <div style={{ marginTop: '21px' }}>
            <TypeSwitch
              selectOptionType={handleChange}
              bookingOptions={bookingOptions}
            />
          </div>

          <FormInput
            haveInput
            primary
            notAnimated
            className="my-8"
            wrapperStyle={{ padding: '0' }}
          >
            <SelectType
              options={specialties}
              onCreate={e => openSpecialtySupport(e)}
              name="specialty_id"
              placeholder="Selecione uma especialidade"
              value={
                bookingOptions &&
                bookingOptions.specialty &&
                bookingOptions.specialty
              }
              onChange={val => handleChange('specialty', val)}
            />
          </FormInput>

          {unavailableSpecialty && (
            <>
              <h1 className="text-xl mb-8 text-center">
                Essa especialidade está{' '}
                <span className="font-medium text-pink-600">Indisponível</span>{' '}
                para agendamento online no momento.{' '}
                <span
                  className="font-medium text-pink-600 cursor-pointer"
                  data-tip
                  data-for="not_available_specialty"
                >
                  Mais informações.
                </span>
              </h1>
              <ReactTooltip
                id="not_available_specialty"
                delayHide={1000}
                effect="solid"
                place="bottom"
                clickable
                className="text-center cursor-pointer"
              >
                <span className="text-base" onClick={openSupportWhatsApp}>
                  Não temos agenda disponíveis para essa <br />
                  especialidade. Em caso de dúvidas, clique aqui <br />
                  para entrar em contato com suporte.
                </span>
              </ReactTooltip>
            </>
          )}

          <Button
            className="w-full"
            id="iniciar-agendamento"
            disabled={!canProceed}
            onClick={handleProceed}
          >
            Próximo
          </Button>
        </Section>
      </AnimatedDiv>

      {!unavailableSpecialty && prices && (
        <PricesDisplay
          prices={prices}
          isFree={isFree}
          isUnlimited={isUnlimited}
          freeAppointment={freeAppointment}
          type={bookingOptions.type}
        />
      )}
    </AnimatedDiv>
  )
}

export default SelectAppointmentType
