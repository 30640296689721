import Icon from '../../ui/Icon'

const UploadFormTitle = props => {
  return (
    <div className="text-center">
      {props.action === '' && (
        <h1 className="text-xl font-normal">Adicionar exame ou anexo</h1>
      )}
      {props.action === 'edit' && (
        <div className="flex flex-row w-full justify-center">
          <Icon name="edit" size={20} />
          <h1 className="text-xl font-normal ml-4">
            Editar {props.uploadType === 'exam' ? 'exame' : 'anexo'}
          </h1>
        </div>
      )}
      {props.action === 'delete' && (
        <div className="flex flex-row w-full justify-center">
          <Icon name="delete" size={20} />
          <h1 className="text-xl font-normal ml-4">
            Deletar {props.uploadType === 'exam' ? 'exame' : 'anexo'}
          </h1>
        </div>
      )}
    </div>
  )
}

export default UploadFormTitle
