import { SummarySection, Chip } from '../../elements'

const PartialAnamnesis = ({ info, isMultiple, indexOnData, maxIndex }) => {
  return Object.keys(info).length !== 0 ? (
    <>
      <SummarySection
        createdBy={info.created_by.nome}
        date={info.data_cadastro.split(' ')[1]}
        title="Anamnese"
        isMultiple={isMultiple}
        indexOnData={indexOnData}
        maxIndex={maxIndex}
      >
        <div className="description-fields">
          {info.queixa_principal ? (
            <div>
              <h3>Queixa Principal</h3>
              <p>{info.queixa_principal.label}</p>
            </div>
          ) : null}

          {info.cid || info.ciap ? (
            <div>
              <h3>Queixa Principal</h3>
              <div className="chip-div">
                <h4 className="text-sm">CID</h4>
                {info.cid
                  ? info.cid.map(item => <Chip label={item} key={item} />)
                  : '-'}
              </div>
              <div className="chip-div">
                <h4 className="text-sm">CIAP</h4>
                {info.ciap
                  ? info.ciap.map(item => <Chip label={item} key={item} />)
                  : '-'}
              </div>
              {info.HPMA && (
                <div>
                  <h4 className="text-sm">HPMA</h4>
                  <p>{info.HPMA}</p>
                </div>
              )}
            </div>
          ) : null}

          {info.queixa_secundaria && (
            <div>
              <h3>Queixa Secundária</h3>
              <p>
                {info.queixa_secundaria ? info.queixa_secundaria.label : '-'}
              </p>
            </div>
          )}

          {info.doencas_adulto.length !== 0 && (
            <div>
              <h3>Doenças Crônicas</h3>
              <div className="chip-div">
                {info.doencas_adulto.length !== 0
                  ? info.doencas_adulto.map(item => (
                      <Chip label={item.label} key={item.value} />
                    ))
                  : '-'}
              </div>
            </div>
          )}

          {info.historico_familiar_cid || info.historico_familiar_ciap ? (
            <>
              <h3 className="text-2xl">Histórico familiar</h3>
              <div className="chip-div">
                <h4 className="text-sm">CID</h4>
                {info.historico_familiar_cid
                  ? info.historico_familiar_cid.map(item => (
                      <Chip label={item} key={item} />
                    ))
                  : '-'}
              </div>
              <div className="chip-div">
                <h4 className="text-sm">CIAP</h4>
                {info.historico_familiar_ciap
                  ? info.historico_familiar_ciap.map(item => (
                      <Chip label={item} key={item} />
                    ))
                  : '-'}
              </div>
              {info.observacoes && (
                <div>
                  <h4 className="text-sm">Observações</h4>
                  <p>{info.observacoes}</p>
                </div>
              )}
            </>
          ) : null}

          {info.historico_familiar.length !== 0 && (
            <div>
              <h3>Historico familiar</h3>
              <div className="chip-div">
                {info.historico_familiar.length !== 0
                  ? info.historico_familiar.map(item => (
                      <Chip label={item.label} key={item.value} />
                    ))
                  : '-'}
              </div>
            </div>
          )}

          {info.habitos.length !== 0 && (
            <div>
              <h3>Hábitos</h3>
              <div className="chip-div">
                {info.habitos.length !== 0
                  ? info.habitos.map(item => (
                      <Chip label={item.label} key={item.value} />
                    ))
                  : '-'}
              </div>
            </div>
          )}

          {info.estilo_vida && (
            <div>
              <h3>Estilo de vida</h3>
              <p>{info.estilo_vida ? info.estilo_vida.label : '-'}</p>
            </div>
          )}

          {info.alergias_medicamentos.length > 0 || info.alergias.length > 0 ? (
            <>
              <h3 className="text-xl">Alergias</h3>
              <div>
                <h4 className="text-sm">A medicamentos</h4>
                <div className="chip-div">
                  {info.alergias_medicamentos.length !== 0
                    ? info.alergias_medicamentos.map(item => (
                        <Chip label={item.label} key={item.value} />
                      ))
                    : '-'}
                </div>
              </div>
              <div>
                <h4 className="text-sm">Outras</h4>
                <div className="chip-div">
                  {info.alergias.length !== 0
                    ? info.alergias.map(item => (
                        <Chip label={item.label} key={item.value} />
                      ))
                    : '-'}
                </div>
              </div>
            </>
          ) : null}

          {info.medicamentos && (
            <div>
              <h3>Medicamentos</h3>
              <p>{info.medicamentos ? info.medicamentos : '-'}</p>
            </div>
          )}

          {info.historico_molestia && (
            <div>
              <h3>Histórico da moléstia</h3>
              <p>{info.historico_molestia ? info.historico_molestia : '-'}</p>
            </div>
          )}

          {info.resultados_exames && (
            <div>
              <h3>Resultados dos Exames</h3>
              <p>{info.resultados_exames ? info.resultados_exames : '-'}</p>
            </div>
          )}

          {info.exame_fisico && (
            <div>
              <h3>Exame físico</h3>
              <p>{info.exame_fisico ? info.exame_fisico : '-'}</p>
            </div>
          )}

          {info.acompanhamentos.length !== 0 && (
            <div>
              <h3>Acompanhamento(s) Medico</h3>

              <div className="especialidade-container">
                {info.acompanhamentos.length !== 0
                  ? info.acompanhamentos.map(item => (
                      <div className="aaa">
                        {item.especialidade.nome && (
                          <p className="dados">
                            Especialidade:{' '}
                            {item.especialidade.nome ? (
                              item.especialidade.nome
                            ) : (
                              <p> - </p>
                            )}
                          </p>
                        )}
                        {item.motivo && (
                          <>
                            <p className="dados">
                              Motivo:
                              {item.motivo ? item.motivo : <p> - </p>}
                            </p>
                          </>
                        )}
                      </div>
                    ))
                  : '-'}
              </div>
            </div>
          )}

          {info.careplans && info.careplans.length !== 0 && (
            <div>
              <h3>Linhas de Cuidado</h3>

              <div className="especialidade-container">
                {info.careplans && info.careplans.length !== 0
                  ? info.careplans.map(item => (
                      <div className="aaa">
                        {item.caring && item.caring.description && (
                          <p className="dados">
                            {item.caring && item.caring.description ? (
                              item.caring.description
                            ) : (
                              <p> - </p>
                            )}
                          </p>
                        )}
                        {item.caring && (
                          <ul className="ml-6 my-2 list-disc">
                            {item.caring &&
                              item.caring.tasks
                                .sort(
                                  (a, b) =>
                                    a.caring &&
                                    b.caring &&
                                    a.caring.description &&
                                    b.caring.description &&
                                    a.caring.description.localeCompare(
                                      b.caring.description
                                    )
                                )
                                .map(m => (
                                  <li>
                                    {m.description} - {m.period.description}
                                  </li>
                                ))}
                          </ul>
                        )}
                      </div>
                    ))
                  : '-'}
              </div>
            </div>
          )}

          {info.adendo && (
            <div>
              <h3>Adendos</h3>
              <p>{info.adendo ? info.adendo : '-'}</p>
            </div>
          )}
        </div>
      </SummarySection>
    </>
  ) : null
}

export default PartialAnamnesis
