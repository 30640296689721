import { differenceInHours } from 'date-fns'

import { ACCREDITATION_WAITING_STATUS } from './constants'
import { parseDateHour } from './formaters'

export const getWaitingStatus = (date: string, border = true) => {
  const createdDate = parseDateHour(date)
  const dateNow = new Date()

  const hoursDifference = differenceInHours(dateNow, createdDate)

  if (hoursDifference <= ACCREDITATION_WAITING_STATUS.AHEAD) {
    return `${border ? 'border-green-600' : 'bg-green-600'}`
  }
  if (hoursDifference <= ACCREDITATION_WAITING_STATUS.ATTENTION) {
    return `${border ? 'border-new_yellow-500' : 'bg-new_yellow-500'}`
  }
  if (hoursDifference <= ACCREDITATION_WAITING_STATUS.MISSING) {
    return `${border ? 'border-orange-500' : 'bg-orange-500'}`
  }
  if (
    hoursDifference <= ACCREDITATION_WAITING_STATUS.MISSED ||
    hoursDifference > ACCREDITATION_WAITING_STATUS.MISSED
  ) {
    return `${border ? 'border-warning-dark' : 'bg-warning-dark'}`
  }
}

export const getPeriod = (period: string): string =>
  period === 'morning' ? 'Manhã' : 'Tarde'

export const AMOUNT_OF_COLUMNS = 5
export const CARD_MIN_WIDTH = 250
const SAFE_DASHBOARD_MARGIN = 100

export const DASHBOARD_MIN_WIDTH =
  CARD_MIN_WIDTH * AMOUNT_OF_COLUMNS + SAFE_DASHBOARD_MARGIN
