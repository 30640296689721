import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import Logo from 'components/ui/Logo/Logo'
import DesktopNavbar from 'components/layouts/Navbar/DesktopNavbar'
import MobileNavbar from 'components/layouts/Navbar/MobileNavbar'

import useResize from 'hooks/useResize'

import { APP_URLS } from 'urls'
import { SIZES } from 'utils/constants'

interface ILayout {
  children: ReactNode
}

export default function Layout({ children }: ILayout) {
  const { width, height } = useResize()

  const isMobile: boolean = width <= SIZES.md

  return (
    <div className={cn('max-h-screen min-h-screen', !isMobile && 'flex')}>
      {isMobile && (
        <div className="flex items-center bg-pink-600 py-4">
          <Link to={APP_URLS.HOME}>
            <Logo height="24" />
          </Link>
        </div>
      )}
      {!isMobile && <DesktopNavbar />}
      <main
        className="bg-white sm:bg-gray-300 overflow-y-auto w-full pb-16 md:pb-0 lg:pb-0"
        style={{ minHeight: isMobile ? height - 56 : height }}
        id="app-main"
      >
        {children}
      </main>
      {isMobile && <MobileNavbar />}
    </div>
  )
}
