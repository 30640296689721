import { ChangeEvent, ReactNode } from 'react'
import { Control, RegisterOptions } from 'react-hook-form'
import { InputAdornment, MenuItem, SelectProps } from '@material-ui/core'
import { ChevronDownIcon } from '@heroicons/react/outline'
import mergeRefs from 'react-merge-refs'

import FormLabelController from '../FormLabelController'
import Text from 'components/ui/Text'
import { KompaGraySelect, KompaWhiteSelect } from 'components/ui/KompaSelect'

type Option = {
  value: string | number
  label: string
}

interface ControlledSelectProps extends Omit<SelectProps, 'onChange'> {
  label?: string
  name: string
  inputColor: 'gray' | 'white'
  control: Control
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  errorMessage?: string
  defaultOption?: string
  onChange?: (
    event: ChangeEvent<{
      value: unknown
    }>
  ) => void
  options: Option[]
  startIcon?: ReactNode
  controllerDefaultValue?: unknown
}

export default function ControlledSelect({
  label,
  control,
  name,
  inputColor,
  rules,
  onChange,
  error,
  errorMessage,
  options,
  defaultOption,
  startIcon,
  inputRef,
  controllerDefaultValue,
  ...rest
}: ControlledSelectProps) {
  const Select = inputColor === 'gray' ? KompaGraySelect : KompaWhiteSelect

  return (
    <div>
      <FormLabelController
        label={label}
        forInput={name}
        name={name}
        control={control}
        rules={rules}
        defaultValue={controllerDefaultValue}
        render={({
          onChange: controllerOnChange,
          value: controllerValue,
          ref: controllerRef,
          ...props
        }) => (
          <Select
            data-testid={`${name}-testId`}
            id={name}
            variant="filled"
            error={error}
            displayEmpty
            style={{
              backgroundColor: inputColor === 'gray' ? '#f2f2f2' : '#fbfbfb'
            }}
            startAdornment={
              startIcon ? (
                <InputAdornment position="start">{startIcon}</InputAdornment>
              ) : null
            }
            IconComponent={props => (
              <ChevronDownIcon
                {...props}
                style={{ height: '24px', color: '#D20E50' }}
              />
            )}
            value={controllerValue}
            onChange={e => {
              controllerOnChange(e.target.value)
              onChange && onChange(e)
            }}
            inputRef={
              inputRef ? mergeRefs([controllerRef, inputRef]) : controllerRef
            }
            {...props}
            {...rest}
          >
            <MenuItem value="">
              {defaultOption ? defaultOption : 'Selecione'}
            </MenuItem>
            {options.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {error && (
        <Text
          as="span"
          className="text-sm select-none font-normal"
          style={{ color: '#b00020' }}
        >
          {errorMessage}
        </Text>
      )}
    </div>
  )
}

/*
  Example Usage

  const {
    control,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: { testInput: '' }
  })

  return (
    <ControlledSelect
      label="Test Select"
      forInput="testSelect"
      defaultOption="None"
      name="testSelect"
      options={[{ value: '1', label: 'Example' }]}
      control={control}
      inputColor="white"
      fullWidth
      startIcon={<SearchIcon className="h-5 text-pink-600" />}
      rules={{ required: true }}
      error={!!errors.testSelect}
      errorMessage={`${
        errors.testSelect?.type === 'required' &&
        FORM_FIELD_MESSAGES.REQUIRED_FIELD
      }`}
    />
  )
*/
