import { createContext, useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useFeatureFlags } from 'posthog-js/react'

import useAuth from 'hooks/useAuth'

import { objToQueryParams, noop } from '../utils'
import { APP_URLS, BACKEND_URLS, SITE_URLS } from '../urls'
import WelcomeModal from '../containers/WelcomeModal/WelcomeModal'
import UpgradeBenefitsModal from '../components/UpgradeBenefitsModal/UpgradeBenefitsModal'
import AppSkeleton from '../components/AppSkeleton/AppSkeleton'
import { DropdownLoader } from '../components/common'
import { fetchApi } from '../services/api'
import { ModalContext } from './ModalContext'
import UpdateUserInfos from '../components/UpdateUserInfos/UpdateUserInfos'
import { getDiffDateToDateNow } from '../utils/Dates'
import { getCookie } from 'utils/cookie'

export const GlobalContext = createContext({
  user: {},
  isLoggedIn: () => {},
  navigateToFeature: feat => {},
  isFree: () => {},
  isUnlimited: () => {},
  holder: () => {},
  loading: () => null,
  setLoading: () => null,
  featureFlags: () => {}
})

export const FEATURES = {
  BOOK_PHYSICAL_DOCTOR: 'BOOK_PHYSICAL_DOCTOR',
  SUBSCRIBE: 'SUBSCRIBE',
  ORIENTATION_24H: 'ORIENTATION_24H',
  DIGITAL_CARD: 'DIGITAL_CARD',
  DRUGSTORES: 'DRUGSTORES',
  BLOG: 'BLOG',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  EXAMS: 'EXAMS',
  HEALTH_MONITORING: 'HEALTH_MONITORING',
  BOOKING: 'BOOKING',
  TELEMEDICINE_VALUES: 'TELEMEDICINE_VALUES',
  PRESCRIPTIONS: 'PRESCRIPTIONS',
  VIRTUAL_CLINIC: 'VIRTUAL_CLINIC',
  SCHEDULE: 'SCHEDULE'
}

const INITIAL_BENEFITS_MODAL = {
  open: false,
  title: '',
  onAccept: () => window.open(SITE_URLS.PLANS),
  onDeny: noop()
}

const DEVELOPMENT_FEATURE_FLAGS = {
  enabled: { 'new-notification-home': true }
}

const GlobalContextProvider = ({ children }) => {
  const history = useHistory()

  const { handleModal } = useContext(ModalContext)
  const { user } = useAuth()

  const [benefitsModal, setBenefitsModal] = useState(INITIAL_BENEFITS_MODAL)
  const isFirstLogin = Boolean(getCookie('first_login'))
  const isFree = () => user.isFree
  const isUnlimited = () => user.isUnlimited
  const isLoggedIn = () => user.cpf
  const holder = user.account_holder
  const [loading, setLoading] = useState(false)

  const featureFlags =
    process.env.APP_ENV === 'production'
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useFeatureFlags()
      : DEVELOPMENT_FEATURE_FLAGS

  useEffect(() => {
    if (!user.cpf)
      window.location.href = BACKEND_URLS.__LOGIN_AND_REDIRECT(
        BACKEND_URLS.SPA_HOME
      )

    if (user.senha_temporaria) history.replace(APP_URLS.CHANGE_PASSWORD_WELCOME)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openBenefitsModal = (title, { afterDeny } = {}) => {
    setBenefitsModal({
      ...INITIAL_BENEFITS_MODAL,
      title,
      open: true,
      onDeny: () => {
        setBenefitsModal(INITIAL_BENEFITS_MODAL)
        afterDeny && afterDeny()
      }
    })
  }

  const navigateToFeature = feature => {
    switch (feature) {
      case FEATURES.DIGITAL_CARD:
        history.push(APP_URLS.HEALTH_CARD)
        break
      case FEATURES.ORIENTATION_24H:
        if (isFree()) {
          openBenefitsModal(
            'Receba orientações de saúde 24 horas, de onde você estiver.'
          )
        } else {
          history.push(APP_URLS.CALL_0800)
        }
        break
      case FEATURES.SUBSCRIBE:
        openBenefitsModal(
          'Tenha uma equipe digital para te ajudar com qualquer problema ao longo da vida'
        )
        break
      case FEATURES.BOOK_PHYSICAL_DOCTOR:
        if (isFree()) {
          openBenefitsModal(
            'Ganhe desconto na sua consulta em qualquer especialidade.',
            {
              afterDeny: () => window.open(getBookingUrl(user), '_blank')
            }
          )
        } else {
          window.open(getBookingUrl(user), '_blank')
        }
        break
      case FEATURES.DRUGSTORES:
        if (isFree())
          openBenefitsModal(
            'Ganhe descontos de até 50% em farmácias próximas a você.'
          )
        else window.location.href = BACKEND_URLS.DRUGSTORES
        break
      case FEATURES.BLOG:
        window.location.href = SITE_URLS.BLOG
        break
      case FEATURES.QUESTIONNAIRE:
        history.push(APP_URLS.QUESTIONNAIRE_WELCOME)
        break
      case FEATURES.EXAMS:
        history.push(APP_URLS.EXAM_UPLOAD)
        break
      case FEATURES.HEALTH_MONITORING:
        history.push(APP_URLS.HEALTH_MONITORING)
        break
      case FEATURES.PRESCRIPTIONS:
        history.push(APP_URLS.PRESCRIPTIONS)
        break
      case FEATURES.BOOKING:
        history.push(APP_URLS.BOOKING)
        break
      case FEATURES.TELEMEDICINE_VALUES:
        history.push(APP_URLS.TELEMEDICINE_VALUES)
        break
      case FEATURES.VIRTUAL_CLINIC:
        history.push(APP_URLS.VIRTUAL_CLINIC)
        break
      case FEATURES.SCHEDULE:
        history.push(APP_URLS.SCHEDULE)
        break
      default:
        throw new Error(`unknown feature ${feature}`)
    }
  }

  /*
      disabled function because its causing several backend errors
    */
  // eslint-disable-next-line no-unused-vars
  const getLastUpdate = async () => {
    try {
      const res = await fetchApi(`/adm/user/last_update`)
      const diff = getDiffDateToDateNow(res.data.last_update)
      if (diff >= 3) {
        handleModal(
          <div className="max-w-lg overflow-auto">
            <UpdateUserInfos />
          </div>
        )
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        user,
        isLoggedIn,
        navigateToFeature,
        isFree,
        isUnlimited,
        loading,
        setLoading,
        holder,
        featureFlags
      }}
    >
      <DropdownLoader color="pink" show={loading} />
      <WelcomeModal isOpen={isFirstLogin} />
      <UpgradeBenefitsModal
        isOpen={benefitsModal.open}
        title={benefitsModal.title}
        onAccept={benefitsModal.onAccept}
        onDeny={benefitsModal.onDeny}
      />
      {!user.cpf ? <AppSkeleton /> : children}
    </GlobalContext.Provider>
  )
}

const getBookingUrl = user => {
  const v = { ...user }
  if (v.phone) delete v.mobilePhone
  else {
    v.phone = v.mobilePhone
    delete v.mobilePhone
  }
  const url = objToQueryParams(v)
  return `${SITE_URLS.BOOK_APPOINTMENT}?${url}`
}

export default GlobalContextProvider
