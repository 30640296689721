/* eslint-disable prefer-rest-params */
import Switch from 'react-switch'
import cn from 'classnames'

function debounce(func, wait, immediate) {
  let timeout
  return () => {
    const context = this
    const args = arguments
    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

const NotificationSwitch = ({
  name,
  checked,
  onChange,
  description,
  obligatory,
  notSupported,
  defaultChecked,
  disabled
}) => {
  const isDisabled = obligatory || notSupported || disabled

  return (
    <label
      className={cn(
        'flex flex-col',
        isDisabled ? 'cursor-default' : 'cursor-pointer'
      )}
    >
      <span className="text-lg text-teal-600 font-medium select-none">
        {name}
      </span>
      <div className="my-2 flex">
        <Switch
          disabled={isDisabled}
          checked={checked}
          onChange={debounce(onChange, 500)}
          boxShadow="0px 2.65625px 5.3125px 0.53125px rgba(0, 0, 0, 0.05)"
          offColor="#ebebeb"
          onColor="#008F8F"
          offHandleColor="#bfbfbf"
          onHandleColor="#ffffff"
          uncheckedIcon={false}
          checkedIcon={false}
          height={24}
          width={48}
          defaultChecked={defaultChecked}
        />
        {obligatory && (
          <span className="text-base text-red-600 ml-2 select-none">
            Obrigatório
          </span>
        )}
      </div>
      {notSupported && (
        <span className="text-base text-red-600 my-2 select-none">
          Não suportado pelo seu navegador
        </span>
      )}
      <p className="text-base text-gray-800 select-none">{description}</p>
    </label>
  )
}

export default NotificationSwitch
