import Head from 'components/common/Head'
import BookingView from '../../components/Booking'

const Booking = () => (
  <>
    <Head title="Agendar consulta" />
    <BookingView />
  </>
)

export default Booking
