import { ToastOptions } from 'react-toastify'

const ToastStyles: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  hideProgressBar: false,
  progressStyle: {
    background: '#d20e50'
  },
  bodyStyle: {
    color: '#303030'
  }
}

export const ToastSuccessStyles: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  hideProgressBar: false,
  progressStyle: {
    background: '#38a169'
  },
  bodyStyle: {
    color: '#fff'
  },
  style: {
    backgroundColor: '#5AC484'
  }
}

export const ToastInvalidStyles: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  hideProgressBar: false,
  progressStyle: {
    background: '#9b2c2c'
  },
  bodyStyle: {
    color: '#fff'
  },
  style: {
    backgroundColor: '#D90D0D'
  }
}

export default ToastStyles
