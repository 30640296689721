import styled from 'styled-components'

export const FilterButtons = styled.div`
  display: flex;
  flex-wrap: wrap;

  button:last-child {
    margin-top: 8px;
  }

  @media (min-width: 425px) {
    flex-wrap: nowrap;

    button:last-child {
      margin-top: 0px;
      margin-left: 8px;
    }
  }
`
