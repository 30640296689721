import { ReactNode } from 'react'
import { Control, RegisterOptions } from 'react-hook-form'
import { InputAdornment, TextFieldProps } from '@material-ui/core'
import mergeRefs from 'react-merge-refs'

import { KompaGrayInput, KompaWhiteInput } from 'components/ui/KompaInput'
import Text from 'components/ui/Text'
import FormLabelController from '../FormLabelController'

interface ControlledInputProps extends Omit<TextFieldProps, ''> {
  label?: string
  name: string
  inputColor: 'gray' | 'white'
  control: Control
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  errorMessage?: string
  startIcon?: ReactNode
  wrapperClassName?: string
  controllerDefaultValue?: unknown
}

export default function ControlledInput({
  label,
  control,
  name,
  inputColor,
  rules,
  onChange,
  onBlur,
  error,
  errorMessage,
  startIcon,
  inputRef,
  wrapperClassName,
  controllerDefaultValue,
  ...rest
}: ControlledInputProps) {
  const Input = inputColor === 'gray' ? KompaGrayInput : KompaWhiteInput

  return (
    <div className={wrapperClassName}>
      <FormLabelController
        label={label}
        forInput={name}
        name={name}
        control={control}
        rules={rules}
        defaultValue={controllerDefaultValue}
        render={({
          onChange: controllerOnChange,
          onBlur: controllerOnBlur,
          ref: controllerRef,
          ...props
        }) => (
          <Input
            data-testid={`${name}-testId`}
            id={name}
            variant="filled"
            error={error}
            InputProps={{
              startAdornment: startIcon ? (
                <InputAdornment position="start">{startIcon}</InputAdornment>
              ) : null
            }}
            onChange={e => {
              controllerOnChange(e.target.value)
              onChange && onChange(e)
            }}
            onBlur={e => {
              controllerOnBlur()
              onBlur && onBlur(e)
            }}
            inputRef={
              inputRef ? mergeRefs([controllerRef, inputRef]) : controllerRef
            }
            {...props}
            {...rest}
          />
        )}
      />
      {error && (
        <Text
          as="span"
          className="text-sm select-none font-normal"
          style={{ color: '#b00020' }}
        >
          {errorMessage}
        </Text>
      )}
    </div>
  )
}

/*
  Example Usage

  const {
    control,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: { testInput: '' }
  })

  return (
    <ControlledInput
      label="Test Input"
      forInput="testInput"
      name="testInput"
      control={control}
      placeholder="Type test"
      inputColor="white"
      startIcon={<SearchIcon className="h-5 text-pink-600" />}
      rules={{ required: true }}
      error={!!errors.testInput}
      errorMessage={`${
        errors.testInput?.type === 'required' &&
        FORM_FIELD_MESSAGES.REQUIRED_FIELD
      }`}
    />
  )
*/
