import React, { useState, useEffect, useContext, useRef } from 'react'

import {
  DesktopLayout,
  MobileLayout,
  Skeleton
} from '../../components/HealthMonitoring'

import measureTypes from '../../utils/measureTypes'

import { getUserSpecificHistory, postSpecificHistory } from '../../services/api'

import { GlobalContext } from '../../contexts/GlobalContext'

export const getAppointmentType = subject =>
  measureTypes.find(item => item.name === subject).type

export default function HealthMonitoring() {
  const { user } = useContext(GlobalContext)

  const [width, setWidth] = useState(0)

  const [subject, setSubject] = useState()
  const [subjectData, setSubjectData] = useState()
  const [hasNoRecords, setHasNoRecords] = useState(false)

  const [loading, setLoading] = useState(false)

  const inputRef = useRef()

  useEffect(() => {
    const newWidth = window.innerWidth
    setWidth(newWidth)
  }, [])

  useEffect(() => {
    const updateWidth = () => {
      const newWidth = window.innerWidth
      setWidth(newWidth)
    }

    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  const getHistory = async (id, item) => {
    const res = await getUserSpecificHistory(id, item)
    if (res.data.length === 0) {
      return setTimeout(() => {
        setHasNoRecords(true)
        return setLoading(false)
      }, 600)
    }
    setLoading(false)
    setHasNoRecords(false)
    setSubjectData(res)
  }

  const postHistory = (id, data) => postSpecificHistory(id, data)

  const addHistory = async data => {
    const { nickname } = measureTypes.find(item => item.name === subject)

    const info = {
      [nickname]: data
    }

    postHistory(user.id, info)

    setTimeout(async () => {
      await updateHistory()

      if (hasNoRecords) {
        setHasNoRecords(false)
      }
    }, 300)
  }

  const updateHistory = async () => {
    if (!subject) {
      return
    }
    const { nickname } = measureTypes.find(item => item.name === subject)
    await getHistory(user.id, nickname)
  }

  useEffect(() => {
    setLoading(true)
    updateHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject])

  return width === 0 ? (
    <Skeleton />
  ) : width < 769 ? (
    <MobileLayout
      subject={subject}
      setSubject={setSubject}
      measureTypes={measureTypes}
      width={width}
      subjectData={subjectData}
      addHistory={addHistory}
      hasNoRecords={hasNoRecords}
      loading={loading}
    />
  ) : (
    <DesktopLayout
      subject={subject}
      setSubject={setSubject}
      measureTypes={measureTypes}
      width={width}
      subjectData={subjectData}
      addHistory={addHistory}
      inputRef={inputRef}
      hasNoRecords={hasNoRecords}
      loading={loading}
    />
  )
}
