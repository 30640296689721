import { FormEvent, useState } from 'react'
import { useQuery } from 'react-query'
import { format } from 'date-fns'

import Head from 'components/common/Head'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import Spinner from 'components/ui/Spinner'
import TableLayout from 'components/layouts/VirtualClinic/TableLayout'
import FiltersLayout from 'components/layouts/VirtualClinic/FiltersLayout'

import useAuth from 'hooks/useAuth'

import { fetchApi } from 'services/api'

import { getAppointmentStatusValue } from 'utils'

import { IAppointment } from 'types/Appointment'

export default function VirtualClinicPage() {
  const { user } = useAuth()

  const [status, setStatus] = useState<string>('')
  const [date, setDate] = useState<string>('')
  const [doctorName, setDoctorName] = useState<string>('')
  const [patientName, setPatientName] = useState<string>('')
  const [hasFilters, setHasFilters] = useState(false)

  const getConsultations = (hasFilters: boolean): Promise<IAppointment[]> => {
    try {
      const url = hasFilters
        ? `/api/v1/consultation/adm?agenda_date=${
            date || format(new Date(), 'yyyy-MM-dd')
          }&doctor_name=${doctorName || (user && user.name)}${
            patientName && `&patient_name=${patientName}`
          }${status && `&status=${getAppointmentStatusValue(status)}`}`
        : `/api/v1/consultation/adm?agenda_date=${format(
            new Date(),
            'yyyy-MM-dd'
          )}&doctor_name=${user && user.name}`
      if (!date && !doctorName && !patientName && !status) {
        setHasFilters(false)
      }
      return fetchApi(url).then(json => json.data)
    } catch (error) {
      throw error
    }
  }

  const {
    data: appointments,
    isError,
    isLoading,
    refetch
  } = useQuery(
    hasFilters ? 'appointments' : ['appointments', hasFilters],
    () => getConsultations(hasFilters),
    {
      refetchInterval: 5000
    }
  )

  const applyFilters = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setHasFilters(true)
    refetch()
  }

  const cleanFilters = () => {
    setHasFilters(false)
    setStatus('')
    setDate('')
    setDoctorName('')
    setPatientName('')
    refetch()
  }

  return (
    <>
      <Head title="Consultório Virtual" />
      <div className="flex flex-col w-full">
        <div className="bg-gray-100 text-center py-8">
          <Heading className="text-pink-600 font-semibold text-4xl">
            Consultório Virtual
          </Heading>
          <Text as="h2" className="text-2xl text-gray-700">
            Exibindo todas as suas consultas
          </Text>
        </div>

        <FiltersLayout
          status={status}
          setStatus={setStatus}
          date={date}
          setDate={setDate}
          doctorName={doctorName}
          setDoctorName={setDoctorName}
          patientName={patientName}
          setPatientName={setPatientName}
          applyFilters={applyFilters}
          cleanFilters={cleanFilters}
        />

        {isLoading && (
          <div className="flex items-center justify-center mb-4 mt-8 h-full">
            <Spinner color="pink" size={35} />
          </div>
        )}

        {!isLoading && isError && (
          <Text className="text-center flex items-center justify-center mb-4 mt-8 h-full">
            Não foi possível carregar suas consultas.
          </Text>
        )}

        {!isLoading && !isError ? (
          appointments && appointments.length !== 0 ? (
            <div className="py-6 px-4">
              <TableLayout appointments={appointments} />
            </div>
          ) : (
            <div className="w-full text-center py-16 text-lg font-semibold">
              <Heading>Sem consultas agendadas!</Heading>
            </div>
          )
        ) : null}
      </div>
    </>
  )
}
