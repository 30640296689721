import { createContext, useEffect, useState } from 'react'

import { PortalModal as Modal } from '../components/ui'
import { useModalContext } from './ModalContext'

export const WindowContext = createContext({})

export const WindowProvider = ({ children }) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const { isBottom, closeButton } = useModalContext()

  useEffect(() => {
    const newWidth = window.innerWidth
    const newHeight = window.innerHeight
    setWidth(newWidth)
    setHeight(newHeight)
  }, [])

  useEffect(() => {
    const updateScreen = () => {
      const newWidth = window.innerWidth
      const newHeight = window.innerHeight
      setWidth(newWidth)
      setHeight(newHeight)
    }

    window.addEventListener('resize', updateScreen)

    return () => window.removeEventListener('resize', updateScreen)
  }, [])

  return (
    <WindowContext.Provider
      value={{
        width,
        height
      }}
    >
      <Modal isBottom={isBottom} closeButton={closeButton} />
      {width !== 0 && children}
    </WindowContext.Provider>
  )
}
