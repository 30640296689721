import Heading from 'components/ui/Heading'

export default function PageHeader({ title }: { title: string }) {
  return (
    <div className="bg-gray-100 text-center py-6">
      <Heading className="text-pink-600 font-semibold text-3xl">
        {title}
      </Heading>
    </div>
  )
}
