import { Button, Icon } from '.'

const Form = ({
  onSubmit,
  children,
  title,
  id,
  save,
  hour,
  print,
  handlePrint,
  hideButtons
}) => {
  return (
    <form className="form" onSubmit={onSubmit} id={id}>
      <div className="form-header">
        <h1>{title}</h1>
      </div>
      <div className="form-body">
        {children}

        {!hideButtons && (
          <div className="form-footer">
            {print && (
              <div className="print-document">
                <Button
                  type="button"
                  color="white"
                  className="button-print"
                  onClick={handlePrint}
                >
                  <Icon name="printer" />
                  Imprimir
                </Button>
              </div>
            )}
            <div className="form-status">
              {save ? (
                <div className="form-saved">
                  <Icon name="checked" />
                  <h1>
                    Formulário <strong>salvo</strong> com sucesso às{' '}
                    <strong>{hour}</strong>
                  </h1>
                </div>
              ) : (
                <div className="form-not-saved">
                  <Icon name="alertWithOctagon" />
                  <h1>Formulário ainda não salvo</h1>
                </div>
              )}
              <Button type="submit" color="primary">
                Salvar
              </Button>
            </div>
          </div>
        )}
      </div>
    </form>
  )
}

export default Form
