import Bugsnag from '@bugsnag/js'
import { useEffect, useState } from 'react'
import { getSesame } from '../services/api'

const useSesame = () => {
  const [loading, setLoading] = useState(false)
  const [sesame, setSesame] = useState('')

  const handleGetSesame = async () => {
    setLoading(true)
    try {
      const response = await getSesame()
      setSesame(response.data.data)
    } catch (err) {
      Bugsnag.notify(`Error on get sesame in pep SmartConsultation: ${err}`)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetSesame()
  }, [])

  return {
    loading,
    sesame
  }
}

export default useSesame
