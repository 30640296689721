import styled, { css, keyframes } from 'styled-components'

import { ReactComponent as ArrowDownIcon } from '../../../../assets/svg/arrow-down-select.svg'

export const DropdownItem = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: 18px;
  padding: 4px 18px;
  width: 100%;
  cursor: pointer;
  z-index: 3;

  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryPink4};
  }
`

export const NotFound = styled.div`
  font-size: 18px;

  color: ${({ theme }) => theme.colors.gray6};

  padding: 4px 12px;
`

export const Input = styled.input`
  cursor: pointer;
  background: none;

  width: 100%;
`

export const PopperContainer = styled.div`
  display: ${({ showPopper }) => (showPopper ? 'block' : 'none')};
  ${({ theme }) => theme.mixins.shadow_medium};
  position: relative;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primaryPink3};
  text-align: left;

  z-index: -10;

  width: 100%;
  padding: 28px 0 12px;
  overflow-y: hidden;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const update = keyframes`
 from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const ArrowDown = styled(ArrowDownIcon)`
  width: 22px;
  height: 10px;

  ${({ open }) =>
    open
      ? css`
          transform: scale(1, -1);
          animation: ${update} 0.2s linear;
        `
      : css`
          animation: ${fadeIn} 0.2s linear;
        `}
`
