import { api } from './api'

import { isUserDoctor } from '../utils/Utils'

import {
  APPOINTMENT_STATUS,
  APPOINTMENT_STATUS_ENTITY
} from '../utils/constants'

import { appointmentStatusToId } from '../utils/appointmentUtils'

export const getAppointment = async appointmentId =>
  api
    .get(`/api/v1/consultation${isUserDoctor() ? '/adm' : ''}`, {
      params: { id: appointmentId }
    })
    .then(({ data }) => data.data[0])
    .catch(error => {
      throw new Error(error)
    })

export const registerStatus = async appointmentId =>
  api
    .patch(`/api/v1/consultation/${appointmentId}/service/status`, {
      status: APPOINTMENT_STATUS.STARTED,
      entity: isUserDoctor()
        ? APPOINTMENT_STATUS_ENTITY.DOCTOR
        : APPOINTMENT_STATUS_ENTITY.PATIENT
    })
    .then(({ data }) => appointmentStatusToId(data.data.appointment_status))
    .catch(error => {
      throw new Error(error)
    })

export const updateStatus = async (appointmentId, status) =>
  api
    .post(`/api/v1/appointment/${appointmentId}/status`, { status })
    .catch(error => {
      throw new Error(error)
    })
