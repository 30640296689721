import { useCallback } from 'react'
import { useQuery } from 'react-query'

import { getCareLines } from 'services/admnistrativeService'

export default function useCareLines() {
  const {
    data: careLines,
    isLoading: isCareLinesLoading,
    isError: isCareLinesError
  } = useQuery('careLines', getCareLines, {
    refetchOnWindowFocus: false
  })

  const getCareLine = useCallback(
    (careLineId: string) => {
      return careLines?.find(({ id }) => id === Number(careLineId))
    },
    [careLines]
  )

  return {
    careLines,
    isCareLinesLoading,
    isCareLinesError,
    getCareLine
  }
}
