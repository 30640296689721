import { SummarySection } from '../../elements'
import { Chip } from '@material-ui/core'

const PartialHypothesis = ({ info, isMultiple, indexOnData, maxIndex }) => {
  return (
    <>
      {Object.keys(info).length !== 0 && (
        <>
          <SummarySection
            createdBy={info.created_by.nome}
            date={info.data_cadastro.split(' ')[1]}
            title="Hipótese Diagnóstica"
            isMultiple={isMultiple}
            indexOnData={indexOnData}
            maxIndex={maxIndex}
          >
            {info.diagnosticos.map(item => (
              <h3 key={item.id}>{item.nome}</h3>
            ))}

            {info.cid ? (
              info.cid.length > 0 ? (
                <div>
                  <h3>CID(s)</h3>
                  <div className="flex gap-1 mt-1">
                    {info.cid.map(item => (
                      <Chip label={item} color="secondary" />
                    ))}
                  </div>
                </div>
              ) : null
            ) : null}

            {info.ciap ? (
              info.ciap.length > 0 ? (
                <div className="mt-1">
                  <h3>CIAP(s)</h3>
                  <div className="flex gap-1 mt-1">
                    {info.ciap.map(item => (
                      <Chip label={item} color="secondary" />
                    ))}
                  </div>
                </div>
              ) : null
            ) : null}

            {info.observacoes ? (
              <div>
                <h3>Observações</h3>
                <p>{info.observacoes}</p>
              </div>
            ) : null}
          </SummarySection>
        </>
      )}
    </>
  )
}

export default PartialHypothesis
