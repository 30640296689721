import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useQuery, useQueryClient } from 'react-query'
import cn from 'classnames'
import { toast } from 'react-toastify'
import { TicketIcon } from '@heroicons/react/outline'

import MuiButton from 'components/ui/MuiButton'
import Dialog from 'components/ui/Dialog'
import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import Spinner from 'components/ui/Spinner'
import TimeChip from 'components/elements/PresentialDashboard/TimeChip'
import OfferItem from 'components/elements/PresentialDashboard/OfferItem'
import NewOfferForm from '../NewOfferForm'
import UpdateOfferForm from '../UpdateOfferForm'
import RequestPaymentStatus from '../RequestPaymentStatus'

import {
  confirmOffer,
  createRequestOffer,
  getRequestOffer,
  updateRequestStatus
} from 'services/accreditationService'

import {
  formatDate,
  formatMobilePhone,
  parseInputDate,
  parseToUniversalDate,
  splitAndParseDate
} from 'utils/formaters'
import { getWaitingStatus } from 'utils/accreditationUtils'

import bugsnag from 'lib/bugsnag'
import ToastStyles from 'utils/ToastStyles'

import {
  ICreateOffer,
  IOffer,
  OfferInputs,
  ConfirmOfferInputs
} from 'types/Offer'
import { AccRequest, AccRequestOffer } from 'types/AccRequest'

const formatValue = (value: string) =>
  Number(value.replace('R$ ', '').replace(',', '.'))

const formatOffers = (offers: AccRequestOffer[]): IOffer[] => {
  return offers.map(
    ({
      id,
      clinic,
      date,
      time,
      original_price,
      appointment_price,
      status,
      period
    }) => ({
      id,
      clinic,
      date,
      hour: time,
      original_price,
      appointment_price,
      status,
      period
    })
  )
}

const DetailsItem = ({
  title,
  content,
  horizontal,
  className
}: {
  title: string
  content: string | number | undefined | null | JSX.Element
  horizontal?: boolean
  className?: string
}) => (
  <div
    className={cn(
      'space-y-0 flex',
      horizontal ? 'flex-row items-center' : 'flex-col',
      className
    )}
  >
    <Heading
      className={cn(
        'font-semibold text-base text-gray-900',
        horizontal && 'mr-2'
      )}
    >
      {title}:
    </Heading>
    <Text className="text-sm text-gray-800 break-words">{content}</Text>
  </div>
)

interface RequestPromptProps {
  isDialogOpen: boolean
  handleDialogClose: () => void
  activeRequest: AccRequest | null
}

export default function RequestPrompt({
  isDialogOpen,
  handleDialogClose,
  activeRequest
}: RequestPromptProps) {
  const isEditable = useCallback(() => {
    if (activeRequest && activeRequest.status === 'new') {
      return true
    }
    return false
  }, [activeRequest])

  const {
    data: requestOffer,
    isLoading: isOfferLoading,
    isError: isOfferError
  } = useQuery(
    ['requestOffer', activeRequest?.id],
    () => getRequestOffer(activeRequest?.id),
    {
      enabled: !!activeRequest,
      refetchOnWindowFocus: false
    }
  )

  const queryClient = useQueryClient()

  const [offers, setOffers] = useState<IOffer[] | []>([])
  const [isSendOffersLoading, setIsSendOffersLoading] = useState<boolean>(false)
  const [isSendOffersError, setIsSendOffersError] = useState<boolean>(false)

  const [currentOffer, setCurrentOffer] = useState<OfferInputs | null>(null)

  const [isConfirmOfferLoading, setIsConfirmOfferLoading] =
    useState<boolean>(false)
  const [isConfirmOfferError, setIsConfirmOfferError] = useState<boolean>(false)

  const [isApproveVoucherLoading, setIsApproveVoucherLoading] =
    useState<boolean>(false)
  const [isApproveVoucherError, setIsApproveVoucherError] =
    useState<boolean>(false)

  const bottomRef = useRef() as MutableRefObject<HTMLDivElement>

  useEffect(() => {
    if (requestOffer) {
      setOffers(formatOffers(requestOffer))
      // reset error displays
      setIsSendOffersError(false)
      setIsConfirmOfferError(false)
      setIsApproveVoucherError(false)
    }
  }, [requestOffer])

  useEffect(() => {
    if (bottomRef.current) {
      if (offers.length !== 0)
        setTimeout(
          () => bottomRef.current.scrollIntoView({ behavior: 'smooth' }),
          100
        )
    }
  }, [offers])

  const handleAddOffer = (newOffer: IOffer) =>
    setOffers(prev => [...prev, newOffer])

  const handleDeleteOffer = (offerIndex: number) =>
    setOffers(prev => prev.filter((_, index) => index !== offerIndex))

  const handleCurrentOffer = (offer: OfferInputs | null) =>
    setCurrentOffer(offer)

  const handleSendOffers = async () => {
    if (isSendOffersLoading) return

    try {
      setIsSendOffersLoading(true)

      if (currentOffer) {
        const {
          clinic,
          hour,
          date,
          appointment_price,
          original_price,
          ...rest
        } = currentOffer
        if (clinic) {
          const data: ICreateOffer = {
            clinic_id: clinic.id,
            date: parseToUniversalDate(parseInputDate(date)),
            appointment_price: formatValue(appointment_price),
            original_price: formatValue(original_price),
            ...rest
          }
          await createRequestOffer(activeRequest?.id, data)
          setCurrentOffer(null)
        }
      }

      if (offers.length !== 0) {
        offers.forEach(async ({ clinic, hour, date, ...rest }) => {
          const data: ICreateOffer = {
            clinic_id: clinic.id,
            date: parseToUniversalDate(date),
            ...rest
          }
          await createRequestOffer(activeRequest?.id, data)
        })
      }

      await updateRequestStatus(activeRequest?.id, 'negotiating')

      setIsSendOffersLoading(false)

      queryClient.invalidateQueries('accreditationRequests')

      handleDialogClose()

      toast('Oferta enviada com sucesso!', ToastStyles)
    } catch (error) {
      setIsSendOffersLoading(false)
      setIsSendOffersError(true)
      bugsnag.notify(`Error on sending offers ${JSON.stringify(error)}`)
    }
  }

  const handleConfirmOffer = async (
    offerId: number,
    { date, hour }: ConfirmOfferInputs
  ) => {
    setIsConfirmOfferLoading(true)

    try {
      await confirmOffer(offerId, {
        date: parseToUniversalDate(parseInputDate(date)),
        hour
      })

      await updateRequestStatus(activeRequest?.id, 'paying')

      queryClient.invalidateQueries('accreditationRequests')

      handleDialogClose()

      setIsConfirmOfferLoading(false)

      toast('Oferta atualizada com sucesso!', ToastStyles)
    } catch (error) {
      setIsConfirmOfferError(true)
      setIsConfirmOfferLoading(false)
      bugsnag.notify(
        `Error on updating request with id: ${
          activeRequest?.id
        } - offer with id: ${offerId} - error: ${JSON.stringify(error)}`
      )
    }
  }

  const handleApproveVoucher = async () => {
    setIsApproveVoucherLoading(true)

    try {
      await updateRequestStatus(activeRequest?.id, 'done')

      queryClient.invalidateQueries('accreditationRequests')

      handleDialogClose()

      setIsApproveVoucherLoading(false)

      toast('Pagamento aprovado!', ToastStyles)
    } catch (error) {
      setIsApproveVoucherError(true)
      setIsApproveVoucherLoading(false)
      bugsnag.notify(
        `Error on approving bank slip voucher with id: ${
          activeRequest?.id
        } - error: ${JSON.stringify(error)}`
      )
    }
  }

  const isStatusNeeded =
    activeRequest?.status !== 'confirmed' &&
    activeRequest?.status !== 'missed' &&
    activeRequest?.status !== 'done'

  const bodyClasses = cn(
    'p-6 h-screen overflow-auto border-l-4 bg-gray-300 flex flex-col justify-between',
    activeRequest && isStatusNeeded
      ? getWaitingStatus(activeRequest.status_updated_at)
      : ''
  )

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="sm"
      bodyClassName={bodyClasses}
      bodyId="dialog-scrollbar"
    >
      {activeRequest && (
        <>
          <div>
            {/* 
              error displays 
            */}
            {isSendOffersError && (
              <div className="bg-pink-700 text-center text-gray-100 font-medium text-lg p-4 mb-8 rounded">
                <Text>Não foi possível enviar as ofertas.</Text>
              </div>
            )}
            {isConfirmOfferError && (
              <div className="bg-pink-700 text-center text-gray-100 font-medium text-lg p-4 mb-8 rounded">
                <Text>Não foi possível confirmar a oferta.</Text>
              </div>
            )}
            {isApproveVoucherError && (
              <div className="bg-pink-700 text-center text-gray-100 font-medium text-lg p-4 mb-8 rounded">
                <Text>Não foi possível confirmar a oferta.</Text>
              </div>
            )}
            <div className="flex justify-between">
              <Heading className="text-lg font-semibold text-pink-600 flex items-center">
                <TicketIcon className="h-5 mr-2" />
                Solicitação
              </Heading>
              <div className="flex items-center space-x-4">
                <Text className="text-sm text-gray-600">
                  {formatDate(
                    splitAndParseDate(
                      activeRequest.data_cadastro.split(' ')[0]
                    ),
                    "dd 'de' MMMM 'de' yyyy"
                  )}
                </Text>
                {isStatusNeeded && (
                  <TimeChip time={activeRequest.status_updated_at} />
                )}
              </div>
            </div>

            <div className="mt-4 mb-6 pb-6 border-b-2 border-gray-500">
              <DetailsItem
                title="Nome"
                horizontal
                content={activeRequest.patient.nome}
              />
              <DetailsItem
                title="Especialidade"
                horizontal
                content={activeRequest.specialty.nome}
              />
              <DetailsItem
                title="Celular"
                horizontal
                content={formatMobilePhone(activeRequest.patient.celular)}
              />
              <div className="grid grid-cols-5 gap-3 items-center mt-2">
                <DetailsItem
                  title="Localidade"
                  className="col-span-4"
                  content={activeRequest.address}
                />
                <DetailsItem
                  title="Raio"
                  content={`${activeRequest.range_in_km}KM`}
                />
              </div>
            </div>

            {isOfferLoading && (
              <div className="flex items-center justify-center mb-4 mt-8 h-full">
                <Spinner color="pink" size={35} />
              </div>
            )}

            {!isOfferLoading && isOfferError && (
              <Text className="text-center flex items-center justify-center mb-4 mt-8 h-full">
                Não foi possível carregar as ofertas.
              </Text>
            )}

            {requestOffer && (
              <>
                <Heading className="text-lg font-semibold text-pink-600 mb-2">
                  Ofertas
                </Heading>

                {offers.length === 0 ? (
                  <Text className="text-center text-base text-gray-700">
                    Nenhuma oferta adicionada
                  </Text>
                ) : (
                  <div className="space-y-4">
                    {/* 
                      if a offer is accepted, return it and show the info it needs on that status 
                    */}
                    {offers.some(({ status }) => status === 'accepted')
                      ? offers.map((offer, index) => {
                          if (offer.status !== 'accepted') {
                            // eslint-disable-next-line array-callback-return
                            return
                          }

                          return (
                            <>
                              <OfferItem
                                offer={offer}
                                index={index}
                                isEditable={isEditable}
                                handleDeleteOffer={handleDeleteOffer}
                                key={offer.id}
                              />
                              {activeRequest.status === 'booked' && (
                                <UpdateOfferForm
                                  offerId={offer.id}
                                  isConfirmOfferLoading={isConfirmOfferLoading}
                                  handleConfirmOffer={handleConfirmOffer}
                                />
                              )}
                              {(activeRequest.status === 'paying' ||
                                activeRequest.status === 'done') && (
                                <RequestPaymentStatus
                                  request={activeRequest}
                                  handleApproveVoucher={handleApproveVoucher}
                                  isApproveVoucherLoading={
                                    isApproveVoucherLoading
                                  }
                                />
                              )}
                            </>
                          )
                        })
                      : offers.map((offer, index) => (
                          <OfferItem
                            offer={offer}
                            index={index}
                            isEditable={isEditable}
                            handleDeleteOffer={handleDeleteOffer}
                            key={offer.id}
                          />
                        ))}
                  </div>
                )}

                {isEditable() && !isSendOffersLoading && (
                  <NewOfferForm
                    handleAddOffer={handleAddOffer}
                    handleCurrentOffer={handleCurrentOffer}
                    currentOffer={currentOffer}
                  />
                )}
              </>
            )}
          </div>

          {isEditable() && (
            <div className="mt-6">
              <div ref={bottomRef} />
              <MuiButton
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleSendOffers}
                disabled={offers.length === 0 && currentOffer == null}
              >
                {isSendOffersLoading ? (
                  <Spinner color="white" size={35} />
                ) : (
                  'Enviar ofertas'
                )}
              </MuiButton>
            </div>
          )}
        </>
      )}
    </Dialog>
  )
}
