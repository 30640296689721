const BACKEND_URL_PREFIX =
  process.env.REACT_APP_BACKEND_URL_PREFIX || 'http://localhost:8000'

export const BACKEND_URLS = {
  TELEMEDICINE: `${BACKEND_URL_PREFIX}/atendimento/consultas`,
  LOGIN: `${BACKEND_URL_PREFIX}/cadastro/login`,
  DRUGSTORES: `${BACKEND_URL_PREFIX}/atendimento/farmacias`,
  SPA_HOME: `${BACKEND_URL_PREFIX}/`,
  __LOGIN_AND_REDIRECT: targetUrl =>
    `${BACKEND_URL_PREFIX}/cadastro/login?next=${targetUrl}`,
  CHAT: `${BACKEND_URL_PREFIX}/chat`
}

export const APP_URLS = {
  WELCOME: '/bem-vindo',
  LOGIN: '/',
  HOME: '/',
  CALL_0800: '/orientacao',
  SUPPORT: '/suporte',
  INVITE_FRIENDS: '/convites',
  QUESTIONNAIRE_WELCOME: '/questionario',
  QUESTIONNAIRE_QUESTIONS: '/questionario/:questionId',
  QUESTIONNAIRE_FINISHED: '/questionario-finished',
  EXAM_UPLOAD: '/upload-arquivos',
  EXAM_UPLOAD_NEW: '/upload-arquivos/novo',
  EXAM_UPLOAD_EDIT: '/upload-arquivos/editar',
  EXAM_UPLOAD_DELETE: '/upload-arquivos/deletar',
  EXAM_PAGE: '/exames/:exameId',
  HEALTH_HISTORY: '/historico',
  CHANGE_PASSWORD_WELCOME: '/senha-temporaria',
  CHANGE_PASSWORD_CREATE: '/senha-temporaria/alterar',
  CHANGE_PASSWORD_FINISHED: '/senha-temporaria/tudo-certo',
  HEALTH_MONITORING: '/monitoramento',
  ONBOARDING: '/onboarding',
  ONBOARDING_QUESTIONS: '/onboarding/sobre-voce',
  ONBOARDING_THANKS: '/onboarding/obrigado',
  PROFILE: '/meu-perfil',
  BILLING: '/meu-perfil/assinaturas',
  BILLING_DETAILS: '/meu-perfil/assinaturas/detalhes/:id',
  CHANGE_PAYMENT_METHOD: '/meu-perfil/assinaturas/:id/alterar-pagamento',
  REQUEST_ERROR: '/erro',
  BOOKING: '/agendamento',
  BOOKING_CHOOSE_UNIT: '/agendamento/:type/:specialty_id/unidades',
  BOOKING_CHOOSE_TIME: '/agendamento/:type/:specialty_id/:unit_id',
  BOOKING_OVERVIEW: '/agendamento/revisao',
  BOOKING_PAYMENT: '/agendamento/pagamento',
  BOOKING_CONFIRM: '/agendamento/confirmacao',
  TELEMEDICINE_VALUES: '/telemedicina/valores',
  SERVICES: '/atendimento',
  SERVICES_EMERGENCY_CARE: '/atendimento/atendimento-rapido',
  PRESCRIPTIONS: '/prescricoes',
  HEALTH_CARD: '/carteirinha',
  HELP: '/ajuda',
  SCHEDULE: '/agenda',
  VIRTUAL_CLINIC: '/consultorio-virtual',
  SMART_CONSULTATIONS: '/consultas-inteligentes',
  PROMPT_MEDICAL_CARE: '/pronto-atendimento',
  USER_REVIEWS: '/avaliacoes-usuarios',
  VIRTUAL_CLINIC_ROOM: '/consultorio-virtual/sala/:appointmentId',
  VIRTUAL_CLINIC_ROOM_KARMEN: '/consultorio-virtual/sala/:appointmentId/karmen',
  VIRTUAL_CLINIC_ROOM_VERSATILIS:
    '/consultorio-virtual/sala/:appointmentId/versatilis',
  VIRTUAL_CLINIC_ROOM_PEP_RECORDS:
    '/consultorio-virtual/sala/:appointmentId/prontuario',
  VIRTUAL_CLINIC_ROOM_SMART_CONSULTATION:
    '/consultorio-virtual/sala/:appointmentId/prontuario/consulta-inteligente',
  VIRTUAL_CLINIC_ROOM_PEP_RECORDS_BIOMETRICS:
    '/consultorio-virtual/sala/:appointmentId/prontuario/biometria',
  VIRTUAL_CLINIC_ROOM_PEP_RECORDS_HYPOTHESIS:
    '/consultorio-virtual/sala/:appointmentId/prontuario/hipotese-diagnostica',
  VIRTUAL_CLINIC_ROOM_PEP_RECORDS_MANAGEMENT:
    '/consultorio-virtual/sala/:appointmentId/prontuario/conduta',
  VIRTUAL_CLINIC_ROOM_PEP_RECORDS_PRESCRIPTIONS:
    '/consultorio-virtual/sala/:appointmentId/prontuario/prescricoes',
  VIRTUAL_CLINIC_ROOM_PEP_RECORDS_DOCUMENTS:
    '/consultorio-virtual/sala/:appointmentId/prontuario/documentos',
  VIRTUAL_CLINIC_ROOM_PEP_RECORDS_EXAMS:
    '/consultorio-virtual/sala/:appointmentId/prontuario/exames',
  VIRTUAL_CLINIC_ROOM_PEP_RECORDS_VERSATILIS:
    '/consultorio-virtual/sala/:appointmentId/prontuario/evolucao-versatilis',
  VIRTUAL_CLINIC_ROOM_PEP_RECORDS_NOTIFICATIONS:
    '/consultorio-virtual/sala/:appointmentId/prontuario/notificacoes',
  VIRTUAL_CLINIC_PATIENT: '/consultorio-virtual/paciente/:patientId',
  VIRTUAL_CLINIC_PATIENT_KARMEN:
    '/consultorio-virtual/paciente/:patientId/karmen',
  VIRTUAL_CLINIC_PATIENT_VERSATILIS:
    '/consultorio-virtual/paciente/:patientId/versatilis',
  VIRTUAL_CLINIC_PATIENT_PEP_RECORDS:
    '/consultorio-virtual/paciente/:patientId/prontuario',
  VIRTUAL_CLINIC_PATIENT_SMART_CONSULTATION:
    '/consultorio-virtual/paciente/:patientId/prontuario/consulta-inteligente',
  VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_BIOMETRICS:
    '/consultorio-virtual/paciente/:patientId/prontuario/biometria',
  VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_HYPOTHESIS:
    '/consultorio-virtual/paciente/:patientId/prontuario/hipotese-diagnostica',
  VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_MANAGEMENT:
    '/consultorio-virtual/paciente/:patientId/prontuario/conduta',
  VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_PRESCRIPTIONS:
    '/consultorio-virtual/paciente/:patientId/prontuario/prescricoes',
  VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_DOCUMENTS:
    '/consultorio-virtual/paciente/:patientId/prontuario/documentos',
  VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_EXAMS:
    '/consultorio-virtual/paciente/:patientId/prontuario/exames',
  VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_VERSATILIS:
    '/consultorio-virtual/paciente/:patientId/prontuario/evolucao-versatilis',
  VIRTUAL_CLINIC_PATIENT_PEP_RECORDS_NOTIFICATIONS:
    '/consultorio-virtual/paciente/:patientId/prontuario/notificacoes',
  ADMINISTRATIVE: {
    HOME: '/admin',
    PRESENTIAL_DASHBOARD: '/admin/credenciamento-presencial',
    USERS: '/admin/usuarios',
    USER_OVERVIEW: '/admin/usuario/:userId',
    USER_HISTORY: '/admin/usuario/:userId/historico',
    USER_RULES: '/admin/usuario/:userId/reguas'
  }
}

export const BACKGROUNDS = {
  TELEMEDICINE: '/backgrounds/bg-telemedicina.svg',
  CALL0800: '/backgrounds/bg-0800.svg',
  ACCREDITED_NETWORK: '/backgrounds/bg-redecredenciada.svg',
  COMING_SOON: '/backgrounds/bg-soon.svg',
  DIGITAL_CARD: '/backgrounds/bg-carteirinha.svg',
  SUPPORT: '/backgrounds/bg-questions.svg',
  DRUGSTORES: '/backgrounds/bg-farmacias.svg',
  BLOG: '/backgrounds/bg-blog.svg',
  EXAMES: '/backgrounds/bg-exames.svg',
  MONITORING: '/backgrounds/bg-monitoring.svg',
  HISTORY: '/backgrounds/bg-history.svg',
  TELEMEDICINE_BOOK: '/backgrounds/bg-telemedicine.svg',
  TELEMEDICINE_VALUES: '/backgrounds/bg-consultations.svg',
  APP_PASSWORD_DESKTOP: '/backgrounds/CirculosBG.svg',
  APP_PASSWORD_MOBILE: '/backgrounds/CirculosBGMobile.svg',
  BG_HEADER: '/backgrounds/br-menuheader.svg',
  BG_SUCCESS: '/backgrounds/bg-success-health.svg',
  BG_HOW_TO: '/backgrounds/bg-how-to-collect.svg',
  BG_MONITORING: '/backgrounds/bg-health-monitoring-desk.svg',
  BG_GUY_STANDING: '/guy-standing.svg',
  SvgInputPassword: '/backgrounds/inputPasswordSvg.svg',
  SvgInputPasswordRed: '/backgrounds/inputPasswordSvgRed.svg',
  SvgWomen: '/backgrounds/Standing.svg',
  SvgErrorRobot: '/backgrounds/ErrorRobot.svg',
  SvgHappy: '/backgrounds/Happy.svg',
  BG_ONBOARDING_MOBILE: '/backgrounds/bg-onboarding-mobile.svg',
  BG_ONBOARDING_DESKTOP: '/backgrounds/bg-onboarding-desktop.svg',
  BG_PWA_PROMPT_FULL: '/backgrounds/bg-pwa-prompt-full.svg',
  BG_PWA_PROMPT_MOBILE: '/backgrounds/bg-pwa-prompt-mobile.svg',
  BG_LOGO: '/backgrounds/LogoBg.svg',
  MESSAGE_PROMPT_MOBILE: '/backgrounds/bg-recover-pass-mobile.svg',
  MESSAGE_PROMPT_DESKTOP: '/backgrounds/bg-recover-pass-desktop.svg',
  DROP_DOWN_ARROW: '/backgrounds/dropDownArray.svg',
  KARMEN: '/backgrounds/bg-karmen.svg',
  HEALTH_CARD: '/backgrounds/bg-health-card.png'
}

export const SITE = 'https://kompa.com.br'

export const SITE_URLS = {
  PURCHASE: `${SITE}/assinar`,
  BOOK_APPOINTMENT: `${SITE}/agendar`,
  DIGITAL_CARD: `${SITE}/carteirinha`,
  PLANS: `${SITE}/#planos`,
  BLOG: 'https://blog.kompa.com.br'
}
