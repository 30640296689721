import { useHistory, useLocation } from 'react-router-dom'
import { usePostHogContext } from 'posthog-js/react'
import { useEffect } from 'react'

// Routes
import UserRoutes from './UserRoutes'
import AuthRoutes from './AuthRoutes'

// Hooks
import useAuth from 'hooks/useAuth'

// Utils
import { APP_URLS } from 'urls'
import { isProduction } from 'utils/constants'

export default function Routes() {
  const { isLoggedIn } = useAuth()

  const history = useHistory()

  useEffect(() => {
    if (!isLoggedIn) history.replace(APP_URLS.WELCOME)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  const location = useLocation()
  const posthogCtx = usePostHogContext()

  useEffect(() => {
    if (isProduction) {
      const page = location.pathname

      posthogCtx.client?.capture(`[NAV] ${page}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return isLoggedIn ? <UserRoutes /> : <AuthRoutes />
}
