import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import Component from '../../components/ExamPage/ExamPage'
import { GlobalContext } from '../../contexts/GlobalContext'
import { fetchApi } from '../../services/api'

const ExamPage = () => {
  const [exams, setExams] = useState(null)
  const [loading, setLoading] = useState(true)
  const { user } = useContext(GlobalContext)
  const { exameId } = useParams()
  const history = useHistory()
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const getExame = async () => {
    try {
      const res = await fetchApi(
        `/prontuario-v1/${user.id}/solicitacao_exame?solicitacao_id=${exameId}`
      )
      setExams(res.data)
      setLoading(false)
    } catch (error) {
      setExams(false)
      setLoading(false)
      throw new Error(error)
    }
  }

  useEffect(() => {
    if (!loading && exams) {
      setLoading(true)
      setExams(null)
    }
    getExame()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exameId])

  return (
    <Component
      exams={exams}
      loading={loading}
      history={history}
      handlePrint={handlePrint}
      componentRef={componentRef}
      user={user}
    />
  )
}

export default ExamPage
