import { useQuery } from 'react-query'
import { addYears, subDays, format } from 'date-fns'

import Spinner from 'components/ui/Spinner'
import Text from 'components/ui/Text'
import Link from 'components/ui/Link'

import AppointmentItem from '../AppointmentItem'

import { getAppointments } from 'services/appointmentService'

import useAuth from 'hooks/useAuth'

import { APP_URLS } from 'urls'
import { openWhatsapp } from 'utils/urls'
import { SUPPORT_WHATSAPP } from 'utils/constants'

export default function UserAppointments() {
  const startAt = subDays(new Date(), 1)
  const endAt = addYears(startAt, 3)
  const _format = 'yyyy-MM-dd'

  const { user } = useAuth()

  const {
    data: appointments,
    isError,
    isLoading
  } = useQuery(
    'appointments',
    () =>
      getAppointments({
        date_range: `${format(startAt, _format)} ${format(endAt, _format)}`
      }),
    {
      onError: error => {
        new Error(String(error))
      }
    }
  )

  if (isLoading) {
    return (
      <div className="flex items-center justify-center mb-4">
        <Spinner color="pink" size={35} />
      </div>
    )
  }

  if (isError) {
    return (
      <Text className="text-center text-new_red-500 font-semibold text-xl">
        Não foi possível carregar suas consultas.
      </Text>
    )
  }

  return (
    <div className="flex flex-col space-y-4 items-center justify-center mb-4">
      {appointments?.length !== 0 ? (
        appointments?.map(appointment => (
          <AppointmentItem data={appointment} key={appointment.id} />
        ))
      ) : (
        <div className="text-center">
          <Text className="text-gray-900">Nenhuma consulta encontrada</Text>
          <Link
            to={
              user.isUnlimited
                ? openWhatsapp(
                    SUPPORT_WHATSAPP,
                    'Olá, quero agendar uma consulta'
                  )
                : APP_URLS.BOOKING
            }
            isExternal={user.isUnlimited}
          >
            <Text className="text-pink-600 tracking-widest">Agende uma!</Text>
          </Link>
        </div>
      )}
    </div>
  )
}
