import { CheckCircleIcon, TrashIcon } from '@heroicons/react/solid'
import cn from 'classnames'

import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'

import { getPeriod } from 'utils/accreditationUtils'
import { formatDate, splitAndParseDate } from 'utils/formaters'

import { IOffer } from 'types/Offer'

const formatValue = (value: string | number): string => {
  let out = String(value)

  if (out.includes('.')) {
    out = out.replace('.', ',')

    const splited = out.split(',')

    if (splited[1].length === 1) {
      out = `${out}0`
    }
  }

  return `R$ ${out}`
}

// TODO: make this editable

const EditableItem = ({
  title,
  content,
  emphasis
}: {
  title: string
  content: string | undefined | JSX.Element
  emphasis?: boolean
}) => {
  return (
    <div>
      <Text className="text-gray-700 text-base">{title}:</Text>
      <Heading
        className={cn(
          'font-semibold text-lg',
          emphasis ? 'text-teal-600' : 'text-gray-900'
        )}
      >
        {content}
      </Heading>
    </div>
  )
}

interface OfferItemProps {
  offer: IOffer
  index: number
  handleDeleteOffer: (offerIndex: number) => void
  isEditable: () => boolean | null
}

export default function OfferItem({
  offer: {
    clinic,
    appointment_price,
    original_price,
    date,
    hour,
    status,
    period
  },
  index,
  handleDeleteOffer,
  isEditable
}: OfferItemProps) {
  return (
    <>
      <div className="space-y-2" key={clinic.nome_razao_social}>
        <div className="flex justify-between items-center">
          <Heading className="text-lg text-teal-800 font-semibold">
            Clinica #{index + 1}
          </Heading>

          {status === 'accepted' && (
            <Heading className="px-2 py-1 text-gray-100 bg-green-600 rounded-md text-base flex items-center font-semibold">
              <CheckCircleIcon className="h-5 mr-2" />
              Aceita
            </Heading>
          )}

          {isEditable() && (
            <button onClick={() => handleDeleteOffer(index)}>
              <TrashIcon className="h-5 text-gray-700" />
            </button>
          )}
        </div>

        <EditableItem
          title="Unidade credenciada"
          content={`${clinic.nome_razao_social} - ${clinic.bairro}`}
        />

        <EditableItem
          title="Data"
          content={
            <>
              {formatDate(splitAndParseDate(date), "dd 'de' MMMM")}{' '}
              {hour ? (
                <>
                  <Heading as="span" className="font-normal">
                    às
                  </Heading>{' '}
                  {hour.slice(0, 5)}
                </>
              ) : (
                <>
                  <Heading as="span" className="font-normal">
                    no periodo da
                  </Heading>{' '}
                  {period && getPeriod(period)}
                </>
              )}
            </>
          }
        />

        <div className="grid grid-cols-2">
          <EditableItem
            title="Preço da consulta"
            content={formatValue(original_price)}
          />

          <EditableItem
            title="Preço no plano"
            emphasis
            content={formatValue(appointment_price)}
          />
        </div>
      </div>
    </>
  )
}
