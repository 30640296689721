import cn from 'classnames'
import Heading from 'components/ui/Heading'

const headerItems = ['Nome', 'CPF', 'Celular', 'Embarque', 'Status']

export default function UsersTableHeader() {
  return (
    <div className="grid grid-cols-6 gap-3">
      {headerItems.map((item, index) => (
        <Heading
          as="h3"
          className={cn(
            'text-base text-gray-800 font-semibold',
            index === 0 && 'col-span-2'
          )}
          key={item}
        >
          {item}
        </Heading>
      ))}
    </div>
  )
}
