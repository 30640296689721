import { backendServices } from './AxiosInstances'

import {
  AdminUsersFilters,
  AdminUsersRequestResponse,
  AdminUserRequestResponse,
  AdminUser
} from 'types/AdminUsers'
import { AdminProfessionalsRequestResponse } from 'types/AdminProfessionals'

export const updateUser = (params: AdminUser) =>
  backendServices
    .put(`/api/v2/users/${params.id}`, params)
    .then(({ data }) => data)
    .catch(error => {
      throw error.response.data.error
    })

export const getUsers = (params: AdminUsersFilters) =>
  backendServices
    .get<AdminUsersRequestResponse>('/api/v2/users', {
      params: {
        ...params,
        is_patient: true
      }
    })
    .then(({ data }) => data)
    .catch(error => {
      throw error.message
    })

export const getUserInfo = (userId: number) =>
  backendServices
    .get<AdminUserRequestResponse>(`/api/v2/users/${userId}`)
    .then(({ data }) => data.data)
    .catch(error => {
      throw error.message
    })

export const getHealthProfessionals = () =>
  backendServices
    .get<AdminProfessionalsRequestResponse>('/api/v2/health-professionals')
    .then(({ data }) => {
      const all = data.data

      const nurses = all.filter(({ is_nurse }) => is_nurse)
      const doctors = all.filter(({ is_medico }) => is_medico)

      return {
        nurses,
        doctors
      }
    })
    .catch(error => {
      throw error.message
    })

interface Plan {
  id: number
  name: string
}

interface PlanRequestResponse {
  results: Plan[]
}

export const getPlans = () =>
  backendServices
    .get<PlanRequestResponse>('/cad/api/v1/Plano')
    .then(({ data }) => data.results)
    .catch(error => {
      throw error.message
    })

interface CareLine {
  id: number
  description: string
}

interface CareLineRequestResponse {
  data: CareLine[]
}

export const getCareLines = () =>
  backendServices
    .get<CareLineRequestResponse>('/api/v2/care')
    .then(({ data }) => data.data)
    .catch(error => {
      throw error.message
    })
