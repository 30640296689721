/* eslint-disable no-underscore-dangle */
import { createContext, useState } from 'react'
import useMedicalRecord from 'hooks/useMedicalRecord'
import useAuth from 'hooks/useAuth'

export const WindowContext = createContext()

const WindowProvider = ({ children }) => {
  const { appointment, patient: cachedPatient } = useMedicalRecord()
  const { user } = useAuth()

  // User Info
  const doctor = user
  const patient = cachedPatient ? cachedPatient : appointment.paciente
  const medApp = appointment

  // Persisted Data
  const [persistAnamnesis, setPersistAnamnesis] = useState({})
  const [persistBiometrics, setPersistBiometrics] = useState({})
  const [persistManagement, setPersistManagement] = useState({})
  const [persistEvolucaoSimples, setPersistEvolucaoSimples] = useState({})
  const [persistHypothesis, setPersistHypothesis] = useState({})
  const [persistDocuments, setPersistDocuments] = useState({})
  const [persistAttachments, setPersistAttachments] = useState({})
  const [persistExams, setPersistExams] = useState({})
  const [filterRecords, setFilterRecords] = useState('todos')
  const [loadingRecords, setLoadingRecords] = useState(true)

  const [openBackdrop, setOpenBackdrop] = useState(false)

  const cleanStates = () => {
    setPersistAnamnesis({})
    setPersistBiometrics({})
    setPersistManagement({})
    setPersistEvolucaoSimples({})
    setPersistHypothesis({})
    setPersistDocuments({})
    setPersistAttachments({})
    setPersistExams({})
  }

  const removeItemsStorage = () => {
    localStorage.removeItem('prontuario')
    localStorage.removeItem('anamnese')
    localStorage.removeItem('biometria')
    localStorage.removeItem('documento')
    localStorage.removeItem('solicitacao_exames')
    localStorage.removeItem('hipotese_diagnostica')
    localStorage.removeItem('anexos')
    localStorage.removeItem('conduta')
    localStorage.removeItem('evolucao_simples')
  }

  const resetAllInfo = () => {
    cleanStates()
    removeItemsStorage()
  }

  const providerValue = {
    doctor,
    patient,
    medApp,
    persistAnamnesis,
    setPersistAnamnesis,
    persistBiometrics,
    setPersistBiometrics,
    persistManagement,
    persistEvolucaoSimples,
    setPersistManagement,
    setPersistEvolucaoSimples,
    persistHypothesis,
    setPersistHypothesis,
    persistDocuments,
    setPersistDocuments,
    persistAttachments,
    setPersistAttachments,
    persistExams,
    setPersistExams,
    filterRecords,
    setFilterRecords,
    loadingRecords,
    setLoadingRecords,
    openBackdrop,
    setOpenBackdrop,
    resetAllInfo
  }
  return (
    <WindowContext.Provider value={providerValue}>
      {children}
    </WindowContext.Provider>
  )
}

export default WindowProvider
