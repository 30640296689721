import { InformationCircleIcon } from '@heroicons/react/solid'

import Heading from 'components/ui/Heading'

export default function TableHeading() {
  const columnTitleStyle = 'text-base font-semibold text-teal-700'
  const columnStatusStyle = 'text-base font-semibold text-pink-600'

  return (
    <div className="my-4 grid grid-cols-9 text-center">
      <Heading className={columnTitleStyle}>Data</Heading>
      <Heading className={columnTitleStyle}>Hora</Heading>
      <Heading className={columnTitleStyle}>Especialidade</Heading>
      <Heading className={columnTitleStyle}>Paciente</Heading>
      <Heading className={columnTitleStyle}>Tipo</Heading>
      <Heading className={columnTitleStyle}>Queixa principal</Heading>
      <Heading className={columnStatusStyle}>
        <InformationCircleIcon className="mr-2 text-pink-600 mx-auto inline-block h-5" />
        Status
      </Heading>
      <Heading className={columnStatusStyle}>
        <InformationCircleIcon className="mr-2 text-pink-600 mx-auto inline-block h-5" />
        Conexão
      </Heading>
    </div>
  )
}
