const Input = ({
  label,
  type,
  className,
  id,
  name,
  defaultValue,
  value,
  placeholder,
  rows,
  hasIcons,
  children,
  onChange,
  blurDiv,
  onKeyAction,
  isValid = true,
  required,
  inputRef
}) => {
  const Component = type === 'textarea' ? 'textarea' : 'input'

  return (
    <div className="input-outside" id={id} onBlurCapture={blurDiv}>
      {label && (
        <label htmlFor={id} className="input-label">
          {label} {required && <span className="text-red-600">*</span>}
        </label>
      )}

      <div
        className={`input-body ${hasIcons && 'has-icon'} `}
        style={
          isValid
            ? {}
            : {
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRadius: '5px',
                borderColor: 'red'
              }
        }
      >
        <Component
          onChange={onChange}
          type={type !== 'textarea' ? type : null}
          className={`input ${className}`}
          name={name}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          rows={type === 'textarea' ? rows : null}
          onKeyUp={onKeyAction}
          ref={inputRef}
        />
        {children}
      </div>
    </div>
  )
}

export default Input
