export const Parent = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
}

export const Item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
}

export const ParentScale = {
  hidden: { scale: 0 },
  show: {
    scale: 1,
    transition: {
      staggerChildren: 0.3,
      type: 'spring'
    }
  },
  leave: { scale: 0 }
}

export const ItemScale = {
  hidden: { scale: 0 },
  show: { scale: 1 },
  leave: { scale: 0 }
}
