import { ChangeEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useQuery } from 'react-query'
import { fetchApi } from 'services/api'

import Head from 'components/common/Head'
import Heading from 'components/ui/Heading'
import KompaPagination from 'components/ui/KompaPagination'
import Text from 'components/ui/Text'
import { UserReviewFilters, UserReviewRequestResponse } from 'types/UserReviews'
import { Spinner } from 'components/ui'
import TableLayout from 'components/layouts/UsersReviews/TableLayout'
import useQueryParams from 'hooks/useQueryParams'

export default function UsersReviews() {
  const getReviews = async (
    params: UserReviewFilters
  ): Promise<UserReviewRequestResponse> => {
    try {
      const page = params.page ?? 1
      const resultsPerPage = params.results_per_page ?? 30
      const url = `/api/v1/consultation/review?page=${page}&resultados=${resultsPerPage}`
      const json = await fetchApi(url)
      return json
    } catch (error) {
      throw error
    }
  }

  const [filters, setFilters] = useState<UserReviewFilters>({
    page: useQueryParams('pagina') || undefined,
    results_per_page: useQueryParams('resultados') || undefined
  })

  const {
    data: reviews,
    isError,
    isLoading
  } = useQuery(
    [
      'userReviews',
      {
        ...filters,
        page: filters.page || '1',
        results_per_page: filters.results_per_page || '30',
        total_items: '0'
      }
    ],
    () =>
      getReviews({
        ...filters,
        page: filters.page || '1',
        results_per_page: filters.results_per_page || '30',
        total_items: '0'
      }),
    {
      refetchInterval: 60000
    }
  )

  const history = useHistory()

  const handlePageChange = (_: ChangeEvent<unknown>, value: number) =>
    setFilters(prev => ({ ...prev, page: String(value) }))

  useEffect(() => {
    const params = new URLSearchParams()

    if (filters.page) params.append('pagina', String(filters.page))
    if (filters.results_per_page)
      params.append('resultados', String(filters.results_per_page))

    history.push({ search: params.toString() })
  }, [filters, history])

  return (
    <>
      <Head title="Avaliações" />
      <div className="flex flex-col w-full bg-gray-100">
        <div className=" py-8 pl-4">
          <Heading className="text-orange-700 font-semibold text-xl">
            Avaliações dos usuários
          </Heading>
        </div>
        {isLoading && (
          <div className="flex items-center justify-center mb-4 mt-8 h-full">
            <Spinner color="pink" size={35} />
          </div>
        )}
        {!isLoading && isError && (
          <Text className="text-center flex items-center justify-center mb-4 mt-8 h-full">
            Não foi possível carregar suas consultas.
          </Text>
        )}
        {!isLoading && !isError ? (
          reviews && reviews.data.length !== 0 ? (
            <div className="py-6 px-4">
              <TableLayout reviews={reviews.data} />
              <Text className="text-center px-3 text-gray-700 select-none">
                {reviews.total_items} resultado
                {reviews.total_items > 1 ? 's' : ''}
              </Text>
              {reviews.total_pages !== 1 && (
                <div className="flex justify-end space-x-2 pt-2 px-3 pb-3">
                  <KompaPagination
                    color="primary"
                    shape="rounded"
                    page={Number(filters.page) || 1}
                    count={reviews.total_pages}
                    onChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="w-full text-center py-16 text-lg font-semibold">
              <Heading>Nenhuma avaliação de usuário encontrada!</Heading>
            </div>
          )
        ) : null}
      </div>
    </>
  )
}
