const Button = ({ type, outlined, color, children, className, onClick }) => {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      style={{ fontFamily: 'Titillium Web' }}
      className={`button ${className} ${color && `button-${color}`} ${
        outlined && 'is-outlined'
      }`}
    >
      {children}
    </button>
  )
}

export default Button
