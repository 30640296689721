import { useState } from 'react'
import cn from 'classnames'
import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon
} from '@heroicons/react/solid'

import Link from 'components/ui/Link'
import LogoHorizontal from 'components/ui/Logo/LogoHorizontal'
import Logo from 'components/ui/Logo/Logo'
import AdminNavbarMenu from '../AdminNavbarMenu'
import DoctorNavbarMenu from '../DoctorNavbarMenu'
import UserNavbarMenu from '../UserNavbarMenu'

import useAuth from 'hooks/useAuth'

import { APP_URLS } from 'urls'
import { setCookie, getCookie } from 'utils/cookie'
import { MAX_AGE_COOKIE, NAVBAR_EXPANDED_COOKIE } from 'utils/constants'

export default function DesktopNavbar() {
  const [navbarExtended, setNavbarExtended] = useState<boolean>(
    getCookie(NAVBAR_EXPANDED_COOKIE) === 'true'
  )

  const { user } = useAuth()

  const handleNavbar = () => {
    setCookie(NAVBAR_EXPANDED_COOKIE, String(!navbarExtended), {
      expires: MAX_AGE_COOKIE
    })
    setNavbarExtended(!navbarExtended)
  }

  return (
    <div
      className="bg-pink-600 py-20"
      style={{ minWidth: navbarExtended ? '200px' : '80px' }}
    >
      <Link to={APP_URLS.HOME}>
        {navbarExtended ? (
          <LogoHorizontal className="mx-auto h-8" />
        ) : (
          <Logo className="mx-auto h-8 w-16" />
        )}
      </Link>
      <div className="flex flex-col items-center space-y-4 mt-20">
        {user.isStaff ? (
          <AdminNavbarMenu />
        ) : user.isMedico ? (
          <DoctorNavbarMenu />
        ) : (
          <UserNavbarMenu />
        )}
      </div>
      <div
        className={cn(
          'flex items-center px-4 cursor-pointer mt-10',
          navbarExtended ? 'justify-end' : 'justify-center'
        )}
        onClick={handleNavbar}
      >
        {navbarExtended ? (
          <ArrowCircleLeftIcon className="text-gray-100 h-6" />
        ) : (
          <ArrowCircleRightIcon className="text-gray-100 h-6" />
        )}
      </div>
    </div>
  )
}
