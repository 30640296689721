import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import Heading from 'components/ui/Heading'
import Text from 'components/ui/Text'
import Head from 'components/common/Head'
import FeatureTitle from 'components/common/FeatureTitle'

import useAuth from 'hooks/useAuth'

import { BACKGROUNDS } from 'urls'

const Card = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;

  height: 500px;

  > div {
    background-image: url(${BACKGROUNDS.HEALTH_CARD});
    background-repeat: no-repeat;
    background-size: cover;

    width: 498.41px;
    height: 300px;

    transform: rotate(90deg);
    transform-origin: center;

    position: relative;

    > div {
      position: absolute;

      bottom: 30px;
      left: 40px;
    }
  }

  @media (min-width: ${({ theme: { sizes } }) => sizes.sm}) {
    height: 375px;

    > div {
      transform: rotate(0deg);
    }
  }
`

export default function HealthCardPage() {
  const { user } = useAuth()

  const location = useLocation()

  return (
    <>
      <Head title="Carteirinha" />
      <div className="flex flex-col w-full">
        <FeatureTitle feature={location.pathname} />
        <div className="mx-auto max-w-xl bg-white my-8 rounded-md">
          <Card className="max-w-sm mx-auto">
            <div>
              <div className="text-gray-100">
                <Heading as="h4">{user.billingId}</Heading>
                <Heading as="h4">{user.name}</Heading>
                <Heading as="h2" className="font-semibold">
                  Assina Saúde - Kompa Saúde
                </Heading>
              </div>
            </div>
          </Card>

          <div className="p-4">
            <Heading as="h3" className="text-xl font-semibold text-teal-600">
              Como usar sua carteirinha:
            </Heading>
            <ol className="mt-4">
              <li>
                <Heading className="text-teal-600 text-lg font-semibold">
                  1. Consulte a farmácia credenciada mais próxima de você
                </Heading>
                <Text>
                  Para encontrar a farmácia mais próxima de você, acesse o site
                  da ePharma{' '}
                  <a
                    href="https://ias2.epharmatecnologia.com.br/sa/pesquisas/lojas"
                    target="_blank"
                    className="font-semibold text-pink-600"
                    rel="noreferrer"
                  >
                    clicando aqui
                  </a>
                  . Para acessar, é só informar os dados conforme escritos na
                  carteirinha acima.
                </Text>
              </li>
              <li className="mt-2">
                <Heading className="text-teal-600 text-lg font-semibold">
                  2. Mostre a sua carteirinha virtual no balcão da farmácia
                </Heading>
                <Text>
                  Após selecionar o seu medicamento, vá até o balcão da farmácia
                  e mostre o seu cartão para ter acesso aos descontos.
                </Text>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  )
}
