import { useContext, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import PDFViewer from '../PDFViewer/PDFViewer'
import { WindowContext } from '../../contexts/WindowContext'
import { Icon } from '../ui'

const FileContainer = styled.div`
  margin: 16px;

  display: flex;
  justify-content: center;
`

const Image = styled.div`
  ${({ controls, imageWidth }) => {
    if (controls) {
      return css`
        min-height: calc(
          ${({ maxHeight }) => maxHeight && maxHeight}px - 200px
        );
        min-width: calc(${({ maxWidth }) => maxWidth && maxWidth}px - 200px);
        max-height: calc(
          ${({ maxHeight }) => maxHeight && maxHeight}px - 200px
        );
        max-width: calc(${({ maxWidth }) => maxWidth && maxWidth}px - 200px);

        display: flex;
        justify-content: ${imageWidth && imageWidth <= 1000
          ? 'center'
          : 'left'};

        @media (max-width: ${({ theme }) => theme.sizes.sm}) {
          min-width: calc(${({ maxWidth }) => maxWidth && maxWidth}px - 80px);
          max-width: calc(${({ maxWidth }) => maxWidth && maxWidth}px - 80px);
          justify-content: left;
        }

        @media (min-width: ${({ theme }) => theme.sizes.xl}) {
          min-width: 1000px;
          max-width: 1000px;
        }
      `
    } else {
      return css`
        height: ${({ maxHeight }) => maxHeight && maxHeight};
        width: ${({ maxWidth }) => maxWidth && maxWidth};
        display: flex;
        justify-content: center;
      `
    }
  }}

  overflow: auto;

  img {
    transition: all 0.2s linear;
    ${({ controls }) => {
      if (controls) {
        return css`
          height: ${({ imageHeight }) => imageHeight && `${imageHeight}px`};
          width: ${({ imageWidth }) => imageWidth && `${imageWidth}px`};
          max-width: none;
        `
      }
    }}
  }
`

const PreviewFileComponent = ({
  file,
  fileType = 'model',
  controls = true,
  scale = 1.0,
  widthWrapper,
  heightWrapper
}) => {
  const { width, height } = useContext(WindowContext)

  const [type, setType] = useState('')
  const [imageHeight, setImageHeight] = useState(500)
  const [imageWidth, setImageWidth] = useState()

  const zoomIn = () => {
    setImageHeight(imageHeight + imageHeight * 0.1)
    setImageWidth(imageWidth + imageWidth * 0.1)
  }
  const zoomOut = () => {
    setImageHeight(imageHeight - imageHeight * 0.1)
    setImageWidth(imageWidth - imageWidth * 0.1)
  }

  const onLoad = e => {
    setImageHeight(e.target.height)
    setImageWidth(e.target.width)
  }

  useEffect(() => {
    if (file && file.nome) {
      setType(file.nome.toLowerCase().substr(-3) === 'pdf' ? 'pdf' : 'image')
    } else if (file && file.name) {
      setType(file.name.toLowerCase().substr(-3) === 'pdf' ? 'pdf' : 'image')
    } else setType('image')
  }, [file])

  return (
    <>
      {!file ? (
        <>
          <span />
        </>
      ) : type === 'pdf' ? (
        <FileContainer>
          <PDFViewer
            file={file && fileType === 'model' ? file.arquivo : file.preview}
            width={widthWrapper || width}
            height={heightWrapper || height}
            controls={controls}
            initialScale={scale}
          />
        </FileContainer>
      ) : (
        <div>
          <Image
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            maxHeight={controls ? height : '100%'}
            maxWidth={controls ? width : '100%'}
            controls={controls}
          >
            <img
              src={file && fileType === 'model' ? file.arquivo : file.preview}
              onLoad={onLoad}
              alt="arquivo"
              className="z-20"
              onDoubleClick={zoomIn}
              controls={controls}
            />
          </Image>
          {controls && (
            <div className="flex justify-center items-center space-x-4 py-4">
              <button type="button" onClick={zoomOut}>
                <Icon name="zoom-out-new" size={24} stroke="#D20E50" />
              </button>
              <button type="button" onClick={zoomIn}>
                <Icon name="zoom-in-new" size={24} stroke="#D20E50" />
              </button>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default PreviewFileComponent
