import TableHeading from 'components/elements/UsersReviews/TableHeading'
import TableItem from 'components/elements/UsersReviews/TableItem'

import { IUserReview } from 'types/UserReviews'

interface ITableLayout {
  reviews: IUserReview[]
}

export default function TableLayout({ reviews }: ITableLayout) {
  return (
    <div className="p-3 bg-gray-100 assina-shadow-sm rounded-md">
      <TableHeading />

      {reviews.map(review => (
        <TableItem data={review} key={review.id} />
      ))}
    </div>
  )
}
