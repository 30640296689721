import React from 'react'
import cn from 'classnames'

import { capitalizeWords } from '../../../utils/formatters'

function SubjectItem({ info: { label, data }, index, maxIndex }) {
  return (
    <div
      className={cn(
        index + 1 !== maxIndex && 'border-b-2 border-gray-400 pb-2'
      )}
    >
      <h4
        className="text-gray-900"
        style={{
          fontWeight: 'normal'
        }}
      >
        {label}
      </h4>
      <p
        className={cn(
          'font-bold',
          data === 'sim' && 'text-green-600',
          (data === 'não' || data === 'unknown') && 'text-warning-dark',
          data === '-' && 'text-gray-900'
        )}
      >
        {data !== 'unknown' ? capitalizeWords(data) : 'Não informado'}
      </p>
    </div>
  )
}

export default function SubjectDisplay({ subjects }) {
  const isSingleColumn = subjects.length === 1

  const columnStyle = isSingleColumn
    ? 'flex flex-col'
    : 'grid grid-cols-2 gap-4'

  return (
    <div className={columnStyle}>
      {subjects.map((item, index) => (
        <div className="space-y-4" key={index}>
          {item.map((info, index) => (
            <SubjectItem
              info={info}
              index={index}
              maxIndex={item.length}
              key={info.label}
            />
          ))}
        </div>
      ))}
    </div>
  )
}
