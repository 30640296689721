/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { ReactComponent as Menu } from '../../assets/icons/menu.svg'
import { ReactComponent as Close } from '../../assets/icons/close.svg'
import { ReactComponent as DiagonalArrow } from '../../assets/icons/arrow-top-right-thick.svg'
import { ReactComponent as Search } from '../../assets/icons/magnify.svg'
import { ReactComponent as Calendar } from '../../assets/icons/calendar-today.svg'
import { ReactComponent as Clock } from '../../assets/icons/clock-time-four-outline.svg'
import { ReactComponent as ProfileCircle } from '../../assets/icons/account-circle-outline.svg'
import { ReactComponent as Plus } from '../../assets/icons/plus.svg'
import { ReactComponent as Save } from '../../assets/icons/content-save.svg'
import { ReactComponent as Check } from '../../assets/icons/check.svg'
import { ReactComponent as Octagon } from '../../assets/icons/octagon-outline.svg'
import { ReactComponent as AlertWithOctagon } from '../../assets/icons/alert-octagon-outline.svg'
import { ReactComponent as Trash } from '../../assets/icons/trash-can-outline.svg'
import { ReactComponent as Printer } from '../../assets/icons/printer.svg'
import { ReactComponent as CheckedIcon } from '../../assets/icons/frame_.svg'
import { ReactComponent as OpenQuiz } from '../../assets/icons/openquiz.svg'
import { ReactComponent as arrowTop } from '../../assets/icons/arrow-top.svg'
import { ReactComponent as arrowDown } from '../../assets/icons/arrow-down.svg'

const iconName = {
  menu: Menu,
  close: Close,
  diagonalArrow: DiagonalArrow,
  search: Search,
  calendar: Calendar,
  clock: Clock,
  profileCircle: ProfileCircle,
  plus: Plus,
  save: Save,
  check: Check,
  octagon: Octagon,
  alertWithOctagon: AlertWithOctagon,
  trash: Trash,
  printer: Printer,
  checked: CheckedIcon,
  openquiz: OpenQuiz,
  arrowTop: arrowTop,
  arrowDown: arrowDown
}

const Icon = ({ name, className, onClick, style, ...rest }) => {
  if (!iconName[name])
    throw new Error(`The icon with name ${name} could not be found!`)

  const IconComponent = iconName[name]

  return (
    <IconComponent
      className={className}
      onClick={onClick}
      style={style}
      {...rest}
    />
  )
}

export default Icon
