import useMedicalRecord from 'hooks/useMedicalRecord'
import NavLink from '../../elements/NavLink'
import { useLocation } from 'react-router-dom'
import { APP_URLS } from 'urls'
import {
  preserveAppointmentIdParam,
  preservePatientIdParam
} from 'utils/helpers'
import { useLastLocation } from 'hooks/useLastLocation'
import { useCallback, useEffect, useState } from 'react'

export default function ServiceNav() {
  const { patient } = useMedicalRecord()
  const { lastLocation } = useLastLocation()

  const { pathname } = useLocation()

  const [lastPepLocation, setLastPepLocation] = useState(null)

  const basePepRoute = patient
    ? preservePatientIdParam(APP_URLS.VIRTUAL_CLINIC_PATIENT)
    : preserveAppointmentIdParam(APP_URLS.VIRTUAL_CLINIC_ROOM)

  useEffect(() => {
    if (lastLocation && lastLocation.includes('prontuario'))
      setLastPepLocation(lastLocation)
  }, [lastLocation])

  const previousPepRoute = useCallback(() => {
    if (!lastLocation) {
      return basePepRoute
    }
    if (pathname === basePepRoute) {
      return basePepRoute
    }
    if (pathname.includes('prontuario')) {
      return basePepRoute
    }
    if (
      lastLocation.includes('karmen') ||
      lastLocation.includes('versatilis')
    ) {
      if (lastPepLocation) return lastPepLocation
      return basePepRoute
    }

    return lastLocation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLocation, pathname])

  return (
    <div className="btns-change-medical-record max-w-screen-md mx-auto">
      <NavLink
        activeStyle={{
          backgroundColor: '#D20E50',
          color: 'white',
          border: 'none'
        }}
        className="text-gray-900"
        to={previousPepRoute}
        isActive={elm =>
          pathname.includes('prontuario') || (elm && elm.url.includes(pathname))
        }
        exact
      >
        P.E.P
      </NavLink>
      <NavLink
        activeStyle={{
          backgroundColor: '#D20E50',
          color: 'white',
          border: 'none'
        }}
        className="text-gray-900"
        to={
          patient
            ? preservePatientIdParam(APP_URLS.VIRTUAL_CLINIC_PATIENT_KARMEN)
            : preserveAppointmentIdParam(APP_URLS.VIRTUAL_CLINIC_ROOM_KARMEN)
        }
        exact={false}
      >
        Karmen
      </NavLink>
      <NavLink
        activeStyle={{
          backgroundColor: '#D20E50',
          color: 'white',
          border: 'none'
        }}
        className="text-gray-900"
        to={
          patient
            ? preservePatientIdParam(APP_URLS.VIRTUAL_CLINIC_PATIENT_VERSATILIS)
            : preserveAppointmentIdParam(
                APP_URLS.VIRTUAL_CLINIC_ROOM_VERSATILIS
              )
        }
        exact={false}
      >
        Versatilis
      </NavLink>
    </div>
  )
}
