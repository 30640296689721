import { ReactComponent as ArrowDown } from 'assets/svg/arrow-down2.svg'

export const ImcChart = ({ colors, imc }) => {
  return (
    <div className="w-full flex flex-col">
      {colors && (
        <div className="border-outline-karmen border-12 h--48 flex w-full">
          {colors.map(({ color, label }, index) => {
            return (
              <div className="w-1/5 flex justify-end flex-col">
                {color === imc.color && (
                  <div
                    style={{
                      color: imc.color,
                      minHeight: 'calc(100% - 12px)'
                    }}
                    className="flex flex-row items-center justify-center"
                  >
                    <ArrowDown height="16px" className="fill-current" />
                    <p className="ml-2 text-sm text-black text-color-m-emphas font-lato inline-block">
                      O imc é{' '}
                      <p
                        style={{ color: imc.color }}
                        className="font-bold inline-block text-lg"
                      >
                        {imc.value}
                      </p>
                    </p>
                  </div>
                )}

                <div
                  style={{
                    backgroundColor: color
                  }}
                  className={`flex h-2  ${index === 0 && 'border-lb-12'} ${
                    index === colors.length - 1 && 'border-rb-12'
                  }`}
                />
              </div>
            )
          })}
        </div>
      )}

      {colors && (
        <div className="flex w-full">
          {colors.map(({ color, label }, index) => {
            return (
              <p
                className={`mt-2 w-full text-center font-bold text-sm font-lato ${
                  color === imc.color
                    ? 'text-color-h-emphase'
                    : 'text-color-m-emphase'
                } text-color-m-emphase`}
              >
                {label}
              </p>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ImcChart
