import cn from 'classnames'

export default function SummaryHeader({
  createdBy,
  date,
  title,
  className,
  isFirst,
  isLast
}) {
  return (
    <div
      className={cn(
        'p-3 bg-teal-600 text-gray-100 space-y-1',
        isFirst && 'rounded-tl-md',
        isLast && 'rounded-bl-md',
        className
      )}
      style={{ maxWidth: '160px', minWidth: '160px' }}
    >
      <h1 className="leading-5">{createdBy}</h1>
      <p className="leading-5">{title}</p>
      <h2 className="leading-5">{date}</h2>
    </div>
  )
}
