import cn from 'classnames'

import Icon from '../../Icon'

interface SelectTypes {
  gray: string
}

export default function SelectComponent(
  prop: {
    className?: string
    color?: string
    name?: string
    defaultValue?: string
    options: string[]
    placeholder?: string
    classNameSelect?: string
    value?: string
    onChange?: () => any
  },
  ...rest: any[]
) {
  const selectTypes: SelectTypes = {
    gray: 'bg-gray-400 border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline'
  }

  return (
    <div className={`inline-block relative w-full ${prop.className}`}>
      <select
        className={cn(
          'block appearance-none w-full border px-4 py-2 pr-8 leading-tight ',
          prop.color ? (selectTypes.gray ? prop.color === 'gray' : '') : '',
          prop.classNameSelect
        )}
        name={prop.name}
        defaultValue={prop.defaultValue}
        placeholder={prop.placeholder}
        value={prop.value}
        onChange={prop.onChange}
        {...rest}
      >
        <option value="" disabled>
          Selecione...
        </option>
        {prop.options.map(item => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
      <div
        className="pointer-events-none absolute inset-y-0 right-0
                    flex items-center px-2 text-gray-700"
      >
        <Icon className="fill-current h-4 w-4" name="arrow-down" />
      </div>
    </div>
  )
}
