import cn from 'classnames'

import Text from 'components/ui/Text'
import Heading from 'components/ui/Heading'
import Icon from 'components/ui/Icon'
import MuiButton from 'components/ui/MuiButton'
import Link from 'components/ui/Link'

import { formatCPF, formatMobilePhone } from 'utils/formaters'

import { AdminUser } from 'types/AdminUsers'

const iconClasses = 'h-6'
const doneClasses = 'text-green-600'
const missingClasses = 'text-warning-dark'
const isPlanActive: any = (
  plan: { id: number; name: string; status: string } | null
) => plan && plan.status === 'active'

interface UserProfileLayoutProps {
  user: AdminUser
}

export default function UserProfileLayout({
  user: {
    name,
    cpf,
    mobile_phone,
    email,
    plan,
    subscription,
    answered_karmen,
    made_first_consultation_with_dedicated_nurse,
    made_first_consultation_with_dedicated_doctor,
    link_versatilis,
    link_vindi,
    id
  }
}: UserProfileLayoutProps) {
  const mobileNumber = mobile_phone ? formatMobilePhone(mobile_phone) : '-'
  const userCpf = formatCPF(cpf)

  return (
    <div className="flex justify-between my-6">
      <div className="flex space-x-4 ml-4">
        <div className="flex flex-col space-y-1">
          <Heading className="text-2xl font-semibold text-pink-600">
            {name}
          </Heading>
          <div className="flex items-center space-x-4">
            <Heading className="text-lg text-teal-600 font-semibold">
              Plano {plan ? (plan.name === 'FREE' ? 'Grátis' : 'Premium') : '-'}
            </Heading>
            <Heading
              as="h3"
              className={cn(
                'px-2 py-1 rounded-md text-gray-100 font-semibold tracking-widest text-sm',
                isPlanActive(plan) ? 'bg-green-600' : 'bg-warning-dark'
              )}
            >
              {isPlanActive(plan) ? 'ativo' : 'inativo'}
            </Heading>
            <div className="flex items-center space-x-2">
              <Icon
                name="karmen"
                className={cn(
                  iconClasses,
                  answered_karmen ? doneClasses : missingClasses
                )}
              />
              <Icon
                name="nurse"
                className={cn(
                  iconClasses,
                  made_first_consultation_with_dedicated_nurse
                    ? doneClasses
                    : missingClasses
                )}
              />
              <Icon
                name="dedicated-doctor"
                className={cn(
                  iconClasses,
                  made_first_consultation_with_dedicated_doctor
                    ? doneClasses
                    : missingClasses
                )}
              />
            </div>
          </div>
          <div className="text-base leading-snug">
            <Text className="text-pink-600 font-semibold">
              CPF:{' '}
              <Text as="span" className="text-gray-800 font-normal">
                {userCpf}
              </Text>
            </Text>
            <Text className="text-pink-600 font-semibold">
              Celular:{' '}
              <Text as="span" className="text-gray-800 font-normal">
                {mobileNumber}
              </Text>
            </Text>
            <Text className="text-pink-600 font-semibold">
              E-mail:{' '}
              <Text as="span" className="text-gray-800 font-normal">
                {email || '-'}
              </Text>
            </Text>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-4">
        <Text className="text-pink-600 font-semibold text-base">
          Data de adesão:{' '}
          <Text as="span" className="text-gray-800 font-normal">
            {subscription ? subscription.start_at.split(' ')[0] : '-'}
          </Text>
        </Text>
        {link_versatilis ? (
          <Link isExternal to={link_versatilis}>
            <MuiButton
              variant="contained"
              color="primary"
              style={{ padding: '8px 16px' }}
              fullWidth
            >
              Abrir no Versatilis
            </MuiButton>
          </Link>
        ) : (
          <MuiButton
            variant="contained"
            color="primary"
            style={{ padding: '8px 16px' }}
            fullWidth
            disabled
          >
            Não cadastrado no Versatilis
          </MuiButton>
        )}
        {link_vindi ? (
          <Link isExternal to={link_vindi}>
            <MuiButton
              variant="contained"
              color="primary"
              style={{ padding: '8px 16px' }}
              fullWidth
            >
              Abrir no Vindi
            </MuiButton>
          </Link>
        ) : (
          <MuiButton
            variant="contained"
            color="primary"
            style={{ padding: '8px 16px' }}
            fullWidth
            disabled
          >
            Não cadastrado no Vindi
          </MuiButton>
        )}
        <Link to={`/consultorio-virtual/paciente/${id}`}>
          <MuiButton
            variant="contained"
            color="primary"
            style={{ padding: '8px 16px' }}
            fullWidth
          >
            Abrir P.E.P
          </MuiButton>
        </Link>
      </div>
    </div>
  )
}
