import ReactSelect from 'react-select'

import SelectStyles, { SelectOutside } from '../utils/SelectStyles'

const Select = ({
  className,
  label,
  name,
  placeholder,
  id,
  value,
  isMulti,
  options,
  onChange,
  blurDiv,
  isClearable,
  backgroundColor,
  disabled = false
}) => {
  const changeBackground = () => {
    SelectStyles.control = styles => ({
      ...styles,
      backgroundColor,
      minHeight: '54px',
      border: 0,
      boxShadow: 'none',
      padding: '5px',
      '&:focus-within': {
        border: '1px solid #e05685',
        boxShadow: '0 0 1px 2.5px #f2b7cb'
      }
    })
    return SelectStyles
  }

  return (
    <SelectOutside onBlurCapture={blurDiv}>
      {label && (
        <label htmlFor={id} className="input-label">
          {label}
        </label>
      )}

      <ReactSelect
        options={options}
        isMulti={isMulti}
        className={`select ${className}`}
        styles={backgroundColor ? changeBackground() : SelectStyles}
        placeholder={placeholder}
        name={name}
        id={id}
        onChange={onChange}
        value={value}
        isClearable={isClearable}
        components={{
          NoOptionsMessage: () => 'Nenhuma opção encontrada',
          LoadingMessage: () => 'Carregando',
          IndicatorSeparator: () => null
        }}
        isDisabled={disabled}
      />
    </SelectOutside>
  )
}

export default Select
