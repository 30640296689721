import { SummarySection } from '../../elements'

const PartialEvolucaoSimples = ({
  info,
  isMultiple,
  indexOnData,
  maxIndex
}) => {
  return (
    <>
      {Object.keys(info).length !== 0 ? (
        <>
          <SummarySection
            className="summary-max-height"
            createdBy={info.created_by.nome}
            date={info.data_cadastro.split(' ')[1]}
            title="Evolução Versatilis"
            isMultiple={isMultiple}
            indexOnData={indexOnData}
            maxIndex={maxIndex}
          >
            <h3>Data</h3>
            <p>{info.data_versatilis}</p>
            <h3>Observações</h3>
            <pre>{info.prontuario}</pre>
          </SummarySection>
        </>
      ) : null}
    </>
  )
}

export default PartialEvolucaoSimples
