import { useState } from 'react'
import { useQuery } from 'react-query'

import { Div } from 'styles/global'

import { getKarmen2Data, getKarmenData } from '../services/karmenService'

import useMedicalRecord from 'hooks/useMedicalRecord'
import ComponentKarmen1 from 'medicalRecord/pages/componentKarmen1'
import ComponentKarmen2 from 'medicalRecord/pages/componentKarmen2'

const UserKarmenInfos = () => {
  const { appointment, patient } = useMedicalRecord()

  const [dataFetched, setDataFetched] = useState(false)

  // TODO: Treat errors

  const {
    data: karmenData,
    isLoading: isLoadingKarmenData,
    isError: isErrorKarmenData
  } = useQuery(
    ['karmenData', patient ? patient.id : appointment.paciente.id],
    () => getKarmenData(patient ? patient.id : appointment.paciente.id),
    {
      onSuccess: () => {
        setDataFetched(true)
      }
    }
  )

  const { data: karmen2Data } = useQuery(
    ['karmen2Data', patient ? patient.cpf : appointment.paciente.cpf],
    () => getKarmen2Data(patient ? patient.cpf : appointment.paciente.cpf)
  )

  return (
    <div className="mt-4 w-full karmen-app">
      {karmen2Data && karmen2Data.sessionId && (
        <ComponentKarmen2 karmen2Data={karmen2Data} />
      )}

      {karmenData && karmenData.sessionId && !karmen2Data && (
        <ComponentKarmen1
          karmenData={karmenData}
          patient={patient}
          appointment={appointment}
          dataFetched={dataFetched}
          isLoadingKarmenData={isLoadingKarmenData}
          isErrorKarmenData={isErrorKarmenData}
        />
      )}

      {!karmen2Data && !karmenData && (
        <Div
          color="transparent"
          className="w-full flex items-center justify-center"
        >
          <h1>O paciente ainda não passou com a trilha da K.A.R.M.E.N</h1>
        </Div>
      )}
    </div>
  )
}

export default UserKarmenInfos
