import { backendServices } from 'services/AxiosInstances'
import { SPECIALTY_ENDOCRINE_ID } from '../utils/constants'

export const getKarmenData = patientId =>
  backendServices
    .get(`/api/v1/karmen-data?patient_id=${patientId}`)
    .then(({ data }) => data)
    .catch(error => {
      throw new Error(error)
    })

export const getKarmen2Data = cpf =>
  backendServices
    .post('/api/v1/karmen-data-2', { cpf })
    .then(({ data }) => data)
    .catch(error => {
      throw new Error(error)
    })

export const getKarmenSpecialty = async (patientId, specialtyId) => {
  let url

  switch (specialtyId) {
    case SPECIALTY_ENDOCRINE_ID:
      url = `/api/v1/karmen-endocrine-data?patient_id=${patientId}`
      break
    default:
  }

  if (url) {
    return await backendServices
      .get(url)
      .then(({ data }) => data)
      .catch(error => {
        throw new Error(error)
      })
  }

  return {}
}
