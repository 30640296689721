import { AnchorHTMLAttributes, ReactNode } from 'react'
import { Link as RouterDomLink } from 'react-router-dom'

interface ILink extends AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string
  isExternal?: boolean
  children: ReactNode
}

export default function Link({ to, isExternal, children, ...rest }: ILink) {
  return isExternal ? (
    <a href={to} target="_blank" rel="noreferrer" {...rest}>
      {children}
    </a>
  ) : (
    <RouterDomLink to={to} {...rest}>
      {children}
    </RouterDomLink>
  )
}
