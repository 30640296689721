/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  overflow-y: hidden;
  padding: 20px;
  margin-right: 4rem;

  border-radius: ${props =>
    props.width < 769 ? '30px 30px 0 0' : '0 50px 50px 0'};

  background: ${props =>
    props.index % 2 === 0
      ? props.theme.colors.secondary
      : props.theme.colors.primary};

  ${props =>
    props.width < 769
      ? ''
      : css`
          height: 100%;
          display: flex;
        `};

  transition: all 0.2s ease;

  @media (max-width: 769px) {
    max-width: 100%;
    min-height: calc(100vh - 130px);
  }
`

export const Title = styled.h1`
  font-weight: 500;
  font-size: 25px;
  color: ${props => props.theme.colors.gray6};

  ${props =>
    props.width < 769
      ? ''
      : css`
          font-size: 35px;
        `};
`

export const Diretionals = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.first &&
    css`
      visibility: hidden;
    `}

  ${props =>
    props.last &&
    css`
      visibility: hidden;
    `}

    svg {
    ${props =>
      props.flip &&
      css`
        transform: scale(-1, -1);
      `}

    cursor: pointer;

    zoom: 1.6;
    fill: ${props => props.theme.colors.white1};
  }
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas: '. .';

  min-height: calc(100vh - 205px);
`

export const LeftPainel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px;

  > div:first-child {
    max-width: 600px;
    max-height: 665px;
    width: 100%;
    height: 100%;

    border-radius: 30px;
    box-shadow: 2px 5px 10px 5px rgba(164, 164, 164, 0.2);

    > div:first-child {
      border-radius: 30px;
      padding: 30px 50px;
      height: 100%;
    }
  }
`

export const SkeletonContainer = styled.div``

export const ChartSkeleton = styled.div`
  margin-top: 1.5rem;

  height: 300px;

  @media (min-width: 1440px) {
    height: 400px;
  }
`

export const MenuItemSkeleton = styled.div`
  height: 170px;
  width: 190px;
`
