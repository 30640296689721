import { MedicalUnitResponse } from 'types/MedicalUnit'
import { backendServices } from './AxiosInstances'

type MedicalUnitsParams = {
  q?: string
  get_all?: 1 | 0
}

export const getMedicalUnits = async (params: MedicalUnitsParams) =>
  await backendServices
    .get<MedicalUnitResponse>('/api/v1/unit', {
      params
    })
    .then(({ data }) => data.data)
    .catch(error => {
      throw new Error(error)
    })
