import * as React from 'react'

import DocTemplate from '../oldAppCommons/components/DocTemplate'

class MedicalCertificate extends React.Component {
  render() {
    const { doctor, date, text } = this.props

    return (
      <DocTemplate doctor={doctor} date={date}>
        <p className="text-base">{text}</p>
      </DocTemplate>
    )
  }
}

export default MedicalCertificate
