import { ReactNode, useEffect } from 'react'

import { isProduction, ONESIGNAL_ID } from 'utils/constants'
import { getCurrentUser } from 'utils/helpers'

window.OneSignal = window.OneSignal || []
const OneSignal = window.OneSignal

const oneSignalPush = (callback: () => void) => OneSignal.push(callback)

const oneSignal = {
  init: () => {
    if (isProduction) {
      oneSignalPush(() =>
        OneSignal.init({
          appId: ONESIGNAL_ID,
          allowLocalhostAsSecureOrigin: true,
          welcomeNotification: {
            title: 'Bem-vindo (a) à Kompa Saúde!',
            message:
              'Você receberá notificações como essa para acompanhar melhor a sua agenda.'
          }
        })
      )
    }
  },
  identify: () => {
    if (isProduction) {
      const currentUser = getCurrentUser()

      if (currentUser) {
        oneSignalPush(() => OneSignal.setExternalUserId(String(currentUser.id)))
      }
    }
  },
  reset: () => {
    if (isProduction) {
      oneSignalPush(() => OneSignal.removeExternalUserId())
    }
  }
}

const OneSignalProvider = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    oneSignal.init()
  }, [])

  return <>{children}</>
}

export { OneSignalProvider }

export default oneSignal
