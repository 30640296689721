import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { APP_URLS } from '../../../urls'
import { Button } from '../../ui'

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  min-height: calc(100vh - var(--height-header-footer));

  color: ${({ theme }) => theme.colors.primaryPink1};

  padding: 0 100px 0;

  ${({ theme }) => theme.mixins.flex_height}

  h1 {
    max-width: 400px;
  }

  button {
    max-width: 170px;
    ${({ theme }) => theme.mixins.shadow_simple}
  }

  @media (max-width: 336px) {
    background: none;
  }

  @media (max-width: 425px) {
    padding: 0 40px 40px;

    min-height: calc(100vh - 180px);

    h1 {
      max-width: 290px;
    }
  }
`

const Welcome = ({ history }) => (
  <Container>
    <h1 className="text-4xl my-10 sm:text-5xl">
      Vamos nos conhecer melhor? Nos fale um pouco mais{' '}
      <span className="font-semibold text-pink-600">sobre você</span>
    </h1>
    <Button
      className="text-2xl rounded-lg px-4"
      onClick={() => history.push(`${APP_URLS.ONBOARDING_QUESTIONS}`)}
    >
      Continuar
    </Button>
  </Container>
)

const ThankYou = ({ history }) => (
  <Container>
    <h1 className="text-3xl my-10">
      <span className="text-5xl font-semibold text-pink-600 mb-10">
        Obrigado!
        <br />
        <br />
      </span>
      Vamos usar essas informações para personalizar o seu plano.
    </h1>
    <Button
      className="text-2xl rounded-lg px-4"
      onClick={() => {
        history.push(APP_URLS.HOME)
        window.location.reload()
      }}
    >
      Entendi
    </Button>
  </Container>
)

const Message = ({ welcome, thanks }) => {
  const history = useHistory()

  if (welcome) return <Welcome history={history} />
  if (thanks) return <ThankYou history={history} />
}

export default Message
