import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'

import Component from '../../components/Home/FirstSteps'
import { APP_URLS } from '../../urls'
import { APP_URLS as NEW_APP_URLS } from 'utils/urls'

import { useModalContext } from '../../contexts/ModalContext'
import { useNotification } from 'contexts/NotificationContext'
import useAuth from 'hooks/useAuth'

import messages from './messages'
import { NotificationPrompt } from '../../components/Notification'
import { checkUserKarmenData } from 'services/api'

const FirstSteps = () => {
  const { push_enabled, push_status } = useNotification()
  const { user } = useAuth()

  const [firstStepsStatus, setFirstStepsStatus] = useState({
    onboarding: null,
    karmen: null,
    push: null
  })

  const [showFirstSteps, setShowFirstSteps] = useState(false)

  const { data: karmenResponse } = useQuery('karmenData', checkUserKarmenData, {
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (karmenResponse) {
      const onboarding = user.gender ? true : false
      const push = push_status === 'granted' && push_enabled
      const karmen = karmenResponse.sessionId ? true : false

      setFirstStepsStatus({
        // @ts-ignore
        onboarding,
        // @ts-ignore
        karmen,
        // @ts-ignore
        push
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [karmenResponse, push_status, push_enabled])

  useEffect(() => {
    const { onboarding, karmen, push } = firstStepsStatus

    if (onboarding != null && karmen !== null && push !== null) {
      /*
          If any step is not finished
      */
      if (!onboarding || !karmen || !push) {
        const firstFalseEntry = Object.values(firstStepsStatus).findIndex(
          item => item !== 'finished' && item !== true
        )

        getIndex(messages[firstFalseEntry].name)
        setActive(messages[firstFalseEntry])
        checkDone(messages[firstFalseEntry].name)
        return setShowFirstSteps(true)
      }

      return setShowFirstSteps(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstStepsStatus])

  const { handleModal, setCloseButton } = useModalContext()

  // Active Item on screen
  const [active, setActive] = useState()
  const [index, setIndex] = useState(0)

  // Directionals
  const [first, setFirst] = useState(false)
  const [last, setLast] = useState(false)

  // General states
  const [percentage, setPercentage] = useState(0)
  const [stepDone, setStepDone] = useState(false)

  const history = useHistory()

  const checkDone = name => {
    switch (firstStepsStatus[name]) {
      case true:
        return setStepDone(true)
      case 'finished':
        return setStepDone(true)
      case 'installed':
        return setStepDone(true)
      default:
    }
  }

  const getIndex = name => {
    setIndex(messages.findIndex(item => item.name === name))
  }

  useEffect(() => {
    if (index === 0) {
      setPercentage(33)
      setLast(false)
      return setFirst(true)
    }

    if (index === 1) {
      setPercentage(66)
      setLast(false)
      return setFirst(false)
    }

    setPercentage(100)
    setFirst(false)
    setLast(true)
  }, [index])

  const getPrevious = () => {
    const newActive = messages[index - 1]
    if (!newActive) return
    setStepDone(false)
    getIndex(newActive.name)
    setActive(newActive)
    checkDone(newActive.name)
  }

  const getNext = () => {
    const newActive = messages[index + 1]
    if (!newActive) return
    setStepDone(false)
    getIndex(newActive.name)
    setActive(newActive)
    checkDone(newActive.name)
  }

  const handleOnboarding = () => history.push(APP_URLS.ONBOARDING)

  const handleKarmen = () => window.open(NEW_APP_URLS.CHAT, '_self')

  const handlePush = () => handleModal(<NotificationPrompt />)

  const handleAction = name => {
    switch (name) {
      case 'onboarding':
        return handleOnboarding()
      case 'karmen':
        return handleKarmen()
      case 'push': {
        setCloseButton(false)
        return handlePush()
      }
      default:
    }
  }

  return showFirstSteps ? (
    <Component
      item={index + 1}
      max={messages.length}
      active={active}
      first={first}
      last={last}
      getPrevious={getPrevious}
      getNext={getNext}
      percentage={percentage}
      buttonAction={handleAction}
      stepDone={stepDone}
    />
  ) : null
}

export default FirstSteps
