/* eslint-disable prefer-rest-params */
import { useCallback, useContext, useEffect } from 'react'
import Scrollbar from 'react-custom-scrollbars'
import { useModalContext } from '../../../contexts/ModalContext'

import { useNotification } from '../../../contexts/NotificationContext'
import { WindowContext } from '../../../contexts/WindowContext'
import { Button } from '../../ui'
import NotificationSwitch from '../NotificationSwitch/NotificationSwitch'

const calculateInnerHeight =
  window.innerHeight > 1000
    ? '100%'
    : window.innerHeight < 600
    ? window.innerHeight - 300
    : 400

const PushNotificationPrompt = () => {
  const {
    handlePush,
    push_enabled,
    push_can_enable,
    push_supported,
    push_status
  } = useNotification()

  const { handleModal, setCloseButton, setIsBottom, isOpen } = useModalContext()

  const handleSave = useCallback(() => {
    handleModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { width } = useContext(WindowContext)

  useEffect(() => {
    if (width <= 425) {
      setCloseButton(false)
      setIsBottom(true)
    } else {
      setCloseButton(false)
      setIsBottom(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, isOpen])

  return (
    <div className="bg-white rounded-md max-w-xl">
      <div className="bg-teal-600 text-center p-4 text-white rounded-t-md">
        <h1 className="text-xl font-medium">Notificações</h1>
        <p className="text-lg mt-2">
          Habilite as notificações e nós te enviaremos alertas sobre questões
          importantes para sua saúde
        </p>
      </div>
      <Scrollbar
        autoHeight
        autoHeightMin={calculateInnerHeight}
        autoHeightMax={calculateInnerHeight}
      >
        {!push_can_enable && push_status !== 'granted' && (
          <h2 className="text-lg text-red-700 font-medium mt-4 px-12 select-none text-center">
            Não é possivel enviar notificações para você pelo seu navegador. Por
            favor verifique as configurações do seu aparelho.
          </h2>
        )}
        <h2 className="text-lg text-gray-800 font-medium my-4 px-12 select-none">
          Tipos de notificação
        </h2>

        <div className="grid grid-cols-1 gap-4 mb-4 px-12">
          <NotificationSwitch
            name="Push"
            checked={push_enabled}
            onChange={() => {
              handlePush(!push_enabled)
            }}
            description="Notificações diretamente no seu navegador ou celular para te manter atualizado sobre consultas marcadas e eventos de sua agenda"
            notSupported={!push_supported}
            disabled={!push_can_enable && push_status !== 'granted'}
          />
        </div>
      </Scrollbar>
      <div className="flex">
        <Button
          className="w-full rounded-b-md rounded-t-none"
          onClick={handleSave}
        >
          Salvar
        </Button>
      </div>
    </div>
  )
}

export default PushNotificationPrompt
