import UsersTableHeader from 'components/elements/UsersPage/UsersTableHeader'
import UsersTableItem from 'components/elements/UsersPage/UsersTableItem'

import { AdminUsersIndex } from 'types/AdminUsers'

interface UsersTableLayoutProps {
  users: AdminUsersIndex[]
}

export default function UsersTableLayout({ users }: UsersTableLayoutProps) {
  return (
    <div className="mx-3 mb-3 mt-2 p-4 bg-gray-100 rounded-md assina-shadow-sm">
      <UsersTableHeader />
      <div className="space-y-2 mt-2">
        {users.map((user, index) => (
          <UsersTableItem user={user} key={index} />
        ))}
      </div>
    </div>
  )
}
